/** @format */

@import '../../components/Commons/Sass/components/colors';

.ph-laboratory-global-container {
	max-width: 1280px;
	padding: 0 10px;
	margin: 18px auto 0;
}

.ph-laboratory-main-container {
	box-shadow: $primaryBoxShadow;
	background-color: $primaryBackgroundColor;
	border-radius: 4px;
	margin-top: 25px;
	margin-bottom: -5px;
	margin-left: 10px;
	margin-right: 10px;
	padding: 20px;
}

.ph-laboratory-main-image-main-container {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.ph-laboratory-main-image-container {
	padding: 10px;
}

.ph-laboratory-main-image {
	width: 100%;
	max-width: 130px;
}

.ph-laboratory-description-main-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	text-align: left;
}

.ph-laboratory-description-title {
	font-size: 20px;
	padding: 0 5px;
	margin-bottom: 15px;
	font-weight: 600;
}

.ph-laboratory-description-info {
	font-size: 14px;
	color: $colorGrayText3;
	margin-bottom: 0;
	padding: 0 10px;
	text-align: justify;
}

@media (max-width: 1280px) {
	.ph-laboratory-main-container {
		margin: 10px 1px -5px 1px;
	}
}

@media (max-width: 1024px) {
	.ph-laboratory-main-container {
		margin: 20px 4px -5px 4px;
	}
}

@media (max-width: 768px) {
	.ph-laboratory-main-container {
		margin: 20px 4px -5px 4px;
	}

	.ph-laboratory-description-title {
		text-align: center;
	}
}

@media (max-width: 600px) {
	.ph-laboratory-global-container {
		padding: 0 5px;
		margin-bottom: 4px;
	}

	.ph-laboratory-main-container {
		padding: 10px 10px 15px 10px;
	}
}
