/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-product-detail-main-title-global-container {
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04), 0 1px 1px 0 rgba(0, 0, 0, 0.04),
		0 1px 3px 0 rgba(0, 0, 0, 0.12);
	padding: 15px 30px;
	border-radius: 4px;
	position: relative;
	margin: 7px;
}

.ph-product-detail-main-title-pharmacy-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-product-detail-main-title-service-image-container {
	padding: 2px;
}

.ph-product-detail-main-title-service-image {
	width: 100%;
	max-width: 90px;
}

.ph-product-detail-main-title-pharmacy-image-container {
	margin-right: 5px;
	margin-top: -3px;
}

.ph-product-detail-main-title-pharmacy-image {
	width: 100%;
	max-width: 18px;
}

.ph-product-detail-main-title-pharmacy-title {
	color: $colorGrayText2;
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 0;
}

.ph-product-detail-main-title-pharmacy-separator {
	margin: 0 5px;
	opacity: 0.5;
}

.ph-product-detail-main-title {
	font-size: 22px;
	font-weight: 900;
	color: $colorGrayText4;
	line-height: normal;
	margin: 3px 0;
	padding-right: 25px;
	text-transform: capitalize;
}

.ph-product-detail-main-title-service-title {
	font-size: 13px;
	font-weight: 500;
	font-style: italic;
	color: $colorGrayText2;
	line-height: normal;
	margin-top: 6px;
	padding: 0 5px;
}

.ph-product-detail-main-title-service-subtitle {
	font-size: 13px;
	font-weight: 500;
	color: $colorGrayText4;
	line-height: normal;
	margin-top: 8px;
	padding: 0 5px;
}

.ph-product-detail-main-title-service-link,
.ph-product-detail-main-title-service-link:hover {
	font-size: 13px;
	font-weight: 500;
	color: $primaryColor;
	line-height: normal;
	margin-top: 8px;
	padding: 0 5px;
	text-decoration: underline;
}

.ph-product-detail-main-title-laboratory-title {
	margin-bottom: 0;
	color: $colorGrayText2;
	font-size: 14px;
	font-weight: 500;
}

.ph-product-detail-main-title-internal-code-container {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 9px;
	right: 9px;
}

.ph-product-detail-main-title-internal-code-title {
	font-size: 10px;
	font-weight: 100;
	color: $colorGrayText3;
	margin-right: 4px;
	margin-bottom: 0;
}

.ph-product-detail-main-title-internal-code-description {
	font-size: 10px;
	font-weight: 900;
	color: $colorGrayText3;
	margin-bottom: 0;
}

@media (max-width: 1024px) {
	.ph-product-detail-main-title-global-container {
		padding: 15px;
		margin: 0 0 10px 0;
	}

	.ph-product-detail-main-title {
		font-size: 18px;
		padding-right: 0;
	}
}

@media (max-width: 575px) {
	.ph-product-detail-main-title-global-container {
		padding: 15px 15px 25px 15px;
	}

	.ph-product-detail-main-title-pharmacy-image {
		max-width: 16px;
	}

	.ph-product-detail-main-title-pharmacy-title {
		font-size: 16px;
	}

	.ph-product-detail-main-title {
		font-size: 15px;
	}

	.ph-product-detail-main-title-laboratory-title {
		font-size: 13px;
	}

	.ph-product-detail-main-title-internal-code-container {
		top: 5px;
		right: 5px;
	}
}
