.box-container {
	img {
		width: 97%;
		border-radius: 10px;
		height: 100%;
		margin: 4px;
	}
}


