/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-detail-simple-infotabs-container {
	max-height: 10.2rem;
	overflow: hidden;
	margin-top: 10px;
	background-color: $primaryBackgroundColor;
	transition: max-height 0.3s ease-out;
	border-radius: 5px;
}

.ph-detail-simple-infotabs-container .ant-tabs-tab {
	padding: 10px 172px 10px 10px;
	width: 100%;
	margin: 0;
}

.ph-detail-simple-infotabs-container .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: $primaryColor;
}

.ph-detail-simple-infotabs-container .ant-tabs-ink-bar {
	background: $primaryColor;
}

.ph-detail-simple-infotabs-container .ant-tabs-tab-btn {
	color: $colorGrayText3;
	font-weight: 500;
	padding: 0 1px;
}

.ph-detail-simple-infotabs-container .ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
	margin-bottom: 2px;
}

.ph-detail-simple-infotabs-container .ant-tabs-nav-wrap {
	background-color: $secondBackgroundColor;
}

@media (max-width: 991px) {
	.ph-detail-simple-infotabs-container {
		max-height: fit-content;
	}
}

@media (max-width: 26.62rem) {
	.ph-detail-simple-infotabs-container .ant-tabs-tab {
		padding: 10px 47px 10px 10px;
	}
}

@media screen and (max-width: 22.5rem) {
	.ph-detail-simple-infotabs-container .ant-tabs-tab {
		padding: 10px 25px 10px 10px;
	}
}

@media screen and (max-width: 20rem) {
	.ph-detail-simple-infotabs-container .ant-tabs-tab {
		padding: 10px 13px 10px 10px;
	}
}
