/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-detail-resend-email-container .ant-modal-content {
	border-radius: 8px;
}

.ph-detail-resend-email-container .ant-modal-content .ant-modal-close {
	top: 11px;
	right: 21px;
}

.ph-detail-resend-email-container .ant-modal-content .ant-modal-close-x {
	border-radius: 8px;
	border: 2px solid $fourthColor;
	border-radius: 100px;
	color: $fourthColor;
	font-weight: bold;
	width: 20px;
	height: 20px;
	font-size: 12px;
	line-height: 0;
	padding: 2px;
}

.ph-detail-resend-email-container .ant-modal-header {
	border-bottom: 1px solid $primaryColorBorder !important;
	padding: 10px 20px;
	border-radius: 8px 8px 0 0;
}

.ph-detail-resend-email-container .ant-modal-header .ant-modal-title {
	font-size: 17px;
	font-weight: 900;
}

.ph-detail-resend-email-container .ant-modal-content .ant-modal-body {
	padding: 16px;
	text-align: center;
}

.ph-detail-resend-email-description {
	font-weight: 400;
	font-size: 15px;
	margin-top: 10px;
	margin-bottom: 30px;
}

.ph-detail-resend-email-button {
	background: $primaryColor !important;
	color: $colorWhiteText !important;
	font-size: 16px;
	font-weight: 500;
	text-transform: capitalize;
	border-radius: 4px;
	min-width: auto;
	width: 100%;
	max-width: 125px;
	border-color: transparent;
	height: 38px;
	line-height: 10px;
	box-shadow: $primaryBoxShadow;
	margin-bottom: 10px;
}
