/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-order-repurchase-main-container {
	width: 400px;
	padding: 10px;
	border-radius: 4px;
	box-shadow: $secondaryBoxShadow;
}

.ph-order-repurchase-main-container .ant-notification-notice-message {
	margin: 0;
}

.ph-order-repurchase-main-container .ant-notification-notice-close {
	top: 11px;
	right: 18px;
}

.ph-order-repurchase-main-container .ant-notification-close-icon,
.ph-order-repurchase-main-container .ant-notification-notice-close-icon {
	border: 2px solid $fourthColor;
	border-radius: 100px;
	color: $fourthColor;
	font-weight: bold;
	width: 18px;
	height: 18px;
	font-size: 10px;
	line-height: 0;
	padding: 2px;
}

.ph-order-repurchase-main-title-container {
	border-bottom: 1px solid $primaryColorBorder;
	width: 95%;
	margin: auto;
	padding-bottom: 7px;
}

.ph-order-repurchase-main-title {
	color: $primaryColor;
	font-weight: 600;
	text-align: left;
	margin-top: 0;
	margin-bottom: 0;
	font-size: 13px;
}

.ph-order-repurchase-products-main-container {
	max-height: 156px;
	overflow-y: auto;
}

.ph-order-repurchase-description-main-container {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	border: 1px solid $primaryColorBorder;
	border-radius: 4px;
	padding: 0;
	margin: 6px 8px;
}

.ph-order-repurchase-description-main-container:last-child {
	margin-bottom: 0;
}

.ph-order-repurchase-image-main-container {
	text-align: center;
}

.ph-order-repurchase-image-container {
	margin-top: 10px;
	display: block;
	padding: 5px;
}

.ph-order-repurchase-image {
	width: 100%;
	max-width: 50px;
}

.ph-order-repurchase-title {
	font-size: 13px;
	padding-right: 0;
	font-weight: 900;
	color: $colorGrayText4;
	line-height: normal;
	margin-top: 0px;
	margin-left: 5px;
	margin-bottom: 2px;
	text-transform: capitalize;
	text-overflow: ellipsis;
	text-decoration: none;
	overflow: hidden;
	white-space: nowrap;
	max-width: 95%;
	width: 100%;
}

.ph-order-repurchase-pharmacy {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-order-repurchase-pharmacy-image-container {
	margin: 0 5px;
}

.ph-order-repurchase-pharmacy-image {
	width: 100%;
	max-width: 12px;
}

.ph-order-repurchase-pharmacy-title {
	margin-top: 3px;
	margin-bottom: 0;
	font-size: 11px;
	color: $colorGrayText4;
	font-weight: 500;
	font-style: italic;
}

.ph-order-repurchase-quantity-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 5px;
}

.ph-order-repurchase-quantity-title {
	color: $primaryColor;
	font-size: 12px;
	font-weight: 300;
	opacity: 0.7;
	margin-top: 0;
	margin-bottom: 0;
	line-height: 15px;
}

.ph-order-repurchase-quantity-description {
	font-weight: 900 !important;
	color: $primaryColor;
	font-size: 12px;
	font-weight: 300;
	margin-top: 0;
	margin-bottom: 0;
	margin-left: 3px;
	line-height: 15px;
}

.ph-order-repurchase-price-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 5px;
}

.ph-order-repurchase-price-title {
	font-size: 12px;
	line-height: 12px;
	text-align: left;
	opacity: 0.7;
	color: $colorGrayText4;
	font-weight: 500;
	margin-bottom: 3px;
}

.ph-order-repurchase-price-description {
	font-size: 12px;
	line-height: 12px;
	text-align: left;
	opacity: 0.7;
	color: $colorGrayText4;
	font-weight: 900;
	margin-bottom: 3px;
	margin-left: 5px;
}

.ph-order-repurchase-stock-notification {
	color: #d7141f;
	font-size: 11px;
	padding: 5px 10px;
	margin-bottom: 0;
	margin-top: 5px;
	font-style: italic;
}

.ph-order-repurchase-separator {
	box-shadow: none;
	margin-top: 10px;
	margin-bottom: 10px;
	height: 1px;
	background-color: $primaryColorBorder;
	border: none;
	width: 94%;
}

.ph-order-repurchase-total-container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 5px;
	padding: 0 5px;
}

.ph-order-repurchase-total-image-container {
	margin-right: 1px;
}

.ph-order-repurchase-total-image {
	width: 100%;
	max-width: 19px;
}

.ph-order-repurchase-total-title {
	font-size: 12px;
	color: $colorGrayText4;
	opacity: 1;
	font-weight: 500;
	margin-top: 4px;
	margin-bottom: 0;
}

.ph-order-repurchase-total-subtitle {
	color: $primaryColor;
	font-size: 12px;
	margin-top: 4px;
	margin-left: 2px;
	margin-bottom: 0;
	font-weight: 900;
}

.ph-order-repurchase-total-description {
	font-weight: 900;
	font-size: 12px;
	color: $colorGrayText4;
	margin-top: 4px;
	margin-left: 4px;
	margin-bottom: 0;
}

.ph-order-repurchase-total-button-main-container {
	text-align: right;
}

.ph-order-repurchase-total-button-container {
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: $secondaryBoxShadow;
	border-radius: 4px;
	width: 100%;
	max-width: 180px;
}

.ph-order-repurchase-total-button-1 {
	background-color: $primaryColor !important;
	color: #fff !important;
	margin: 0 auto;
	margin-top: 0;
	font-weight: 900;
	height: 36px;
	width: 100%;
	max-width: 40px;
	box-shadow: none !important;
	border: none;
	border-radius: 4px 0 0 4px;
	margin-right: 1px;
	font-size: 19px;
	padding: 0 10px;
}

.ph-order-repurchase-total-button-2 {
	background-color: $primaryColor !important;
	color: $colorWhiteText !important;
	margin: 0 auto;
	margin-top: 0;
	font-weight: 900;
	height: 36px;
	width: 100%;
	max-width: 140px;
	box-shadow: none !important;
	border: none;
	border-radius: 0 4px 4px 0;
	font-size: 12px;
	padding: 0 10px;
}
