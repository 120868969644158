@import "../../../../../../../../../../../../components/Commons/Sass/components/colors";

.ph-product-detail-add-button,
.ph-product-detail-add-button:hover,
.ph-product-detail-add-button:active,
.ph-product-detail-add-button:focus {
  background-color: $secondColor;
  border-color: $secondColor;
  color: rgb(255, 255, 255);
  margin: 0;
  margin-right: 0px;
  font-size: 15px;
  border-radius: 4px;
  font-weight: 600;
  text-transform: uppercase;
  width: 100%;
  height: 36px;
  max-width: 130px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.12),
    0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}

.ph-product-detail-alternative-add-button {
  background-color: rgb(255, 255, 255);
  color: $primaryColor;
  margin: 0;
  padding: 4px 7px;
  font-size: 13px;
  border-radius: 4px;
  font-weight: 600;
  text-transform: uppercase;
  width: 100%;
  height: 36px;
  max-width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.12),
    0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border: 2px solid $primaryColor !important;
}

@media (max-width: 768px) {

  ph-product-detail-add-button,
  .ph-product-detail-add-button:hover,
  .ph-product-detail-add-button:active,
  .ph-product-detail-add-button:focus {
    max-width: 130px;
  }
}

@media (max-width: 575px) {
  .ph-product-detail-alternative-add-button {
    padding: 0 10px;
    line-height: 25px;
    font-size: 11px;
  }
}