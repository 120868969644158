/** @format */

@import '../../../Commons/Sass/components/colors';

.ph-elastic-right-side-paging-info {
	margin-top: 10px;
	font-size: 12px;
	width: 100%;
}

.ph-elastic-right-side-paging-info-title {
	color: $colorGrayText2;
}

.ph-elastic-right-side-paging-info-subtitle {
	color: $primaryColor;
}