/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-map-withdrawal-routes-marker-pharol {
	color: $primaryColor;
	font-weight: 700;
	text-align: center;
}

.ph-map-withdrawal-pharmacy-routes-container .leaflet-container {
	width: 100%;
	height: 300px;
	z-index: 1;
	margin: auto;
}
