/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-not-found-products-main-container {
	padding: 10px;
	margin-bottom: 40px;
	min-height: calc(100vh - 189px);
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-not-found-products-container {
	padding: 5px;
	position: relative;
}

.ph-not-found-products-inner-container {
	text-align: center;
}

.ph-not-found-products-image-container {
	padding: 10px;
}

.ph-not-found-products-image {
	width: 100%;
	max-width: 120px;
	opacity: 0.4;
}

.ph-not-found-products-title {
	color: $colorGrayText4;
	font-weight: 900;
	font-size: 25px;
	opacity: 0.5;
	margin-bottom: 0;
}

.ph-not-found-products-subtitle {
	color: $colorGrayText4;
	font-weight: 500;
	font-size: 15px;
	opacity: 0.5;
	max-width: 600px;
}

.ph-not-found-products-link {
	color: $primaryColor;
	font-weight: 500;
	font-size: 17px;
	opacity: 0.5;
	text-decoration: underline;
}

.ph-not-found-products-button {
	background-color: $secondColor;
	border-color: $secondColor;
	color: $colorWhiteText;
	margin: 5px 0 8px;
	font-size: 15px;
	border-radius: 4px;
	font-weight: 600;
	text-transform: uppercase;
	width: 100%;
	height: 35px;
	max-width: 175px;
	box-shadow: $secondaryBoxShadow;
}

.ph-not-found-products-button:hover,
.ph-not-found-products-button:focus,
.ph-not-found-products-button:active {
	background-color: $secondColor;
	border-color: $secondColor;
	color: $colorWhiteText;
}

@media (max-width: 600px) {
	.ph-not-found-products-subtitle {
		font-size: 15px;
	}
}
