/** @format */

@import '../../components/Commons/Sass/components/colors';

.ph-rapigo-global-container {
	max-width: 1185px;
	padding: 10px;
	margin: auto;
	box-sizing: border-box;
}

.ph-rapigo-header-container {
	position: relative;
}

.ph-rapigo-header-content {
	background: url('https://storage.googleapis.com/master-image-bucket/tinified-delivery/main-background.png');
	height: 450px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: left top;
	background-attachment: fixed;
}

.ph-rapigo-header-content:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	z-index: 0;
}

.ph-rapigo-header-inner-content {
	position: relative;
	z-index: 1;
	height: 100%;
	display: flex;
	align-items: center;
	height: 450px;
}

.ph-rapigo-header-main-content {
	padding: 40px;
	text-align: center;
}

.ph-rapigo-header-image-container {
	width: 100%;
	max-width: 450px;
}

.ph-rapigo-global-global-title {
	padding: 30px;
	margin-bottom: 10px;
	text-align: center;
}

.ph-rapigo-global-title-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-rapigo-global-title-text {
	color: $primaryColor;
	font-weight: 900;
	font-size: 35px;
	margin-bottom: 0;
}

.ph-rapigo-global-subtitle-text {
	color: $colorGrayText4;
	font-weight: 500;
	font-size: 20px;
	max-width: 700px;
	margin: auto;
}

.ph-rapigo-personal-date-container {
	width: 100%;
	border-radius: 4px;
	box-shadow: $primaryBoxShadow;
	padding: 10px;
	background: $primaryBackgroundColor;
	z-index: 1;
}

.ph-rapigo-trasnport-container {
	display: flex;
	height: 115px;
	vertical-align: middle;
	justify-content: space-between;
}

.ph-rapigo-telefhone-container,
.ph-rapigo-form-prevision-container {
	align-items: center;
}

.ph-rapigo-trasnport-dates-container-p {
	margin-bottom: 5px;
	margin-top: 28px;
	color: $colorGrayText4;
	font-weight: 500;
	font-size: 15px;
}

.ph-rapigo-type-input {
	width: 90%;
}

.ph-rapigo-profession-date {
	width: 99%;
	margin-top: -34px;
}

.ph-rapigo-form-name-container {
	width: 100%;
	max-width: 240px;
	min-width: 240px;
	padding: 10px;
	height: 55px;
}

.ph-rapigo-personal-date-content- {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: auto;
}

.ph-rapigo-personal-date-input {
	width: 95%;
}

.ph-rapigo-direction-input {
	width: 98.5%;
}

.ph-rapigo-ofice-input,
.ph-rapigo-type-profesion-input {
	width: 97%;
}

.ph-rapigo-personal-date-inner {
	margin: 0 5px;
}

.ph-rapigo-span-texts {
	padding-right: 8px;
	padding-left: 8px;
	font-size: 20px;
	color: $colorBackText;
}

.ph-rapigo-profession-p,
.ph-rapigo-profession-date-p {
	color: $colorGrayText4;
	font-weight: 500;
	font-size: 15px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.ph-rapigo-text-radio {
	color: $colorGrayText4;
	font-size: 12px !important;
}

.ph-rapigo-form-title {
	color: $primaryColor;
	font-weight: 900;
	font-size: 20px;
	padding: 10px;
	margin-bottom: 15px;
}

.ph-rapigo-form-subtitle {
	color: $colorGrayText4;
	font-weight: 500;
	font-size: 15px;
	margin: 5px 15px;
}

.ph-rapigo-form-name {
	color: $colorGrayText4;
	font-weight: 500;
	font-size: 15px;
	margin: 5px 5px;
}

.ph-rapigo-date-profesion-text {
	color: $colorGrayText4;
	font-weight: 500;
	font-size: 15px;
	margin-top: 10px;
}

.ph-rapigo-date-profesion-btn-container,
.ph-rapigo-global-container .ant-radio-group {
	min-width: 200px;
	width: 100%;
	height: auto;
	margin: 10px;
}

.ph-rapigo-personal-date-container Input {
	min-height: 45px;
	border-radius: 6px;
	border: 1px solid $primaryColorBorder !important;
	padding: 0 10px;
}

.ph-rapigo-email-phone-container-2 Input {
	min-height: auto;
	border-radius: 6px;
	border: none !important;
	padding: 0 15px !important;
}

.ph-rapigo-personal-date-container-phone Input {
	min-height: auto;
	border-radius: 6px;
	border: 1px solid $primaryColorBorder !important;
	padding: 0 10px;
}

.ph-rapigo-date-direction-container {
	margin-bottom: 10px;
	width: 100%;
}

.ph-rapigo-form-container-trasnport {
	display: flex;
	justify-content: space-around;
	height: 100px;
	position: absolute;
}

.ph-rapigo-date-direction-texts {
	display: flex;
	width: 100%;
	padding: 5px;
}

.ph-rapigo-global-container .ant-checkbox + span {
	font-size: 20px;
	color: $colorBackText;
	vertical-align: middle;
}

.ph-rapigo-form-img-trasnport {
	max-width: 50px;
	right: -80px;
	top: 0;
	vertical-align: middle;
	position: absolute;
}

.ph-rapigo-form-img-trasnports {
	max-width: 40px;
	right: -80px;
	top: 0;
	vertical-align: middle;
	position: absolute;
}

.ph-rapigo-img-trasnports {
	max-width: 40px;
	right: -80px;
	top: -5px;
	vertical-align: middle;
	position: absolute;
}

.ph-rapigo-global-container .ant-form-item-explain.ant-form-item-explain-error {
	font-weight: 800;
	margin-top: 5px;
	margin-left: 5px;
}

.ph-rapigo-form-img-telefone {
	width: 100%;
	max-width: 30px;
	position: absolute;
	right: 28px;
}

.ph-rapigo-form-movil-container {
	width: 100%;
	max-width: 240px;
	border: 1px solid $primaryColorBorder;
	padding: 20px;
	border-radius: 4px;
	margin: 5px;
	font-size: 20px;
	color: $colorBackText;
}

.ph-rapigo-form-presision-content {
	width: 100%;
	max-width: 240px;
	padding: 10px;
	border-radius: 4px;
	margin: 5px;
}

.ph-rapigo-email-direction-container {
	display: flex;
	justify-content: space-between;
}

.ph-rapigo-phone-input,
.ph-rapigo-email-input {
	width: 98%;
}

.ph-rapigo-terms-container,
.ph-rapigo-btn-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 30px 0;
}

.ph-rapigo-terms-container {
	font-size: 16px;
	text-align: center;
}

.ph-rapigo-btn,
.ph-rapigo-btn:focus,
.ph-rapigo-btn:hover,
.ph-rapigo-btn:active {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $primaryColor !important;
	color: $colorWhiteText;
	font-size: 14px;
	border-radius: 4px;
	font-weight: 900;
	padding: 5px 27px !important;
	height: 40px;
	border: $primaryColor;
	min-width: 155px;
	box-shadow: $primaryBoxShadow;
	cursor: pointer;
}

.ph-rapigo-link {
	color: $primaryColor;
	text-decoration: underline;
	font-weight: 400;
	padding: 0 5px;
}

.ph-rapigo-terms-text {
	color: $colorBackText;
	font-size: 15px;
	font-weight: lighter;
	margin-bottom: 0;
}

.ph-rapigo-dowlon-container {
	background-color: $primaryBackgroundColor;
	z-index: 1;
	position: relative;
	margin-top: 30px;
}

.ph-rapigo-dowlon-img {
	position: absolute;
	bottom: -15px;
	right: 30px;
	width: 100%;
	max-width: 350px;
}

.ph-rapigo-dowlon-img-two {
	position: absolute;
	bottom: -15px;
	left: 30px;
	width: 100%;
	max-width: 350px;
}

.ph-rapigo-dowlon-content {
	text-align: center;
	margin: 10px 0;
}

.ph-rapigo-dowlon-span {
	color: $primaryColor;
	font-size: 20px;
	font-style: italic;
}

.ph-rapigo-dowlon-title {
	color: $colorGrayText4;
	line-height: 40px;
	margin-bottom: 20px;
	font-size: 45px;
	font-weight: bold;
}

.ph-rapigo-dowlon-paraghraf {
	color: $colorGrayText2;
	font-size: 15px;
}

.ph-rapigo-dowlon-content-two {
	display: flex;
	align-items: center;
	justify-content: center;
}

.ph-rapigo-dowlon-googleplay {
	max-width: 150px;
	margin: 0 15px;
}

.ph-rapigo-form-main-container .ant-form-item-explain.ant-form-item-explain-error,
.ph-rapigo-form-main-container .ant-form-item-explain.ant-form-item-explain-success {
	font-size: 13px;
	font-weight: 300;
}

.ph-retirement-personal-data-agree-container .ant-checkbox-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}

@media (max-width: 1024px) {
	.ph-rapigo-header-inner-content {
		padding-top: 90px;
		padding-bottom: 90px;
		height: auto;
	}
	.ph-rapigo-header-content {
		height: auto;
	}
}

@media (max-width: 992px) {
	.ph-rapigo-type-input {
		width: 93%;
	}
}
@media (max-width: 768px) {
	.ph-rapigo-header-content {
		padding-top: 0;
		padding-bottom: 0;
	}
	.ph-rapigo-header-inner-content {
		padding-top: 0px;
		padding-bottom: 0px;
		height: auto;
	}
	.ph-rapigo-header-image-container {
		max-width: 400px;
	}
	.ph-rapigo-global-title-text {
		font-size: 35px;
	}
	.ph-rapigo-global-subtitle-text {
		font-size: 20px;
	}
	.ph-rapigo-global-global-title {
		padding: 20px 30px 0px 20px;
	}
	.ph-rapigo-dowlon-img {
		bottom: -60px;
		right: 2px;
		max-width: 286px;
	}
	.ph-rapigo-dowlon-img-two {
		bottom: -60px;
		left: 2px;
		max-width: 286px;
	}
	.ph-rapigo-personal-date-input {
		width: 95%;
	}
	.ph-rapigo-type-input {
		width: 90%;
	}
	.ph-rapigo-profession-date {
		margin-top: 3px;
	}
	.ph-rapigo-phone-input,
	.ph-rapigo-email-input {
		width: 99%;
	}
	.ph-rapigo-form-img-trasnport {
		right: -58px;
	}
	.ph-rapigo-form-img-trasnports {
		right: -58px;
		top: -3px;
	}
	.ph-rapigo-img-trasnports {
		right: -58px;
		top: -10px;
	}
}

@media (max-width: 667px) {
	.ph-rapigo-header-content {
		background-attachment: scroll;
		background: url('https://storage.googleapis.com/master-image-bucket/tinified-delivery/main-background-mobile.png');
		background-size: cover;
		background-repeat: no-repeat;
	}
	.ph-rapigo-global-title-text {
		font-size: 25px;
		margin-bottom: 15px;
	}
	.ph-rapigo-global-subtitle-text {
		font-size: 16px;
		margin-bottom: 10px;
	}
	.ph-rapigo-global-global-title {
		padding: 20px 15px 0px 15px;
	}
	.ph-rapigo-dowlon-img {
		max-width: 204px;
		right: 1px;
		bottom: -50px;
	}
	.ph-rapigo-dowlon-img-two {
		max-width: 204px;
		left: 1px;
		bottom: -50px;
	}
	.ph-rapigo-global-container {
		padding: 0;
	}
	.ph-rapigo-personal-date-container {
		margin: auto;
		width: 100%;
		max-width: 400px;
	}
	.ph-rapigo-personal-date-inner {
		margin: 0;
	}
	.ph-rapigo-telefhone-container {
		justify-content: center;
	}
	.ph-rapigo-profession-p {
		color: $colorGrayText4;
		font-weight: 500;
		font-size: 11px;
		margin-top: 10px;
	}
	.ph-rapigo-terms-text {
		font-size: 15px;
	}
	.ph-rapigo-personal-date-input {
		width: 100%;
	}
	.ph-rapigo-profession-p {
		margin-bottom: 2px;
	}
	.ph-rapigo-profession-date {
		margin-top: 0px;
	}
	.ph-rapigo-form-title {
		margin-bottom: 5px;
	}
	.ph-rapigo-trasnport-container {
		height: 210px;
		margin-top: 14px;
	}
	.ph-rapigo-transport-global-container {
		height: 40px;
	}
}

@media (max-width: 480px) {
	.ph-rapigo-global-title-text {
		font-size: 22px;
		margin-bottom: 15px;
	}
	.ph-rapigo-global-subtitle-text {
		font-size: 15px;
		margin-bottom: 0;
	}
	.ph-rapigo-global-container {
		width: 100%;
		padding: 25px;
		margin: auto;
		box-sizing: border-box;
	}
	.ph-rapigo-dowlon-paraghraf {
		font-size: 12px;
	}
	.ph-rapigo-dowlon-img-two {
		left: -24px;
		bottom: -75px;
		max-width: 140px;
	}
	.ph-rapigo-dowlon-img {
		right: -24px;
		bottom: -75px;
		max-width: 140px;
	}
	.ph-rapigo-terms-text {
		font-size: 15px;
		margin-top: 5px;
		width: 90%;
	}
	.ph-rapigo-type-input,
	.ph-rapigo-direction-input,
	.ph-rapigo-phone-input {
		width: 97%;
	}
}

@media (max-width: 375px) {
	.ph-rapigo-form-img-trasnport,
	.ph-rapigo-form-img-trasnports {
		right: -40px;
	}
	.ph-rapigo-img-trasnports {
		right: -36px;
	}
}
