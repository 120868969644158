/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-bag-payment-upload-recipe-button-container {
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: $secondaryBoxShadow;
	border-radius: 4px;
	width: 100%;
	max-width: 250px;
	margin: 15px auto 0;
}

.ph-bag-payment-upload-recipe-button-1,
.ph-bag-payment-upload-recipe-button-1:hover,
.ph-bag-payment-upload-recipe-button-1:focus,
.ph-bag-payment-upload-recipe-button-1:active {
	background-color: $primaryColor;
	color: $colorWhiteText;
	margin: 0 auto;
	margin-top: 0;
	font-weight: 500;
	height: 36px;
	width: 100%;
	box-shadow: none !important;
	border: none;
	border-radius: 4px 0 0 4px;
	margin-right: 1px;
	font-size: 20px;
	padding: 0 10px;
	max-width: 50px;
}

.ph-bag-payment-upload-recipe-button-2,
.ph-bag-payment-upload-recipe-button-2:hover,
.ph-bag-payment-upload-recipe-button-2:focus,
.ph-bag-payment--upload-recipebutton-2:active {
	background-color: $primaryColor;
	color: $colorWhiteText;
	margin: 0 auto;
	margin-top: 0;
	font-weight: 500;
	height: 36px;
	width: 100%;
	box-shadow: none !important;
	border: none;
	border-radius: 0 4px 4px 0;
	font-size: 15px;
	padding: 0 35px;
	letter-spacing: 0.8px;
}

.ph-bag-payment-button-container {
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: $secondaryBoxShadow;
	border-radius: 4px;
	width: 100%;
	max-width: 250px;
	margin: 15px auto 0;
}

.ph-bag-payment-button-1,
.ph-bag-payment-button-1:hover,
.ph-bag-payment-button-1:focus,
.ph-bag-payment-button-1:active {
	background-color: $secondColor;
	color: $colorWhiteText;
	margin: 0 auto;
	margin-top: 0;
	font-weight: 900;
	height: 36px;
	width: 100%;
	box-shadow: none !important;
	border: none;
	border-radius: 4px 0 0 4px;
	margin-right: 1px;
	font-size: 20px;
	padding: 0 10px;
	max-width: 50px;
}

.ph-bag-payment-button-2,
.ph-bag-payment-button-2:hover,
.ph-bag-payment-button-2:focus,
.ph-bag-payment-button-2:active {
	background-color: $secondColor;
	color: $colorWhiteText;
	margin: 0 auto;
	margin-top: 0;
	font-weight: 900;
	height: 36px;
	width: 100%;
	box-shadow: none !important;
	border: none;
	border-radius: 0 4px 4px 0;
	font-size: 15px;
	padding: 0 35px;
	letter-spacing: 1.2px;
}

.ph-bag-payment-modal .ant-modal-header {
	display: none;
}

.ph-bag-payment-modal .ant-modal-close {
	display: none;
}

.ph-bag-payment-modal .ant-modal-body {
	text-align: center;
}

.ph-bag-payment-modal .ant-modal-content {
	border-radius: 8px;
}

.ph-bag-payment-modal-title {
	margin-bottom: 20px;
	color: $primaryColor;
	font-weight: 600;
}

.ph-bag-payment-modal-image-container {
	padding: 0;
	margin: 0;
}

.ph-bag-payment-modal-image {
	position: absolute;
	width: 100%;
	max-width: 40%;
	top: 0;
	left: 0;
	right: 0;
	margin: auto;
	bottom: 0;
	border-radius: 100px;
	opacity: 0.1;
}

@media (max-width: 992px) {
	.ph-bag-payment-upload-recipe-button-container {
		margin: 0;
	}

	.ph-bag-payment-upload-recipe-button-container {
		max-width: 176px;
	}

	.ph-bag-payment-upload-recipe-button-2,
	.ph-bag-payment-upload-recipe-button-2:hover,
	.ph-bag-payment-upload-recipe-button-2:focus,
	.ph-bag-payment--upload-recipebutton-2:active {
		max-width: 140px;
		font-size: 13px;
		padding: 0px 15px;
	}

	.ph-bag-payment-upload-recipe-button-1,
	.ph-bag-payment-upload-recipe-button-1:hover,
	.ph-bag-payment-upload-recipe-button-1:focus,
	.ph-bag-payment--upload-recipebutton-1:active {
		max-width: 35px;
	}
}

@media (max-width: 992px) {
	.ph-bag-payment-button-container {
		margin-top: 0;
		max-width: 181px;
	}

	.ph-bag-payment-button-2,
	.ph-bag-payment-button-2:hover,
	.ph-bag-payment-button-2:focus,
	.ph-bag-payment-button-2:active {
		padding: 0 15px;
		max-width: 200px;
		max-width: 140px;
	}

	.ph-bag-payment-button-1,
	.ph-bag-payment-button-1:hover,
	.ph-bag-payment-button-1:focus,
	.ph-bag-payment-button-1:active {
		max-width: 40px;
	}
}
