/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

@mixin button-shared-styles($bgColor, $color, $padding, $fSize, $fWeight) {
	background-color: $bgColor;
	color: $color;
	//margin: 0;
	padding: $padding;
	font-size: $fSize;
	border-radius: 4px;
	font-weight: $fWeight;
	text-transform: uppercase;
	height: 35px;
	box-shadow: $mainHeaderboxShadow;
}

.ph-product-pharol-price-modal-container {
	& .ant-modal-body {
		padding: 13.75px;
	}

	& .ant-modal-footer {
		border-top: none;
		padding: 0;
	}
}

.ph-pharol-product-pharol-price-modal-benefit-to-cancel-approve {
	font-weight: bold;
	color: #669bfb;
}

.ph-product-pharol-price-modal-are-you-agree-container {
	margin-top: 11px;
	color: #555555;
	letter-spacing: -0.49px;
	font-size: 14px;
}

.ph-product-pharol-price-modal-buttons-container {
	display: flex;
	margin-top: 24.54px;

	& > :nth-child(1) {
		flex: 1;
		margin-right: 15px;
	}
	& > :nth-child(2) {
		flex: 1;
		margin-left: 15.13px;
	}
}

.ph-product-detail-quote-new-button {
	&,
	&:hover,
	&:active,
	&:focus {
		@include button-shared-styles($secondColor, $colorWhiteText, 4px 18px, 15px, 600);
		border-color: $secondColor;
	}
}

.ph-product-detail-alternative-new-button {
	&,
	&:hover,
	&:active,
	&:focus {
		@include button-shared-styles($primaryBackgroundColor, $primaryColor, 4px 7px, 13px, 500);
		border: 2px solid $primaryColor;
	}
}
