/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-product-detail-price-before-symbol {
	color: $colorGrayText3;
	font-weight: 500;
	font-size: 15px;
	text-decoration: line-through;
	margin-bottom: 0;
}

.ph-product-detail-price-before {
	color: $colorGrayText3;
	font-weight: 500;
	font-size: 18px;
	text-decoration: line-through;
	margin-bottom: 0;
}

.ph-product-detail-price-normal-symbol {
	font-size: 17px;
	color: $colorGrayText4;
	font-weight: 600;
	margin-bottom: 0;
}

.ph-product-detail-price-normal {
	font-size: 28px;
	color: $colorGrayText4;
	font-weight: 600;
	margin-bottom: 0;
}
