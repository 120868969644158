/** @format */

@import "../../../../../../components/Commons/Sass/components/colors";

.ph-profile-no-favorites-global-container {
  max-width: 1185px;
  padding: 10px;
  margin: auto;
}

.ph-profile-no-favorites-main-container {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
  box-shadow: $primaryBoxShadow;
  border-radius: 4px;
  padding: 10px;
}

.ph-profile-no-favorites-image-container {
  padding: 10px;
  display: block;
  margin-top: 20px;
}

.ph-profile-no-favorites-image {
  width: 100%;
  max-width: 150px;
}

.ph-profile-no-favorites-title {
  color: $colorGrayText4;
  font-weight: 900;
  font-size: 25px;
  opacity: 0.8;
  margin-bottom: 0;
}

.ph-profile-no-favorites-description {
  color: $colorGrayText4;
  font-weight: 500;
  font-size: 20px;
  opacity: 0.6;
}

.ph-profile-no-favorites-link,
.ph-profile-no-favorites-link:hover {
  color: $primaryColor;
  text-decoration: underline;
  font-size: 15px;
  font-weight: 500;
  font-style: italic;
  display: block;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .ph-profile-no-favorites-image-container {
    padding: 5px;
  }

  .ph-profile-no-favorites-image {
    max-width: 150px;
  }

  .ph-profile-no-favorites-title {
    font-size: 15px;
  }

  .ph-profile-no-favorites-description {
    font-size: 12px;
  }
}
