/** @format */

@import "../../../../../../components/Commons/Sass/components/colors";

.ph-detail-product-address-info-notification-main-container {
  width: 340px;
  padding: 10px;
  border-radius: 4px;
  box-shadow: $secondaryBoxShadow;
}

.ph-detail-product-address-info-notification-main-container
  .ant-notification-notice-message {
  margin: 0;
}

.ph-detail-product-address-info-notification-main-container
  .ant-notification-notice-close {
  top: 11px;
  right: 18px;
}

.ph-detail-product-address-info-notification-main-container
  .ant-notification-close-icon,
.ph-detail-product-address-info-notification-main-container
  .ant-notification-notice-close-icon {
  color: $colorGrayText1;
  font-weight: bold;
  width: 20px;
  height: 20px;
  font-size: 16px;
  line-height: 0;
  padding: 2px;
}

.ph-detail-product-address-info-notification-main-title-container {
  border-bottom: 1px solid $primaryColorBorder;
  width: 95%;
  margin: auto;
  padding-bottom: 7px;
}

.ph-detail-product-address-info-notification-main-title {
  color: $primaryColor;
  font-weight: 500;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
}

.ph-detail-product-address-info-notification-description-main-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.ph-detail-product-address-info-notification-image-main-container {
  text-align: center;
  margin-top: 13px;
}

.ph-detail-product-address-info-notification-image-container {
  display: block;
  padding: 1px;
}

.ph-detail-product-address-info-notification-image {
  width: 100%;
  max-width: 50px;
}

.ph-detail-product-address-info-notification-title {
  font-size: 15px;
  padding-right: 0;
  font-weight: 900;
  color: $colorGrayText4;
  line-height: normal;
  margin-top: 0px;
  margin-left: 5px;
  margin-bottom: 5px;
  text-transform: capitalize;
  text-overflow: ellipsis;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  max-width: 95%;
  width: 100%;
}

.ph-detail-product-address-info-notification-pharmacy {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ph-detail-product-address-info-notification-pharmacy-image-container {
  margin: 0 5px;
}

.ph-detail-product-address-info-notification-pharmacy-image {
  width: 100%;
  max-width: 15px;
}

.ph-detail-product-address-info-notification-pharmacy-title {
  margin-top: 3px;
  margin-bottom: 0;
  font-size: 12px;
  color: $colorGrayText4;
  font-weight: 500;
  font-style: italic;
}

.ph-detail-product-address-info-notification-quantity-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-style: italic;
}

.ph-detail-product-address-info-notification-quantity-title {
  color: $primaryColor;
  font-size: 14px;
  font-weight: 300;
  opacity: 0.7;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 20px;
}

.ph-detail-product-address-info-notification-quantity-description {
  font-weight: 900 !important;
  color: $primaryColor;
  font-size: 13px;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 3px;
  line-height: 20px;
  text-overflow: ellipsis;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
}

.ph-detail-product-address-info-notification-price-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 5px;
}

.ph-detail-product-address-info-notification-price-title {
  font-size: 13px;
  line-height: 17px;
  text-align: left;
  opacity: 0.7;
  color: $colorGrayText4;
  font-weight: 500;
  margin-bottom: 3px;
}

.ph-detail-product-address-info-notification-price-description {
  font-size: 12px;
  line-height: 12px;
  text-align: left;
  opacity: 0.7;
  color: $primaryColor;
  font-weight: 500;
  font-style: italic;
  margin-bottom: 3px;
  margin-left: 5px;
}

.ph-detail-product-address-info-notification-price-description-2 {
  font-size: 13px;
  line-height: 12px;
  text-align: left;
  opacity: 1;
  color: $secondColor;
  font-weight: 900;
  margin-bottom: 3px;
  margin-left: 5px;
}

.ph-detail-product-address-info-notification-separator {
  box-shadow: none;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 1px;
  background-color: $primaryColorBorder;
  border: none;
  width: 94%;
}

.ph-detail-product-address-info-notification-response-withdrawal-image-container {
  margin-left: 7px;
}

.ph-detail-product-address-info-notification-response-withdrawal-image {
  width: 100%;
  max-width: 30px;
}

.ph-detail-product-address-info-notification-loading-container
  .ant-spin-spinning
  .anticon-spin {
  font-size: 13px !important;
  color: $primaryColor;
}

.ph-detail-product-address-info-notification-total-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
  padding: 0 5px;
}

.ph-detail-product-address-info-notification-total-image-container {
  margin-right: 0;
}

.ph-detail-product-address-info-notification-total-image {
  width: 100%;
  max-width: 19px;
}

.ph-detail-product-address-info-notification-total-title {
  font-size: 13px;
  color: $colorGrayText4;
  opacity: 1;
  font-weight: 500;
  margin-top: 4px;
  margin-bottom: 0;
  margin-left: 5px;
  margin-right: 5px;
}

.ph-detail-product-address-info-notification-total-subtitle {
  color: $primaryColor !important;
  font-size: 13px;
  margin-top: 4px;
  margin-left: 2px;
  margin-bottom: 0;
  font-weight: 900;
  text-decoration: underline !important;
  cursor: pointer;
}

.ph-detail-product-address-info-notification-total-description {
  font-weight: 900;
  font-size: 12px;
  color: $colorGrayText4;
  margin-top: 4px;
  margin-left: 4px;
  margin-bottom: 0;
}

.ph-detail-product-address-info-notification-price-title-success {
  color: $successColor;
}
