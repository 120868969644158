/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-complaint-book-list-content {
	background-color: #fff !important;
	padding: 10px 15px !important;
	border-radius: 5px;
	margin-top: 15px;
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 4%), 0 1px 1px 0 rgb(0 0 0 / 4%),
		0 1px 3px 0 rgb(0 0 0 / 12%);
}

.ph-complaint-book-name {
	font-weight: 500;
}

.ph-complaint-book-date {
	color: rgb(0, 0, 0, 0.5);
}

.ph-complaint-book-oc {
	color: $primaryColor;
	font-weight: 600;
	margin-right: 5px;
}

.ph-complaint-book-type {
	font-weight: 700;
}

.ph-complaint-book-id-title {
	padding-right: 4px;
}

.ph-complaint-book-type-claim {
	color: $errorColor;
}

.ph-complaint-book-type-suggestion {
	color: $successColor;
}

.ph-complaint-book-subject {
	font-size: 15px;
	font-weight: 500;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
	padding-right: 8px;
}

.ph-complaint-book-message {
	font-size: 12px;
	color: rgb(0, 0, 0, 0.5);
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
	padding-right: 8px;
}

.ph-complaint-book-id-container {
	display: flex;
}
