.claim-container {
	padding-left: 10px;

	.claims-cards-container {
		padding-left: 0 !important;
	}

	.title-container {
		display: flex;
		flex-direction: column;
		width: 1165px;
		padding: 20px 0px 10px 0px;
		gap: 10px;

		h3 {
			margin-bottom: 0;
			font-size: 18px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px;
		}
	}
}



.claims-content {
	width: 100%;
	max-width: 1185px;
	margin: 10px auto;
	padding: 10px 0;
}