/** @format */

@import '../../../../components/Commons/Sass/components//colors';

.ph-product-detail-service-desktop-price-container {
	display: flex;
	justify-content: center;
	align-items: flex-end;
}

.ph-product-detail-service-desktop-price-title {
	font-size: 15px;
	color: $colorGrayText4;
	margin-right: 6px;
	margin-bottom: 5px;
	font-weight: 500;
}

.ph-product-detail-service-desktop-price-title-asterisk {
	color: $errorColor;
}

.ph-product-detail-service-desktop-price-subtitle {
	font-size: 22px;
	color: $colorGrayText4;
	font-weight: 600;
	margin-bottom: 0;
}

.ph-product-detail-service-desktop-price-loading-container {
	width: 100%;
	text-align: center;
	padding: 20px 5px;
}

.ph-product-detail-service-desktop-price-loading-container .anticon-loading {
	color: $primaryColor;
}

.ph-product-detail-cenabast-promotion-title {
	color: $primaryColor;
	font-weight: 600;
	font-size: 15px;
	margin-bottom: 5px;
	text-align: center;
}

.ph-product-detail-button-container {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.ph-product-detail-service-price-title {
	margin-bottom: 0;
	color: $colorGrayText4;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 2px;
}

.ph-product-detail-service-price-title-parent {
	color: $colorGrayText4;
	font-weight: 500;
}

.ph-product-detail-service-price-title-asterisk {
	color: $errorColor;
	font-weight: 500;
}

.ph-product-detail-service-price-subtitle {
	margin-bottom: 0;
	color: $colorGrayText3;
	font-weight: 500;
	font-size: 14px;
	line-height: 15px;
}

.ph-product-detail-service-price-asterisk {
	color: $errorColor;
}

.ph-product-detail-service-price-description {
	color: $colorGrayText2;
	font-weight: 500;
	font-size: 13px;
	text-align: center;
	width: 100%;
	max-width: 200px;
	margin: auto;
	margin-bottom: 8px;
	padding-top: 4px;
	border-top: 1px solid $primaryColorBorder;
}

.ph-product-detail-service-price-description-price {
	font-weight: 600;
}

.ph-product-detail-button,
.ph-product-detail-button:hover,
.ph-product-detail-button:active,
.ph-product-detail-button:focus {
	background-color: $secondColor;
	border-color: $secondColor;
	color: $colorWhiteText;
	margin: 0;
	font-size: 15px;
	border-radius: 4px;
	font-weight: 600;
	text-transform: uppercase;
	width: 100%;
	height: 36px;
	max-width: 160px;
	margin-right: 20px;
	box-shadow: $mainHeaderboxShadow;
}

/*
.ph-product-detail-button-alternative,
.ph-product-detail-button-alternative:hover,
.ph-product-detail-button-alternative:active,
.ph-product-detail-button-alternative:focus {
	background-color: $secondColor;
	border-color: $secondColor;
	color: $colorWhiteText;
	margin: 0;
	font-size: 15px;
	border-radius: 4px;
	font-weight: 600;
	text-transform: capitalize;
	width: 100%;
	height: 36px;
	max-width: 220px;
	box-shadow: $mainHeaderboxShadow;
}
*/

.ph-product-detail-quotation-button,
.ph-product-detail-quotation-button:hover,
.ph-product-detail-quotation-button:active,
.ph-product-detail-quotation-button:focus {
	background-color: $primaryColor;
	border-color: $primaryColor;
	color: $colorWhiteText;
	margin: 0;
	font-size: 15px;
	border-radius: 4px;
	font-weight: 600;
	text-transform: uppercase;
	width: 100%;
	height: 36px;
	max-width: 220px;
	box-shadow: $mainHeaderboxShadow;
	margin-top: 10px;
}

.ph-product-detail-quantity-global-container {
	position: absolute;
	left: 15%;
	top: -13px;
}

.ph-product-detail-quantity-main-container {
	max-width: 84px;
	margin: 0 0 0 auto;
}

.ph-product-detail-button-quantity-main-title {
	color: $colorGrayText4;
	font-size: 10px;
	font-weight: 100;
	margin-bottom: 0;
	text-align: center;
	padding: 0 5px;
}

.ph-product-detail-hashtag-container {
	box-shadow: $primaryBoxShadow;
	border-radius: 4px;
	padding: 13px 10px 10px 10px;
	margin: -15px 7px 7px 7px;
	position: relative;
	z-index: -1;
}

.ph-product-detail-hashtag-inner-container {
	margin-top: 12px;
	text-align: center;
}

.ph-product-detail-hashtag-title {
	font-size: 14px;
	color: $colorGrayText1;
	font-style: italic;
	margin-bottom: 0;
	display: inline;
	padding-right: 6px;
}

.ph-product-detail-mobile-service-loading {
	color: $primaryColor;
	font-size: 10px;
}

.ph-product-detail-mobile-service-loading .anticon-loading {
	font-size: 12px !important;
}

@media (max-width: 1024px) {
	.ph-product-detail-hashtag-container {
		margin: 0;
		padding: 5px;
	}

	.ph-product-detail-hashtag-inner-container {
		margin-top: 0;
	}
}

@media (max-width: 992px) {
	.ph-product-detail-button-container {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		background-color: $primaryBackgroundColor;
		z-index: 2;
		border-top: 1px solid $primaryColorBorder;
		padding: 10px;
		justify-content: flex-end;
	}

	.ph-product-detail-quantity-global-container {
		top: unset;
		left: 10px;
		bottom: 12px;
	}

	.ph-product-detail-button-quantity-main-title {
		display: none;
	}

	.ph-product-detail-service-price-description {
		margin-bottom: 0;
	}
}

@media (max-width: 768px) {
	.ph-product-detail-img-bolsa-not-stock-h4 {
		color: $colorGrayText2;
	}
	.ph-product-detail-img-bolsa-not-stock-content-pt5 {
		padding-top: 5px;
	}
	.ph-product-detail-img-bolsa-not-stock-content-pl5 {
		padding-left: 5px;
	}
	.ph-product-detail-button,
	.ph-product-detail-button:hover,
	.ph-product-detail-button:active,
	.ph-product-detail-button:focus {
		max-width: 130px;
	}
}
