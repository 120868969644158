/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-product-detail-promotion-description-main-title {
	text-align: center;
	font-size: 12px;
	color: $colorGrayText3;
	margin-bottom: 7px;
}

.ph-product-detail-promotion-description-main-asterisk {
	color: $errorColor;
	padding-right: 5px;
}

.ph-product-detail-promotion-description-title {
	color: $colorGrayText4;
	font-weight: 900;
	text-decoration: none;
	padding-left: 4px;
}

.ph-product-detail-promotion-description-title-gp {
	color: $errorColor;
	font-weight: 900;
	text-decoration: none;
	padding-left: 4px;
}
