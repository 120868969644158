/** @format */

@import '../../../../../../../../../../components/Commons/Sass/components/colors';

.ph-electronic-pharmacy-product-list-loader-container-image {
	color: $primaryColor;
}

.ph-electronic-pharmacy-product-list-loader-container {
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
}
