@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,500');
@import '../../_config/colors/global.scss';

.own-landing-web {
	background-color: $background;

	// .ant-menu-light .ant-menu-item:hover,
	// .ant-menu-light .ant-menu-item-active,
	// .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
	// .ant-menu-light .ant-menu-submenu-active,
	// .ant-menu-light .ant-menu-submenu-title:hover {
	// 	color: $color2 !important;
	// }

	.ant-select-arrow {
		display: flex !important;
		align-items: center !important;
		width: fit-content !important;
	}

	.own-body {
		padding: 8px 30px 0;
		margin: auto;
		width: 100%;
		max-width: 1185px;
		justify-content: center;

		.own-title {
			display: flex;
			width: 1165px;
			padding: 20px 10px 10px 10px;
			flex-direction: column;
			align-items: flex-start;
			gap: 20px;

			h2 {
				color: $disabled-dark;
				font-family: Ubuntu;
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px;
			}
		}

		.own-filter {
			width: 100%;
			display: flex;
			flex-direction: row;
			margin-bottom: 20px;

			.ant-select {
				margin: 10px 5px;
			}

			.ant-select-selection-item {
				color: $disabled-dark !important;
			}

			.own-see {
				span {
					padding: 10px;
					color: $disabled-dark;
					font-family: Ubuntu;
					font-size: 13px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px;
					margin-bottom: 10px;
				}
			}

			h5 {
				color: $disabled-dark;
				font-family: Ubuntu;
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
				padding-left: 10px;
				margin-bottom: 0;

				span {
					color: $color2;
				}
			}
		}

		.own-landing-menu {
			margin-top: 6px;
			border-radius: 5px;
			box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
			margin-right: 2.5px;

			.ant-menu-inline,
			.ant-menu-vertical,
			.ant-menu-vertical-left {
				border: none;
			}

			.ant-menu-item {
				display: flex;
				width: 224px;
				height: 36px;
				flex-direction: column;
				justify-content: center;
				padding: 6px 10px;
				margin: 0 !important;
				width: auto;

				span {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			.ant-menu-item-active:hover,
			.ant-menu-item-selected:hover {
				border-left: none;
				background-color: $greyAntd;
			}

			.ant-menu-title-content {
				color: $colorGrayText4;
				font-family: Ubuntu;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
			}
			.ant-menu-item-active,
			.ant-menu-item-selected {
				border-left: 2px solid $color2;
				background-color: $greyAntd;

				.ant-menu-title-content {
					color: $color2;
					font-size: 14px;
					line-height: normal;
				}
			}
		}

		.own-pagination {
			padding-top: 20px;
			padding-bottom: 60px;
			flex-direction: column;
			align-items: center;

			h5 {
				color: $disabled-dark;
				margin-top: 20px;
				font-family: Ubuntu;
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;

				span {
					color: $color2;
				}
			}
			.ant-pagination-item-active {
				border-color: $color2;
				a {
					color: $color2;
				}
			}
			.ant-pagination-item:hover {
				border-color: $color2 !important;
				a {
					color: $color2 !important;
				}
			}
			.ant-pagination-next:hover .ant-pagination-item-link,
			.ant-pagination-prev:hover .ant-pagination-item-link {
				color: $color2 !important;
				border-color: $color2 !important;
			}
		}
	}
}

.own-landing-mobile {
	padding: 8px 10px 0;
	margin: auto;
	width: 100%;
	max-width: 1185px;
	justify-content: center;
	background-color: $colorGrayText0;

	.own-title {
		display: flex;
		width: 1165px;
		padding: 20px 10px 10px 10px;
		flex-direction: column;
		align-items: flex-start;
		gap: 20px;

		h2 {
			color: $disabled-dark;
			font-family: Ubuntu;
			font-size: 18px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
		}
	}

	.own-filter {
		width: 100%;
		display: flex;
		flex-direction: row;
		margin-bottom: 20px;

		.own-select {
			width: 100%;
			height: 36px;
			margin: 10px 5px;
			border-radius: 4px;
			border: 1.3px solid $primaryColor;
			background: $color3;

			&.ant-select-disabled {
				& .ant-select-arrow svg {
					color: $disabled;
				}
			}

			& .ant-select-arrow svg {
				color: $disabled-darker;
				transition: transform 0.3s ease;
			}

			&.ant-select-open .ant-select-arrow svg {
				transform: rotate(-180deg);
				transform-origin: center;
			}

			& .ant-select-selection-placeholder {
				font-family: 'Ubuntu', sans-serif;
				color: $disabled;
				font-size: 14px;
				color: $disabled !important;
				padding-inline-start: 8px !important;
				padding-inline-end: 32px !important;
			}

			& .ant-select-selection-item {
				font-family: 'Ubuntu', sans-serif;
				font-size: 14px;
				line-height: 16px;
				color: $disabled !important;
				padding-inline-start: 8px !important;
				padding-inline-end: 32px !important;
			}

			&.ant-select-open {
				border-color: $color2 !important;
			}

			& .ant-select-selector {
				height: auto !important;
				padding: 0 !important;
			}

			&:hover {
				border-color: $color2 !important;
			}

			&:focus {
				border-color: $color2 !important;
				box-shadow: none;
			}

			&:disabled {
				background-color: $background;
				color: $disabled-dark;
				border: 1px solid $background;

				&:hover {
					border: 1px solid $background;
				}
			}
		}

		.ant-select-arrow {
			margin-top: -9px !important;
			right: 15px !important;
		}

		.ant-select-single .ant-select-selector .ant-select-selection-item,
		.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
			line-height: 33px;
		}

		h5 {
			color: $disabled-dark;
			font-family: Ubuntu;
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px;
			padding-left: 10px;
			margin-bottom: 0;

			span {
				color: $color2;
			}
		}
	}

	.own-card-product {
		width: 100%;
	}

	.own-pagination {
		padding-top: 20px;
		padding-bottom: 60px;
		flex-direction: column;
		align-items: center;

		h5 {
			color: $disabled-dark;
			margin-top: 20px;
			font-family: Ubuntu;
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px;

			span {
				color: $color2;
			}
		}
		.ant-pagination-item-active {
			border-color: $color2;
			a {
				color: $color2;
			}
		}
		.ant-pagination-item:hover {
			border-color: $color2 !important;
			a {
				color: $color2 !important;
			}
		}
		.ant-pagination-next:hover .ant-pagination-item-link,
		.ant-pagination-prev:hover .ant-pagination-item-link {
			color: $color2 !important;
			border-color: $color2 !important;
		}
	}
}

@media (max-width: 768px) {
	.own-landing-container {
		padding: 0 5px;
	}
}
