/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-profile-recipes-pagination-container {
	margin-top: 30px;
	text-align: center;
}

.ph-profile-recipes-pagination-container .ant-pagination-item {
	color: $primaryColor;
	border: 1px solid $primaryColorBorder;
}

.ph-profile-recipes-pagination-container .ant-pagination-item-active {
	background: $primaryColor;
	border: none !important;
	border-radius: 4px !important;
}

.ph-profile-recipes-pagination-container .ant-pagination-item a {
	color: $primaryColor;
}

.ph-profile-recipes-pagination-container .ant-pagination-item-active a {
	color: $colorWhiteText;
}

.ph-profile-recipes-pagination-container .ant-pagination-prev .ant-pagination-item-link,
.ph-profile-recipes-pagination-container .ant-pagination-next .ant-pagination-item-link {
	color: $primaryColor;
	border: 1px solid $primaryColorBorder;
	border-radius: 4px;
}

.ph-profile-recipes-pagination-container .ant-pagination-disabled .ant-pagination-item-link,
.ph-profile-recipes-pagination-container .ant-pagination-disabled:hover .ant-pagination-item-link,
.ph-profile-recipes-pagination-container .ant-pagination-disabled:focus .ant-pagination-item-link {
	color: $colorGrayText0;
	border: 1px solid $primaryColorBorder;
	border-radius: 4px;
}

.ph-profile-recipes-pagination-container .ant-pagination-total-text {
	position: absolute;
	left: 0;
	bottom: 0;
	font-size: 12px;
	color: $primaryColor;
}

@media (max-width: 575px) {
	.ph-profile-recipes-pagination-container .ant-pagination-total-text {
		bottom: -40px;
		right: 0;
		margin: auto;
	}
}
