/** @format */

@import '../../components/Commons/Sass/components/colors';

.ph-review-request-global-container {
	max-width: 1185px;
	padding: 10px;
	margin: auto;
}

.ph-review-request-main-container {
	margin-bottom: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: auto;
	min-height: calc(100vh - 163px);
}

.ph-review-request-container {
	text-align: center;
}

.ph-review-request-image-container {
	padding: 5px;
	display: block;
}

.ph-review-request-image {
	width: 100%;
	max-width: 150px;
}

.ph-review-request-main-title {
	color: $primaryColor;
	font-weight: 600;
	text-align: center;
	margin-bottom: 0;
}

.ph-review-request-main-subtitle {
	color: $colorGrayText3;
	font-weight: 600;
	text-align: center;
	margin-bottom: 0;
}

.ph-review-request-stars-container {
	text-align: center;
	margin-top: 20px;
	margin-bottom: 10px;
}

.ph-review-request-stars-filled {
	color: #fadb15;
	font-size: 35px;
	padding: 0 7px;
}

.ph-review-request-stars-no-filled {
	color: $thirdBackgroundColor;
	font-size: 35px;
	padding: 0 7px;
}

.ph-review-request-main-description {
	text-align: center;
	color: $colorGrayText1;
	font-style: italic;
	margin-bottom: 15px;
}

.ph-review-request-text-input-container {
	text-align: center;
}

.ph-review-request-text-input {
	border-radius: 6px !important;
	width: 100% !important;
	max-width: 350px !important;
}

.ph-review-request-success-button-container {
	text-align: center;
}

.ph-review-request-success-button,
.ph-review-request-success-button:hover,
.ph-review-request-success-button:active,
.ph-review-request-success-button:focus {
	border: none !important;
	background-color: $secondColor !important;
	border-radius: 4px;
	box-shadow: $primaryBoxShadow;
	color: $colorWhiteText !important;
	font-weight: 600;
	padding: 5px 25px !important;
	font-size: 16px;
	height: auto;
}

.ph-review-request-success-thanks-container {
	box-shadow: $primaryBoxShadow;
	border-radius: 4px;
	padding: 15px;
	margin-top: 15px;
	margin-bottom: 30px;
}

.ph-review-request-success-thanks-title {
	margin-bottom: 0;
	color: $primaryColor;
	font-size: 18px;
	font-weight: 600;
	text-align: center;
}

.ph-review-request-success-thanks-link-container {
	text-align: center;
}

.ph-review-request-success-thanks-link,
.ph-review-request-success-thanks-link:hover,
.ph-review-request-success-thanks-link:active,
.ph-review-request-success-thanks-link:focus {
	background-color: $secondColor;
	border-radius: 4px;
	box-shadow: $primaryBoxShadow;
	color: $colorWhiteText;
	font-weight: 600;
	padding: 10px 25px;
	font-size: 17px;
}

.ph-review-request-loading-container {
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-review-request-loading-icon {
	color: $primaryColor;
	margin-top: -120px;
}

.ph-review-request-loading-image {
	font-size: 30px;
}
