/** @format */

.ph-detail-product-subtotal-row {
	margin-top: 20px;
	//padding: 0 13px;
}

.ph-detail-product-quantity-text {
	margin-right: 10px;
	color: #2E2D36;
}

.ph-detail-productsubtotal-available-stocks-container {
	margin-left: 18px;
}

.ph-detail-productsubtotal-subtotal-price-container {
	margin-left: 30px;
	display: flex;
	justify-content: space-between;
	font-size: 18px;
	font-weight: 600;

	&>div {
		font-size: inherit;
		font-weight: inherit;
	}
}

.ph-detail-product-subtotal-text {
	font-size: 16px;
	color: #555555;
	letter-spacing: -0.54px;
	margin-right: 6px;

}

.ph-detail-productsubtotal-buttons-container {
	display: flex;
	justify-content: center;
	flex: 1;
	margin-top: 20px;
}

@media screen and (max-width: 576px) {
	.ph-detail-product-subtotal-row {
		margin-top: 10px;
		//padding: 0 13px;
	}

	.ph-detail-productsubtotal-available-stocks-container {
		margin-left: 14px;
	}

	.ph-detail-productsubtotal-subtotal-price-container {
		width: 100%;
		margin: 10px 0px 0px 0px;
		justify-content: space-between;
		align-items: center;
	}

	.ph-detail-productsubtotal-buttons-container {
		justify-content: center;
		width: 100%;
		margin-top: 20px;

		& button {
			//	margin: auto;
			height: 36px !important;
		}
	}
}