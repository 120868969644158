/** @format */

@import "../../../../../../components/Commons/Sass/components/colors";

.ph-bag-products-list-global-container {
  background-color: $primaryBackgroundColor;
  width: 100%;
  position: relative;
}

.ph-bag-products-list-main-title {
  font-weight: 400;
  font-size: 15px;
  color: $colorGrayText2;
  margin-bottom: 5px;
  padding: 0 10px;
}

.ph-bag-product-list-container {
  position: relative;
  border-radius: 4px;
  //padding: 15px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.20);
}

.ph-bag-product-list-container:last-child {
  margin-bottom: 0;
}

.ph-bag-product-list-image-main-container {
  position: relative;
  display: block;
}

.ph-bag-product-list-image-container {
  display: block;
  padding: 0 5px;
  text-align: center;
  margin-right: 10px;
}

.ph-bag-product-list-image {
  width: 100%;
  max-width: 105px;
}

.ph-bag-product-quantity-image {
  position: absolute;
  bottom: 5px;
  right: 45px;
  background-color: $primaryBackgroundColor;
  border-radius: 100px;
  border: 1px solid #555;
  padding: 3px 5px;
  line-height: 10px;
  font-size: 10px;

  display: none;
}

.ph-bag-product-type-one-container {
  display: flex;
  align-items: center;
  position: absolute;
  //bottom: -11px;
  bottom: 0;
  left: 40px;
}

.ph-bag-product-list-title{
  width: 100%;
  font-size: 14px;
  color: var(--Generic-DarkGrey-1, #555);
  font-weight: 600;
  //padding-left: 10px;
  overflow: hidden;
  white-space: nowrap;
  //max-width: 400px;
  border-top: 1px solid #eeeeee;
  padding: 10px 0 10px 0;
}

.ph-bag-product-list-detail-title {
  width: 100%;
  font-size: 14px;
  color: $colorGrayText4;
  font-weight: 600;
  text-transform: none;
  text-overflow: ellipsis;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  max-width: 400px;
  margin-bottom: 0;
}

.ph-bag-product-list-detail-laboratory-title {
  display: flex;
  font-size: 13px;
  font-weight: 100;
  color: $colorGrayText1;
  margin-top: 10px;
  margin-bottom: 1px;

}

.ph-bag-product-list-detail-on-demand-main-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ph-bag-product-list-detail-on-demand-image-container {
  margin-left: 3px;
}

.ph-bag-product-list-detail-on-demand-image {
  width: 100%;
  max-width: 18px;
}

.ph-bag-product-list-detail-on-demand-title {
  font-size: 13px;
  font-weight: 100;
  color: $primaryColor;
  margin-bottom: 1px;
}

.ph-bag-product-list-detail-stock-calculate-title {
  font-size: 13px;
  font-weight: 100;
  color: $errorColor;
  font-style: italic;
  margin-bottom: 1px;
}

.ph-bag-product-list-detail-stock-calculate-subtitle {
  font-weight: 900;
}

.ph-bag-product-list-detail-price-title-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  //font-style: italic;
}

.ph-bag-product-list-detail-price-title {
  font-size: 12px;
  font-weight: 100;
  color: $colorGrayText4;
  margin-bottom: 3px;
  //margin-right: 7px;
  text-align: end;
}

.ph-bag-product-list-detail-price-subtitle {
  font-size: 14px;
  font-weight: 600;
  color: $colorGrayText4;
  //margin-top: 20px;
  margin-left: 15px;
  display: inline-block;
  min-width: 65px;
}

.ph-bag-product-list-detail-price-subtitle-asterisk {
  color: $errorColor;
  margin-left: 5px;
}

.ph-bag-product-list-detail-price-promotion-asterisk {
  color: $errorColor;
  font-size: 10px;
}

.ph-bag-product-list-detail-promotion-subtitle {
  color: $colorGrayText1;
  font-size: 11px;
}

.ph-bag-product-list-detail-price-quantity {
  //color: $primaryColor;
  color: $colorGrayText4;
  font-weight: 400;
}
.ph-product-promotion-container .ph-product-promotion-icon-container{
  display: none !important;
}
.ph-product-promotion-container .ph-product-promotion-title {
  background-color: $mecanicColor;
  color: $color3;
  display: flex;
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: flex-end;
  height: 20px;
  align-items: center;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 2px;
}


.ph-bag-product-list-delete-button,
.ph-bag-product-list-delete-button:active,
.ph-bag-product-list-delete-button:focus {
  padding: 8px;
  margin: 0;
  width: auto;
  height: auto;
  border: none;
  box-shadow: none;
  //position: absolute;
  //bottom: 20px;
  //right: -7px;
  //line-height: 20px;
  color: $colorGrayText1;
}

.ph-bag-product-list-delete-button:hover {
  color: $colorGrayText4;
}

.ph-bag-product-button-add-delete-container {
  position: absolute;
  top: 0;
  right: 0;
}

.ph-bag-product-list-button-add-delete-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.3px solid $disabled;
  border-radius: 4px;
  padding: 0;
  //max-width: 70px;
  width: 90px;
  min-width: 83px;
  max-width: 130px;
  //margin: 0 0 0 auto;
  //background-color: $primaryBackgroundColor;
  //position: absolute;
  //top: 15px;
  //right: 0;
}

.ph-bag-product-list-button-add-quantity,
.ph-bag-product-list-button-add-quantity:hover,
.ph-bag-product-list-button-add-quantity:active,
.ph-bag-product-list-button-add-quantity:focus {
  border: none;
  box-shadow: none;
  border-radius: 7px;
  padding: 7px;
  //color: $primaryColor !important;
  color: $colorGrayText4 !important;
  font-size: 9px;
  font-weight: 900;
  width: 30px;
}

.ph-bag-product-list-button-quantity-input {
  border: none;
  border-left: 1.3px solid $disabled;/* Add left border */
  border-right: 1.3px solid $disabled;
  background-color: #fff !important;
  cursor: auto !important;
  color: $colorGrayText4 !important;
  text-align: center;

  padding: 5px 0;
  width: 30px;
}

.ph-bag-product-list-button-quantity-input::placeholder {
  color: $colorGrayText4 !important;
  opacity: 0.9;
}

.ph-bag-product-list-button-delete-quantity,
.ph-bag-product-list-button-delete-quantity:hover,
.ph-bag-product-list-button-delete-quantity:active,
.ph-bag-product-list-button-delete-quantity:focus {
  border: none;
  box-shadow: none;
  border-radius: 7px;
  padding: 7px;
  //color: $primaryColor !important;
  color: $colorGrayText4 !important;
  font-size: 9px;
  font-weight: 900;
  width: 30px;
}

.ph-cartproducts-price-container .ph-product-price-normal {
  //color: $primaryColor;
  //font-size: 16px;
  //font-style: normal;
  color: $colorGrayText4;
  margin:0;
  font-family: Ubuntu, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ph-cartproducts-price-container .ph-product-price-normal-symbol {
  color: $primaryColor;
  font-style: normal;
}

.ph-cartproducts-price-container .ph-product-price-promotion{
  color:  $colorGrayText4;
  text-align: center;
  font-family: Ubuntu, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media (max-width: 600px) {
  .ph-bag-product-list-container {
    padding: 10px;
  }

  .ph-bag-product-list-image-main-container {
    margin-top: 17px;
  }

  .ph-bag-product-quantity-image {
    left: 0;
    right: unset;
    bottom: 0;
    display: none;
  }

  .ph-bag-product-type-one-container {
    left: 0;
    //bottom: -20px;
  }

  .ph-bag-product-list-detail-price-title-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

  }

  .ph-bag-product-list-detail-title {
    width: 85%;
    text-overflow: unset;
    text-decoration: none;
    overflow: hidden;
    white-space: normal;
    max-width: none;
    margin-bottom: 0;
  }

  .ph-bag-product-list-delete-button,
  .ph-bag-product-list-delete-button:active,
  .ph-bag-product-list-delete-button:focus {
    bottom: 0;
  }

  .ph-bag-product-list-button-add-delete-container {
    top: -2px;
  }
}