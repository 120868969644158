/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-bag-modal-recipe-list-recipes-modal-button-container {
	text-align: center;
	padding-bottom: 10px;
}

.ph-bag-modal-recipe-list-recipes-modal-button-icon {
	font-weight: 600;
	font-size: 18px;
}

.ph-bag-modal-recipe-list-recipes-modal-button-title {
	position: relative;
	top: -2px;
}

.ph-bag-modal-recipe-list-recipes-modal-button-title-load {
	top: 0;
}

.ph-bag-modal-recipe-list-recipes-modal-button,
.ph-bag-modal-recipe-list-recipes-modal-button:hover,
.ph-bag-modal-recipe-list-recipes-modal-button:active,
.ph-bag-modal-recipe-list-recipes-modal-button:focus {
	line-height: 13px;
	font-size: 14px;
	padding: 0;
	border: none;
	color: $primaryColor;
	border-radius: 4px;
	border: 1px solid $primaryColor;
	box-shadow: $primaryBoxShadow;
	padding: 0 15px;
	letter-spacing: -0.3px;
}

.ph-bag-modal-recipe-list-recipes-modal-container .ant-modal-content {
	border-radius: 8px;
}

.ph-bag-modal-recipe-list-recipes-modal-container .ant-modal-content .ant-modal-close {
	top: 14px;
	right: 21px;
}

.ph-bag-modal-recipe-list-recipes-modal-container .ant-modal-content .ant-modal-close-x {
	border-radius: 8px;
	border: 2px solid $fourthColor;
	border-radius: 100px;
	color: $fourthColor;
	font-weight: bold;
	width: 20px;
	height: 20px;
	font-size: 12px;
	line-height: 0;
	padding: 2px;
}

.ph-bag-modal-recipe-list-recipes-modal-container .ant-modal-header {
	border-bottom: 1px solid $primaryColorBorder !important;
	padding: 12px 20px;
	border-radius: 8px 8px 0 0;
}

.ph-bag-modal-recipe-list-recipes-modal-container .ant-modal-header .ant-modal-title {
	font-size: 16px;
	font-weight: 900;
}

.ph-bag-modal-recipe-list-recipes-modal-title {
	font-style: italic;
	font-weight: 500;
	color: $primaryColor;
	opacity: 0.9;
	max-width: 550px;
	margin: auto;
	margin-bottom: 10px;
}

.ph-bag-modal-recipe-list-recipes-modal-container .ant-modal-content .ant-modal-body {
	padding: 10px;
	text-align: center;
}

.ph-bag-modal-recipe-list-recipes-modal-container .ant-modal-footer {
	border-top: none;
	padding: 0;
}

.ph-bag-modal-recipe-list-recipes-list-main-container {
	max-height: 250px;
	overflow-y: auto;
	padding: 2px;
}

.ph-bag-modal-recipe-list-recipes-list-container {
	box-shadow: $primaryBoxShadow;
	border-radius: 4px;
	margin-bottom: 10px;
	padding: 7px;
}

.ph-bag-modal-recipe-list-recipes-list-container:last-child {
	margin-bottom: 0;
}

.ph-bag-modal-recipe-list-recipes-list-title-container {
	text-align: left;
}

.ph-bag-modal-recipe-list-recipes-list-title {
	color: $colorGrayText3;
	margin-bottom: 0;
	margin-left: 10px;
	font-size: 12px;
}

.ph-bag-modal-recipe-list-recipes-list-subtitle {
	color: $primaryColor;
	margin-bottom: 0;
	margin-left: 10px;
	font-size: 13px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
	max-width: 90%;
}

.ph-bag-modal-recipe-list-recipes-list-button-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.ph-bag-modal-recipe-list-recipes-list-button,
.ph-bag-modal-recipe-list-recipes-list-button:active,
.ph-bag-modal-recipe-list-recipes-list-button:focus,
.ph-bag-modal-recipe-list-recipes-list-button:hover {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $primaryColor !important;
	color: $colorWhiteText;
	padding: 7px 5px;
	border-radius: 4px;
	font-weight: 500;
	box-shadow: $primaryBoxShadow;
	border: 2px solid $primaryColor;
	text-transform: capitalize;
	height: 28px;
}

.ph-bag-modal-recipe-list-recipes-list-button-second-title {
	margin-bottom: 0;
	color: $primaryColor;
	font-size: 12px;
}

.ph-bag-modal-recipe-list-recipes-list-second-button,
.ph-bag-modal-recipe-list-recipes-list-second-button:active,
.ph-bag-modal-recipe-list-recipes-list-second-button:focus,
.ph-bag-modal-recipe-list-recipes-list-second-button:hover {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fff !important;
	color: $primaryColor;
	padding: 7px 5px;
	border-radius: 4px;
	font-weight: 500;
	box-shadow: $primaryBoxShadow;
	border: 1px solid $primaryColor;
	text-transform: capitalize;
	height: 28px;
}

.ph-bag-modal-recipe-list-recipes-list-button-title {
	margin-bottom: 0;
	color: $colorWhiteText;
	font-size: 12px;
}

.ph-bag-modal-recipe-list-recipes-list-no-data-container {
	border: 1px solid $primaryColor;
	border-radius: 4px;
	opacity: 0.8;
	padding: 10px;
	min-height: 60px;
}

.ph-bag-modal-recipe-list-recipes-list-no-data-title {
	color: $colorGrayText3;
	font-weight: 600;
	font-style: italic;
	margin-top: 7px;
	margin-bottom: 0;
}

.ph-bag-modal-recipe-list-modal-pdf-container {
	text-align: center;
}

.ph-bag-modal-recipe-list-modal-pdf-icon {
	color: red;
	font-size: 100px;
	margin: 30px 0;
}
