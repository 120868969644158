/** @format */

@import '../../../Sass/components/colors';

.ph-product-promotion-title {
	font-weight: 500;
	font-size: 12px;
	color: $primaryColor;
	margin-top: 4px;
	margin-bottom: 0;
}

.ph-product-promotion-container {
	padding: 0 15px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-products-cart-promotion-padding {
	padding: 0 !important;
}

.ph-product-promotion-icon-container {
	margin-right: 4px;
}

.ph-product-promotion-icon-container-alternatives {
	margin-top: -5px;
}

.ph-product-promotion-icon {
	width: 13px;
}

.ph-product-list-promotion-title {
	font-size: 14px !important;
	font-weight: 900 !important;
}

.ph-product-list-promotion-title-alternatives {
	font-size: 12px !important;
	margin-top: -2px;
}

@media (max-width: 600px) {
	.ph-product-list-promotion-title {
		font-size: 12px !important;
		font-weight: 600 !important;
	}
}
