.google-image-container {
	position: relative;
	display: inline-block;
}

.main-container {
	height: 50vh;
	width: 100%;
}

