/** @format */

@import '../../../Sass/components/colors.scss';

.ph-banner-container {
	margin: 10px;
	border: none;
	box-shadow: $primaryBoxShadow;
	transition: 0.4s ease;
}

.ph-banner-container:hover {
	box-shadow: $secondaryBoxShadow;
	transition: 0.4s ease;
}

.ph-banner-container .ant-card-body {
	padding: 0;
}

.ph-banner-image {
	width: 100%;
}

@media (max-width: 600px) {
	.ph-banner-container {
		margin: 4px;
	}
}