/** @format */

@import '../Sass/components/colors';

.ph-edit-profile-field-input-mask {
	max-width: 100%;
	height: 45px;
	border-radius: 6px;
	font-size: 13px;
	color: $colorGrayText4;
	opacity: 0.8;
}

.ph-edit-profile-field-input-mask:hover,
.ph-edit-profile-field-input-mask:focus,
.ph-edit-profile-field-input-mask:active {
	border: 1px solid $primaryColor !important;
	box-shadow: none;
}
