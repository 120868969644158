/** @format */

.ph-pharolGo-politics-container {
	width: 100%;
	max-width: 1185px;
	padding: 8px 30px 0;
	margin: auto;
	text-align: justify;
}

.ph-pharolGo-politics-header {
	width: 100%;
	max-width: 1185px;
	height: auto;
	margin: 50px 0px 50px 0px;
	text-align: center;
}

.ph-pharolGo-politics-tittle {
	font-size: 24px;
	font-weight: bolder;
}

.ph-pharolGo-politics-paragraph {
	font-size: 16px;
	margin-bottom: 16px;
}

@media only screen and (max-width: 480px) {
	.ph-pharolGo-politics-header {
		margin: 20px 0px 20px 0px;
	}

	.ph-pharolGo-politics-tittle {
		font-size: 21px;
		margin: 5px;
	}

	.ph-pharolGo-politics-paragraph {
		font-size: 14px;
	}
}
