/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-local-by-date-select-pharmacy-list-global-container {
	padding: 10px;
	box-shadow: $primaryBoxShadow;
	margin: 4px;
	border-radius: 4px;
	margin-bottom: 15px;
}

.ph-local-by-date-select-pharmacy-list-main-container {
	border-radius: 6px;
	margin-bottom: 10px;
}

.ph-local-by-date-select-pharmacy-list-main-container:last-child {
	margin-bottom: 0;
}

.ph-local-by-date-select-pharmacy-title-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 9px;
}

.ph-local-by-date-select-pharmacy-image-container {
	padding: 2px;
	margin-right: 5px;
}

.ph-local-by-date-select-pharmacy-image {
	width: 100%;
	max-width: 22px;
}

.ph-local-by-date-select-pharmacy-main-title {
	margin-bottom: 0;
	color: $colorGrayText4;
	font-weight: 600;
}

.ph-local-by-date-select-pharmacy-local-selected-address-main-container {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	padding-left: 15px;
}

.ph-local-by-date-select-pharmacy-list-button-info-destiny-image-container {
	border: 1px solid $thirdColor;
	border-radius: 100%;
	padding: 0 2px;
	margin-top: 5px;
	margin-right: 10px;
}

.ph-local-by-date-select-pharmacy-list-button-info-destiny-image {
	width: 100%;
	max-width: 17px;
	min-width: 17px;
}

.ph-local-by-date-select-pharmacy-list-button-info-start-destiny-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-local-by-date-select-pharmacy-list-button-info-start-location-icon-title {
	color: $colorGrayText3;
	font-size: 11px;
	text-align: left;
	margin-bottom: 0;
	font-weight: 600;
}

.ph-local-by-date-select-pharmacy-list-button-info-road-location-icon {
	border: 2px solid $colorGrayText2;
	border-radius: 100%;
	width: 4px;
	display: block;
	height: 4px;
	margin-left: 2px;
	margin-top: 2px;
	margin-bottom: 2px;
	background-color: $colorGrayText2;
	opacity: 0.6;
}

.ph-local-by-date-select-pharmacy-list-button-info-destiny-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-local-by-date-select-pharmacy-list-button-info-start-location-icon {
	border: 2px solid $colorGrayText1;
	border-radius: 100%;
	width: 8px;
	min-width: 8px;
	display: block;
	height: 8px;
	min-height: 8px;
	margin-right: 4px;
}

.ph-local-by-date-select-pharmacy-list-button-info-start-location-icon-title {
	color: $colorGrayText3;
	font-size: 11px;
	text-align: left;
	margin-bottom: 0;
	font-weight: 600;
}

.ph-local-by-date-select-pharmacy-list-button-info-destiny-location-distance-title {
	font-size: 12px;
	color: $colorGrayText4;
	font-style: italic;
	margin-bottom: 0;
}

.ph-local-by-date-select-pharmacy-list-button-info-destiny-location-distance-subtitle {
	font-weight: 600;
}

.ph-local-by-date-select-pharmacy-list-delivery-info-title {
	color: $primaryColor;
	margin-top: 3px;
	margin-bottom: 3px;
	padding-bottom: 5px;
	font-style: italic;
	font-weight: 500;
	border-bottom: 1px solid $primaryColorBorder;
	width: 100%;
	max-width: 200px;
}

.ph-local-by-date-select-pharmacy-list-delivery-info-description-container {
	display: flex;
	align-items: center;
	width: 100%;
	max-width: 200px;
	position: relative;
}

.ph-local-by-date-select-pharmacy-list-delivery-info-2-description-title {
	color: $colorGrayText3;
	font-size: 11px;
	text-align: left;
	margin-bottom: 0;
	font-weight: 500;
	font-style: italic;
}

.ph-local-by-date-select-pharmacy-list-delivery-info-description-title {
	color: $colorGrayText4;
	font-size: 12px;
	text-align: left;
	margin-bottom: 0;
	font-weight: 500;
}

.ph-local-by-date-select-pharmacy-list-delivery-info-description-subtitle {
	color: $colorGrayText4;
	font-size: 14px;
	text-align: left;
	margin-bottom: 0;
	font-weight: 600;
}

.ph-local-by-date-select-pharmacy-list-delivery-info-description-no-price {
	margin-bottom: 0;
	color: $colorGrayText2;
	font-size: 12px;
	font-style: italic;
}

.ph-local-by-date-select-pharmacy-list-delivery-info-description-no-price-2 {
	margin-bottom: 0;
	color: $primaryColor;
	font-size: 12px;
	font-style: italic;
	opacity: 0.7;
}

.ph-local-by-date-select-pharmacy-list-delivery-info-description-subtitle-2 {
	color: $primaryColor;
	font-size: 14px;
	text-align: left;
	margin-bottom: 0;
	font-weight: 600;
}

.ph-local-by-date-select-pharmacy-list-delivery-info-2-description-subtitle-2 {
	color: $primaryColor;
	font-size: 13px;
	text-align: left;
	margin-bottom: 0;
	font-weight: 500;
	opacity: 0.8;
}

.ph-local-by-date-select-pharmacy-list-delivery-select-container {
	display: flex !important;
	justify-content: space-between !important;
	flex-direction: column !important;
	height: 100% !important;
}

.ph-local-by-date-select-pharmacy-list-button-info-destiny-location-icon-title {
	color: $colorGrayText3;
	font-size: 11px;
	text-align: left;
	margin-bottom: 0;
	font-weight: 500;
	text-decoration: underline;
}

.ph-local-by-date-select-pharmacy-list-preview-map-title {
	color: $primaryColor;
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 12px;
	font-weight: 600;
	text-decoration: underline;
	cursor: pointer;
}

.ph-local-by-date-select-pharmacy-list-products-row-container {
	text-align: right;
	width: 100%;
	justify-content: flex-end;
}

.ph-local-by-date-select-pharmacy-quote-message {
	color: $errorColor;
	font-style: italic;
	font-size: 12px;
	margin-top: 35px;
}

.ph-local-by-date-select-pharmacy-list-products-container {
	display: flex;
	justify-content: flex-start;
	width: 100%;
}

.ph-local-by-date-select-pharmacy-list-products-image-container {
	padding: 3px;
}

.ph-local-by-date-select-pharmacy-list-products-image {
	width: 100%;
	max-width: 50px;
}

.ph-local-by-date-select-pharmacy-list-delivery-info-description-icon {
	color: $errorColor;
	font-weight: 600;
	position: absolute;
	right: -19px;
	animation: shadow-pulse 2s infinite;
	border-radius: 100%;
	cursor: pointer;
}

.ph-checkout-local-by-date-info-modal-container .ant-modal-content {
	border-radius: 8px;
}

.ph-checkout-local-by-date-info-modal-container .ant-modal-content .ant-modal-close {
	top: 11px;
	right: 21px;
}

.ph-checkout-local-by-date-info-modal-container .ant-modal-content .ant-modal-close-x {
	border: 2px solid $secondColor;
	border-radius: 100px;
	color: $secondColor;
	font-weight: bold;
	width: 20px;
	height: 20px;
	font-size: 12px;
	line-height: 0;
	padding: 2px;
}

.ph-checkout-local-by-date-info-modal-container .ant-modal-header {
	border-bottom: 1px solid $primaryColorBorder !important;
	padding: 10px 20px;
	border-radius: 8px 8px 0 0;
}

.ph-checkout-local-by-date-info-modal-container .ant-modal-header .ant-modal-title {
	font-size: 14px;
	font-weight: 500;
	text-transform: capitalize;
}

.ph-checkout-local-by-date-info-modal-container .ant-modal-content .ant-modal-body {
	padding: 0 10px 10px 10px;
}

.ph-checkout-local-by-date-info-modal-container .ant-modal-footer {
	display: none;
}

.ph-checkout-local-by-date-info-delivery-main-container {
	width: 100%;
	text-align: center;
	padding: 5px;
}

.ph-checkout-local-by-date-info-delivery-image-container {
	padding: 3px;
}

.ph-checkout-local-by-date-info-delivery-image {
	width: 100%;
	max-width: 70px;
}

.ph-checkout-local-by-date-info-delivery-description {
	margin-bottom: 0;
	font-size: 13px;
	padding: 10px;
}

@keyframes shadow-pulse {
	0% {
		box-shadow: 0 0 0 0px rgba(215, 20, 31, 0.5);
	}
	100% {
		box-shadow: 0 0 0 5px rgba(215, 20, 31, 0);
	}
}

@media (max-width: 768px) {
	.ph-local-by-date-select-pharmacy-list-delivery-select-main-container {
		margin-top: 25px;
	}
}

@media (max-width: 600px) {
	.ph-local-by-date-select-pharmacy-list-button-info-destiny-image-container {
		padding: 0 2px;
	}

	.ph-local-by-date-select-pharmacy-list-global-container {
		padding: 0;
	}

	.ph-local-by-date-select-pharmacy-local-selected-address-main-container {
		padding-left: 0;
	}
}
