/** @format */

@import "../../../../components/Commons/Sass/components/colors";
@import "../../../../v2-config/colors.scss";

.ph-bag-main-info-global-container {
  padding: 20px 10px;
  border-radius: 4px;
}

.ph-bag-main-info-main-container {
  margin-bottom: 0;
}

.ph-bag-main-info-mobile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $primaryColor;
  margin: 0 10px;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.ph-bag-main-info-mobile-title {
  margin-bottom: 0;
  color: $primaryColor;
  font-weight: 600;
  text-transform: uppercase;
}

.ph-bag-main-info-mobile-button {
  color: $primaryColor;
  font-size: 20px;
}

.ph-bag-main-info-main-title-container {
  margin-left: 15px;
  margin-top: 20px;
}

.ph-bag-main-info-main-title {
  color: $colorGrayText4;
  font-size: 13px;
  font-weight: 900;
  margin-bottom: 0;
  margin-left: 6px;
}

.ph-bag-main-info-main-title-dot {
  width: 8px;
  height: 8px;
  border: 2px solid $secondColor;
  border-radius: 100%;
  display: inline-block;
}

.ph-bag-main-info-delivery-container {
  padding: 30px 5px 5px 5px;
  border-top: 1px solid $primaryColorBorder;
  border-bottom: 1px solid $colorWhiteBorder;
  margin: 20px 0 15px 0;
}

.ph-bag-main-info-total-price {
  font-weight: 500;
  padding: 0;
  font-size: 19px;
  color: $colorGrayText4;
  line-height: 20px;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ph-bag-main-info-total-price-info {
  font-size: 25px;
  color: $colorBackText;
  font-weight: 900;
}

.ph-bag-main-info-total-price-info-symbol {
  font-size: 17px;
  margin-right: 3px;
}

.ph-bag-main-info-main-asterisk-2 {
  color: $errorColor;
  font-style: italic;
  position: absolute;
  left: 200px;
  top: -2px;
}

.ph-bag-main-info-main-subtitle-2 {
  color: $colorGrayText3;
  font-style: italic;
  position: absolute;
  left: 208px;
  font-size: 11px;
}

.ph-bag-main-info-main-agrement-button {
  font-style: italic;
  right: 0;
  position: absolute;
  top: -4px;
  font-size: 15px;
  color: $primaryColor;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
  transition: 0.3s ease;
}

.ph-bag-main-info-main-agrement-button:hover {
  color: $secondColor;
  transition: 0.3s ease;
}

.ph-bag-main-info-box-collapse {
  transform: translate(0, 0);
  transition: transform 0.6s ease;
  background-color: $generic-white;
}

.ph-bag-main-info-box-collapsed {
  transform: translate(0, 252px);
  transition: transform 0.6s ease;
}

@media (max-width: 992px) {
  .ph-bag-main-info-global-container {
    padding: 0;
  }

  .ph-bag-main-info-delivery-container {
    display: none;
  }

  .ph-bag-main-info-button-calculate-container {
    display: none;
  }
}