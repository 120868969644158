/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-detail-global-container {
	max-width: 1185px;
	padding: 10px;
	margin: auto;
}

.ph-detail-main-container {
	padding: 10px 25px;
	margin-bottom: 40px;
}

.ph-detail-titlebox-countdown-container {
	position: relative;
}

.ph-detail-list-main-container {
	box-shadow: $primaryBoxShadow;
	background-color: $primaryBackgroundColor;
	border-radius: 4px;
	padding: 10px;
	margin: 4px;
}

.ph-detail-list-container {
	border: 1px solid $thirdBackgroundColor;
	border-radius: 4px;
	padding: 10px;
	margin-bottom: 10px;
}

.ph-detail-list-container:last-child {
	margin-bottom: 0;
}

.ph-detail-list-container-pharmacy-title {
	color: $colorGrayText3;
	font-weight: 900;
	text-transform: capitalize;
}

.ph-detail-list-container .leaflet-container {
	width: 100%;
	height: 250px;
	z-index: 0;
	margin: auto;
}

.ph-detail-list-map-container {
	padding-right: 10px;
}

.ph-detail-list-map-description {
	color: $primaryColor;
	text-align: left;
	font-size: 13px;
	font-weight: 500;
	font-style: italic;
	margin-top: 5px;
	margin-bottom: 5px;
}

.ph-detail-list-detail-title {
	color: $secondColor;
	font-weight: 600;
	font-size: 13px;
	line-height: 10px;
}

.ph-detail-list-detail-subtitle {
	color: $colorGrayText4;
	font-weight: 600;
	font-size: 13px;
	line-height: 10px;
}

.ph-detail-list-detail-description {
	color: $primaryColor;
	font-weight: 600;
	font-size: 13px;
	line-height: 10px;
	margin-top: 15px;
}

.ph-detail-list-total-pharmacy {
	color: $colorGrayText4;
	font-weight: 900;
	margin-top: 10px;
	margin-bottom: 0;
	padding: 0 5px;
	text-align: right;
}

.ph-detail-list-total-pharmacy-subtitle {
	color: $primaryColor;
}

/** @format */

.ph-checkout-detail-button-container {
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: $mainHeaderboxShadow;
	border-radius: 4px;
	width: 100%;
	max-width: 190px;
	margin: 15px auto 0;
}

.ph-checkout-detail-button-1,
.ph-checkout-detail-button-1:hover,
.ph-checkout-detail-button-1:focus,
.ph-checkout-detail-button-1:active {
	background-color: $secondColor;
	color: $colorWhiteText;
	margin: 0 auto;
	margin-top: 0;
	font-weight: 900;
	height: 36px;
	width: 100%;
	box-shadow: none !important;
	border: none;
	border-radius: 4px 0 0 4px;
	margin-right: 1px;
	font-size: 20px;
	padding: 0 10px;
}

.ph-checkout-detail-button-2,
.ph-checkout-detail-button-2:hover,
.ph-checkout-detail-button-2:focus,
.ph-checkout-detail-button-2:active {
	background-color: $secondColor;
	color: $colorWhiteText;
	margin: 0 auto;
	margin-top: 0;
	font-weight: 900;
	height: 36px;
	width: 100%;
	box-shadow: none !important;
	border: none;
	border-radius: 0 4px 4px 0;
	font-size: 15px;
	padding: 0 35px;
}

.ph-detail-list-delivery-container {
	box-shadow: $primaryBoxShadow;
	background-color: $primaryBackgroundColor;
	border-radius: 4px;
	margin: 4px;
	padding: 10px;
}

.ph-detail-list-delivery-container .leaflet-container {
	width: 100%;
	height: 250px;
	z-index: 0;
	margin: auto;
}

.ph-detail-box-main-container {
	box-shadow: $primaryBoxShadow;
	background-color: $primaryBackgroundColor;
	border-radius: 4px;
	margin: 4px;
	padding: 20px 25px;
}

.ph-checkout-detail-button-mobile-price-main-title {
	font-size: 15px;
	color: $colorGrayText4;
	font-weight: 500;
	margin-bottom: 3px;
}

.ph-checkout-detail-button-mobile-price-title {
	font-size: 20px;
	color: $colorBackText;
	font-weight: 900;
	margin-bottom: 0;
	line-height: 15px;
}

.ph-checkout-detail-button-mobile-price-description {
	font-size: 15px;
}

@media (max-width: 1024px) {
	.ph-detail-main-container {
		padding: 0;
		margin-bottom: 55px;
	}
}

@media (max-width: 992px) {
	.ph-detail-box-global-container {
		padding-bottom: 10px;
	}

	.ph-detail-box-main-container {
		margin: -4px 4px 4px 4px;
	}
}

@media (max-width: 768px) {
	.ph-detail-global-container {
		padding: 5px;
	}

	.ph-detail-list-main-container {
		padding: 5px;
		margin: 4px 4px 8px 4px;
	}

	.ph-checkout-detail-button-main-container {
		position: fixed;
		z-index: 2;
		bottom: 0;
		background-color: $primaryBackgroundColor;
		width: 100%;
		margin-bottom: 0;
		border-top: 1px solid $thirdBackgroundColor;
		left: 0;
		right: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 2px 15px 12px 15px;
	}

	.ph-checkout-detail-button-container {
		margin: 10px auto 0;
	}
}

@media (max-width: 768px) {
	.ph-detail-list-total-pharmacy {
		font-size: 15px;
	}
}
