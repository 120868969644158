/** @format */

@import '../../../Sass/components/colors';
@import '../../../../../v2-config/colors.scss';

.icons-theme-search {
	color: $primary-default;
	height: 24px;
	width: 24px;
	cursor: pointer;
	&:hover {
		color: $primary-hover;
	}
	&:active,
	&.active {
		color: $primary-default;
	}
}
.ph-search-header-main-container {
	border: none !important;
	border-color: transparent !important;
	box-shadow: none !important;
	outline: transparent !important;
	width: 100%;
	max-width: 575px;
	margin-left: 10px;
}

.ph-search-header-container {
	position: relative;
}

.ph-search-header-form-item {
	margin-bottom: 0;
}

.ph-search-header-mobile-arrow-back {
	display: none;
}

.ph-search-header-pharmacy-container {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
}

.ph-search-header-pharmacy {
	height: 39px;
	width: 100%;
	line-height: 40px;
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
	border: 1px solid $secondColor;
	border-radius: 2px;
	padding: 0 10px;
	background: $primaryBackgroundColor;
	font-size: 12px;
	color: $colorGrayText4;
}

.ph-search-pharmacy-check-main-container {
	position: absolute;
	z-index: 3;
	top: 9px;
	right: 140px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.ph-search-pharmacy-check-icon-disabled {
	color: $colorGrayText1;
	margin-right: 4px;
}

.ph-search-pharmacy-check-icon-active {
	color: $primaryColor;
	margin-right: 4px;
}

.ph-search-header-icon-pharmacy-button {
	width: 100%;
	max-width: 25px;
}

.ph-search-header-pharmacy-main-title {
	font-size: 13px;
	color: $primaryColor;
	font-weight: 100;
	margin-bottom: 0;
}

.ph-search-header-pharmacy-subtitle {
	text-transform: capitalize;
	font-weight: 600;
	color: $primaryColor;
}

.ph-search-header-pharmacy-notification-info {
	position: absolute;
	top: 90px;
	color: $secondColor;
	font-size: 12px;
	font-style: italic;
	left: 15px;
	right: 0;
	text-align: left;
}

.ph-search-header-pharmacy-button {
	background-color: transparent;
	border: none;
	padding: 0;
	box-shadow: none;
	padding: 6px 18px 6px 18px;
	height: 100%;
}

.ph-search-header {
	height: 39px;
	width: 100%;
	line-height: 40px;
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
	border: 1px solid $mainSearchBorderColor;
	border-radius: 4px;
	padding: 0 10px;
	background: $primaryBackgroundColor;

	color: $colorGrayText4;
	box-shadow: $primaryBoxShadow !important;
}

.ph-search-header-input-iphone {
	font-size: 16px;
}

.ph-search-header-input-windows {
	font-size: 12px;
}

.ph-search-header:focus,
.ph-search-header:hover,
.ph-search-header.ant-input-focused {
	border-color: $mainSearchBorderColor !important;
}

.ph-search-header-button-container {
	position: absolute;
	margin-bottom: 0;
	top: 0;
	right: 2px;
	z-index: 1;
}

.ph-search-header-button {
	height: auto;
	background: transparent;
	border: none;
	padding: 8px 18px 6px 18px;
	width: 100%;
	min-width: auto;
	opacity: 0.7;
	transition: 0.3s ease;
}

.ph-search-header-button:before,
.ph-search-header-pharmacy-button:before {
	content: '';
	width: 1px;
	height: 70%;
	position: absolute;
	top: 5px;
	left: 0;
	bottom: unset;
	right: unset;
	z-index: 1;
	display: unset;
	background: transparent;
	background-color: $thirdBackgroundColor;
	opacity: 0.9;
}

.ph-search-header-button:hover {
	background-color: transparent;
	color: transparent;
	border-color: transparent;
	opacity: 1;
	transition: 0.3s ease;
}

.ph-search-header-button:active {
	background-color: transparent;
	background: transparent;
	color: transparent;
	border-color: transparent;
	opacity: 1;
	transition: 0.3s ease;
}

.ph-search-header-icon-button {
	width: 100%;
	max-width: 25px;
}

.ph-search-header-list-main-container {
	width: 100%;
	height: 100%;
	min-height: 300px;
	max-height: 300px;
	max-width: 575px;
	padding: 0;
	margin: 3px 0 0 0;
	position: absolute;
	background-color: $primaryBackgroundColor;
	overflow-y: auto;
	overflow-x: hidden;
	z-index: 2;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
	box-shadow: $secondaryBoxShadow;
}

.ph-search-header-list {
	border-bottom: 1px solid $primaryColorBorder;
}

.ph-search-header-list:hover {
	background: $secondBackgroundColor;
}

.ph-search-header-list-description {
	padding: 7px 5px;
	font-size: 15px;
	font-weight: 300;
	color: $colorGrayText3;
	text-overflow: ellipsis;
	text-decoration: none;
	overflow: hidden;
	white-space: nowrap;
	max-width: 100%;
	width: 100%;
	position: relative;
	height: auto;
	display: block;
}

.ph-search-header-list-description:hover {
	color: $colorGrayText4 !important;
}

.ph-search-header-list-image-container {
	margin-right: 10px;
}

.ph-search-header-list-image {
	width: 30px;
	height: 30px;
	background-color: $primaryBackgroundColor !important;
}

.ph-search-header-images-search-content {
	text-align: center;
	padding: 5px;
}

.ph-search-header-titles-search-h3 {
	margin-bottom: 0px;
	font-size: 14px;
	color: $colorGrayText4;
	font-weight: 500;
}

.ph-search-header-titles-search-h3 img {
	width: 18px;
	margin-left: 5px;
	margin-top: -2px;
}

.ph-search-header-titles-search-p {
	margin-bottom: 0px;
	font-size: 12px;
	font-weight: 100;
}

.ph-search-header-titles-search-contents-p-span {
	color: $primaryColor;
}

.ph-search-header-titles-search-contents-price {
	font-size: 15px;
	font-weight: 500;
	color: $colorGrayText4;
}

.ph-search-header-titles-search-contents-price-symbol {
	font-size: 10px;
	font-weight: 500;
}

.ph-search-header-titles-search-contents-add {
	color: $primaryColor;
}

@media (max-width: 1024px) {
	.ph-search-header-list-main-container {
		width: 100%;
		box-shadow: none;
		border-bottom: 1px solid $primaryColorBorder;
	}

	.ph-search-header {
		padding: 0 10px 0 40px;
		border: none;
		border-radius: 0;
		height: 45px;
	}

	.ph-search-header-button {
		padding: 10px 10px 10px 10px;
	}

	.ph-search-header-titles-search-h3 span {
		display: none;
	}
}

@media (min-width: 601px) {
	.ph-search-header-pharmacy-notification-info {
		display: none !important;
	}
}

@media (max-width: 600px) {
	.ph-search-pharmacy-check-main-container {
		top: 46px;
		left: 0;
		right: 0;
		width: 100%;
		justify-content: flex-start;
		border-radius: 8px;
		padding: 9px 16px;
		background-color: $secondBackgroundColor;
	}

	.ph-search-header-pharmacy-main-title {
		margin-left: 7px;
	}

	.ph-search-header-list-description {
		padding: 5px 10px;
		font-size: 13px;
		height: 46px;
	}

	.ph-search-header-list-main-container {
		min-height: 285px;
		max-height: 285px;
	}

	.ph-search-header-list-mobile-main-container {
		top: 77px;
	}

	.ph-search-header-titles-search-h3 {
		padding-left: 8px;
	}

	.ph-search-header-titles-search-p {
		padding-left: 8px;
	}

	.ph-search-header-main-container {
		margin-left: 0;
	}
}
