.drawer-default {
	/* CSS para pantallas medianas */
	@media (min-width: 768px) {
		.ant-drawer-content-wrapper {
			width: 50% !important; /* Ancho para pantallas medianas */
		}
	}

	/* CSS para pantallas pequeñas */
	@media (max-width: 767px) {
		.ant-drawer-content-wrapper {
			width: 90% !important; /* Ancho para pantallas pequeñas (100% del ancho del viewport) */
		}
	}
}
