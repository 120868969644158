/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-payment-card-disable-container {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1;
}

.ph-payment-card-no-available-container {
	width: 93%;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	left: 5px;
	z-index: 1;
	background-color: rgba(255, 255, 255, 0.95);
	border-radius: 10px;
	text-align: center;
	padding: 5px;
}

.ph-payment-card-no-available-image-container {
	padding: 5px;
	margin-top: 5px;
	display: block;
}

.ph-payment-card-no-available-image {
	width: 100%;
	max-width: 32px;
	opacity: 0.5;
}

.ph-payment-card-no-available-title {
	font-size: 12px;
	line-height: 12px;
	color: $colorGrayText4;
	margin-top: 4px;
}

.ph-payment-card-no-available-subtitle {
	font-size: 11px;
	line-height: 12px;
	color: $colorGrayText3;
	font-style: italic;
}

.ph-payment-card-container {
	box-shadow: $mainHeaderboxShadow;
	border: none;
	border-radius: 10px;
	margin: 5px;
	cursor: pointer;
	width: 140px;
	height: 140px;
	position: relative;
	padding: 5px;
}

.ph-payment-card-container .ant-card-body {
	padding: 0;
	border-radius: 10px;
	overflow: hidden;
}

.ph-payment-methods-image-disable {
	filter: grayscale(100%);
	opacity: 0.3;
}

.ph-payment-methods-image {
	width: 100%;
}

.ph-payment-card-discount-container {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 3px;
	right: 10px;
}

.ph-payment-card-discount-image {
	width: 100%;
	max-width: 14px;
}

.ph-payment-card-discount-title {
	color: $primaryColor;
	margin-bottom: 0;
	font-size: 12px;
	font-weight: 600;
	margin-left: 3px;
	letter-spacing: -0.3px;
}
