/** @format */

@import '../../components/Commons/Sass/components/colors';

.ph-types-recipes-global-container {
	max-width: 1185px;
	padding: 10px;
	margin: auto;
	box-sizing: border-box;
}

.ph-types-recipes-header {
	margin: 30px 0px;
	text-align: center;
}

.ph-types-recipes-cont-title {
	margin-bottom: 20px;
	text-align: center;
}

.ph-types-recipes-title {
	font-size: 40px;
	color: $primaryColor;
	font-weight: bolder;
}

.ph-types-recipes-titleheader {
	color: $secondColor;
	font-size: 1.5em;
	font-weight: bold;
}

.ph-types-recipes-subtitle {
	text-align: center;
	font-size: 16px;
	font-weight: 100;
	color: $colorGrayText4;
}

.ph-types-recipe-container-image-principal {
	max-width: 100%;
	width: 100%;
	text-align: center;
}

.ph-types-recipe-image-principal {
	max-width: 80%;
	width: 100%;
}

.ph-types-recipes-main {
	margin: 20px 0px;
	justify-content: center;
}

.ph-types-recipes-title-main {
	color: $primaryColor;
	padding: 0 0 70px 0;
	font-size: 35px;
	font-weight: 700;
	margin: 0;
}

.ph-types-recipes-herosection-col {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.ph-types-recipes-box-hero {
	width: 500px;
	display: flex;
	justify-content: center;
	align-items: center;
	vertical-align: center;
	margin: 0px 0px 20px 200px;
}

.ph-types-recipe-image-herosection {
	max-width: 350px;
	height: 200px;
	align-items: center;
	text-align: center;
}

.ph-types-recipes-hero {
	width: 500px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	vertical-align: center;
	margin-bottom: 20px;
}

.ph-types-recipes-hero-title {
	font-size: 18px;
	color: $colorGrayText4;
	font-weight: 800;
	margin-left: 15px;
	margin-bottom: 0px;
}

.ph-types-recipe-container-i {
	max-width: 25px;
	width: 100%;
	margin: 0;
}

.ph-types-recipes-btn,
.ph-types-recipes-btn:focus,
.ph-types-recipes-btn:hover,
.ph-types-recipes-btn:active {
	border-radius: 4px;
	padding: 0 20px;
	background-color: $errorColor;
	color: $colorWhiteText;
	font-weight: 900;
	font-size: 16px;
	margin-top: 15px;
	height: 36px;
	min-width: 200px;
}

.ph-types-recipes-typescontainers {
	width: 90%;
	height: auto;
	padding: 20px;
	margin: 20px;
	background-color: $primaryBackgroundColor;
	border-radius: 5px;
	box-shadow: $primaryBoxShadow;
}

.ph-types-recipes-hero-parra {
	font-size: 15px;
	font-weight: 300;
	color: $colorGrayText4;
}

@media only screen and (max-width: 480px) {
	.ph-types-recipes-global-container {
		padding: 20px;
	}
	.ph-types-recipes-header {
		margin: 10px 0px;
	}
	.ph-types-recipes-main {
		display: none;
	}
	.ph-types-recipes-title {
		font-size: 30px;
	}
	.ph-types-recipes-titleheader {
		font-size: 20px;
	}
	.ph-types-recipes-subtitle,
	.ph-types-recipes-hero-parra {
		font-size: 14px;
	}
	.ph-types-recipes-hero-title {
		font-size: 15px;
		font-weight: 600;
		padding-left: 3px;
	}
	.ph-types-recipes-hero {
		width: 300px;
	}
}
@media only screen and (max-width: 576px) {
	.ph-types-recipes-global-container {
		padding: 20px;
	}
	.ph-types-recipes-header {
		margin: 10px 0px;
	}
	.ph-types-recipes-main {
		display: none;
	}
	.ph-types-recipes-hero {
		width: 300px;
	}
}

@media only screen and (max-width: 768px) {
	.ph-types-recipes-global-container {
		padding: 20px;
	}
	.ph-types-recipes-header {
		margin: 10px 0px;
	}
	.ph-types-recipes-main {
		display: none;
	}
	.ph-types-recipes-hero {
		width: 300px;
	}
}

@media only screen and (max-width: 992px) {
	.ph-types-recipes-main {
		display: none;
	}
	.ph-types-recipes-hero {
		width: 300px;
	}
}
