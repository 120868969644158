/** @format */

@import "../../../../components/Commons/Sass/components/colors";

.ph-favorites-global-container {
  max-width: 1185px;
  padding: 10px;
  margin: auto;
}

.ph-favorites-main-container {
  padding: 20px;
  border-radius: 4px;
  box-shadow: $primaryBoxShadow;
}

.ph-favorites-colapse {
  padding: 0px !important;
  margin: 0px !important;
  height: auto;
}

.ph-favorites-inner-container {
  border: 1px solid $primaryColorBorder;
  border-radius: 3px;
  transition: 0.2s ease;
  position: relative;
  margin-bottom: 10px;
  padding: 7px;
}

.ph-favorites-inner-container:last-child {
  margin-bottom: 0px;
}

.ph-favorites-btn-container {
  text-align: right;
  margin: auto;
  margin-top: 40px;
  margin-right: 10px;
}

.ph-favorites-blue-btn,
.ph-favorites-blue-btn:hover,
.ph-favorites-blue-btn:active,
.ph-favorites-blue-btn:focus {
  background-color: $secondColor !important;
  color: $colorWhiteText;
  padding: 8px 25px;
  font-size: 15px;
  border-radius: 4px;
  font-weight: 900;
  box-shadow: $primaryBoxShadow;
  border: 2px solid $secondColor;
  text-transform: uppercase;
  height: 38px;
}

.ph-favotire-content-hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $primaryColorBorder;
  margin: 15px auto;
  width: 100%;
}

.ph-favotire-content-logo-content {
  padding: 5px;
}

.ph-favotire-content-logo-content-image {
  width: 100%;
  max-width: 24px;
}

.ph-favotire-content-active-title-main {
  color: $colorGrayText4;
}

.ph-favotire-content-active-title-description {
  color: $colorGrayText3;
}

.ph-favotire-content-active {
  border: 1px solid $primaryColorBorder;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 15px;
}

.ph-favotire-content-active-title {
  color: $colorGrayText3;
}

.ph-favotire-content-active-delete {
  text-align: right;
  padding-right: 10px;
}

.ph-favotire-btn-delete-notification {
  color: $primaryColor;
  cursor: pointer;
}

.ph-favorites-icon-container {
  cursor: pointer;
  margin-top: 10px;
}

.ph-favorites-icon-container span {
  color: $primaryColor;
}

.ph-favorites-product-image-container {
  margin: auto;
  max-width: 100%;
}

.ph-favorites-product-imgage {
  max-width: 100px;
  max-height: 100px;
  margin-left: 7px;
  margin-top: 5px;
}

.ph-favorites-product-title {
  font-size: 20px;
  font-weight: 900;
  color: $colorGrayText4;
  line-height: normal;
  margin: 3px 0;
}

.ph-favorites-product-id,
.ph-favorites-product-laboratory {
  font-size: 12px;
  color: $colorGrayText2;
  font-style: italic;
  margin-top: 0;
  margin-bottom: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  height: auto;
  width: 100%;
  height: 19px;
}

.ph-favorites-orange-btn,
.ph-favorites-orange-btn:hover,
.ph-favorites-orange-btn:active,
.ph-favorites-orange-btn:focus {
  background-color: $secondColor !important;
  color: $colorWhiteText;
  padding: 5px 20px;
  font-size: 15px;
  border-radius: 4px;
  font-weight: 900;
  box-shadow: $primaryBoxShadow;
  border: 2px solid $secondColor;
  text-transform: capitalize;
  height: 38px;
}

.ph-favorites-pharmacy-btn-price {
  color: $colorGrayText4;
  font-size: 15px;
  font-weight: 900;
  text-align: right;
  margin-bottom: 2px;
  margin-left: 5px;
}

.ph-favorites-product-pharmacy {
  font-weight: 100;
  font-size: 13px;
  color: $colorGrayText4;
  margin-top: 0;
  margin-bottom: 0;
}

.ph-favorites-pharmacy-image-container,
.ph-favorites-pharmacy-image-card-container {
  margin: auto;
  max-width: 100%;
}

.ph-favorites-pharmacy-imgage {
  max-width: 17px;
  max-height: 17px;
  margin-left: 2px;
  margin-bottom: 2px;
}

.ph-favorites-icon-delivery-container,
.ph-favorites-icon-retirement-container {
  max-width: 100px;
}

.ph-favorites-icon-delivery,
.ph-favorites-icon-retirement {
  max-width: 30px;
  max-height: 30px;
}

.ph-favorites-pharmacy-cards-container {
  padding: 0;
  margin: 0;
}

.ph-favorites-collapse {
  transform: translate(0px, 2px) !important;
  transition: 0.2s !important;
}

.ph-favorites-collapsed {
  transform: translate(0px, 2px) !important;
  transition: 0.2s !important;
}

.ph-favorites-pharmacy-cards-title {
  color: $colorGrayText4;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
}

.ph-favorites-pharmacy-cards-content {
  border-radius: 4px;
  box-shadow: $primaryBoxShadow;
  padding: 5px 12px;
  margin: 10px 5px;
}

.ph-favorites-pharmacy-cards-price {
  color: $colorGrayText4;
  font-size: 1.17em;
  font-weight: 900;
  padding: 4px;
  margin-bottom: 0;
  text-align: center;
}

.ph-favorites-promotions-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ph-favorites-pharmacy-promotion-title {
  font-size: 12px;
  color: $primaryColor;
  font-weight: 200;
  padding: 4px;
  margin-bottom: 0;
  text-align: center;
}

.ph-favorites-pharmacy-cards-pharmacy {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 4px;
}

.ph-favorites-pharmacy-cards-title-img {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  margin: auto;
  margin-left: 2px;
}

.ph-favorites-pharmacy-card-imgage {
  max-width: 20px;
  max-height: 20px;
  border-radius: 50px;
}

.ph-favorites-pharmacy-cards-name-pharmacy {
  font-size: 14px;
  color: $colorGrayText4;
  font-weight: 900;
  margin-bottom: 0;
  margin-top: 3px;
  margin-left: 5px;
}

.ph-favorites-type-delivery-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ph-favorites-type-delivery-title {
  color: $colorGrayText4;
  font-weight: 600;
  font-size: 10px;
  line-height: 11pt;
  margin-bottom: 0;
  margin-left: 5px;
}

.ph-favorites-pharmacy-cards-stock-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 25px;
}

.ph-favorites-pharmacy-cards-stock {
  font-size: 12px;
  font-weight: 300;
  color: $colorGrayText3;
  margin-bottom: 0;
}

.ant-collapse-content>.ant-collapse-content-box {
  padding: 0;
}

.ph-favorites-pharmacy-cards-disponibility-green {
  color: $successColor;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 0;
  margin-left: 5px;
}

.ph-favorites-pharmacy-cards-disponibility-red {
  color: $errorColor;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 0;
  margin-left: 5px;
}

.ph-favorites-pharmacy-cards-disponibility-yellow {
  color: $thirdColor;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 0;
  margin-left: 5px;
}

.ph-favorites-pharmacy-main-content {
  display: flex;
  align-items: center;
}

.ph-favorites-promotion-container {
  max-width: 100%;
}

.ph-favorites-promotion-img {
  max-width: 20px;
}

.ph-phofile-favorites-pagination-container {
  position: relative;
  width: 100%;
  max-width: 1185px;
  padding: 0 10px;
  margin: auto;
}

.ph-favotire-content-form-item-reminder {
  padding-right: 10px;
}

.ph-favotire-datepicker-reminder.ant-picker {
  max-width: 100%;
  height: 45px !important;
  border-radius: 6px !important;
}

.ph-favotire-datepicker-reminder>.ant-picker-input>input {
  font-size: 13px !important;
}

.ph-favotire-selector-reminder .ant-select-selector {
  max-width: 100%;
  height: 45px !important;
  border-radius: 6px !important;
  font-size: 13px !important;
}

.ph-favotire-selector-reminder.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 44px !important;
}

.ph-favotire-selector-reminder.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  height: 44px !important;
  line-height: 44px !important;
}

.ph-favotire-selector-reminder.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border: 1px solid rgba(255, 87, 34, 0.47843) !important;
}

.ph-favotire-selector-reminder.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  height: 44px !important;
}

.ph-favorite-btn-activar-notification {
  background-color: $primaryColor !important;
  color: white;
  padding: 8px 25px;
  font-size: 13px;
  border-radius: 4px;
  font-weight: 900;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 4%), 0 1px 1px 0 rgb(0 0 0 / 4%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
  border: 2px solid $primaryColor;
  text-transform: uppercase;
  height: 40px;
  margin-top: 3px;
}

@media (min-width: 992px) {
  .ph-favorites-pharmacy-cards-content {
    margin: 10px 5px;
  }

  .ph-favorites-pharmacy-btn-price,
  .ph-favorites-type-delivery-icons {
    display: none;
  }

  .ph-favorites-pharmacy-cards-name-pharmacy {
    font-size: 13px;
  }

  .ph-favorites-type-delivery-title {
    margin-left: 10px;
  }

  .ph-favorites-pharmacy-cards-name-pharmacy {
    margin-top: 1px;
  }

  .ph-favorites-type-delivery-title {
    margin-left: 3px;
  }

  .ph-favorites-icon-delivery,
  .ph-favorites-icon-retirement {
    padding-left: 1px;
  }

  .ph-favorites-promotion-img {
    max-width: 15px;
    margin-left: 5px;
  }

  .ph-phofile-favorites-pagination-container {
    padding: 0 5px;
  }
}

@media (max-width: 768px) {
  .ph-favorites-product-title {
    font-size: 16px;
  }

  .ph-favorites-blue-btn,
  .ph-favorites-orange-btn {
    height: 40px !important;
    width: 100px !important;
    padding: 6px 0 !important;
    border-radius: 4px !important;
    font-size: 13px !important;
  }

  .ph-favorites-pharmacy-cards-stock-container {
    margin-left: 0px;
    margin-bottom: 1px;
    margin-top: 3px;
  }

  .ph-favorites-pharmacy-cards-price {
    text-align: left;
  }

  .ph-favorites-pharmacy-btn-price {
    text-align: center;
    margin-left: 23px;
  }

  .ph-favorites-icon-delivery,
  .ph-favorites-icon-retirement {
    max-width: 23px;
  }

  .ph-favorites-btn-container {
    text-align: right;
    margin: auto;
    margin-top: 40px;
    margin-right: 5px;
  }

  .ph-favotire-content-active-delete {
    text-align: right;
    padding-right: 10px;
    position: absolute;
    right: 0;
    margin-top: 10px;
  }
}

@media (max-width: 576px) {
  .ph-favorites-global-container {
    min-height: 110vh;
  }

  .ph-favorites-product-title {
    font-size: 15px;
  }

  .ph-favorites-blue-btn,
  .ph-favorites-orange-btn {
    height: 35px !important;
    width: 90px !important;
    padding: 6px 10px !important;
    border-radius: 4px !important;
    font-size: 12px !important;
  }

  .ph-favorites-pharmacy-cards-title {
    font-size: 15px;
    margin-left: 10px;
  }

  .ph-favorites-type-delivery-container {
    display: none;
  }

  .ph-favorites-pharmacy-cards-name-pharmacy {
    margin-top: 2px;
  }

  .ph-favorites-pharmacy-cards-price {
    text-align: left;
  }

  .ph-favorites-icon-delivery {
    max-width: 25px;
    max-height: 25px;
    margin-left: 0px;
  }

  .ph-favorites-icon-retirement {
    max-width: 25px;
    max-height: 25px;
  }

  .ph-favorites-pharmacy-cards-stock-container {
    margin-left: 0px;
    margin-top: 2px;
  }

  .ph-favorites-pharmacy-cards-stock {
    font-size: 11px;
  }

  .ph-favorites-pharmacy-btn-price {
    text-align: center;
    margin-left: 30px;
  }

  .ph-phofile-favorites-pagination-separator-container {
    height: 35px;
  }
}

@media (max-width: 480px) {
  .ph-favorites-product-imgage {
    max-width: 70px;
    max-height: 70px;
    margin: 0;
    margin-top: 10px;
  }

  .ph-favorites-product-title {
    font-size: 14px;
  }

  .ph-favorites-product-pharmacy {
    font-size: 12px;
  }

  .ph-favorites-orange-btn,
  .ph-favorites-blue-btn {
    font-size: 12px !important;
  }

  .ph-favorites-pharmacy-card-imgage {
    max-width: 18px;
  }

  .ph-favorites-pharmacy-cards-name-pharmacy,
  .ph-favorites-pharmacy-cards-price {
    font-size: 12px;
  }

  .ph-favorites-pharmacy-cards-title {
    font-size: 14px;
  }

  .ph-favorites-pharmacy-cards-price {
    text-align: left;
  }

  .ph-favorites-icon-delivery {
    max-width: 20px;
    max-height: 20px;
    margin-left: 0px;
  }

  .ph-favorites-icon-retirement {
    max-width: 20px;
    max-height: 20px;
  }

  .ph-favorites-pharmacy-btn-price {
    margin-left: 22px;
  }

  .ph-favorites-promotion-img {
    max-width: 10px;
  }

  .ph-favorites-pharmacy-promotion-title {
    font-size: 10px;
  }
}

@media (max-width: 414px) {
  .ph-favorites-main-container {
    padding: 10px;
  }

  .ph-favorites-blue-btn,
  .ph-favorites-orange-btn {
    height: 25px !important;
    width: 70px !important;
    padding: 5px 10px !important;
    border-radius: 2px !important;
    font-size: 10px !important;
  }

  .ph-favorites-product-imgage {
    width: 70px;
    margin: 0;
  }

  .ph-favorites-product-title {
    font-size: 13px;
  }

  .ph-favorites-product-id,
  .ph-favorites-product-laboratory {
    font-size: 10px;
  }

  .ph-favorites-pharmacy-cards-content {
    padding: 2px 10px;
  }

  .ph-favorites-pharmacy-cards-title {
    font-size: 12px;
    margin-left: 5px;
    margin-bottom: 0px;
  }

  .ph-favorites-pharmacy-btn-price {
    margin-left: 10px;
  }

  .ph-favorites-pharmacy-cards-disponibility-green,
  .ph-favorites-pharmacy-cards-disponibility-red,
  .ph-favorites-pharmacy-cards-disponibility-yellow {
    font-size: 10px;
  }

  .ph-favorites-pharmacy-cards-stock {
    font-size: 9px;
  }

  .ph-favorites-pharmacy-cards-name-pharmacy {
    font-size: 10px;
    margin-top: 5px;
  }

  .ph-favorites-pharmacy-card-imgage {
    max-width: 12px;
  }
}