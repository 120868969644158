/** @format */

@import '../../../Sass/components/colors';

.ph-product-logos-main-container {
	position: absolute;
	bottom: 1px;
	left: 15px;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-product-promotion-image {
	position: absolute;
	z-index: 1;
	right: 6px;
	top: 6px;
	width: 30px;
}

.ph-product-promotion-cp-image-container {
	margin-right: 0;
	display: block;
	border: 1px solid $primaryColorBorder;
	background-color: $primaryBackgroundColor;
	border-radius: 100px;
	margin: 0 2px;
}

.ph-product-promotion-cp-image {
	width: 26px;
}

.ph-product-promotion-cenabast-image-container {
	margin-left: 12px !important;
	margin-top: 0 !important;
	display: block;
	background-color: $primaryBackgroundColor;
	border-radius: 100px;
	margin: 0 2px;
}

.ph-product-promotion-cenabast-image {
	width: 32px;
}

.ph-product-detail-promotion-image {
	width: 45px;
}

.ph-product-detail-promotion-cp-image {
	width: 35px;
}

.ph-product-detail-promotion-cenabast-image {
	width: 35px;
}

.ph-product-detail-promotion-image-main-container {
	position: absolute;
	top: 10px;
}