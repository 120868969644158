/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-bag-main-info-mobile-main-container {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 3;
	background-color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: 1px solid $primaryColorBorder;
	padding: 6px 10px;
}
