@import './generic-colors.scss';
/* Colores principales */
$primary-default: #553a8f;
$primary-hover: #8b73bf;
$primary-active: #3d2966;
$primary-shadow: 0px 0px 5px 0px #553a8f80;

$accent-default: #35a93b;
$accent-hover: #65cc6a;
$accent-active: #1b7920;
$accent-shadow: 0px 0px 5px 0px #35a93b80;

/* Botones primarios */
$button-primary-background-color-default: $accent-default;
$button-primary-border-color-default: $accent-default;
$button-primary-color-default: $generic-white;
$button-primary-shadow-default: $accent-shadow;

$button-primary-background-color-hover: $accent-hover;
$button-primary-border-color-hover: $accent-hover;
$button-primary-color-hover: $generic-white;
$button-primary-shadow-hover: $accent-shadow;

$button-primary-background-color-disabled: $generic-grey1;
$button-primary-border-color-disabled: $generic-grey1;
$button-primary-color-disabled: $generic-white;
$button-primary-shadow-disabled: none;

$button-primary-background-color-active: $accent-active;
$button-primary-border-color-active: $accent-active;
$button-primary-color-active: $generic-white;
$button-primary-shadow-active: $accent-shadow;

/* Botones secundarios */
$button-secondary-background-color-default: $primary-default;
$button-secondary-border-color-default: $primary-default;
$button-secondary-color-default: $generic-white;
$button-secondary-shadow-default: $primary-shadow;

$button-secondary-background-color-hover: $primary-hover;
$button-secondary-border-color-hover: $primary-hover;
$button-secondary-color-hover: $generic-white;
$button-secondary-shadow-hover: $primary-shadow;

$button-secondary-background-color-disabled: $generic-grey2;
$button-secondary-border-color-disabled: $generic-grey2;
$button-secondary-color-disabled: $generic-white;
$button-secondary-shadow-disabled: none;

$button-secondary-background-color-active: $primary-active;
$button-secondary-border-color-active: $primary-active;
$button-secondary-color-active: $generic-white;
$button-secondary-shadow-active: $primary-shadow;

/* Botones terciarios */
$button-tertiary-background-color-default: $generic-white;
$button-tertiary-border-color-default: $primary-default;
$button-tertiary-color-default: $primary-default;
$button-tertiary-shadow-default: $primary-shadow;

$button-tertiary-background-color-hover: $generic-white;
$button-tertiary-border-color-hover: $primary-hover;
$button-tertiary-color-hover: $primary-hover;
$button-tertiary-shadow-hover: $primary-shadow;

$button-tertiary-background-color-disabled: $generic-white;
$button-tertiary-border-color-disabled: $generic-grey2;
$button-tertiary-color-disabled: $generic-grey2;
$button-tertiary-shadow-disabled: none;

$button-tertiary-background-color-active: $generic-white;
$button-tertiary-border-color-active: $primary-active;
$button-tertiary-color-active: $primary-active;
$button-tertiary-shadow-active: $primary-shadow;

/* Botones rojos genéricos */
$button-generic-red-background-color-default: $generic-red-default;
$button-generic-red-border-color-default: $generic-red-default;
$button-generic-red-color-default: $generic-white;
$button-generic-red-shadow-default: $generic-red-shadow;

$button-generic-red-background-color-hover: $generic-red-hover;
$button-generic-red-border-color-hover: $generic-red-hover;
$button-generic-red-color-hover: $generic-white;
$button-generic-red-shadow-hover: $generic-red-shadow;

$button-generic-red-background-color-disabled: $generic-grey2;
$button-generic-red-border-color-disabled: $generic-grey2;
$button-generic-red-color-disabled: $generic-white;
$button-generic-red-shadow-disabled: none;

$button-generic-red-background-color-active: $generic-red-active;
$button-generic-red-border-color-active: $generic-red-active;
$button-generic-red-color-active: $generic-white;
$button-generic-red-shadow-active: $generic-red-shadow;

/* Botones verdes genéricos */
$button-generic-green-background-color-default: $generic-green-default;
$button-generic-green-border-color-default: $generic-green-default;
$button-generic-green-color-default: $generic-white;
$button-generic-green-shadow-default: $generic-green-shadow;

$button-generic-green-background-color-hover: $generic-green-hover;
$button-generic-green-border-color-hover: $generic-green-hover;
$button-generic-green-color-hover: $generic-white;
$button-generic-green-shadow-hover: $generic-green-shadow;

$button-generic-green-background-color-disabled: $generic-grey1;
$button-generic-green-border-color-disabled: $generic-grey1;
$button-generic-green-color-disabled: $generic-white;
$button-generic-green-shadow-disabled: none;

$button-generic-green-background-color-active: $generic-green-active;
$button-generic-green-border-color-active: $generic-green-active;
$button-generic-green-color-active: $generic-white;
$button-generic-green-shadow-active: $generic-green-shadow;

/* Botón de texto */
$link-color-default: $primary-default;
$link-color-hover: $primary-hover;
$link-color-disabled: $generic-grey2;
$link-color-active: $primary-active;
$link-shadow-active: $primary-shadow;
