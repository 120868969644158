/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-checkout-recipe-button-main-container {
	margin-bottom: 0;
}

.ph-checkout-recipe-button-checkbox-hidden-container {
	display: none;
}

.ph-checkout-recipe-button-checkbox-container {
	border-bottom: 1px solid $primaryColorBorder;
	margin-bottom: 0;
}

.ph-checkout-recipe-agree-button-title {
	color: $colorGrayText3;
	font-size: 10px;
}

.ph-checkout-recipe-button-checkbox-container .ant-checkbox-inner {
	position: relative;
	top: 8px;
}

.ph-checkout-recipe-button-checkbox-container .ant-checkbox-checked .ant-checkbox-inner {
	background-color: $primaryColor !important;
	border-color: $primaryColor !important;
}
.ph-checkout-recipe-button-checkbox-container .ant-checkbox-checked::after {
	border: none;
}

.ph-checkout-recipe-button-checkbox-container .ant-checkbox:hover .ant-checkbox-inner {
	border-color: $primaryColor !important;
}

.ph-checkout-recipe-button-checkbox-container .ant-form-item-explain-success {
	color: $successColor;
	padding: 0 40px;
	font-size: 12px;
	opacity: 0.8;
}

.ph-checkout-recipe-button-checkbox-container .ant-form-item-explain-error {
	color: $errorColor;
	padding: 0 40px;
	font-size: 12px;
	opacity: 0.8;
	font-weight: 600;
}

.ph-checkout-recipe-button-inner-container {
	margin-bottom: 0;
}

.ph-checkout-recipe-button-container {
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: $mainHeaderboxShadow;
	border-radius: 4px;
	width: 100%;
	max-width: 190px;
	margin: 15px auto 0;
}

.ph-checkout-recipe-button-1,
.ph-checkout-recipe-button-1:hover,
.ph-checkout-recipe-button-1:focus,
.ph-checkout-recipe-button-1:active {
	background-color: $secondColor;
	color: $colorWhiteText;
	margin: 0 auto;
	margin-top: 0;
	font-weight: 900;
	height: 36px;
	width: 100%;
	box-shadow: none !important;
	border: none;
	border-radius: 4px 0 0 4px;
	margin-right: 1px;
	font-size: 20px;
	padding: 0 10px;
}

.ph-checkout-recipe-button-2,
.ph-checkout-recipe-button-2:hover,
.ph-checkout-recipe-button-2:focus,
.ph-checkout-recipe-button-2:active {
	background-color: $secondColor;
	color: $colorWhiteText;
	margin: 0 auto;
	margin-top: 0;
	font-weight: 900;
	height: 36px;
	width: 100%;
	box-shadow: none !important;
	border: none;
	border-radius: 0 4px 4px 0;
	font-size: 15px;
	padding: 0 35px;
}

.ph-checkout-recipe-button-1[disabled],
.ph-checkout-recipe-button-2[disabled] {
	background-color: $secondBackgroundColor !important;
}

@media (max-width: 768px) {
	.ph-checkout-recipe-button-main-container {
		position: fixed;
		z-index: 2;
		bottom: 0;
		background-color: $primaryBackgroundColor;
		width: 100%;
		margin-bottom: 0;
		border-top: 1px solid $primaryColorBorder;
		padding-bottom: 12px;
		padding-top: 2px;
		left: 0;
		right: 0;
	}

	.ph-checkout-recipe-button-container {
		margin: 10px auto 0;
	}
}
