/** @format */

@import '../../components/Commons/Sass/components/colors.scss';
@import '../../v2-config/colors.scss';

.ph-contact-global-container {
	background-color: none;
	margin-bottom: 75px;

	.leaflet-popup-content {
		text-align: center;
	}
}

.ph-contact-container {
	display: flex;
	justify-content: center;
	height: 350px;
}

.ph-contact-image-container {
	background-image: url('https://storage.googleapis.com/master-image-bucket/react-contact/contact-main.jpg');
	background-image: -webkit-image-set(url('https://storage.googleapis.com/master-image-bucket/react-contact/contact-main.webp') 1x);
	background-size: cover;
	height: 276px;
	max-width: 1165px;
	position: relative;
	background-position: 50%;
	border-radius: 10px;
	margin-top: 10px;
	width: 100%;
}

.ph-contact-description-main-container {
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;
}

.ph-contact-description-main-container {
	max-width: 1185px;
	margin: auto;
	padding: 10px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-contact-description-container {
	margin-top: -90px;
	width: 100%;
	text-align: left;
	position: relative;
}

.ph-contact-logo-white {
	width: 100%;
	max-width: 120px;
	filter: drop-shadow(5px 5px 5px $colorBackText);
}

.ph-contact-title {
	color: $colorWhiteText !important;
	margin-top: 10px;
	margin-bottom: 10px !important;
	font-size: 35px !important;
	text-shadow: 4px 4px 5px $colorBackText;
}

.ph-contact-description {
	color: $colorWhiteText;
	font-size: 20px;
	text-shadow: 4px 4px 5px $colorBackText;
}

.ph-contact-button-container {
	margin-top: 20px;
}

.ph-contact-button-register-issues {
	font-weight: 600;
	padding: 3px 15px;
	line-height: 34px !important;
	text-transform: uppercase;
	background-color: $errorColor !important;
	border: 1px solid $errorColor;
	color: $colorWhiteText !important;
	border-radius: 4px;
	font-size: 15px !important;
	height: 35px;
	min-width: 110px;
}

.ph-contact-button-register-issues:hover {
	background-color: $errorColor;
	border: 1px solid $errorColor;
}

.ph-contact-button-register-issues:active {
	border-color: $errorColor;
}

.ph-contact-info-container {
	max-width: 1185px;
	margin: auto 10px;
	padding: 10px;
}

.ph-contact-info-inner-container {
	background-color: $primaryBackgroundColor;
	border-radius: 10px;
	box-shadow: $primaryBoxShadow;
	position: relative;
	padding: 35px;
	padding-bottom: 110px;
	min-height: 420px;
	margin-top: -160px;
}

.ph-contact-info-text-container {
	width: 100%;
}

.ph-contact-info-title {
	color: $primary-default !important;
	margin-bottom: 20px !important;
	font-size: 20px !important;
	font-weight: 700 !important;
	word-spacing: 1px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

.ph-contact-info-separator {
	border-color: transparent;
	box-shadow: none;
	opacity: 0.4;
	margin: 0 0 25px 0;
}

.ph-contact-info-description-icon {
	font-family: Ubuntu, sans-serif !important;
	display: block;
	color: $colorGrayText3 !important;
	font-weight: 500;
	font-size: 14px;
	padding-bottom: 10px;
	word-spacing: 1px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	margin-top: 4px;
}

.ph-contact-info-description {
	font-family: Ubuntu, sans-serif !important;
	display: block;
	color: $colorGrayText3 !important;
	font-weight: 500;
	font-size: 14px;
	padding-bottom: 10px;
	word-spacing: 1px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

.ph-contact-info-description-days {
	font-family: Ubuntu, sans-serif !important;
	display: block;
	color: $colorGrayText3 !important;
	font-weight: 500;
	font-size: 14px;
	padding-bottom: 10px;
	word-spacing: 1px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

.ph-contact-social-icons-container {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: 35px;
	left: 0;
	right: 0;
}

.ph-contact-social-icons {
	display: grid;
	text-align: center;
	padding: 5px;
	border-radius: 5px;
	min-width: 65px;
	margin-right: 20px;
}

.ph-contact-social-icons:last-child {
	margin-right: 0;
}

.ph-contact-social-image {
	width: 100%;
	max-width: 25px;
	margin: auto;
	filter: contrast(50%);
}

.ph-contact-social-title {
	color: $colorGrayText3;
	font-size: 11px;
	margin-top: 5px;
}

.ph-contact-col-custom-container {
	max-width: 61.5% !important;
	flex-basis: 61.5% !important;
	z-index: 1;
}

.ph-contact-map-container {
	background-color: $primaryBackgroundColor;
	box-shadow: $primaryBoxShadow;
	position: relative;
	min-height: 420px;
	margin-top: -160px;
	border-radius: 10px;
}

.ph-contact-map-container .leaflet-container {
	width: 100%;
	height: 420px;
	border-radius: 10px;
}

.ph-contact-info-text-container .ant-space-align-center {
	align-items: flex-start;
}

@media (max-width: 767px) {
	.ph-contact-info-inner-container {
		padding: 20px;
		padding-bottom: 95px;
		min-height: auto;
	}

	.ph-contact-info-title {
		margin-bottom: 10px !important;
	}

	.ph-contact-info-separator {
		margin: 0 0 15px 0;
	}

	.ph-contact-col-custom-container {
		max-width: 100% !important;
		flex-basis: 100% !important;
	}

	.ph-contact-map-container {
		margin-top: 20px;
	}

	.ph-contact-description-container {
		text-align: center;
	}
}