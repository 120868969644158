/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-product-detail-dispatch-contact-service-main-container {
	position: absolute;
	right: 0;
	top: 5px;
}

.ph-product-detail-dispatch-contact-service-container {
	position: absolute;
	width: 100%;
}

.ph-product-detail-dispatch-contact-service-description {
	color: $colorGrayText3;
	font-size: 13px;
	padding: 0 10px;
	margin-top: 15px;
}

.ph-product-detail-dispatch-main-title {
	color: $colorGrayText3;
	font-size: 14px;
	font-weight: 500;
	margin-left: 5px;
	margin-bottom: 0;
	margin-top: 10px;
	padding-top: 5px;
	border-top: 1px solid #eeeeee;
}

.ph-product-detail-dispatch-main-subtitle {
	color: $errorColor;
	font-size: 14px;
	font-weight: 500;
	margin-left: 5px;
	margin-bottom: 0;
	padding-top: 5px;
	opacity: 0.6;
	font-style: italic;
}
