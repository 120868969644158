/** @format */

@import '../../components/Commons/Sass/components/colors';

.ph-mediclic-global-container {
	max-width: 1185px;
	padding: 24px;
	margin: auto;
	box-sizing: border-box;
}

.ph-mediclic-header-container {
	margin-bottom: 70px;
	text-align: center;
}

.ph-mediclic-title {
	color: $primaryColor;
	font-size: 40px;
	font-weight: 500;
	margin: 15px 0;
}

.ph-mediclic-title-detail {
	font-weight: 900;
}

.ph-mediclic-subtitle {
	max-width: 900px;
	color: $colorGrayText3;
	font-size: 16px;
	margin: 10px 0;
}

.ph-mediclic-logo-content {
	max-width: 200px;
}

.ph-mediclic-logo-img {
	max-width: 350px;
	width: 100%;
	margin: 10px 0;
}

.ph-mediclic-banner-content {
	max-width: 750px;
}

.ph-mediclic-banner-img {
	max-width: 750px;
	width: 100%;
	margin: 10px 0;
}

.ph-mediclic-btn-header,
.ph-mediclic-btn-header:focus,
.ph-mediclic-btn-header:hover,
.ph-mediclic-btn-header:active {
	border-radius: 4px;
	padding: 0 20px;
	background-color: $errorColor;
	color: $colorWhiteText;
	font-weight: 900;
	font-size: 16px;
	margin-top: 15px;
	height: 36px;
	min-width: 200px;
}

.ph-mediclic-main-container {
	background: $thirdBackgroundColor;
	opacity: 1;
	position: relative;
	z-index: 1;
	padding-bottom: 60px;
}

.ph-mediclic-images-overlay {
	position: absolute;
	overflow: hidden;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.ph-mediclic-image-sample-1::before {
	background-image: url('https://storage.googleapis.com/master-image-bucket/mediclic/pag1.jpg');
}

.ph-mediclic-image-sample-2::before {
	background-image: url('https://storage.googleapis.com/master-image-bucket/mediclic/pag2.jpg');
}

.ph-mediclic-image-sample-3::before {
	background-image: url('https://storage.googleapis.com/master-image-bucket/mediclic/pag3.jpg');
}

.ph-mediclic-image-sample-4::before {
	background-image: url('https://storage.googleapis.com/master-image-bucket/mediclic/pag4.jpg');
}

.ph-mediclic-image-sample-5::before {
	background-image: url('https://storage.googleapis.com/master-image-bucket/mediclic/pag5.jpg');
}

.ph-mediclic-image.ph-mediclic-image-sample-1 {
	top: -20px;
	right: -18px;
	width: 250px;
	height: 350px;
	position: absolute;
}

.ph-mediclic-image.ph-mediclic-image-sample-2 {
	top: 300px;
	right: -200px;
	width: 250px;
	height: 350px;
	position: absolute;
}

.ph-mediclic-image.ph-mediclic-image-sample-3 {
	top: 150px;
	right: 195px;
	width: 250px;
	height: 350px;
	position: absolute;
}

.ph-mediclic-image.ph-mediclic-image-sample-4 {
	top: 470px;
	right: 13px;
	width: 250px;
	height: 350px;
	position: absolute;
}

.ph-mediclic-image-b.ph-mediclic-image-sample-5 {
	top: 0px;
	left: -150px;
	width: 250px;
	height: 350px;
	position: absolute;
}

.ph-mediclic-main-container::before {
	position: absolute;
	background-color: $thirdBackgroundColor;
	content: '';
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
}

.ph-mediclic-image::before {
	content: '';
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-repeat: no-repeat;
	box-shadow: $primaryBoxShadow;
	border-radius: 10px;
	z-index: -1;
	-webkit-transform: rotateZ(-30deg);
	transform: rotateZ(-30deg);
	position: absolute;
}
.ph-mediclic-image-b::before {
	content: '';
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-repeat: no-repeat;
	box-shadow: $primaryBoxShadow;
	border-radius: 10px;
	z-index: -1;
	-webkit-transform: rotateZ(30deg);
	transform: rotateZ(30deg);
	position: absolute;
}

.ph-mediclic-main-title {
	color: $colorGrayText3;
	margin: 50px 0px;
	font-size: 40px;
	font-weight: 500;
}

.ph-mediclic-main-content {
	max-width: 700px;
	padding-left: 120px;
}

.ph-mediclic-icons-service-content {
	max-width: 60px;
}

.ph-mediclic-icons-service-img {
	max-width: 60px;
	width: 100%;
	padding: 5px;
	margin: 0;
}

.ph-mediclic-services-titles {
	color: $colorGrayText4;
	font-weight: 500;
	font-size: 20px;
	margin: 15px 0 0 0;
}

.ph-mediclic-services-main-subtitles {
	margin: 0 0 40px 190px;
	color: $colorGrayText1;
	font-size: 16px;
}

.ph-mediclic-inner-container {
	padding: 50px 0;
}

.ph-mediclic-inner-title {
	margin: 0 0 50px 0;
	font-size: 40px;
	text-align: center;
	font-weight: 500;
	color: $colorGrayText3;
}

.ph-mediclic-doctor-content {
	margin: auto;
	text-align: center;
}

.ph-mediclic-doctor-img {
	width: 100%;
	max-width: 450px;
}

.ph-mediclic-doctor-icons-container {
	margin-top: 80px;
}

.ph-mediclic-icon-title-conteiner {
	margin-bottom: 20px;
}

.ph-mediclic-icons-content {
	max-width: 30px;
}

.ph-mediclic-icons-img {
	max-width: 30px;
	width: 100%;
	margin-right: 25px;
}

.ph-mediclic-doctor-services-titles {
	color: $colorGrayText4;
	font-weight: 500;
}

.ph-mediclic-services-subtitles {
	color: $colorGrayText1;
	font-size: 14px;
	padding-left: 5px;
}

.ph-mediclic-inner-btn {
	margin-left: 85px;
}

.ph-mediclic-download-app-container {
	background: $thirdBackgroundColor;
	text-align: center;
	padding: 60px 0 0px 0;
	margin-bottom: -50px;
}

.ph-mediclic-download-app-title {
	font-weight: 500;
	color: $colorGrayText3;
	font-size: 40px;
}

.ph-mediclic-download-app-subtitle {
	color: $colorGrayText3;
	font-size: 14px;
	max-width: 500px;
}

.ph-mediclic-download-app-content-img {
	max-width: 200px;
}

.ph-mediclic-download-app-img {
	max-width: 200px;
	width: 100%;
	margin-bottom: 40px;
	padding: 5px;
}

.ph-mediclic-family-img {
	width: 250px;
}

@media screen and (max-width: 480px) {
	.ph-mediclic-title,
	.ph-mediclic-main-title {
		font-size: 22px;
	}
	.ph-mediclic-inner-title {
		font-size: 32px;
		margin: 0;
	}
	.ph-mediclic-inner-btn {
		margin-left: 40px;
	}
	.ph-mediclic-icons-img {
		margin: 0;
		padding: 5px;
	}
	.ph-mediclic-services-titles {
		font-size: 13px;
		margin-bottom: 0;
	}
	.ph-mediclic-download-app-title {
		font-size: 30px;
	}
	.ph-mediclic-download-app-container {
		padding: 5px;
		margin-bottom: -55px;
	}
	.ph-mediclic-family-img {
		width: 200px;
	}
	.ph-mediclic-doctor-services-titles,
	.ph-mediclic-services-subtitles {
		font-size: 11px;
		margin-top: 7px;
	}
}

@media screen and (max-width: 576px) {
	.ph-mediclic-main-content {
		padding-left: 10px;
	}
	.ph-mediclic-services-main-subtitles {
		margin: 0 0 15px 50px;
		font-size: 12px;
	}
	.ph-mediclic-images-overlay {
		opacity: 0.2;
	}
	.ph-mediclic-image-b.ph-mediclic-image-sample-5 {
		display: none;
	}
	.ph-mediclic-services-main-subtitles {
		color: $colorGrayText3;
	}
}

@media screen and (max-width: 768px) {
	.ph-mediclic-main-content {
		padding-left: 20px;
	}
	.ph-mediclic-services-main-subtitles {
		margin: 0 0 40px 60px;
	}
}
