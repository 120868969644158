/** @format */

@import '../../components/Commons/Sass/components/colors';

.ph-upload-pharmacy-header-container {
	background-image: url('https://storage.googleapis.com/master-image-bucket/react-page-static/upload-pharmacy/bg-header.jpg');
	background-image: -webkit-image-set(
		url('https://storage.googleapis.com/master-image-bucket/react-page-static/upload-pharmacy/bg-header.webp')
			1x
	);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	height: 450px;
	position: relative;
}

.ph-upload-pharmacy-header-container:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	z-index: 0;
}

.ph-upload-pharmacy-header-contain {
	width: 100%;
	padding: 120px 10px;
	text-align: center;
	z-index: 1;
	position: relative;
}

.ph-types-recipe-logo-pharol {
	width: 100%;
	max-width: 200px;
	-webkit-filter: drop-shadow(5px 5px 5px #1d1d1d);
	filter: drop-shadow(5px 5px 5px #1d1d1d);
	margin: auto;
}

.ph-upload-pharmacy-title {
	color: $colorWhiteText;
	font-size: 40px;
	font-weight: 900;
	text-shadow: 4px 4px 5px #1d1d1d;
	-webkit-filter: drop-shadow(5px 5px 5px #1d1d1d);
	filter: drop-shadow(5px 5px 5px #1d1d1d);
	margin: 0;
}

.ph-upload-pharmacy-span {
	color: $colorWhiteText;
	font-size: 20px;
	font-weight: 300;
	text-shadow: 4px 4px 5px #1d1d1d;
	-webkit-filter: drop-shadow(5px 5px 5px #1d1d1d);
	filter: drop-shadow(5px 5px 5px #1d1d1d);
}

.ph-upload-pharmacy-subtitle {
	margin: 20px 0;
	font-size: 34px;
	font-weight: bold;
	color: $primaryColor;
	text-align: center;
}

.ph-upload-pharmacy-global-container {
	max-width: 1185px;
	padding: 10px;
	margin: auto;
	box-sizing: border-box;
}

.ph-upload-pharmacy-main-container {
	margin: auto;
	width: 100%;
	justify-content: start;
}

.ph-upload-pharmacy-hero-title {
	font-size: 35px;
	font-weight: 900;
	margin-left: 25px;
	margin-bottom: 10px;
}

.ph-upload-pharmacy-hero-subtitle {
	font-size: 23px;
	font-weight: 500;
	color: $colorGrayText4;
	text-align: start;
	padding-left: 10px;
}

.ph-upload-pharmacy-hero-pharagraf {
	font-size: 19px;
	color: $colorGrayText4;
	text-align: justify;
}

.ph-types-recipe-image-steps {
	max-width: 100%;
	width: 100%;
}

.ph-upload-pharmacy-container-image,
.ph-upload-pharmacy-container-image-main {
	max-width: 50px;
	width: 100%;
	margin: auto;
}

.ph-upload-pharmacy-divider-img {
	max-width: 500px;
	padding-left: 20%;
}

.ph-upload-pharmacy-btn,
.ph-upload-pharmacy-btn:focus,
.ph-upload-pharmacy-btn:hover,
.ph-upload-pharmacy-btn:active {
	border-radius: 4px;
	padding: 0 20px;
	background-color: $errorColor;
	color: $colorWhiteText;
	font-weight: 900;
	font-size: 16px;
	height: 36px;
	min-width: 150px;
}

@media screen and (max-width: 480px) {
	.ph-upload-pharmacy-header-container {
		height: 220px;
		padding: 25px 10px;
	}
	.ph-types-recipe-logo-pharol {
		max-width: 150px;
	}
	.ph-upload-pharmacy-title {
		font-size: 26px;
	}
	.ph-upload-pharmacy-span {
		font-size: 12px !important;
	}
	.ph-upload-pharmacy-header-container {
		background-attachment: unset;
	}
}

@media screen and (max-width: 576px) {
	.ph-upload-pharmacy-span {
		font-size: 14px;
	}
}

@media screen and (max-width: 768px) {
	.ph-upload-pharmacy-header-container {
		height: 270px;
	}
	.ph-upload-pharmacy-header-contain {
		padding: 60px 10px;
	}
	.ph-upload-pharmacy-global-container {
		padding: 20px;
	}
	.ph-upload-pharmacy-main-container,
	.ph-upload-pharmacy-inner-container {
		justify-content: center;
		text-align: center;
	}
	.ph-upload-pharmacy-hero-pharagraf,
	.ph-upload-pharmacy-hero-subtitle {
		justify-content: center;
		text-align: center;
	}
	.ph-upload-pharmacy-hero-pharagraf {
		font-size: 16px;
		color: $colorGrayText4;
	}
	.ph-upload-pharmacy-divider-img {
		display: none;
	}
	.ph-upload-pharmacy-header-container {
		background-attachment: unset;
	}
}
