/** @format */

@import "../../../Sass/components/colors";

.ph-product-button-container {
  margin-bottom: 0;

  .button-secondary{
    margin: 5px auto 0;
  }

}



.ph-product-button {
  background-color: $secondColor;
  border-color: $secondColor;
  color: $colorWhiteText;
  font-size: 13px;
  border-radius: 4px;
  font-weight: 600;
  text-transform: uppercase;
  width: 100%;
  height: 36px;
  max-width: 130px;
  box-shadow: $secondaryBoxShadow;
  display: flex;
  justify-content: center;
  align-items: center;
  //margin: 5px auto 10px;
  margin: 5px auto 0;
}

.ph-product-button:hover,
.ph-product-button:focus,
.ph-product-button:active {
  background-color: $secondColor;
  border-color: $secondColor;
  color: $colorWhiteText;
}

.ph-product-button-quote,
.ph-product-button-quote:hover,
.ph-product-button-quote:focus,
.ph-product-button-quote:active {
  background-color: $primaryColor;
  border-color: $primaryColor;
  color: $colorWhiteText;
  font-size: 13px;
  border-radius: 4px;
  font-weight: 600;
  text-transform: uppercase;
  width: 100%;
  height: 36px;
  max-width: 130px;
  box-shadow: $secondaryBoxShadow;
  padding: 4px 15px;
  border: 1px solid transparent;
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  display: flex;
  justify-content: center;
  align-items: center;
  //margin: 5px auto 10px;
  margin: 5px auto 0;
}

.ph-product-button-on_demand,
.ph-product-button-on_demand:hover,
.ph-product-button-on_demand:focus,
.ph-product-button-on_demand:active {
  background-color: $primaryColor;
  border-color: $primaryColor;
  color: $colorWhiteText;
  font-size: 13px;
  border-radius: 4px;
  font-weight: 600;
  text-transform: uppercase;
  width: 100%;
  height: 36px;
  max-width: 130px;
  box-shadow: $secondaryBoxShadow;
  padding: 4px 15px;
  border: 1px solid transparent;
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  display: flex;
  justify-content: center;
  align-items: center;
  //margin: 5px auto 10px;
  margin: 5px auto 0;
}

.ph-product-list-button-container {
  margin-bottom: 0 !important;
}

.ph-product-list-button {
  margin: 0 !important;
  min-width: 145px;
}

.ph-product-list-button-alternatives {
  min-width: 100px;
  font-size: 11px;
  height: 30px;
  max-width: 100px;
}

.ph-product-list-button-quote {
  margin: 0 !important;
  min-width: 145px;
}

.ph-product-list-button-quote-alternatives {
  min-width: 100px;
  font-size: 11px;
  height: 30px;
  max-width: 100px;
}

.ph-product-list-button-quote-alternatives,
.ph-product-list-button-quote-alternatives:hover,
.ph-product-list-button-quote-alternatives:focus,
.ph-product-list-button-quote-alternatives:active {
  min-width: 85px;
  font-size: 11px;
  height: 30px;
  max-width: 100px;
  font-weight: 600;
  padding: 6px 10px;
}

.ph-product-button-alternative,
.ph-product-button-alternative:hover,
.ph-product-button-alternative:focus,
.ph-product-button-alternative:active {
  background-color: $primaryBackgroundColor;
  border: 2px solid $primaryColor !important;
  color: $primaryColor;
  font-size: 13px;
  border-radius: 4px;
  font-weight: 600;
  text-transform: uppercase;
  width: 100%;
  height: 36px;
  max-width: 130px;
  box-shadow: $secondaryBoxShadow;
  display: flex;
  justify-content: center;
  align-items: center;
  //margin: 5px auto 10px;
  margin: 5px auto 0;
}

@media (max-width: 575px) {
  .ph-product-messaje-main-container {
    padding: 10px 5px;
  }

  .ph-product-messaje-title {
    font-size: 12px;
  }

  .ph-product-messaje-pharmacy-title {
    font-size: 11px;
  }

  .ph-product-messaje-quantity-title {
    font-size: 11px;
  }

  .ph-product-messaje-quantity-description {
    font-size: 11px;
  }

  .ph-product-messaje-total-title {
    font-size: 10px;
  }

  .ph-product-messaje-total-button-container {
    margin-right: 6px;
  }

  .ph-product-messaje-total-button-1 {
    font-size: 16px;
    padding: 0 8px;
  }

  .ph-product-messaje-total-button-2 {
    font-size: 11px;
    padding: 0 8px;
  }
}

@media (max-width: 600px) {
  .ph-product-list-button {
    min-width: 130px;
  }

  .ph-product-list-button-quote {
    min-width: 130px;
  }
}
