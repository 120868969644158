/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-preorder-schedules-global-container {
	max-width: 900px;
	padding: 50px 10px 80px 10px;
	margin: auto;
}

.ph-preorder-schedules-global-inner-container {
	height: auto;
	min-height: calc(100vh - 268px);
}

.ph-preorder-schedules-main-container {
	padding: 20px;
	border-radius: 4px;
	box-shadow: $mainHeaderboxShadow;
}

.ph-preorder-schedules-text-container {
	padding: 0 10px;
	margin-bottom: 20px;
}

.ph-preorder-schedules-text-title {
	margin: 0px;
	color: $colorGrayText3;
	font-size: 17px;
	margin-bottom: 1px;
	font-weight: lighter;
}

.ph-preorder-form-container,
.ph-preorder-upload-container,
.ph-preorder-button-container {
	padding: 0 10px;
}

.ph-preorder-field-input {
	max-width: 100%;
	height: 45px;
	border-radius: 6px;
	font-size: 13px;
	color: $colorGrayText2;
}

.ph-preorder-schedules-form-container {
	padding: 0 10px;
}

.ph-preorder-schedules-delivery-selector .ant-select-selector {
	max-width: 100%;
	height: 45px !important;
	border-radius: 6px !important;
	font-size: 13px !important;
}

.ph-preorder-schedules-global-container .ant-form-item {
	margin-bottom: 5px;
}

.ph-preorder-button-schedule-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 0 10px;
	margin: 10px 0;
}

.ph-preorder-button-schedule,
.ph-preorder-button-schedule:active,
.ph-preorder-button-schedule:focus,
.ph-preorder-button-schedule:hover {
	background-color: $secondColor !important;
	color: $colorWhiteText;
	padding: 5px 10px;
	font-size: 15px;
	border-radius: 4px;
	font-weight: 900;
	border: 2px solid $secondColor !important;
	text-transform: capitalize;
	height: 36px;
	width: 170px;
}

@media (max-width: 576px) {
	.ph-preorder-schedules-text-title {
		font-size: 15px;
	}
}

@media (max-width: 480px) {
	.ph-preorder-schedules-text-title {
		margin-top: 0px;
		font-size: 12px;
	}
}
