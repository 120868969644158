/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-bag-no-products-main-container {
	padding: 10px;
	margin-bottom: 40px;
	min-height: calc(100vh - 189px);
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-bag-no-products-electronic-main-container {
	padding: 10px;
	margin-bottom: 40px;
	min-height: calc(100vh - 153px);
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-bag-no-products-container {
	padding: 5px;
	position: relative;
}

.ph-bag-no-products-inner-container {
	text-align: center;
	margin-bottom: 50px;
}

.ph-bag-no-products-image-container {
	padding: 10px;
}

.ph-bag-no-products-image {
	width: 250px;
//	max-width: 10px;
	opacity: 0.4;
}

.ph-bag-no-products-title {
	color: $colorGrayText4;
	font-weight: 900;
	font-size: 25px;
	opacity: 0.5;
	margin-bottom: 0;
}

.ph-bag-no-products-subtitle {
	color: $colorGrayText4;
	font-weight: 500;
	font-size: 20px;
	opacity: 0.5;
}

.ph-bag-no-products-link {
	color: $primaryColor;
	font-weight: 500;
	font-size: 17px;
	opacity: 0.5;
	text-decoration: underline;
}

.ph-bag-no-products-message {
	color: $colorGrayText4;
	font-style: italic;
	font-size: 14px;
	max-width: 600px;
}

.ph-bag-error-product-button {
	background-color: $secondColor;
	border-color: $secondColor;
	color: $colorWhiteText;
	margin: 5px 0 8px;
	font-size: 15px;
	border-radius: 4px;
	font-weight: 600;
	text-transform: uppercase;
	width: 100%;
	height: 35px;
	max-width: 175px;
	box-shadow: $secondaryBoxShadow;
}

.ph-bag-error-product-button:hover,
.ph-bag-error-product-button:focus,
.ph-bag-error-product-button:active {
	background-color: $secondColor;
	border-color: $secondColor;
	color: $colorWhiteText;
}

@media (max-width: 992px){
	.ph-bag-no-products-image {
		width: 130px;
	}
}


@media (max-width: 600px) {
	.ph-bag-no-products-subtitle {
		font-size: 15px;
	}
}

