/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-checkout-main-box-main-container {
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 4%), 0 1px 1px 0 rgb(0 0 0 / 4%), 0 1px 3px 0 rgb(0 0 0 / 12%);
	background-color: $primaryBackgroundColor;
	border-radius: 4px;
	margin: 4px;
	padding: 20px 25px;
}

.ph-checkout-main-box-main-title-container::marker {
	font-size: 26px;
	line-height: 5px;
	height: auto;
	margin: 0 !important;
	padding: 0 !important;
	color: $secondColor;
	text-align-last: auto !important;
}

.ph-checkout-main-box-main-title-container {
	position: relative;
	list-style: disc;
	margin-bottom: 5px;
	opacity: 0.7;
}

.ph-checkout-main-box-main-title {
	color: $colorGrayText4;
	font-size: 14px;
	font-weight: 900;
	margin-top: -4px;
	margin-bottom: 0;
	margin-left: -20px;
	position: absolute;
}

.ph-checkout-main-box-delivery-image-1-container {
	margin-right: 10px;
	top: -4px;
	position: relative;
}

.ph-checkout-main-box-delivery-image-1 {
	width: 100%;
	max-width: 20px;
}

.ph-checkout-main-box-delivery-image-1-container {
	margin-right: 2px;
	top: -3px;
	position: relative;
}

.ph-checkout-main-box-delivery-title {
	color: $colorGrayText2;
	font-weight: 500;
	position: relative;
	top: -2px;
}

.ph-checkout-main-box-step-title {
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 0;
	max-width: 235px;
	width: 100%;
}

.ph-checkout-main-box-inner-container {
	position: relative;
	display: flex;
	width: 100%;
	margin-bottom: 15px;
	padding: 0 10px;
	opacity: 0.7;
}

.ph-checkout-main-box-description-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-checkout-main-box-image-logo-container {
	margin-right: 5px;
	margin-top: -2px;
}

.ph-checkout-main-box-image-logo {
	width: 100%;
	max-width: 18px;
}

.ph-checkout-main-box-delivery-container {
	border-top: 1px solid $primaryColorBorder;
	margin-top: 5px;
	margin-bottom: 10px;
}

.ph-checkout-main-box-title-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-checkout-main-box-title {
	font-size: 12px;
	font-weight: 900;
	text-align: center;
	margin-top: 3px;
	margin-bottom: 0;
}

.ph-checkout-main-box-subtitle {
	text-align: center;
	color: $primaryColor;
	font-size: 13px;
	font-weight: 600;
	line-height: 15px;
	text-transform: capitalize;
}

.ph-checkout-main-box-subtitle-info {
	color: $colorGrayText4;
	text-align: center;
	font-size: 13px;
	opacity: 0.5;
	font-style: italic;
	font-weight: 500;
	margin-top: 5px;
}

.ph-checkout-main-box-subtitle-disabled {
	text-align: center;
	color: $primaryColor;
	font-size: 13px;
	font-weight: 600;
	line-height: 15px;
	opacity: 0.6;
}

.ph-checkout-main-box-total-price {
	font-weight: 900;
	padding: 15px;
	font-size: 14px;
	color: $colorGrayText4;
	line-height: 20px;
	margin-bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-checkout-main-box-total-price-info {
	font-size: 18px;
	color: $colorBackText;
}

.ph-checkout-main-box-price-main-title {
	color: $colorGrayText4;
	font-weight: 600;
	font-size: 22px;
	margin-bottom: 10px;
	text-align: center;
}

.ph-checkout-main-box-price-detail-delivery {
	background-color: $primaryBackgroundColor;
	border: none;
}

.ph-checkout-main-box-price-detail-delivery .ant-collapse-header {
	padding: 0 15px !important;
	font-size: 12px;
	color: $colorGrayText4 !important;
	font-weight: 900;
}

.ph-checkout-main-box-price-detail-delivery > .ant-collapse-item {
	border-bottom: none !important;
}

.ph-checkout-main-box-price-detail-delivery .ant-collapse-content {
	border-top: none !important;
}

.ph-checkout-main-box-price-detail-delivery .ant-collapse-content > .ant-collapse-content-box {
	padding: 3px 20px;
}

.ph-checkout-main-box-price-detail-delivery-pharmacy-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-checkout-main-box-price-detail-delivery-pharmacy-inner-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-checkout-main-box-price-detail-delivery-pharmacy-image-container {
	margin-right: 5px;
	margin-top: -3px;
}

.ph-checkout-main-box-price-detail-delivery-pharmacy-image {
	width: 100%;
	max-width: 12px;
}

.ph-checkout-main-box-price-detail-delivery-pharmacy-title {
	padding: 0;
	font-size: 12px;
	color: $colorGrayText3 !important;
	font-weight: 600;
	margin-bottom: 0;
}

.ph-checkout-main-box-price-detail-delivery-pharmacy-price {
	padding: 0;
	font-size: 12px;
	color: $colorGrayText3 !important;
	font-weight: 600;
	margin-bottom: 0;
}

.ph-checkout-main-box-price-detail-delivery-pharmacy-total-main-container {
	padding: 0 15px;
}

.ph-checkout-main-box-price-detail-delivery-pharmacy-separator {
	border-top: 1px solid $primaryColorBorder;
	margin-top: 5px;
	margin-bottom: 5px;
}

.ph-checkout-main-box-delivery-price-container {
	padding: 10px 10px 15px 10px;
	border-bottom: 1px solid $primaryColorBorder;
	margin: 10px 0;
}

.ph-cart-checkout-box-voucher-container {
	border: 1px solid $primaryColorBorder;
	border-radius: 4px;
	padding: 5px;
	max-width: 240px;
	margin: auto;
}

.ph-cart-checkout-box-voucher-main-title {
	font-weight: 900;
	padding: 0 15px;
	font-size: 12px;
	color: $colorGrayText4;
	margin-bottom: 3px;
}

.ph-cart-checkout-box-voucher-image-container {
	padding: 2px 2px 5px 2px;
	margin: 0 10px;
	width: 100%;
}

.ph-cart-checkout-box-voucher-image {
	width: 100%;
	max-width: 25px;
	opacity: 0.7;
}

.ph-cart-checkout-box-voucher-title {
	color: $primaryColor;
	font-size: 12px;
	margin-bottom: 0;
	font-weight: 500;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
	opacity: 0.8;
}

.ph-checkout-main-box-delivery-pharmacy-main-container {
	margin-left: 15px;
}

.ph-checkout-main-box-delivery-pharmacy-image-main-container {
	display: flex;
	align-items: center;
}

.ph-checkout-main-box-delivery-pharmacy-image-container {
	margin-right: 3px;
}

.ph-checkout-main-box-delivery-pharmacy-image {
	width: 100%;
	max-width: 15px;
	margin-top: -4px;
}

.ph-checkout-main-box-delivery-pharmacy-main-title {
	padding: 0;
	font-size: 12px;
	color: #7b7b7b !important;
	font-weight: 600;
	margin-bottom: 0;
}

.ph-checkout-main-box-delivery-pharmacy-retirement-main-container {
	display: flex;
	align-items: center;
	margin-left: 10px;
}

.ph-checkout-main-box-delivery-pharmacy-retirement-title {
	color: $colorGrayText2;
	font-size: 14px;
	font-weight: 500;
}

.ph-cart-checkout-box-total-price {
	font-weight: 500;
	padding: 0;
	font-size: 19px;
	color: $colorGrayText4;
	line-height: 20px;
	margin-bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-cart-checkout-box-total-price-info {
	font-size: 25px;
	color: $colorBackText;
	font-weight: 900;
}

.ph-cart-checkout-box-total-price-info-symbol {
	font-size: 17px;
	margin-right: 3px;
}

@media (max-width: 1100px) {
	.ph-checkout-main-box-main-title {
		font-size: 12px;
		margin-top: 0;
	}
}
@media (max-width: 1024px) {
	.ph-checkout-main-box-delivery-price-container {
		padding: 10px 10px 0 10px;
		border-bottom: none;
		margin: 10px 0;
	}
}

@media (max-width: 1024px) {
	.ph-checkout-main-box-delivery-container {
		padding: 9px 4px;
	}

	.ph-checkout-main-box-title {
		font-size: 11px;
	}
}

@media (max-width: 992px) {
	.ph-cart-checkout-box-voucher-container {
		max-width: 100%;
	}
}

@media (max-width: 600px) {
	.ph-checkout-last-main-box-delivery-container{
		border-top: 1px solid $primaryColorBorder;
		margin-top: 5px;
		margin-bottom: 10px;
		padding: 4px 4px;
	}
}