/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-profile-recipes-error-main-container {
	padding: 10px;
	width: 100%;
	max-width: 1140px;
	margin: auto;
}

.ph-profile-recipes-error-container {
	margin-top: 30px;
	box-shadow: $primaryBoxShadow;
	padding: 20px;
	border-radius: 4px;
	min-height: 400px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.ph-profile-recipes-error-title {
	color: $secondColor;
	font-weight: 600;
	font-size: 18px;
}

.ph-profile-recipes-error-subtitle {
	color: $colorGrayText3;
	font-weight: 500;
	font-size: 15px;
	font-style: italic;
}
