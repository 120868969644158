/** @format */

@import '../../components/Commons/Sass/components/colors';

.ph-about-global-container {
	max-width: 1185px;
	padding: 10px;
	margin: auto;
	box-sizing: border-box;
}

.ph-about-header-container {
	position: relative;
}

.ph-about-header-content {
	background: url('https://i.ibb.co/py8C2xB/medic1.jpg');
	height: 650px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: left top;
	background-attachment: fixed;
}

.ph-about-header-content:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	z-index: 0;
}

.ph-about-header {
	position: relative;
	z-index: 1;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 650px;
}

.ph-about-header-image-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.ph-about-header-image {
	width: auto;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	height: -webkit-fill-available;
	height: 400px;
}

.ph-about-header-text-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 15px;
	margin: auto;
}

.ph-about-header-title {
	color: $colorWhiteText;
	font-size: 50px;
	font-weight: bold;
}

.ph-about-header-subtitle {
	color: $colorWhiteText;
	font-size: 20px;
	margin: 15px 0 25px 0;
	font-weight: 400;
}

.ph-about-header-btn,
.ph-about-header-btn:focus,
.ph-about-header-btn:hover,
.ph-about-header-btn:active {
	background-color: $primaryColor !important;
	color: $colorWhiteText;
	font-size: 14px;
	border-radius: 4px;
	font-weight: 900;
	padding: 5px 27px !important;
	height: 40px;
	border: $primaryColor;
	max-width: 155px;
	box-shadow: $primaryBoxShadow;
	margin: auto;
}

.ph-about-main-container,
.ph-about-info-container,
.ph-about-iphone-container {
	padding: 100px 0;
}

.ph-about-main-img-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.ph-about-main-img {
	width: 100%;
	max-width: 500px;
}

.ph-about-main-text-container {
	margin: auto;
	padding: 20px;
}

.ph-about-main-span {
	color: $colorGrayText1;
	font-size: 20px;
}

.ph-about-main-title {
	color: $primaryColor;
	margin: 20px 0;
	font-size: 30px;
	font-weight: 800;
}

.ph-about-main-subtitle {
	color: $colorGrayText4;
}

.ph-about-info-mision-container {
	padding: 0 35px;
}

.ph-about-info-mision-content {
	text-align: right;
	margin-top: -50px;
}

.ph-about-info-mision-img {
	width: 100%;
	max-width: 400px;
}

.ph-about-info-mision-text {
	width: 80%;
	height: auto;
	padding: 50px 50px;
	position: relative;
	background-color: $primaryBackgroundColor;
	border-radius: 8px;
	box-shadow: $primaryBoxShadow;
}

.ph-about-info-vision-text {
	width: 80%;
	height: auto;
	padding: 50px 50px;
	position: relative;
	background-color: #ffffff;
	border-radius: 8px;
	margin-top: -60px;
	box-shadow: $primaryBoxShadow;
}

.ph-about-info-mision-title {
	color: $primaryColor;
	margin-bottom: 20px;
	font-weight: 800;
	font-size: 24px;
}

.ph-about-info-mision-subtitle {
	color: $colorGrayText4;
	font-weight: 300;
	font-size: 16px;
}

.ph-about-info-mision-img-desktop {
	text-align: right;
	margin-top: -50px;
}

.ph-about-info-mision-img-desk {
	width: 100%;
	max-width: 400px;
}

.ph-about-info-vision-container {
	padding: 0 35px;
}

.ph-about-info-vision-content {
	text-align: right;
}

.ph-about-info-vision-img {
	width: 100%;
	max-width: 400px;
}

.ph-about-download {
	position: relative;
	margin-bottom: -50px;
}

.ph-about-download-content {
	background: url('https://i.ibb.co/PGMbThF/purchase.jpg');
	height: 400px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: left top;
	background-attachment: fixed;
	z-index: 1;
	position: relative;
}

.ph-about-download-content:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	z-index: 0;
}

.ph-how-to-by-dowlond-text-content {
	margin: 100px 0 0 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 1;
}

.ph-how-to-by-dowlond-buttons-content {
	z-index: 1;
}

.ph-about-download-inner-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: auto;
	z-index: 1;
}

.ph-about-download-title {
	color: $colorWhiteText;
	font-size: 40px;
	margin-bottom: 15px;
	font-weight: 500;
	text-align: center;
	align-items: center;
}

.ph-about-download-paraghraf {
	color: $colorWhiteText;
	font-size: 20px;
	margin-bottom: 30px;
}

.ph-about-iphone-conteiners {
	margin: auto;
}

.ph-about-iphone-conteiners-text {
	min-height: 160px;
	text-align: left;
	padding: 15px;
}

.ph-about-iphone-conteiners-title {
	margin: 5px 0;
	color: $colorGrayText3;
	font-weight: 800;
	font-size: 19px;
}

.ph-about-iphone-conteiners-paraghraf {
	font-size: 13px;
	color: $colorGrayText3;
}

.ph-about-iphone-conteiners-img {
	width: 100%;
	max-width: 38px;
}

.ph-about-iphone-movil-img {
	width: 100%;
	max-width: 290px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
}

.ph-about-iphone-conteiners-text-right {
	min-height: 160px;
	text-align: right;
	padding: 15px;
	margin: auto;
}

.ph-about-iphone-conteiners-title-right {
	margin: 5px 0;
	color: $colorGrayText3;
	font-weight: 800;
	font-size: 19px;
}
.ph-about-iphone-conteiners-subtitle-right {
	margin: 5px 0;
	color: $colorGrayText3;
	font-size: 13px;
	color: $colorGrayText4;
}

@media (min-width: 1024px) {
	.ph-about-header-text-container {
		margin: auto;
	}
}

@media (max-width: 1024px) {
	.ph-about-info-mision-text,
	.ph-about-info-vision-text {
		padding: 20px;
		text-align: center;
		height: auto;
	}
}

@media (min-width: 992px) {
	.ph-about-info-mision-img {
		display: none;
	}
	.ph-about-info-mision-img-desktop {
		display: block;
	}
	.ph-about-header-btn,
	.ph-about-header-btn:focus,
	.ph-about-header-btn:hover,
	.ph-about-header-btn:active {
		margin: 0;
	}
}

@media (max-width: 769px) {
	.ph-about-info-mision-img {
		display: block;
		max-width: 300px;
		margin-bottom: 2px;
		margin-left: 100px;
	}
	.ph-about-info-mision-img-desktop {
		display: none;
	}
}

@media (max-width: 768px) {
	.ph-about-main-container {
		padding: 100px 0 40px 0;
	}
	.ph-about-main-img-container {
		width: 100%;
		max-width: 100%;
		padding: 15px;
	}
	.ph-about-main-img {
		width: 100%;
		max-width: 300px;
	}
	.ph-about-header,
	.ph-about-main-subtitle,
	.ph-about-main-span {
		text-align: center;
	}
	.ph-about-header-image {
		display: none;
	}
	.ph-about-info-mision-content {
		text-align: center;
	}
	.ph-about-info-mision-img {
		max-width: 300px;
		margin-bottom: 2px;
		margin-left: 100px;
	}
	.ph-about-info-mision-text,
	.ph-about-info-vision-text {
		margin: auto;
		max-height: 250px;
	}
	.ph-about-info-mision-img-desktop {
		display: none;
	}
	.ph-about-info-vision-img {
		max-width: 300px;
		margin-top: 60px;
		margin-left: 18px;
	}
	.ph-about-info-vision-content {
		text-align: center;
	}
	.ph-about-info-vision-text {
		margin: -45px auto 0;
	}
	.ph-about-main-text-container {
		padding: 20px;
		text-align: center;
	}
	.ph-about-download-title {
		font-size: 30px;
	}
	.ph-about-download-paraghraf {
		font-size: 17px;
	}
	.ph-about-iphone-content {
		padding: 100px 35px;
	}
	.ph-about-iphone-conteiners-text {
		max-width: 400px;
		margin: auto;
		text-align: center;
	}
	.ph-about-iphone-movil-img {
		display: none;
	}
	.ph-about-iphone-container,
	.ph-about-iphone-content {
		padding: 0;
	}
	.ph-about-iphone-conteiners-text-right {
		min-height: 160px;
		max-width: 370px;
		text-align: center;
		padding: 15px;
		margin: auto;
	}
	.ph-about-main-text-container-span {
		display: none;
	}
	.ph-how-to-by-header-image {
		max-width: 170px;
		margin: auto;
	}
}

@media (max-width: 667px) {
	.ph-about-info-vision-container,
	.ph-about-info-mision-container {
		padding: 0;
	}
}

@media (max-width: 576px) {
	.ph-about-header-title {
		font-size: 40px;
	}
	.ph-how-to-by-header-image {
		max-width: 157px;
		margin: auto;
	}
	.ph-about-header-subtitle {
		font-size: 15px;
		padding: 0 5px;
	}
	.ph-about-main-container {
		padding: 10px 0 10px 0;
	}
	.ph-about-main-title {
		font-size: 25px;
	}
	.ph-about-main-subtitle {
		font-size: 15px;
	}
	.ph-about-info-mision-img {
		margin-left: 90px;
	}
	.ph-about-info-vision-img {
		margin-left: 7px;
	}
	.ph-about-download-title {
		font-size: 25px;
	}
	.ph-about-download-paraghraf {
		font-size: 15px;
	}
	.ph-how-to-by-dowlond-playstore,
	.ph-how-to-by-dowlond-appstore {
		max-width: 161px;
		width: 100%;
		padding: 2px;
	}
	.ph-about-info-mision-subtitle {
		font-size: 13px;
	}
}

@media (max-width: 480px) {
	.ph-about-info-mision-img {
		max-width: 300px;
		margin-bottom: 2px;
		margin-left: 13px;
	}
	.ph-about-download-title {
		font-size: 22px;
	}
	.ph-about-download-paraghraf {
		font-size: 13px;
	}

	.ph-how-to-by-header-image {
		max-width: 157px;
		margin: auto;
	}
}
