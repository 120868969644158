/** @format */

@import '../../../../../../../../../../components/Commons/Sass/components/colors';

.ph-loginpharmacies-loading-container {
	background-color: $primaryBackgroundColor;
	border-radius: 5px;
	margin-bottom: 15px;
	box-shadow: $primaryBoxShadow;
}

.ph-deatail-pharmacy-product-list-loader-container-image {
	color: $primaryColor;
}

.ph-deatail-pharmacy-product-list-loader-container {
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
}
