/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-product-detail-quote-contact-container {
	margin-bottom: 0;
	padding: 14px 0;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-product-detail-quote-contact-title {
	font-size: 13px;
	color: $colorGrayText4;
	font-weight: 100;
	margin-bottom: 0;
}

.ph-product-detail-quote-contact-link {
	color: $primaryColor;
	font-weight: 600;
}

.ph-product-detail-quote-contact-link-span {
	margin-right: 20px;
}

.ph-product-detail-quote-contact-link:hover,
.ph-product-detail-quote-contact-link:active,
.ph-product-detail-quote-contact-link:focus {
	color: $primaryColor;
	font-weight: 600;
	text-decoration: underline;
}

@media (max-width: 575px) {
	.ph-product-detail-quote-contact-title {
		font-size: 12px;
	}

	.ph-product-detail-quote-contact-container {
		display: none;
	}
}
