/** @format */

@import '../../../../../../Sass/components/colors';
@import '../../../../../../../../v2-config/colors.scss';

.icons-theme {
	color: $generic-dark-grey1;
	height: 30px;
	width: 30px;
	margin: 0px 0px;
	cursor: pointer;

	&:hover {
		color: $primary-hover;
	}

	&:active,
	&.active {
		color: $primary-default;
	}
}

.login-aux {
	top: 14px;
	right: 36px;
	position: absolute;
}

.ph-loginuser-dropdown-menu {
	border-radius: 2px;
	text-align: center;
	padding: 10px;
	box-shadow: none !important;
}

.ph-loginuser-list-item {
	font-size: 14px;
	color: $colorGrayText3;
	font-weight: 100;
	padding: 7px 5px !important;
	margin: 0 !important;
	height: auto !important;
	line-height: 20px !important;
}

.ph-loginuser-list-item:hover {
	background-color: $primaryBackgroundColor;
}

.ph-loginuser-button-register {
	background: $primaryColor !important;
	color: $colorWhiteText !important;
	font-size: 14px;
	text-transform: capitalize;
	border-radius: 4px;
	margin: 6px 8px !important;
	padding: 10px 30px !important;
	height: 40px;
	box-shadow: $secondaryBoxShadow;
	border: none;
	line-height: 20px !important;
	margin-top: 5px !important;
}

.ph-loginuser-list-item-register {
	padding: 0 !important;
	margin: 5px 0 3px 0 !important;
	height: auto !important;
	line-height: 15px !important;
}

.ph-loginuser-list-item-register-link {
	margin-top: 4px;
	color: $primaryColor !important;
	font-weight: 500;
	font-size: 16px;
}

.ph-loginuser-global-container {
	display: block;
	text-align: right;
	width: 100%;
	max-width: 250px;
	margin: 2px 0 0 auto;
}

.ph-loginuser-link-container {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: flex-end;
	min-height: 48px;
}

.ph-loginuser-icon-container-last {
	width: 40px;
	height: 40px;
	display: inline-block;
	border: 1px solid $colorWhiteBorder;
	padding: 1px;
	transition: 0.3s ease;
}

.ph-loginuser-icon-container-last-arg {
	@extend .ph-loginuser-icon-container-last;
	border: 1px solid $fourthBackgroundColor;
}

.ph-loginuser-icon {
	width: 32px;
	z-index: 1;
	border-radius: 0;
}

.ph-loginuser-dropdown {
	top: 45px !important;
	position: fixed;
	box-shadow: $secondaryBoxShadow;
}

.ph-loginuser-button-user {
	height: auto;
	padding: 0;
	border: none;
	box-shadow: none;
}

.ph-loginuser-text {
	color: $colorGrayText3;
	font-size: 16px;
	margin-top: -100px !important;
	font-weight: 400;
}

.ph-loginuser-text-logged {
	color: $primaryColor; // !important
	font-size: 15px;
	position: relative;
	font-weight: 500;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 220px;
	//width: 220px;
}

.ph-loginuser-text-logged-arg {
	color: $colorWhiteText;
}