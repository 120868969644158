/** @format */

.ph-electronic-quote-pharmacy-list-pharmacy-geo-icon {
	font-size: 17px;
}

.ph-electronic-quote-pharmacy-list-pharmacy-geo-title {
	font-size: 12px;
	letter-spacing: -0.6px;
	margin-bottom: 0;
	line-height: 12px;
}
