/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-detail-list-product-container {
	border: 1px solid $secondBackgroundColor;
	border-radius: 4px;
	margin-bottom: 10px;
	padding: 7px;
}
.ph-detail-list-product-container:last-child {
	margin-bottom: 0;
}

.ph-checkout-detail-on-demand-main-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.checkout-detail-on-demand-image-container {
	margin-left: 3px;
}

.checkout-detail-on-demand-image {
	width: 100%;
	max-width: 16px;
}

.checkout-detail-on-demand-title {
	font-size: 13px;
	font-weight: 100;
	color: $primaryColor;
	margin-bottom: 1px;
}

.ph-detail-list-product-image-container {
	padding: 10px;
	display: block;
}

.ph-detail-list-product-image {
	width: 100%;
	max-width: 75px;
	margin: auto;
}

.ph-detail-list-product-title {
	color: $primaryColor;
	font-weight: 600;
	font-size: 13px;
	line-height: 17px;
	margin-bottom: 2px;
}

.ph-detail-list-product-pharmacy-title {
	color: $colorGrayText3;
	font-weight: 600;
	font-size: 12px;
	line-height: 17px;
	margin-bottom: 2px;
}

.ph-detail-list-product-quantity {
	color: $colorGrayText3;
	font-weight: 500;
	font-size: 12px;
	line-height: 17px;
	margin-bottom: 2px;
}

.ph-detail-list-product-promotion {
	color: $colorGrayText3;
	font-weight: 500;
	font-size: 12px;
	line-height: 17px;
	margin-bottom: 2px;
}

.ph-detail-list-product-promotion-title {
	color: $primaryColor;
}

.ph-detail-list-product-price-unity {
	color: $colorGrayText3;
	font-weight: 500;
	font-size: 12px;
	line-height: 17px;
	margin-bottom: 2px;
}

.ph-detail-list-product-total {
	color: $colorBackText;
	font-weight: 600;
	font-size: 12px;
	line-height: 17px;
	margin-bottom: 2px;
}
