/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-bag-recipe-product-container {
	display: flex;
	align-items: flex-start;
}

.ph-bag-recipe-product-image-container {
	margin-right: 10px;
	width: 15%;
}

.ph-bag-recipe-product-image {
	width: 100%;
	max-width: 70px;
}

.ph-bag-recipe-product-prescription-main-container {
	width: 85%;
}

.ph-bag-recipe-product-prescription-container {
	display: flex;
	align-items: center;
}

.ph-bag-recipe-product-image-prescription-container {
	margin-right: 3px;
	margin-top: -2px;
}

.ph-bag-recipe-product-image-prescription {
	width: 100%;
	max-width: 16px;
}

.ph-bag-recipe-product-prescription-title {
	margin-bottom: 0;
	color: $colorGrayText3;
	font-style: italic;
	font-size: 13px;
}

.ph-bag-recipe-product-title {
	color: $primaryColor;
	margin-bottom: 0;
	font-weight: 600;
	margin-left: 2px;
}

.ph-bag-recipe-agree-title-wp-main-container {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.ph-bag-recipe-agree-title-wp-main-container .ant-checkbox-checked .ant-checkbox-inner {
	background-color: $primaryColor;
	border-color: $primaryColor;
}

.ph-bag-recipe-agree-title-wp-main-container .ant-checkbox:hover .ant-checkbox-inner {
	border-color: $primaryColor;
}

.ph-bag-recipe-agree-title-wp-container {
	margin-top: 0;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.ph-bag-recipe-agree-title-wp-container .ant-checkbox {
	margin-top: 3px;
}

.ph-bag-recipe-agree-title-wp {
	color: $colorGrayText3;
	font-size: 13px;
	margin-top: 3px;
	margin-left: 4px;
	margin-bottom: 0;
}

.ph-bag-recipe-agree-title-wp-show-more {
	color: $primaryColor;
	text-decoration: underline;
	cursor: pointer;
}

.ph-bag-recipe-agree-see-more {
	display: none;
}

.ph-bag-recipe-agree-title-wp-description {
	font-size: 12px;
	margin: 3px 0 0 0;
	color: $colorGrayText3;
	font-style: italic;
	border: 1px solid $primaryColorBorder;
	max-width: 368px;
	border-radius: 4px;
	padding: 4px 10px;
	text-align: justify;
	line-height: 16px;
}

.ph-bag-recipe-agree-title-wp-asterick {
	color: $errorColor;
	margin-right: 4px;
}

.ph-bag-recipe-product-uploaded-image-main-container {
	display: flex;
	align-items: center;
}

.ph-bag-recipe-product-uploaded-image-container {
	box-shadow: $primaryBoxShadow;
	padding: 4px;
	display: block;
	max-width: 50px;
}

.ph-bag-recipe-product-uploaded-image {
	width: 100%;
	max-width: 40px;
}

.ph-bag-recipe-product-uploaded-image-main-title {
	color: $colorGrayText3;
	margin-bottom: 0;
	font-size: 11px;
	padding-left: 5px;
}

@media (max-width: 600px) {
	.ph-bag-recipe-product-title {
		font-size: 12px;
	}

	.ph-bag-recipe-agree-title {
		font-size: 12px !important;
	}
}
