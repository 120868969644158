@import '../../../v2-config/colors.scss';

@mixin form-style {
	font-size: 18px;
	line-height: 18px;
}
.form-primary label {
	@include form-style;
	color: $generic-grey1;
}
