@import '../../../v2-config/colors.scss';

@mixin input-style {
	font-size: 18px;
	border-radius: 10px;
	height: 40px;
	line-height: 40px;
}
.input-primary {
	@include input-style;
	border-color: $primary-default;

	&:hover {
		border-color: $primary-hover;
	}

	&:active {
		border-color: $primary-active;
	}
}
