/** @format */

@import '../../../../../../../../../../../../components/Commons/Sass/components/colors';

.ph-electronic-quote-price-main-container {
	text-align: center;
	text-align: -webkit-center;
	width: 100%;
}

.ph-electronic-quote-price-container {
	display: flex;
	justify-content: center;
	align-items: baseline;
	max-width: 150px;
}

.ph-electronic-quote-price-container-main-title {
	font-size: 12px;
	color: $colorGrayText4;
	margin-right: 6px;
	font-weight: 600;
}

@media (max-width: 768px) {
	.ph-electronic-quote-price-container-main-title {
		display: none;
	}
}

@media (max-width: 575px) {
	.ph-electronic-quote-price-container {
		max-width: unset;
		justify-content: flex-end;
	}
}
