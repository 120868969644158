/** @format */
@import '../../../../../../../../../../../../../../components/Commons/Sass/components/colors';

.ph-electronic-quote-price-before-symbol {
	color: $colorGrayText2;
	font-weight: 500;
	font-size: 13px;
	text-decoration: line-through;
	margin-bottom: 0;
}

.ph-electronic-quote-price-before {
	color: $colorGrayText2;
	font-weight: 500;
	font-size: 15px;
	text-decoration: line-through;
	margin-bottom: 0;
}

.ph-electronic-quote-price-normal-symbol {
	font-size: 14px;
	color: $colorGrayText4;
	font-weight: 600;
	margin-bottom: 0;
}

.ph-electronic-quote-price-normal {
	font-size: 20px;
	color: $colorGrayText4;
	font-weight: 600;
	margin-bottom: 0;
}

@media (max-width: 575px) {
	.ph-electronic-quote-price-before-symbol {
		font-size: 11px;
	}

	.ph-electronic-quote-price-before {
		font-size: 12px;
	}

	.ph-electronic-quote-price-normal-symbol {
		font-size: 12px;
	}

	.ph-electronic-quote-price-normal {
		font-size: 15px;
		padding-right: 18px;
	}
}
