/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-complaint-book-search > .ant-input-group > .ant-input {
	border: 1px solid #2d72b7;
	outline: none;
}

.ph-complaint-book-search > .ant-input-group > .ant-input:focus {
	outline: none;
}

.ph-complaint-book-search > .ant-input-group > .ant-input-group-addon > button {
	background-color: $primaryColor;
}
