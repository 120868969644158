/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-product-detail-detailpharmacy-container {
	background-color: $primaryBackgroundColor;
	box-shadow: $primaryBoxShadow;
	border-radius: 4px;
	padding: 10px;
	margin-top: 14px;
}

.ph-product-detail-detailpharmacy-info-title {
	color: #7b7b7b;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: -0.53px;
	margin-bottom: 10px;
}

.ph-product-detail-detailpharmacy-agreement-icon {
	transition: transform 0.2s ease-out;
	margin-left: auto;
}

.ph-product-detail-detailpharmacy-info-container {
	width: 100%;

	//min-height: 44px;
	padding: 5px 0px;

	&.agreement-collapse {
		cursor: pointer;

		&.agreement-icon-active {
			& .ph-product-detail-detailpharmacy-agreement-icon {
				transform: rotate(180deg);
			}
		}
	}
}

.ph-product-detail-detailpharmacy-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.pharol-price-first-row {
	margin-bottom: 10px;
}

.pharol-price-extended {
	height: 102px;
}



.ph-product-detail-detailpharmacy-info {

	&-first {
		color: $colorGrayText3;
		font-size: 14px;
		font-weight: 500;
	}

	&-first-final-price {
		color: $colorGrayText4;
		font-size: 16px;
		font-weight: 500;
	}

	&-second {
		//color: $colorGrayText4;
		font-size: 14px;
		font-weight: 500;
	}

	&-final-price {
		font-size: 18px;
		font-weight: 700;
		color: $colorGrayText4;
	}

	&-first.agreement-row {
		display: flex;
		align-items: center;
		width: 100%;
		color: $primaryColor;
		font-weight: 600;

		/*
		& > div:last-child {
			margin-left: auto;
		}
		*/
	}

	&-first.promotion-mecanic {
		color: #669bfb;
		font-size: 15px;
		font-weight: 500;
	}

	&-third {
		color: #669bfb;
		font-size: 14px;
		font-weight: 500;
		//max-width: 15%;
		margin-left: 8px;
		text-align: center;
	}
}

.pharol-price {
	&-second {
		//color: #669bfb;
		font-size: 14px;
		color: #aaaaaa;
		letter-spacing: -0.49px;
	}

	&-third {
		color: #aaaaaa;
		font-size: 16px;
		letter-spacing: -0.56px;
		font-weight: bold;
	}
}

.ph-product-detail-detailpharmacy-second-container {
	border-top: solid;
	border-bottom: solid;
	border-width: 1px;
	border-color: #0000001a;
}

.ph-product-detail-detailpharmacy-promotion-container {
	//margin-top: 15px;
}

.ph-product-detail-detailpharmacy-info-promotion {
	font-size: 13px;
}

.ph-product-detail-detailpharmacy-info-delivery {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: 6px 0 10px;
	font-size: 13px;
	//letter-spacing: -0.46px;
	color: $colorGrayText4;
}

.ph-product-detail-detailpharmacy-info-delivery-image-container {
	margin-right: 10px;
}

.ph-product-detail-detailpharmacy-info-delivery-image {
	width: 16px;
}

.ph-product-detail-detailpharmacy-dispatch-unavailable {
	font-size: 14px;
	font-weight: 400;
	color: $colorGrayText1
}

.ph-product-detail-detailpharmacy-second-container {
	margin-bottom: 10px;
}

.ph-product-detail-detailpharmacy-agreement-container-collapse {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.2s ease-out;

	& .ant-col:first-child {
		& .ant-col:first-child {
			max-width: 80%;
		}
	}
}

.ph-product-detail-detailpharmacy-pharol-price-description {
	font-size: 12px;
	letter-spacing: -0.42px;
	color: #555555;
}

.ph-product-detail-detailpharmacy-request-checkbox-container {
	text-align: end;
	flex: 1;
}

.ph-product-detail-detailpharmacy-request {
	letter-spacing: -0.45px;
	color: #555555;
	font-weight: bold;
	font-size: 15px;
	margin-right: 16px;
}

.ph-product-detail-detailpharmacy-header {
	border-bottom: solid;
	border-width: 1px;
	border-color: #0000001a;
	padding-bottom: 10px;
}

.ph-product-detail-detailpharmacy-header-container {
	position: relative;
	justify-content: flex-start;
	align-items: center;

	.ph-product-detail-detailpharmacy-header-container-title-container {
		display: flex;
	}

}

.ph-product-detail-detailpharmacy-header-image-container {
	margin-right: 5px;
	border-radius: 50px;
	width: 100%;
	max-width: 25px;
	min-width: 25px;
}

.ph-product-detail-detailpharmacy-header-image {
	width: 100%;
	max-width: 20px;
}

.ph-product-detail-detailpharmacy-header-pharmacy-title {
	color: $colorGrayText4;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 0;
}

.ph-product-detail-detailpharmacy-header-subtitle {
	color: $colorGrayText4;
	font-size: 13px;
	font-weight: 400;
}

.ph-product-detail-detailpharmacy-header-pharmacy-stock {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	//  letter-spacing: -0.42px;

	&.green {
		color: $successColor;
	}

	&.orange {
		color: $secondColor;
	}

	&.gray {
		color: $colorGrayText3;
	}

	&.benvida-green {
		color: $primaryColor;
	}
}

.promotion-percentaje {

	color: $primaryColor;

}

@media screen and (max-width: 990px) {
	.ph-product-detail-detailpharmacy-info-title {
		display: none;
	}
}

@media screen and (max-width: 576px) {
	.ph-product-detail-detailpharmacy-agreement-container-collapse {
		& .ant-col:first-child {
			& .ant-col:first-child {
				max-width: 100%;
				margin-bottom: 8px;
				margin-left: 5px;
			}
		}
	}


	.ph-product-detail-detailpharmacy-info-delivery {
		margin: 10px 0;
	}



	.ph-product-detail-detailpharmacy-info-row {
		border: 0px;
		border-radius: 0px;
		width: 100%;
		display: flex;
		align-items: center;
		padding: 8px 12px;

		&.agreement-collapse {
			cursor: pointer;

			&.agreement-icon-active {
				& .ph-product-detail-detailpharmacy-agreement-icon {
					transform: rotate(180deg);
				}
			}
		}
	}

	.ph-product-detail-detailpharmacy-agreement-icon {
		margin-left: 0;
	}




}