@import '../../../v2-config/colors.scss';

@mixin select-style {
	height: 40px !important;
	line-height: 37px !important;
}
.select-primary {
	.ant-select-selector {
		@include select-style;
		border-color: $primary-default !important;
		border-radius: 10px !important;
	}
	.ant-select-arrow {
		color: $primary-default !important;
		font-size: 14px !important;
	}
	.ant-select-selection-item {
		@include select-style;
	}
	&:hover {
		border-color: $primary-hover;
	}

	&:active {
		border-color: $primary-active;
	}
}
