/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-userinfo-global-container {
	max-width: 1185px;
	padding: 10px;
	margin: auto;
}

.ph-userinfo-main-container {
	padding: 14px;
}

.ph-userinfo-container {
	position: relative;
	padding: 20px;
	border-radius: 4px;
	box-shadow: $primaryBoxShadow;
}

.ph-userinfo-inner-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-userinfo-perfil-image-main-container {
	text-align: center;
}

.ph-userinfo-perfil-image-container {
	padding: 10px;
}

.ph-userinfo-perfil-image {
	width: 100%;
	max-width: 150px;
	border-radius: 100px;
}

.ph-userinfo-perfil-title-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 15px;
}

.ph-userinfo-perfil-title {
	font-size: 16px;
	font-weight: 500;
	color: $colorGrayText4 !important;
	margin-right: 5px;
}

.ph-userinfo-perfil-subtitle {
	font-size: 16px;
	font-weight: 900;
	color: $colorGrayText4 !important;
	margin-right: 5px;
}

.ph-userinfo-perfil-description {
	font-size: 15px;
	color: $colorGrayText3;
	opacity: 0.7;
	font-weight: 600;
	margin-bottom: 0;
}

.ph-no-userdata-inner-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-no-userdata-inner-title {
	color: $colorGrayText2;
	font-style: italic;
}

.ph-userdata-inner-container {
	border-left: 1px solid $primaryColorBorder;
}

.ph-userinfo-edit-button {
	position: absolute;
	top: 20px;
	right: 20px;
}

.ph-userinfo-edit-button-title {
	margin-bottom: 0;
	color: $colorWhiteText;
}

@media (max-width: 1024px) {
	.ph-userinfo-perfil-subtitle {
		font-size: 14px;
	}
}

@media (min-width: 576px) {
	.ph-userinfo-edit-button-icon {
		display: none !important;
	}
}

@media (max-width: 575px) {
	.ph-userinfo-main-container {
		padding: 0;
	}

	.ph-userinfo-title-container {
		margin-top: 0;
	}

	.ph-userinfo-main-title {
		font-size: 22px;
	}

	.ph-userinfo-main-subtitle {
		margin-bottom: 10px;
	}

	.ph-userinfo-perfil-image {
		max-width: 80px;
	}

	.ph-userinfo-perfil-title {
		margin-bottom: 2px;
	}

	.ph-userinfo-perfil-subtitle {
		margin-bottom: 2px;
	}

	.ph-userdata-inner-container {
		border-left: none;
	}

	.ph-userinfo-edit-button-title {
		display: none !important;
	}

	.ph-userinfo-edit-button,
	.ph-userinfo-edit-button:hover,
	.ph-userinfo-edit-button:active,
	.ph-userinfo-edit-button:focus {
		top: 10px;
		right: 10px;
		padding: 10px;
		border-radius: 100px;
		width: 38px;
		height: 38px;
	}
}
