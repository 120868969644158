/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-edit-profile-modal-container .ant-modal-content {
	border-radius: 8px;
}

.ph-edit-profile-modal-container .ant-modal-content .ant-modal-close {
	top: 14px;
	right: 21px;
}

.ph-edit-profile-modal-container .ant-modal-content .ant-modal-close-x {
	border-radius: 8px;
	border: 2px solid $fourthColor;
	border-radius: 100px;
	color: $fourthColor;
	font-weight: bold;
	width: 20px;
	height: 20px;
	font-size: 12px;
	line-height: 0;
	padding: 2px;
}

.ph-edit-profile-modal-container .ant-modal-header {
	border-bottom: 1px solid $primaryColorBorder !important;
	padding: 12px 20px;
	border-radius: 8px 8px 0 0;
}

.ph-edit-profile-modal-container .ant-modal-header .ant-modal-title {
	font-size: 16px;
	font-weight: 900;
}

.ph-edit-profile-modal-container .ant-modal-content .ant-modal-body {
	padding: 16px;
	text-align: center;
}

.ph-edit-profile-form-main-container .ant-form-item-control {
	min-height: 63px;
	padding: 0 5px;
}

.ph-edit-profile-form-main-container .ant-form-item-explain-error {
	font-size: 12px;
	text-align: left;
	margin: 0 10px;
	min-height: 15px;
}

.ph-edit-profile-form-main-container .ant-form-item {
	margin-bottom: 0;
}

.ph-edit-profile-form-main-container .ant-form-item-label {
	padding-bottom: 0;
	padding-left: 15px;
	padding-right: 15px;
}

.ph-edit-profile-form-main-container .ant-form-item-label label {
	color: $primaryColor;
	opacity: 0.8;
	font-size: 12px;
}

.ph-edit-profile-field-input {
	max-width: 375px;
	height: 45px;
	border-radius: 6px;
	font-size: 13px;
	color: $colorGrayText4;
	opacity: 0.8;
}

.ph-edit-profile-field-input:hover,
.ph-edit-profile-field-input:focus,
.ph-edit-profile-field-input:active {
	border: 1px solid $primaryColor !important;
	box-shadow: none;
}

.ph-edit-profile-field-input-email {
	max-width: 265px;
	height: 45px;
	border-radius: 6px;
	font-size: 13px;
}

.ph-edit-profile-field-input-email:hover,
.ph-edit-profile-field-input-email:focus,
.ph-edit-profile-field-input-email:active {
	border: 1px solid $primaryColorBorder !important;
	box-shadow: none;
}

.ph-edit-profile-field-selector {
	max-width: 265px;
}

.ph-edit-profile-field-selector .ant-select-selector {
	height: 45px !important;
	border-radius: 6px !important;
	border: 1px solid $primaryColorBorder !important;
}

.ph-edit-profile-field-selector .ant-select-selection-item {
	font-size: 13px;
	color: $colorGrayText4;
	opacity: 0.8;
	line-height: 45px !important;
	text-align: left;
}

.ph-edit-profile-form-main-container .ant-form-item-control-input-content .ant-picker {
	width: 100%;
	height: 45px;
	border-radius: 6px;
	max-width: 265px;
}

.ph-edit-profile-form-main-container .ant-form-item-control-input-content .ant-picker-focused {
	border: 1px solid $primaryColor !important;
	box-shadow: none;
}

.ph-edit-profile-form-main-container .ant-picker-input input {
	font-size: 13px;
	color: $colorGrayText4;
	opacity: 0.8;
}

.ph-edit-profile-modal-button {
	background: $primaryColor !important;
	color: $colorWhiteText !important;
	font-size: 16px;
	font-weight: 500;
	text-transform: capitalize;
	border-radius: 4px;
	min-width: auto;
	width: 100%;
	max-width: 125px;
	border-color: transparent;
	height: 38px;
	line-height: 10px;
	box-shadow: $primaryBoxShadow;
	margin-top: 5px;
	margin-bottom: 3px;
}

.ph-edit-profile-modal-button-disabled {
	@extend .ph-edit-profile-modal-button;
	background-color: $colorGrayText1 !important;
}

@media (max-width: 575px) {
	.ph-edit-profile-field-input {
		max-width: 100%;
	}

	.ph-edit-profile-field-input-email {
		max-width: 100%;
	}

	.ph-edit-profile-field-selector {
		max-width: 100%;
	}

	.ph-edit-profile-form-main-container .ant-form-item-control-input-content .ant-picker {
		max-width: 100%;
	}
}