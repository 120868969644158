/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-profile-link-card {
	background-color: $primaryBackgroundColor;
	border-radius: 5px;
	box-shadow: $primaryBoxShadow;
	margin: 0 10px;
	padding: 15px 25px;
	transition: 0.4s ease;
	cursor: pointer;
}

.ph-profile-link-card:hover {
	background-color: $thirdBackgroundColor;
	box-shadow: $secondaryBoxShadow;
	transition: 0.3s ease;
}

.ph-profile-link-card-title {
	font-weight: 600;
	font-size: 16px;
	color: $colorGrayText3;
	margin-bottom: 8px;
}

.ph-profile-link-card-description {
	font-size: 13px;
	margin-top: 5px;
	margin-bottom: 5px;
	color: $primaryColor;
	cursor: pointer;
	text-decoration: underline;
	display: block;
	width: 100%;
}

@media (max-width: 1024px) {
	.ph-profile-link-card-title {
		font-size: 14px;
	}
}

@media (max-width: 950px) {
	.ph-profile-link-card {
		padding: 10px;
	}
}

@media (max-width: 768px) {
	.ph-profile-link-card {
		margin: 10px;
	}
}

@media (max-width: 768px) {
	.ph-profile-link-card {
		margin: 10px 0;
	}
}
