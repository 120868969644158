/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-product-detail-main-title-stock-container {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: 10px;
	right: 15px;
}

.ph-product-detail-main-title-stock-title {
	font-size: 13px;
	font-weight: 900;
	color: $colorGrayText2;
	margin-right: 5px;
	margin-bottom: 0;
}

.ph-product-detail-main-title-stock-description {
	font-size: 13px;
	font-weight: 900;
	color: $colorGrayText2;
	margin-bottom: 0;
}

.ph-product-detail-main-title-stock-description-green {
	color: green;
}

.ph-product-detail-main-title-stock-description-orange {
	color: $secondColor;
}

.ph-product-detail-main-title-stock-description-red {
	color: $errorColor;
}

@media (max-width: 575px) {
	.ph-product-detail-main-title-stock-container {
		bottom: 5px;
		right: 5px;
	}
}
