/** @format */

@import '../Commons/Sass/components/colors';

.ph-product-detail-button-quantity-main-container {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid $primaryColorBorder;
	border-radius: 7px;
	padding: 0;
	max-width: 84px;
	margin: 0 0 0 auto;
	background-color: $primaryBackgroundColor;
}

.ph-product-detail-button-add-quantity,
.ph-product-detail-button-add-quantity:hover,
.ph-product-detail-button-add-quantity:active,
.ph-product-detail-button-add-quantity:focus {
	border: none;
	box-shadow: none;
	border-radius: 7px;
	padding: 10px;
	color: $colorGrayText4 !important;
	font-size: 9px;
	font-weight: 900;
}

.ph-product-detail-button-quantity-input {
	border: none;
	background-color: #fff !important;
	cursor: auto !important;
	color: $colorGrayText4 !important;
	text-align: center;
	width: 100%;
	max-width: 30px;
	padding: 5px 0;
}

.ph-product-detail-button-quantity-input::placeholder {
	color: $colorGrayText4 !important;
	opacity: 0.9;
}

.ph-product-detail-button-delete-quantity,
.ph-product-detail-button-delete-quantity:hover,
.ph-product-detail-button-delete-quantity:active,
.ph-product-detail-button-delete-quantity:focus {
	border: none;
	box-shadow: none;
	border-radius: 7px;
	padding: 10px;
	color: $colorGrayText4 !important;
	font-size: 9px;
	font-weight: 900;
}
