/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-request-preorder-success-global-container {
	max-width: 1185px;
	padding: 10px;
	margin: auto;
}

.ph-request-preorder-success-main-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: auto;
	min-height: calc(100vh - 133px);
}

.ph-request-preorder-success-inner-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.ph-request-preorder-succes-img-container {
	max-width: 250px;
	text-align: center;
	margin-bottom: 30px;
}

.ph-request-preorder-succes-img {
	max-width: 110px;
}

.ph-request-preorder-success-succes-title {
	color: $primaryColor;
	font-size: 30px;
	font-weight: 800;
	margin-bottom: 0;
}

.ph-request-preorder-success-description-main {
	text-align: center;
	color: $colorGrayText2;
	font-size: 15px;
	font-weight: 600;
	margin-top: 5px;
	margin-bottom: 5px;
}

.ph-request-preorder-success-description-span {
	text-align: center;
	color: $colorGrayText2;
	font-size: 15px;
	font-weight: 500;
	margin-top: 5px;
	margin-bottom: 15px;
}

.ph-request-preorder-success-paraghaf {
	color: $colorGrayText3;
	font-weight: 500;
	font-style: italic;
	font-size: 15px;
	margin-top: 0px;
	margin-bottom: 0px;
}

.ph-request-preorder-success-number {
	color: $colorBackText;
	font-size: 20px;
}

.ph-request-preorder-success-description-span-i {
	color: $errorColor;
	padding-right: 5px;
}

.ph-request-preorder-success-description-contact {
	color: $primaryColor;
	font-size: 11px;
	padding-left: 5px;
}

.ph-request-preorder-success-payment-button-container {
	display: flex;
	justify-content: center;
	margin-top: 0px;
}

.ph-request-preorder-success-payment-button,
.ph-request-preorder-success-payment-button:focus,
.ph-request-preorder-success-payment-button:hover,
.ph-request-preorder-success-payment-button:active {
	color: $colorWhiteText;
	font-weight: bold;
	margin: auto;
	height: 36px;
	width: 100%;
	max-width: 100px;
	max-width: 160px;
	box-shadow: $mainHeaderboxShadow !important;
	border: 2px solid $secondColor;
	background-color: $secondColor;
	border-radius: 4px;
	padding: 5px 20px;
}

.ph-request-preorder-success-payment-button-span {
	padding-top: 5px;
}

.ph-request-preorder-success-payment-button-tracking {
	font-weight: 300;
	text-decoration: underline;
	margin-top: 10px;
	font-size: 11px;
	font-style: italic;
	color: $primaryColor;
}

@media screen and (max-width: 992px) {
	.ph-request-preorder-success-succes-title {
		font-size: 28px;
	}
}
@media screen and (max-width: 768px) {
	.ph-request-preorder-success-succes-title {
		font-size: 25px;
	}
}
@media screen and (max-width: 576px) {
	.ph-request-preorder-success-succes-title {
		font-size: 20px;
	}
}

@media (max-width: 600px) {
	.ph-request-preorder-success-img-container {
		margin-bottom: 15px;
	}
}
