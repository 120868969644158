.slider-internal {
	padding: 10px;
	img {
		max-width: 1165px;
		max-height: 276px;
		border-radius: 10px !important;
		width: 100%;
		margin: 0px auto;
	}
}
