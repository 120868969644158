/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-complaint-book-list-content {
	background: $thirdBackgroundColor;
	padding: 0px 15px;
	border-radius: 5px;
	margin-bottom: 10px;
	box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%) !important;
}

.ph-complaint-book-list-name {
	text-transform: capitalize;
}
