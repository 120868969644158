/** @format */

@import '../../../../../../../../../../components/Commons/Sass/components/colors';

.ph-product-detail-quote-main-km-agreement-mobile-container {
	display: none;
}

.ph-product-detail-quote-main-title-stock-container {
	display: flex;
	align-items: center;
	width: 100%;

	&>span:nth-child(n + 2) {
		display: none;
	}
}

.ph-product-detail-quote-main-title-stock {
	font-size: 11px;
	font-weight: 600;
	color: $colorGrayText2;
	margin-bottom: 0;
	letter-spacing: -0.42px;

	&-green {
		color: $successColor;
	}

	&-benvida-green {
		color: $primaryColor;
	}

	&-orange {
		color: $secondColor;
	}

	&-gray {
		color: $colorGrayText3;
	}

	&-pink {
		color: $fifthColor;
	}
}

.ph-product-detail-quote-main-desc {
	margin-left: auto;
	font-size: 11px;
	letter-spacing: -0.32px;
	color: rgb(0, 0, 0, 0.6);
	font-weight: 600;
}

@media (max-width: 992px) {
	.ph-product-detail-quote-main-km-agreement-mobile-container {
		display: flex;
		width: 100%;
		align-items: center;
	}

	.ph-product-detail-quote-main-km-mobile {
		display: block;
		margin: 0;
		font-size: 11px;
		font-weight: 600;
		margin-bottom: 0;
		color: #555555;
		letter-spacing: -1.1px;
	}

	.ph-product-detail-quote-main-agreement-mobile {
		margin-left: auto;
		font-size: 11px;
		color: #669bfb;
		font-weight: 600;
	}

	.ph-product-detail-quote-main-title-stock-container {
		width: auto;
		max-width: unset;
		padding-right: 0;

		&>span:nth-child(n + 2) {
			display: inline-block;
		}
	}

	.ph-product-detail-quote-main-price-title-mobile {
		margin-left: auto;
		margin-right: 5px;
		font-weight: 500;
		font-size: 11px;
	}

	.ph-product-detail-quote-main-price-mobile {
		font-weight: 600;
		font-size: 13px;
	}

	.ph-product-detail-quote-main-desc {
		display: none;
	}
}

@media (max-width: 575px) {
	.ph-product-detail-quote-main-title-stock-container {
		width: 104%;
	}

	.ph-product-detail-quote-main-km-agreement-mobile-container {
		width: 104%;
	}
}