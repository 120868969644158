/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-order-detail-pharmacies-main-container {
	padding: 20px;
	border-radius: 4px;
	box-shadow: $primaryBoxShadow;
	margin-bottom: 30px;
}

.ph-order-detail-pharmacies-container {
	position: relative;
	border: 1px solid $primaryColorBorder;
	border-radius: 4px;
	padding: 10px 25px;
	margin-bottom: 20px;
}

.ph-order-detail-pharmacies-container:last-child {
	margin-bottom: 0;
}

.ph-order-detail-pharmacies-map-content {
	margin-top: 20px;
}

.ph-order-detail-pharmacies-map-image-container {
	margin-right: 5px;
	margin-left: 5px;
	float: left;
}

.ph-order-detail-pharmacies-map-image {
	width: 100%;
	max-width: 30px;
}

.ph-order-detail-pharmacies-map-title {
	color: $colorGrayText2;
	font-weight: 600;
	font-size: 15px;
}

.ph-order-detail-pharmacies-map-iframe {
	border-width: 2px !important;
	border: 1px solid $primaryColorBorder;
	border-radius: 8px;
	box-shadow: $primaryBoxShadow;
}

@media (max-width: 575px) {
	.ph-order-detail-pharmacies-main-container {
		padding: 10px;
		margin-bottom: 30px;
	}

	.ph-order-detail-pharmacies-container {
		padding: 10px;
		margin-bottom: 10px;
	}
}
