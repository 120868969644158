/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-objected-recipe-succes-global-container {
	max-width: 900px;
	padding: 90px 10px;
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-objected-recipe-succes-main-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: auto;
	min-height: calc(70vh - 2px);
}

.ph-objected-recipe-succes-inner-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.ph-objected-recipe-succes-img-container {
	text-align: center;
	margin-bottom: 5px;
}

.ph-objected-recipe-success-img {
	max-width: 40px;
	margin: auto;
}

.ph-objected-recipe-success-buttons-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 20px 0;
}

.ph-objected-recipe-success-title {
	color: $primaryColor;
	font-size: 28px;
	font-weight: 900;
	margin-bottom: 10px;
}

.ph-objected-recipe-success-subtitle {
	color: $colorGrayText2;
	font-size: 17px;
	margin-top: 1px;
	font-weight: 600;
}

.ph-objected-recipe-success-subtitle-oc {
	color: $colorBackText;
	font-weight: 900;
	padding-left: 5px;
	font-size: 16px;
}

.ph-objected-recipe-success-button-return-container {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.ph-objected-recipe-success-button-return,
.ph-objected-recipe-success-button-return:focus,
.ph-objected-recipe-success-button-return:hover,
.ph-objected-recipe-success-button-return:active {
	color: $colorWhiteText;
	font-weight: bold;
	margin: auto;
	height: 36px;
	width: 100%;
	max-width: 160px;
	box-shadow: $mainHeaderboxShadow !important;
	border: 2px solid $secondColor;
	background-color: $secondColor;
	border-radius: 4px;
	padding: 5px 20px;
}

.ph-objected-recipe-success-button-return-span {
	padding-top: 7px;
}
