/** @format */

@import '../../../Sass/components/colors';

.ph-knowloginuser-msn-content .ant-modal-confirm-btns {
	display: none;
}

.ph-knowloginuser-access-button-login,
.ph-knowloginuser-access-button-login:hover,
.ph-knowloginuser-access-button-login:active,
.ph-knowloginuser-access-button-login:focus {
	background-color: $primaryColor;
	color: $colorWhiteText;
	border: none !important;
	border-radius: 4px !important;
	padding: 9px 15px;
}

.ph-knowloginuser-access-button-register,
.ph-knowloginuser-access-button-register:hover,
.ph-knowloginuser-access-button-register:active,
.ph-knowloginuser-access-button-register:focus {
	background-color: $primaryBackgroundColor;
	color: $primaryColor;
	border: 1px solid $primaryColor;
	border-radius: 4px !important;
	padding: 9px 15px;
}

.ph-knowloginuser-close-btn {
	right: 15px;
	position: absolute;
	top: 10px;
}
