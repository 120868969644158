/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-product-detail-background-container {
	padding-bottom: 50px;
}

.ph-product-detail-global-container {
	max-width: 1200px;
	padding: 10px;
	margin: auto;
}

.ph-product-detail-featured-carousel-title {
	color: $primaryColor;
	font-weight: 600;
	font-size: 20px;
	text-align: left;
	padding-top: 10px;
	padding-left: 25px;
	padding-right: 25px;
	margin-top: 0;
	margin-bottom: 7px;
}

@media (max-width: 1024px) {
	.ph-product-detail-main-container {
		padding: 0;
	}
}

@media (max-width: 768px) {
	.ph-product-detail-featured-carousel-title {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media (max-width: 600px) {
	.ph-product-detail-global-container {
		margin-bottom: 15px;
	}
}
