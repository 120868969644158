/** @format */

@import '../Commons/Sass/components/colors';
@import '../../v2-config/colors.scss';

.ph-elastic-global-container {
	.ph-elastic-searchbox-container {
		justify-content: center;

		form {
			width: 100%;
			margin: 0 4px;
		}
	}
	.sui-layout-main-footer {
		display: block;
	}

	.ant-row.ph-elastic-searchbox-container {
		margin-left: 14px;
		margin-right: 14px;
		margin-bottom: 5px;
	}

	.sui-search-box {
		.sui-search-box__wrapper {
			input {
				font-size: 12px;
				color: #555;
				padding-left: 15px;
				border-radius: 8px;
				height: 36px;
				padding-top: 8px;
				padding-bottom: 8px;
				margin-left: 7px;
			}
		}

		.sui-search-box__submit {
			padding: 8px;
			height: 34px;
			width: 34px;
			border-top-width: 1px;
			border-bottom-width: 1px;
			background: none;
			background-color: $primaryColor !important;
			border-radius: 8px;
			margin-left: 15px;

			svg {
				width: 18px;
				height: 18px;
			}
		}
	}
}

.ph-elastic-global-container .sui-layout {
	padding-top: 10px;
}

.ph-elastic-global-container .sui-layout .sui-layout-header {
	display: none;
}

.sui-layout-body {
	&::after {
		background: none;
		background-color: transparent;
	}
}

.ph-elastic-global-container .sui-layout .sui-layout-body {
	background-color: transparent !important;
}

.ph-elastic-global-container .sui-layout .sui-layout-body .sui-layout-body__inner .sui-layout-sidebar {
	padding: 0;
}

.ph-elastic-global-container .sui-layout .sui-layout-body .sui-layout-body__inner .sui-layout-main {
	padding: 0;
}

.ph-elastic-main-Header-main-container {
	width: 100%;
	margin: 4px;
	padding: 15px;
	min-height: 35px;
	box-shadow: $primaryBoxShadow;
	border-radius: 4px;
	background-color: $generic-white;
}

.ph-elastic-main-Header-main-container .sui-sorting__label {
	text-transform: capitalize;
	letter-spacing: 0;
	color: $colorGrayText2;
}

.ph-elastic-main-Header-main-container .sui-sorting .sui-select__control {
	max-width: 155px;
	height: 30px;
	border: 1px solid $primaryColorBorder !important;
	background-color: $generic-white !important;
}

.ph-elastic-main-Header-main-container .sui-select__indicator.sui-select__dropdown-indicator {
	height: 25px;
	width: 25px;
	padding: 0 3px;
	padding: 0 6px;
}

.ph-elastic-main-Header-main-container .sui-select__indicators {
	margin-right: 5px;
}

.ph-elastic-main-Header-main-container .sui-sorting .sui-select__control .sui-select__single-value {
	font-size: 12px;
	color: $colorGrayText3;
}

.ph-elastic-main-Header-main-container .sui-select .sui-select__menu {
	z-index: 2 !important;
	margin-top: 2px !important;
	width: 100%;
	max-width: 155px;
	font-size: 12px !important;
}

.ph-elastic-main-Header-sorting-container {
	width: 100% !important;
	max-width: 100% !important;
	display: flex;
	justify-content: space-between;
	flex: none;

	.sui-sorting {
		width: initial;
	}
}

.css-dvua67-singleValue {
	overflow: visible !important;
	position: unset !important;
	transform: none !important;
}

.css-qc6sy-singleValue {
	overflow: visible !important;
}

.ph-elastic-main-header-right-inner-container {
	display: flex;
	align-items: center;
}

.ph-elastic-main-header-right-inner-container .sui-results-per-page__label {
	display: none;
}

.ph-elastic-main-header-right-sort-title {
	margin-right: 6px;
	color: $colorGrayText3;
}

.ph-elastic-main-header-right-inner-container .sui-select__control {
	border: 1px solid $primaryColorBorder !important;
	background-color: #ffffff;
}

.ph-elastic-main-header-right-inner-container .sui-select__control .sui-select__single-value {
	font-size: 12px;
	color: $colorGrayText3;
}

.ph-elastic-main-header-right-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.ph-elastic-main-header-right-pagination-container {
	margin-top: 30px;
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.ph-elastic-main-header-right-pagination-container .rc-pagination.sui-paging {
	margin-bottom: 0;
}

.ph-elastic-results-card-container {
	margin-bottom: 8px;
}

.ph-elastic-pagination-main-container .rc-pagination-item {
	box-shadow: $accent-shadow;
	border-radius: 4px;
	min-width: 25px;
	height: 25px;
	line-height: 25px;
	margin-right: 6px;
}

.ph-elastic-pagination-main-container .rc-pagination-item a {
	color: $colorGrayText3;
}

.ph-elastic-pagination-main-container .rc-pagination-item.rc-pagination-item-active {
	background-color: $button-primary-background-color-default;
}

.ph-elastic-pagination-main-container .rc-pagination-item.rc-pagination-item-active a {
	color: $generic-white;
	font-weight: 500;
}

.ph-elastic-pagination-main-container .rc-pagination-next,
.ph-elastic-pagination-main-container .rc-pagination-prev {
	box-shadow: $button-primary-shadow-default;
	min-width: 23px;
	height: 23px;
	margin-top: 1px;
	line-height: 23px;
}

.ph-elastic-footer-main-container {
	width: 100%;
	margin: 4px;
	padding: 15px;
	min-height: 90px;
	box-shadow: $primaryBoxShadow;
	border-radius: 4px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $generic-white;
}

.ph-elastic-footer-main-container .rc-pagination.sui-paging {
	margin-bottom: 0;
}

.ph-elastic-footer-page-info-container {
	position: absolute;
	bottom: 10px;
	left: 15px;
}

.ph-elastic-global-container .sui-search-box .sui-search-box__wrapper input {
	margin-left: 0;
}

@media (max-width: 1100px) {
	.ph-elastic-global-container .sui-layout-body__inner {
		padding: 0 6px;
	}
}

@media (max-width: 992px) {
	.ph-elastic-global-container .sui-layout .sui-layout-body .sui-layout-body__inner .sui-layout-sidebar {
		display: none;
	}

	.ph-elastic-global-container .sui-layout .sui-layout-body .sui-layout-body__inner .sui-layout-main {
		width: 100%;
	}

	.ph-elastic-global-container .sui-layout-main-header__inner {
		display: block;
		justify-content: unset;
		align-items: unset;
	}

	.sui-layout-main-header__inner {
		.ph-elastic-main-panel-container {
			width: 100%;
			margin-left: 4px;
			margin-right: 4px;
		}

		.ant-row {
			justify-content: center;
			margin: 15px;

			form {
				width: 99%;

				.sui-search-box {
					.sui-search-box__wrapper {
						input {
							font-size: 12px;
							color: #555;
							padding-left: 11px;
							border-radius: 8px;
							height: 36px;
							padding-top: 8px;
							padding-bottom: 8px;
						}
					}

					.sui-search-box__submit {
						padding-top: 8px;
						padding-bottom: 8px;
						height: 36px;
						border-top-width: 1px;
						border-bottom-width: 1px;
						background: none;
						background-color: $primaryColor !important;
						border-radius: 8px;
					}
				}
			}
		}

		.ph-elastic-main-Header-main-container {
			.ant-row {
				flex-flow: row;
				justify-content: space-between;
				align-items: center;
				margin: 15px;

				.ant-col-xs-24 {
					flex: none;

					.sui-sorting {
						text-align: start;
					}
				}

				.ph-elastic-order-view-main-container {
					h3 {
						margin-right: 10px;
						font-weight: 400;
					}
				}
			}
		}
	}
}

@media (max-width: 992px) {
	.sui-layout-main-header__inner .ph-elastic-main-panel-container {
		width: auto;
	}

	.sui-layout-main-header__inner .ph-elastic-main-Header-main-container .ant-row {
		margin: 5px 0;
	}

	.ph-elastic-global-container .sui-search-box .sui-search-box__wrapper input {
		margin-left: 0;
	}
}

// @media (max-width: 200px) {
// 	.ph.elastic-global-container {
// 		.sui-layout-main-footer {
// 			display: block;
// 		}
// 	}
// }

@media (max-width: 600px) {
	.ph-elastic-main-Header-main-container {
		padding: 10px;
		width: auto;
	}

	.sui-layout-main-header__inner .ph-elastic-main-Header-main-container .ant-row {
		justify-content: center;
	}

	.sui-layout-main-header__inner .ant-row form {
		width: 100%;
	}

	.sui-layout-main-header__inner .ph-elastic-main-Header-main-container .ant-row .ant-col-xs-24 {
		max-width: 70%;
	}

	.ph-elastic-main-Header-main-container .sui-sorting {
		text-align: center;
		display: flex;
		align-items: center;
	}

	.ph-elastic-main-Header-main-container .sui-sorting .sui-select__control {
		margin: auto;
		margin-bottom: 8px;
	}

	.ph-elastic-main-header-right-pagination-container {
		justify-content: center;
		margin-top: 10px;
	}

	.ph-elastic-main-header-right-container {
		justify-content: center;
	}

	.ph-elastic-footer-page-info-container {
		bottom: 4px;
		left: 9px;
	}

	.ph-elastic-global-container {
		margin-bottom: 30px;
	}
}
