/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-objected-recipe-pharmacy-list-global-container {
	padding: 12px;
	border-radius: 4px;
	box-shadow: $primaryBoxShadow;
	margin: 10px 0;
}

.ph-recipe-objeted-pharmacy-list-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-recipe-objeted-pharmacy-image-container {
	margin-right: 6px;
}

.ph-recipe-objeted-pharmacy-image {
	width: 100%;
	max-width: 20px;
}

.ph-recipe-objeted-pharmacy-list-title {
	color: $colorGrayText4;
	font-weight: 500;
	font-size: 18px;
	margin-bottom: 0;
}

.ph-recipe-objeted-pharmacy-list-description {
	font-size: 13px;
	padding: 0 25px;
	margin-bottom: 10px;
	color: $colorGrayText2;
}

.ph-recipe-objeted-pharmacy-btn-container {
	margin: auto;
	text-align: end;
}

.ph-recipe-objeted-pharmacy-btn,
.ph-recipe-objeted-pharmacy-btn:hover,
.ph-recipe-objeted-pharmacy-btn:active,
.ph-recipe-objeted-pharmacy-btn:focus {
	background-color: $primaryColor !important;
	color: $colorWhiteText;
	margin: 0 0 0 auto;
	height: 36px;
	width: 100%;
	max-width: 120px;
	box-shadow: $mainHeaderboxShadow !important;
	border: 2px solid $primaryColor !important;
	border-radius: 4px;
	padding: 5px;
}

.ph-recipe-objeted-pharmacy-list-main-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-recipe-objeted-pharmacy-inputs-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: 5px;
}

.ph-recipe-objeted-pharmacy-local-selector {
	width: 100%;
	max-width: 400px !important;
	height: 45px !important;
	border-radius: 6px !important;
	font-size: 13px !important;
	margin-right: 12px;
}

.ph-recipe-objeted-pharmacy-local-selector:last-child {
	margin-right: 0;
}

.ph-recipe-objeted-pharmacy-local-selector .ant-select-selector {
	width: 100%;
	max-width: 400px !important;
	height: 45px !important;
	border-radius: 6px !important;
	font-size: 13px !important;
}

.ph-recipe-objeted-pharmacy-local-selector.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
	.ant-select-selector
	.ant-select-selection-item {
	line-height: 45px;
}

@media (max-width: 768px) {
	.ph-recipe-objeted-pharmacy-local-selector {
		max-width: 350px !important;
	}
}

@media (max-width: 576px) {
	.ph-recipe-objeted-pharmacy-local-selector {
		max-width: 235px !important;
	}
}

@media (max-width: 480px) {
	.ph-recipe-objeted-pharmacy-inputs-container {
		display: block;
	}
	.ph-recipe-objeted-pharmacy-local-selector {
		max-width: 100% !important;
	}
	.ph-recipe-objeted-pharmacy-local-selector:last-child {
		margin-top: 10px;
	}
}
