/** @format */

@import '../../../../Sass/components/colors';

.ph-header-mobile-footer-global-container {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 2;
	padding: 4px;
	background-color: $primaryBackgroundColor;
	border-top: 1px solid $primaryColorBorder;
}

.ph-header-mobile-footer-global-container-arg {
	@extend .ph-header-mobile-footer-global-container;
	background-color: $fourthBackgroundColor ;
}