/** @format */

@import '../../components/Commons/Sass/components/colors';

.ph-results-global-container {
	max-width: 1280px;
	padding: 0 10px;
	margin: 18px auto 0;
}

@media (max-width: 1280px) {
	.ph-results-global-container {
		margin: 10px auto 0;
	}
}

@media (max-width: 600px) {
	.ph-results-global-container {
		margin: 6px auto 0;
	}
}
