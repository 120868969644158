/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-order-detail-products-item-price-main-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-order-detail-products-item-price-main-title {
	font-size: 13px;
	color: $colorGrayText4;
	font-weight: 500;
	margin-right: 5px;
	margin-bottom: 0;
}

.ph-order-detail-products-item-price-before-symbol {
	color: $colorGrayText2;
	font-weight: 500;
	font-size: 13px;
	text-decoration: line-through;
	margin-bottom: 0;
}

.ph-order-detail-products-item-price-before {
	color: $colorGrayText2;
	font-weight: 500;
	font-size: 13px;
	text-decoration: line-through;
	margin-bottom: 0;
}

.ph-order-detail-products-item-price-normal-symbol {
	color: $colorGrayText4;
	font-weight: 900;
	font-size: 12px;
	margin-top: 1px;
	margin-bottom: 0;
}

.ph-order-detail-products-item-price-normal {
	color: $colorGrayText4;
	font-weight: 900;
	font-size: 14px;
	margin-bottom: 0;
}

.ph-order-detail-products-item-price-spacer {
	width: 15px;
}

.ph-order-detail-products-item-price-promotion-symbol {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	color: $primaryColor;
	font-weight: 700;
	margin-top: 1px;
	font-size: 12px;
}

.ph-order-detail-products-item-price-promotion {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	color: $primaryColor;
	font-weight: 700;
}
