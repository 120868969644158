/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-product-detail-promotion-title {
	font-weight: 900;
	font-size: 17px;
	color: $primaryColor;
	margin-bottom: 0;
}

.ph-product-detail-promotion-container {
	padding: 0 15px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-product-detail-promotion-icon-container {
	margin-right: 5px;
}

.ph-product-detail-promotion-icon {
	width: 13px;
}
