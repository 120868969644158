/** @format */

@import '../Sass/components/colors';

.ph-recipe-upload-image-container .ant-upload.ant-upload-select-picture-card {
	width: 100%;
	margin: 0;
	border: 2px dashed $primaryColorBorder;
	height: 200px;
	background-color: $secondBackgroundColor;
	border-radius: 5px;
}

.ph-recipe-upload-image-container .ant-upload.ant-upload-select-picture-card:hover {
	border: 2px dashed $secondColor;
}

.ph-recipe-upload-component-main-container {
	width: 100%;
}

.ph-recipe-upload-component-title-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;
}

.ph-recipe-upload-component-title-icon {
	font-size: 20px;
	margin-right: 5px;
	color: $colorGrayText4;
}

.ph-recipe-upload-component-title {
	color: $colorGrayText4;
	margin-bottom: 0;
	font-size: 15px;
}

.ph-recipe-upload-component-subtitle {
	font-weight: 900;
}

.ph-recipe-upload-component-list {
	margin-bottom: 0;
}

.ph-recipe-upload-component-list li {
	text-align: left;
	color: $colorGrayText4;
	font-size: 12px;
}

.ph-recipe-upload-component-list-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-recipe-upload-component-image {
	width: 100%;
	max-width: 100px;
	opacity: 0.6;
}

.ph-recipe-upload-image-container .ant-upload-list-picture-card-container {
	width: 100%;
	height: 150px;
	margin: 0;
}

.ph-recipe-upload-image-container .ant-upload-list-picture-card .ant-upload-list-item {
	border: 1px solid $primaryColorBorder;
}

.ph-checkout-receipt-modal-container .ant-modal-content {
	border-radius: 8px;
}

.ph-checkout-receipt-modal-container .ant-modal-content .ant-modal-close {
	top: 11px;
	right: 21px;
}

.ph-checkout-receipt-modal-container .ant-modal-content .ant-modal-close-x {
	border: 2px solid $fourthColor;
	border-radius: 100px;
	color: $fourthColor;
	font-weight: bold;
	width: 20px;
	height: 20px;
	font-size: 12px;
	line-height: 0;
	padding: 2px;
}

.ph-checkout-receipt-modal-container .ant-modal-header {
	border-bottom: 1px solid $primaryColorBorder !important;
	padding: 10px 20px;
	border-radius: 8px 8px 0 0;
}

.ph-checkout-receipt-modal-container .ant-modal-header .ant-modal-title {
	font-size: 17px;
	font-weight: 900;
}

.ph-checkout-receipt-modal-container .ant-modal-content .ant-modal-body {
	padding: 16px;
}

.ph-recipe-upload-image-container .ant-upload-list-picture-card .ant-upload-list-item .ant-upload-list-item-info::before {
	left: 0;
}

@media (max-width: 768px) {
	.ph-recipe-upload-image-container .ant-upload.ant-upload-select-picture-card {
		height: auto;
		padding: 15px 2px;
	}
}

@media (max-width: 600px) {
	.ph-recipe-upload-component-list {
		padding-inline-start: 23px;
	}

	.ph-recipe-upload-component-list li {
		font-size: 10px;
	}

	.ph-recipe-upload-component-image {
		max-width: 70px;
	}
}
