/** @format */

@import "../../../../components/Commons/Sass/components/colors";

.ph-profile-address-global-container {
  max-width: 1185px;
  padding: 10px;
  margin: auto;
  min-height: calc(100vh - 146px);
}

.ph-profile-address-main-container {
  padding: 14px;
}

.ph-profile-address-inner-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.ph-profile-address-resend-email-button,
.ph-profile-address-resend-email-button:active,
.ph-profile-address-resend-email-button:focus,
.ph-profile-address-resend-email-button:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primaryColor !important;
  color: $colorWhiteText;
  padding: 5px 10px;
  font-size: 15px;
  border-radius: 4px;
  font-weight: 500;
  box-shadow: $primaryBoxShadow;
  border: 2px solid $primaryColor;
  text-transform: capitalize;
  height: 36px;
}

.ph-profile-address-resend-email-button .anticon {
  line-height: 0 !important;
}

.ph-profile-address-resend-email-button-title {
  margin-left: 4px;
  margin-bottom: 0;
  color: $colorWhiteText;
  font-size: 15px;
}

.ph-profile-address-container {
  position: relative;
  padding: 20px;
  border-radius: 4px;
  box-shadow: $primaryBoxShadow;
  margin-bottom: 20px;
}

.ph-profile-address-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ph-profile-address-info-title {
  color: $colorGrayText3;
  text-align: center;
  max-width: 300px;
  font-style: italic;
  margin-bottom: 0;
}

.ph-profile-address-info-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ph-profile-address-list-container {
  border: 1px solid $primaryColorBorder;
  border-radius: 5px;
  transition: 0.2s ease;
  position: relative;
  margin-bottom: 15px;
  padding: 12px;
}

.ph-profile-address-list-title {
  color: $primaryColor;
  font-weight: 700;
  text-transform: capitalize;
  text-overflow: ellipsis;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 5px;
}

.ph-profile-address-list-description {
  color: $colorGrayText3;
  font-weight: 500;
  text-transform: capitalize;
  text-overflow: ellipsis;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0;
  padding: 0 20px;
  font-style: italic;
}

.ph-profile-address-list-container:last-child {
  margin-bottom: 0;
}

.ph-profile-address-list-button,
.ph-profile-address-list-button:active,
.ph-profile-address-list-button:focus {
  position: absolute;
  bottom: 10px;
  right: 10px;
  border: none;
  border-radius: 4px;
  font-size: 20px;
  padding: 0;
  margin: 0;
  height: auto;
  line-height: 25px;
  color: $colorGrayText4;
  opacity: 0.6;
  transition: 0.3s ease;
}

.ph-profile-address-list-button:hover {
  color: $colorGrayText4;
  opacity: 1;
  transition: 0.3s ease;
}

.ph-profile-address-spin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70px;
}

@media (max-width: 768px) {
  .ph-profile-address-main-container {
    padding: 0;
  }

  .ph-profile-address-container {
    padding: 10px;
  }

  .ph-profile-address-info-button {
    margin: 15px auto 5px;
  }
}

@media (max-width: 575px) {
  .ph-profile-address-resend-email-button,
  .ph-profile-address-resend-email-button:active,
  .ph-profile-address-resend-email-button:focus,
  .ph-profile-address-resend-email-button:hover {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .ph-profile-address-resend-email-button-title {
    font-size: 14px;
  }

  .ph-profile-address-inner-container {
    margin-bottom: 10px;
  }

  .ph-profile-address-info-title {
    line-height: 18px;
  }

  .ph-profile-address-list-title {
    font-size: 14px;
  }

  .ph-profile-address-list-description {
    font-size: 13px;
    padding: 0 20px 0 0;
  }
}
