/** @format */

@import '../../../Sass/components/colors';
@import '../../../../../v2-config/colors.scss';

.icons-theme-address {
	color: $generic-dark-grey1;
	height: 30px;
	width: 30px;
	cursor: pointer;
	&:hover {
		color: $primary-hover;
	}
	&:active,
	&.active {
		color: $primary-default;
	}
}
.ph-address-container {
	color: $colorGrayText2;
	font-size: 10px;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	transition: 0.2s ease;
	margin-top: -8px;
	cursor: pointer;
	width: 90%;
}

.ph-address-container-arg {
	color: $colorWhiteText;
}

.ph-address-description-container {
	width: 87%;
}

.ph-address-container:hover {
	color: $thirdColor;
	transition: 0.2s ease;
}

.ph-address-container-arg:hover {
	color: $colorSecondaryTex;
}

.ph-address-image-logo-container {
	width: 13%;
	padding-right: 2px;
	text-align: center;
}

.ph-address-image-logo {
	width: 100%;
	max-width: 20px;
	margin-top: 3px;
}

.ph-address-description-1 {
	font-size: 10px;
	font-weight: 100;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	margin-bottom: 0;
}

.ph-address-description-2 {
	font-size: 11px;
	font-weight: 400;
	margin-top: -3px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
}

.ph-address-modal-container .ant-modal-content {
	border-radius: 8px;
	left: -75px;
	&::after {
        content: '';
        position: absolute;
        top: -24px; 
        left: 5%; 
        transform: translateX(-50%); 
        border-width: 12px; 
        border-style: solid;
        border-color: transparent transparent white transparent; // Color del triángulo
    }
}

.ph-address-modal-container {
	div[aria-hidden="true"] {
		display: none;
	}
}

.ph-address-modal-container .ant-modal-content .ant-modal-close {
	top: 11px;
	right: 21px;
}

.ph-address-modal-container .ant-modal-content .ant-modal-close-x {
	color: $colorGrayText1;
	font-weight: bold;
	width: 20px;
	height: 20px;
	font-size: 16px;
	line-height: 0;
	padding: 2px;
}

.ph-address-modal-container .ant-modal-header {
	border-bottom: 1px solid $primaryColorBorder !important;
	padding: 10px 20px;
	border-radius: 8px 8px 0 0;
}

.ph-address-modal-container .ant-modal-header .ant-modal-title {
	color: $generic-dark-grey1;
	font-family: Ubuntu;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; 
	padding: 10px 0;
}

.ph-address-modal-container .ant-modal-content .ant-modal-body {
	padding: 0 10px;
}

.ant-select-dropdown {
	z-index: 1060; //Fix Bug not show select dropdown
}

.ph-address-modal-form-container {
	padding-left: 10px;
	padding-top: 10px;

	
	.ph-address-form{
	
		.ph-address-modal-form-title {
			color: $generic-dark-grey1;
			font-family: Ubuntu;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 16px;
			margin-bottom: 20px;
		}
		.ph-address-modal-form-sub-title{
			color: $generic-dark-grey2;
			font-family: Ubuntu;
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: 14px; 
		}
		.form-address-modal-text-address{
			color: $generic-dark-grey2;
			font-family: Ubuntu;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px; 
			display: flex;
			padding: 10px 8px;
			align-items: center;
			align-content: center;
			align-self: stretch;
			flex-wrap: wrap;
			border-radius: 4px;
			border: 1.3px solid $generic-grey2;
			background: $generic-white;
			
		}

		.form-address-modal-text-address-confirmed{
			color: $generic-grey1;
			font-family: Ubuntu;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px;
			padding: 10px 8px;
			align-items: center;
			align-content: center;
			align-self: stretch;
			flex-wrap: wrap;
			border-radius: 4px;
			background: $generic-background-grey;
			margin-bottom: 20px;
		}
		.ant-form-item-label{
			padding-bottom: 6px;
			label{
			color: $generic-dark-grey2;
			font-family: Ubuntu;
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: 14px; 
			height: 14px;
			display: block;
			}
		}
	
		select{
			border-radius: 4px;
			border: 1.3px solid $generic-grey2;
			background: $generic-white;
			height: 36px;
			padding: 6px 8px;
		}

		

/* 		.ant-select-arrow{
			color: $generic-dark-grey2;
			top:35%;
			padding-right: 8px;
			width: 16px;
		}  */

		.ant-input{
			border-radius: 4px;
			border: 1.3px solid $generic-grey2;
			background: $generic-white;
			height: 36px;
			padding: 6px 8px;
		}

		.form-item-street{
			margin-bottom: 10px;
			.ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
				background-color: $generic-green-default;
				border-color: $generic-green-default;
			  }
			  
			  .ant-checkbox-disabled .ant-checkbox-inner {
				background-color: $generic-grey2 !important;
				border-color: $generic-grey2 !important;
			  }
		}

		.span-container{
			height: 100%;	
			display: flex;
			align-items: flex-end;
		}
		.form-required-span{
			color: $generic-dark-grey2;
			font-family: Ubuntu;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; 
			text-align: center;
			 .form-link-button{
				margin: 0;
				padding: 0;
			}
		}
		.text-align{
			text-align: start;
		}

		.form-buttons-container{
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 20px 20px 10px;
			.ant-col {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;
			}
		
		}

		.height-fit-content{
			height: fit-content;
		}

		.form-back-button{
			display: flex;
			width: 160px;
			height: 36px;
			padding: 9px 12.8px;
			justify-content: center;
			align-items: center;
			border-radius:  4px;
			border: 1.3px solid $primary-default;
			background: $generic-white;
			box-shadow: 0px 0px 5px 0px $primary-default;
			color: $primaryColor;
			text-align: center;
			font-family: Ubuntu;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 16px; 
			text-transform: uppercase;
			margin-top: auto;
		}
		.form-confirm-button{
			display: flex;
			width: 160px;
			height: 36px;
			padding: 9px 12.8px;
			justify-content: center;
			align-items: center;
			border-radius: 4px;
			background: $generic-grey2;
			color: $generic-white;
			text-align: center;
			font-family: Ubuntu;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 16px;
			text-transform: uppercase;
			margin-top: auto;
		}

		.enabled-button{
			border: 1px solid $secondColor;
			background: $secondColor;
			box-shadow: 0px 0px 5px 0px $accent-shadow;
		}

	}
} 

.ph-address-modal-title-container {
	background-color: $primaryBackgroundColor;
	text-align: center;
}

.ph-address-modal-default-warning {
	color: red;
	text-align: center;
}

.ph-address-modal-title {
	color: $colorGrayText4;
	font-weight: 600;
	font-size: 19px;
	margin-bottom: 0;
}

.ph-address-modal-description {
	color: $colorGrayText1;
	font-weight: 500;
	font-size: 15.5px;
	margin-bottom: 0;
}

.ph-address-form-main-container {
	position: relative;
	max-width: 350px;
	margin: auto;
}

.ph-address-form-container {
	position: relative;
	max-width: 350px;
	margin: auto;
	padding-top: 10px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	margin-bottom: 20px;
}

.ph-address-form-container .ant-form-item-explain.ant-form-item-explain-error {
	font-size: 12px !important;
	margin-top: 2px !important;
	text-align: left !important;
	padding: 0 5px !important;
	color: $errorColor !important;
}

.ph-address-form-container .ant-form-item-explain.ant-form-item-explain-success {
	font-size: 12px !important;
	margin-top: 2px !important;
	text-align: left !important;
	padding: 0 5px !important;
	color: $colorGrayText4 !important;
}

.ph-address-form-container .ant-form-item-explain {
	min-height: 19px !important;
}

.ph-address-form-container .ant-form-item {
	width: 100%;
	height: 45px;
	margin-bottom: 0;
}

.ph-address-form{
	display: flex;
	flex-direction: column;
	height: 100%;
}

.ph-address-search-field {
	height: 45px;
	width: 100%;
	border: 1px solid $primaryColor;
	border-radius: 4px 0 0 4px;
	box-shadow: $secondaryBoxShadow;
	font-size: 15px;
}

.ph-address-search-field:hover,
.ph-address-search-field:focus,
.ant-input-focused {
	border: 1px solid $primaryColor;
	border-color: $primaryColor !important;
	border-right-width: 0 !important;
	box-shadow: $primaryBoxShadow;
}

// .ph-address-search-field::selection {
//   background: transparent !important;
// }

.ph-address-form-container .ant-form-item-has-error .ant-input:focus,
.ph-address-form-container .ant-form-item-has-error .ant-input {
	border-color: $primaryColor !important;
	box-shadow: $secondaryBoxShadow;
	border-right-width: 0 !important;
}

.ph-address-search-button,
.ph-address-search-button:hover,
.ph-address-search-button:active,
.ph-address-search-button:focus {
	margin: 0;
	width: auto;
	min-width: 45px;
	box-shadow: $secondaryBoxShadow !important;
	height: 45px;
	border: 1px solid $primaryColor !important;
	border-radius: 0 4px 4px 0;
	background-color: $primaryColor !important;
	padding: 0 10px !important;
	font-size: 14px;
	color: $colorWhiteText;
}

.ph-address-search-button .anticon {
	font-size: 23px;
	font-weight: 900;
}

.ph-address-search-list {
	position: absolute;
	top: 55px;
	left: 0;
	z-index: 2;
	width: 100%;
	max-width: 304px;
	box-shadow: $primaryBoxShadow;
	background-color: $primaryBackgroundColor;
}

.ph-address-search-list-description {
	background-color: $primaryBackgroundColor !important;
	cursor: pointer;
	text-align: left;
	padding: 7px !important;
	border-bottom: 1px solid $primaryColorBorder !important;
	font-size: 13px;
	text-overflow: ellipsis !important;
	text-decoration: none;
	overflow: hidden !important;
	white-space: nowrap;
	max-width: 100%;
	width: 100%;
	margin: 0 !important;
	height: auto !important;
	line-height: 1.5 !important;
	color: $colorBackText !important;
}

.ph-address-search-list-description:hover {
	background-color: $colorGrayText0 !important;
}

.ph-address-search-list-image-container {
	margin-right: 4px;
}

.ph-address-search-list-image {
	width: 100%;
	max-width: 17px;
}

.ph-address-box-title {
	color: $primaryColor !important;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	text-align: left;
	margin-bottom: 0;
	margin-right: 7px;
}

.ph-address-box-inner-container {
	display: flex;
	align-items: center;
}

.ph-address-box-alias-container {
	background-color: $primaryBackgroundColor;
}

.ph-address-box-inner-main-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-address-box-address-display-container {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	padding: 5px 10px;
}

.ph-address-box-image-container {
	margin-right: 3px;
	margin-left: 3px;
}

.ph-address-box-description-1 {
	color: $colorGrayText3;
	font-size: 12px;
	font-weight: 500;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	margin-bottom: 0;
}

.ph-address-box-description-2 {
	color: $colorGrayText3;
	font-size: 13px;
	font-weight: 600;
	margin-top: -3px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	margin-bottom: 0;
}

.ph-address-notification {
	color: $colorGrayText4;
	text-align: center;
	font-style: italic;
	margin-top: 5px;
	margin-bottom: 0;
	font-size: 12px;
}

.ph-address-pharmacy-title {
	color: $primaryColor !important;
	font-weight: 500;
	font-size: 15px;
	margin: 12px;
	margin-bottom: 0;
	text-align: left;
}

.ph-address-pharmacy-list {
	border-right: none;
}

.ph-address-box-list-pharmacies-empty-container {
	box-shadow: $primaryBoxShadow;
	width: 100%;
	max-width: 820px;
	margin: 10px auto;
	text-align: center;
	border-radius: 4px;
}

.ph-address-box-list-pharmacies-empty-image-container {
	margin-top: 5px;
	padding: 0;
	display: block;
}

.ph-address-box-list-pharmacies-empty-title {
	color: $colorGrayText3;
	font-style: italic;
}

.ph-address-box-list-pharmacies-empty-image {
	width: 100%;
	max-width: 40px;
	opacity: 0.5;
}

.ph-address-type-delivery-main-container {
	background-color: $primaryBackgroundColor;
}

.ph-address-type-delivery-container {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-right: 20px;
}

.ph-address-type-delivery-image {
	width: 22px;
	height: auto;
}

.ph-address-type-delivery-title {
	font-size: 13px;
	font-style: italic;
	cursor: pointer;
	margin-top: 1px;
	margin-bottom: 0;
}

.ph-address-modal-container .ant-modal-content .ant-modal-footer {
	padding: 16px;
	padding-top: 0;
	border-top: none;
	text-align: center;
}

.ph-address-modal-container .ant-modal-content .ant-modal-footer .ant-btn {
	background: $primaryColor !important;
	color: $primaryBackgroundColor !important;
	font-size: 20px;
	text-transform: capitalize;
	border-radius: 4px;
	min-width: auto;
	width: 160px;
	margin-top: 1px;
	border-color: transparent;
	height: 36px;
	margin: 0 0 5px 0;
	line-height: 10px;
	box-shadow: $primaryBoxShadow;
}

.ph-address-search-button[disabled] {
	box-shadow: $primaryBoxShadow;
	height: 45px;
	border: 1px solid $colorGrayText3 !important;
	border-radius: 0 4px 4px 0;
	background-color: $colorGrayText3 !important;
	padding: 0 10px !important;
	font-size: 14px;
	color: $colorWhiteText !important;
}

.ph-address-search-list-on-demand-title {
	font-size: 13px;
	color: $errorColor;
	font-style: italic;
	line-height: 15px;
	margin-bottom: 15px;
}

.ph-address-d-none {
	display: none;
}

@media (max-width: 1080px) {
	.ph-address-modal-container .ant-modal-content {
		left: -50px;
	}
}

@media (max-width: 1024px) {
	.ph-address-container {
		margin-top: 1px;
	}

	.ph-address-description-2 {
		margin-bottom: 0;
	}

	.ph-address-modal-container .ant-modal-content {
		left: -15px;
	}
}

@media (max-width: 991px){
	.ph-address-modal-container .ant-modal-content {
		left: 0px;
	
		&::after {
			content: none;
		}
	}
}

@media (max-width: 767px) {
	.display-none{
		display: none;
	}
	.ph-address-modal-container{
		.ant-modal{
			top: 45px;
		}
		.ant-modal-header{
			padding: 0 10px;
		}
		.ant-modal-body{
			padding-top: 0!important;
		}
		.ph-address-modal-form-container{
			padding-left: 0;
		}
		.text-align{
			text-align: center!important;
		}

		.form-buttons-container{
			padding-left: 0!important;
			padding-right: 0!important;
			.form-back-button{
				margin-right: auto;
			}
			.margin-left-auto{
				margin-left: auto;
			}
		}
	}
}

@media (max-width: 600px) {
	.ph-address-box-inner-main-container {
		display: block;
	}

	.ph-address-box-address-display-container {
		margin-top: 10px;
		display: block;
	}

	.ph-address-box-description-2 {
		width: 92%;
	}
}

@media (max-width: 575px) {
	.ph-address-modal-title {
		font-weight: 900;
		font-size: 12px;
	}

	.ph-address-modal-description {
		font-size: 11px;
	}

	.ph-address-box-container {
		margin: 0;
		margin-bottom: 10px;
	}

	.ph-address-box-container {
		height: auto;
		min-height: auto;
	}

	.ph-address-pharmacy-list-container {
		margin: 10px 0px !important;
	}

	.ph-address-modal-container .ant-modal-content .ant-modal-body {
		padding: 10px;
		// padding-bottom: 40px;
	}

	.ph-address-modal-container .ant-modal-content .ant-modal-footer {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: $primaryBackgroundColor;
		border-top: 1px solid $primaryColorBorder !important;
		padding-top: 10px;
		padding-bottom: 10px;
		z-index: 1;
	}
}
