/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-product-detail-quote-title-info-container {
	width: 100%;
}

.ph-product-detail-quote-title-info-subtitle-hashtag-container {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}

.ph-product-detail-quote-title-info-main-subtitle {
	display: flex;
	color: #669bfb;
	font-size: 12px;
	font-weight: 300;
	padding: 0 0px;
}

.ph-product-detail-quote-title-info-title {
	margin-bottom: 0;
	color: $colorGrayText2;
	font-size: 12px;
	font-weight: 300;
	opacity: 0.7;
	text-align: left;
}

.ph-product-detail-quote-title-info-inner-title {
	font-weight: 900;
	padding-left: 4px;
}

.ph-product-detail-quote-title-info-name-favorite-container {
	display: flex;
	align-items: center;
	margin-bottom: 5px;

	& :last-child {
		margin-left: auto;
		align-self: flex-start;
	}
}

.ph-product-detail-quote-title-info-main-title {
	font-size: 18px;
	font-weight: 900;
	color: $colorGrayText4;
	margin-bottom: 0;
}

.ph-product-detail-quote-icon-container {
	width: 26px;
	display: inline-block;
	cursor: pointer;
}

.ph-product-detail-quote-icon-image {
	width: 100%;
	max-width: 26px;
}

.ph-product-detail-quote-title-info-favorite-icon {
	width: 26px;
	min-height: 26px;
}

.ph-product-detail-quote-title-info-subtitle {
	margin-bottom: 0;
	color: $colorGrayText2;
	font-size: 14px;
	font-weight: 500;
	opacity: 0.8;
	margin-left: 10px;
}

.ph-product-detail-quote-hashtag-inner-container {
	margin-left: auto;
	display: inline-flex;

	&.ph-mobile {
		display: none;
	}
}

.ph-product-detail-quote-hashtag-title {
	font-size: 14px;
	color: $colorGrayText2;
	font-style: italic;
	margin-bottom: 0;
	padding-right: 6px;
	margin-left: auto;
}

.ph-product-detail-quote-share-popup-container {
	position: relative;

	&:hover .ph-product-detail-quote-social-network-container {
		visibility: visible;
		opacity: 1;
	}
}

.ph-product-detail-quote-share-icon {
	width: 22px;
	min-height: 26px;
}

.ph-product-detail-quote-social-network-container {
	visibility: hidden;
	opacity: 0;
	background-color: $primaryBackgroundColor;
	position: absolute;
	top: 32px;
	width: 220px;
	left: -200px;
	z-index: 1;
	box-shadow: 0 1px 3px rgb(0 0 0 / 4%), 0 1px 1px rgb(0 0 0 / 4%), 0 1px 3px rgb(0 0 0 / 12%);
	border-radius: 5px;
	transition: all 0.25s linear;

	&::after {
		content: '';
		position: absolute;
		top: -12.5px;
		right: 5px;
		transform: rotate(180deg);
		border-width: 7px;
		border-style: solid;
		border-color: $primaryBackgroundColor transparent transparent transparent;
	}
}

.ph-product-detail-quote-social-network-container-AR {
	@extend .ph-product-detail-quote-social-network-container;
	width: fit-content;
	left: -50px;
}

.ph-product-detail-type-recepit-main-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 10px;
	box-shadow: $primaryBoxShadow;
	background-color: $primaryBackgroundColor;
	border-radius: 5px;
}

@media (max-width: 992px) {
	.ph-product-detail-quote-title-info-name-favorite-container {
		margin-bottom: 8px;
	}

	.ph-product-detail-quote-title-main-container {
		padding: 0;
	}

	.ph-product-detail-quote-title-info-container {
		margin-left: 0;
		padding-left: 0;
		border-left: none;
	}

	.ph-product-detail-quote-title-info-main-title {
		width: 100%;
		max-width: 70%;
	}

	.ph-product-detail-quote-title-info-main-subtitle {
		font-size: 13px;
	}

	.ph-product-detail-quote-title-info-subtitle {
		font-size: 12px;
	}

	.ph-product-detail-quote-title-info-subtitle-hashtag-container {
		margin-bottom: 0;
	}

	.ph-product-detail-quote-hashtag-inner-container {
		display: none;
		margin: 10px 0;

		&.ph-mobile {
			display: inline-flex;
			float: right;
		}
	}

	.ph-product-detail-quote-hashtag-title {
		font-size: 11px;
	}

	.ph-product-detail-quote-share-popup-container {
		margin-left: auto;
	}

	.ph-product-detail-type-recepit-main-container {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 10px;
		margin-bottom: 10px;
	}
}
