/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-bag-electronic-cart-presciption-producs-main-container {
	box-shadow: $primaryBoxShadow;
	border-radius: 4px;
	margin: 5px;
	margin-top: 10px;
	height: auto;
	padding: 10px;
	position: relative;
}

.ph-bag-electronic-cart-presciption-producs-title-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-top: 5px;
	padding-left: 5px;
	padding-right: 5px;
	margin-bottom: 10px;
}

.ph-bag-electronic-cart-presciption-producs-title-image-container {
	margin-right: 6px;
	margin-top: -1px;
}

.ph-bag-electronic-cart-presciption-producs-title-image {
	width: 100%;
	max-width: 20px;
}

.ph-bag-electronic-cart-presciption-producs-title {
	color: $colorGrayText4;
	font-weight: 600;
	font-size: 15px;
	margin-bottom: 0;
}

.ph-bag-electronic-cart-presciption-collapse-container {
	border: none;
	border-radius: 4px;
	background-color: $primaryBackgroundColor;
}

.ph-bag-electronic-cart-presciption-collapse-container.ant-collapse .ant-collapse-item {
	border: 1px solid $primaryColorBorder;
	border-radius: 4px;
	margin-bottom: 10px;
}

.ph-bag-electronic-cart-presciption-collapse-container.ant-collapse .ant-collapse-item:last-child {
	margin-bottom: 0;
}

.ph-bag-electronic-cart-presciption-collapse-container .ant-collapse-header {
	padding: 10px !important;
	background-color: rgba(221, 221, 221, 0.1) !important;
}

.ph-bag-electronic-cart-presciption-collapse-container .ant-collapse-content {
	border-top: 1px solid $primaryColorBorder;
}

.ph-bag-electronic-cart-presciption-collapse-container .ant-collapse-header .ant-collapse-arrow {
	font-size: 9px !important;
}

.ph-bag-electronic-cart-presciption-collapse-container
	.ant-collapse-content
	.ant-collapse-content-box {
	padding: 9px;
}

.ph-bag-electronic-cart-presciption-producs-panel-collapse-image-container {
	padding: 10px;
	display: block;
	margin: 10px 0;
}

.ph-bag-electronic-cart-presciption-producs-panel-collapse-image {
	width: 100%;
}

.ph-bag-electronic-cart-presciption-product-description-container {
	padding: 0;
}

.ph-bag-electronic-cart-presciption-product-description-title {
	width: 100%;
	font-size: 15px;
	color: $colorGrayText3;
	font-weight: 900;
	text-transform: none;
	text-overflow: ellipsis;
	text-decoration: none;
	overflow: hidden;
	max-width: 100%;
	margin-bottom: 0;
}

.ph-bag-electronic-cart-presciption-product-description-laboratory {
	margin-bottom: 5px;
	color: $colorGrayText3;
	font-size: 14px;
	font-weight: 300;
	font-style: italic;
	margin-bottom: 0;
}

.ph-bag-electronic-cart-presciption-product-description-type {
	margin-bottom: 5px;
	color: $primaryColor;
	font-size: 13px;
	font-weight: 300;
	font-style: italic;
	margin-bottom: 0;
	opacity: 0.8;
}

.ph-bag-electronic-cart-presciption-product-description-subtype {
	font-weight: 900;
}

.ph-bag-electronic-cart-presciption-product-description-on-demand-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-bag-electronic-cart-presciption-product-description-on-demand {
	margin-bottom: 5px;
	color: $primaryColor;
	font-size: 14px;
	font-weight: 300;
	margin-bottom: 0;
}

.ph-bag-electronic-cart-presciption-panel-pharmacy-on-demand-inner-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-bag-electronic-cart-presciption-panel-container {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 10px;
	margin: 10px 0;
}

.ph-bag-electronic-cart-presciption-pharmacies-container {
	padding: 2px;
	max-height: 400px;
	overflow-y: auto;
}

.ph-bag-electronic-cart-presciption-pharmacies-title {
	color: $primaryColor;
	font-weight: 500;
	font-size: 16px;
	text-align: left;
	margin-bottom: 3px;
	padding: 0 5px;
}

.ph-bag-electronic-cart-presciption-producs-title-image-on-demand-container {
	margin-left: 6px;
	margin-top: -1px;
}

.ph-bag-electronic-cart-presciption-producs-title-on-demand-image {
	width: 100%;
	max-width: 20px;
}

.ph-bag-electronic-cart-presciption-panel-pharmacy-container {
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: $primaryBoxShadow;
	border-radius: 4px;
	padding: 6px 10px;
	margin-bottom: 10px;
	cursor: pointer;
}

.ph-bag-electronic-cart-presciption-panel-pharmacy-container:hover {
	background-color: rgba(221, 221, 221, 0.1);
}

.ph-bag-electronic-cart-presciption-panel-pharmacy-title-description {
	width: 100%;
	max-width: 200px;
}

.ph-bag-electronic-cart-presciption-panel-pharmacy-title-inner-description {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-bag-electronic-cart-presciption-panel-pharmacy-on-demand-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-bag-electronic-cart-presciption-panel-pharmacy-on-demand-title {
	font-size: 12px;
	font-weight: 500;
	color: $colorGrayText3;
	margin-bottom: 0;
	margin-left: 5px;
}

.ph-bag-electronic-cart-presciption-panel-pharmacy-on-demand-image-container {
	margin-left: 3px;
	margin-top: -4px;
}

.ph-bag-electronic-cart-presciption-panel-pharmacy-on-demand-image {
	width: 100%;
	max-width: 15px;
}

.ph-bag-electronic-cart-presciption-panel-pharmacy-image-container {
	margin-right: 3px;
}

.ph-bag-electronic-cart-presciption-panel-pharmacy-image {
	width: 100%;
	max-width: 20px;
}

.ph-bag-electronic-cart-presciption-panel-pharmacy-title {
	font-size: 12px;
	color: $colorGrayText4;
	font-weight: 500;
	margin-bottom: 0;
}

.ph-bag-electronic-cart-presciption-panel-pharmacy-container:last-child {
	margin-bottom: 0;
}

.ph-bag-electronic-cart-presciption-panel-pharmacy-price {
	width: 100%;
	max-width: 160px;
}

.ph-bag-electronic-cart-presciption-panel-pharmacy-button-container {
	width: 100%;
	max-width: 180px;
	text-align: right;
}

.ph-bag-electronic-cart-presciption-panel-pharmacy-button,
.ph-bag-electronic-cart-presciption-panel-pharmacy-button:hover,
.ph-bag-electronic-cart-presciption-panel-pharmacy-button:active,
.ph-bag-electronic-cart-presciption-panel-pharmacy-button:focus {
	color: $colorWhiteText;
	font-weight: bold;
	margin: auto;
	height: 35px;
	width: 100%;
	max-width: 100px;
	box-shadow: $mainHeaderboxShadow !important;
	border: 2px solid $secondColor;
	background-color: $secondColor;
	border-radius: 4px;
	padding: 5px;
}

.ph-bag-electronic-cart-presciption-panel-pharmacy-button[disabled],
.ph-bag-electronic-cart-presciption-panel-pharmacy-button[disabled]:hover,
.ph-bag-electronic-cart-presciption-panel-pharmacy-button[disabled]:active,
.ph-bag-electronic-cart-presciption-panel-pharmacy-button[disabled]:focus {
	background: $thirdBackgroundColor !important;
	border-color: $thirdBackgroundColor !important;
}

.ph-bag-electronic-cart-presciption-panel-pharmacy-button-alternative,
.ph-bag-electronic-cart-presciption-panel-pharmacy-button-alternative:hover,
.ph-bag-electronic-cart-presciption-panel-pharmacy-button-alternative:active,
.ph-bag-electronic-cart-presciption-panel-pharmacy-button-alternative:focus {
	color: $primaryColor;
	font-weight: 500;
	margin: auto;
	height: 35px;
	width: 100%;
	max-width: 100px;
	box-shadow: $mainHeaderboxShadow !important;
	border: 2px solid $primaryColor;
	background-color: $primaryBackgroundColor;
	border-radius: 4px;
	padding: 5px;
	font-size: 12px;
}

@media (max-width: 1150px) {
	.ph-bag-electronic-cart-presciption-panel-pharmacy-title {
		font-size: 12px;
	}
}

@media (max-width: 992px) {
	.ph-bag-electronic-cart-presciption-producs-panel-collapse-image {
		max-width: 100px;
	}

	.ph-bag-electronic-cart-presciption-panel-pharmacy-container {
		padding: 6px 10px;
	}
}

@media (max-width: 768px) {
	.ph-bag-electronic-cart-presciption-pharmacies-container {
		padding-left: 0;
	}
	.ph-bag-electronic-cart-presciption-producs-main-container {
		padding: 5px;
	}
}

@media (max-width: 600px) {
	.ph-bag-electronic-cart-presciption-producs-title {
		font-size: 12px;
	}
}
