/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-service-tabs-plans-forms-main-container {
	padding: 0 15px;
}

.ph-service-tabs-plans-options-container {
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid $primaryColorBorder;
	margin-top: 12px;
	padding-bottom: 12px;
}

.ph-service-tabs-plans-options-select-container .ant-form-item-explain {
	text-align: right;
	margin-right: 15px;
}

.ph-service-tabs-plans-options-select-container .ant-form-item {
	margin-bottom: 0;
}

.ph-service-tabs-plans-options-title {
	margin-bottom: 0;
	font-weight: 500;
	color: $colorGrayText3;
	padding-left: 10px;
	font-size: 13px;
}

.ph-service-tabs-plans-options-2-container {
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid $primaryColorBorder;
	margin-top: 12px;
	padding-bottom: 12px;
}

.ph-service-tabs-plans-options-commune-title {
	font-size: 12px;
	margin-bottom: 0;
	padding-left: 15px;
	color: $colorGrayText3;
	font-style: italic;
}

.ph-service-tabs-plans-options-commune-title-price {
	font-style: normal;
	color: $colorGrayText4;
	font-weight: 600;
}

.ph-service-tabs-plans-options-commune-description {
	font-size: 12px;
	margin-bottom: 0;
	padding-left: 15px;
	color: $colorGrayText3;
	font-style: italic;
}

.ph-service-tabs-plans-options-commune-description-price {
	font-style: normal;
	color: $colorGrayText4;
	font-weight: 600;
}

.ph-service-tabs-plans-options-commune-loading-container {
	width: 100%;
	text-align: center;
	padding: 7px 5px;
}

.ph-service-tabs-plans-options-commune-loading-container .anticon-loading {
	color: $primaryColor;
}

.ph-service-tabs-plans-options-select-title-container {
	color: $colorGrayText3;
	font-size: 13px;
}

.ph-service-tabs-plans-options-select-container .ant-radio-checked .ant-radio-inner {
	border-color: $primaryColor;
}

.ph-service-tabs-plans-options-select-container .ant-radio-inner::after {
	background-color: $primaryColor;
}

.ph-service-tabs-plans-options-select-container .ant-form-item-control-input-content {
	text-align: right;
}

.ph-service-tabs-plans-options-select-container .ant-checkbox-checked .ant-checkbox-inner {
	background-color: $primaryColor;
	border-color: $primaryColor;
}

.ph-service-tabs-plans-main-logo-image-container {
	padding: 13px 5px;
	display: block;
	text-align: right;
}

.ph-service-tabs-plans-main-logo-image {
	width: 100%;
	max-width: 100px;
}

.ph-service-tabs-plans-options-select-commune-selector {
	max-width: 100%;
	border-radius: 6px;
	font-size: 13px;
}

.ph-service-tabs-plans-options-select-commune-selector .ant-select-selector {
	max-width: 100%;
	border-radius: 6px !important;
	font-size: 13px !important;
}

.ph-service-tabs-plans-options-select-commune-selector .ant-select-selection-item {
	color: $colorGrayText2;
}

.ph-service-tabs-plans-options-select-commune-container .ant-form-item {
	margin-bottom: 0;
}

.ph-service-tabs-plans-options-select-commune-container {
	width: 100%;
	max-width: 250px;
}

.ph-service-tabs-plans-options-aditional-info-title {
	font-size: 12px;
	margin-bottom: 0;
	color: $colorGrayText3;
	border-bottom: 1px solid $primaryColorBorder;
	padding: 4px 15px;
}

.ph-service-tabs-plans-options-aditional-info-title-2 {
	font-size: 13px;
	margin-bottom: 0;
	color: $colorGrayText3;
	padding: 10px 15px 4px 15px;
}

.ph-service-tabs-plans-options-aditional-info-title-2-asterisk {
	color: $errorColor;
}

@media (max-width: 992px) {
	.ph-service-tabs-plans-forms-main-container {
		padding: 0 7px;
	}

	.ph-service-tabs-plans-options-title-container {
		max-width: 50%;
		flex: 0 0 50%;
	}

	.ph-service-tabs-plans-options-select-container {
		max-width: 50%;
		flex: 0 0 50%;
	}

	.ph-service-tabs-plans-options-select-container .ant-form-item-control-input-content {
		text-align: left;
	}
}

@media (max-width: 600px) {
	.ph-service-tabs-plans-options-2-container {
		display: block;
	}
	.ph-service-tabs-plans-options-2-title-container {
		max-width: 100%;
		flex: 0 0 100%;
		padding-bottom: 13px;
	}

	.ph-service-tabs-plans-options-select-commune-container {
		max-width: 100%;
	}
}
