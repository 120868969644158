@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,500');
@import '../../../../../_config/colors/global.scss';

.own-card-product-mobile-landing {
	cursor: pointer;
	width: 100%;
	margin: 5px 0;
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.05) 0 0 0 1px;

	.ant-card-body {
		padding: 10px;
	}


.own-favorite-container-mobile {
	padding-left: 10px;

	.own-laboratory-label-mobile {
		color: $colorGrayText1;
		font-family: Ubuntu;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
	}

	.own-favorite-mobile {
		border: none;
		box-shadow: none;
		background-color: transparent;
		display: grid;

		svg {
			width: 24px;
			height: 24px;
			justify-self: flex-end;
		}
	}

	.own-product-title-mobile {
		color: $colorGrayText4;
		font-family: Ubuntu;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		text-align: start;
	}

	.own-since-mobile {
		@extend .own-laboratory-label-mobile;
		color: $colorGrayText4;

	}

	.own-promotion-mobile,
	.own-mecanic-mobile {
		background-color: $mecanicColor;
		color: $color3;
		display: flex;
		width: fit-content;
		min-width: 100px;
		padding-left: 10px;
		padding-right: 10px;
		height: 22px;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-family: Ubuntu;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		border-radius: 2px;
	}



	.own-price-mobile-container {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		align-self: stretch;

		.own-promotion-price-mobile {
			margin-bottom: 0;
			margin-right: 5px;
			color: $colorGrayText1;
			font-family: Ubuntu;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
			text-decoration-line: line-through;
		}

		.own-final-price-mobile {
			margin-bottom: 0;
			color: $colorGrayText4;
			font-family: Ubuntu;
			font-size: 18px;
			font-style: normal;
			font-weight: 700;
			line-height: 26px;

			span {
				font-size: 13px;
				font-weight: 400;
				line-height: 20px;
			}
		}
	}
}

.own-image-icons-container-mobile {
	min-width: 75px !important;

	.own-tag-icon-container-mobile {
		position: absolute;
		top: 0;
	}

	.own-image-mobile-container {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;

		.ant-row .ant-row-start .ant-row-middle {
			width: 75px !important;
		}

	}


	.own-recipe-image-icons-container {
		position: absolute;
		bottom: 0;
		gap: 2px;
	}
}
}