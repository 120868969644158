/** @format */

@import '../../../../../../Sass/components/colors';
@import '../../../../../../../../v2-config/colors.scss';
.icons-theme-user-actions {
	color: $generic-dark-grey1;
	height: 30px;
	width: 30px;
	margin: -5px 5px 10px 5px;
	display: inline-block;
	position: relative;
	cursor: pointer;
	&:hover {
		color: $primary-hover;
	}
	&:active,
	&.active {
		color: $primary-default;
	}
}

.ph-user-actions-main-container {
	text-align: right;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.ph-user-actions-header-component {
	margin-top: -5px;
}

.ph-user-actions-link-container-home {
	display: inline-block;
	position: relative;
	cursor: pointer;
}

.ph-user-actions-link-container-search {
	display: inline-block;
	position: relative;
	cursor: pointer;
}

.ph-user-actions-link-container {
	display: inline-block;
	position: relative;
	cursor: pointer;
}

.ph-user-actions-icon-container {
	width: 40px;
	height: 40px;
	display: inline-block;
	border: 1px solid transparent;
	padding: 5px;
	transition: 0.3s ease;
	border-radius: 100px;
}

.ph-user-actions-button-user {
	line-height: auto;
	height: auto;
	padding: 0;
	border: none;
	border-radius: 100%;
}

.ph-user-actions-icon-container:hover,
.ph-user-actions-icon-container-last:hover {
	border: 1px solid transparent;
	transition: 0.3s ease;
}

.ph-user-actions-icon {
	width: 30px;
	z-index: 1;
	border-radius: 0;
}

.ph-user-actions-baloon-icon {
	background: $secondColor;
	padding: 0 2px;
	border-radius: 50%;
	position: absolute;
	margin-top: 3px;
	z-index: 2;
	border: none;
	height: 16px;
	top: -6px;
	right: 0;
	line-height: 14.4px;
	width: 16px;
	text-align: center;
}

.ph-user-actions-baloon-title {
	color: $colorWhiteText;
	font-weight: 500;
	font-size: 10px;
	position: absolute;
	top: 1px;
	left: 1px;
	right: 0;
	bottom: 0;
}
