$generic-white: #ffffff;
$generic-grey1: #7b7b7b;
$generic-grey2: #aaaaaa;
$generic-dark-grey1: #555555;
$generic-dark-grey2: #2e2d36;

$generic-green-default: #088926;
$generic-green-hover: #58cc73;
$generic-green-active: #066b1e;
$generic-green-shadow: 0px 0px 10px 0px #08892633;

$generic-red-default: #d2000c;
$generic-red-hover: #bd020c;
$generic-red-active: #f2656d;
$generic-red-shadow: 0px 0px 10px 0px #d2000c33;

$generic-background-grey: #F7F7F7;
$generic-background-red: #FFF2F3;
$generic-background-green: #E5FFEB;


.own-bold {
	font-weight: 600;
}

.own-medium {
	font-weight: 500;
}

.own-regular {
	font-weight: 400;
}

.own-through {
	text-decoration-line: line-through;
}

h1.lg {
	font-size: 20px;
	line-height: 55px;
}

h1.md {
	font-size: 20px;
	line-height: 30px;
}

h2.md {
	font-size: 18px;
	line-height: 20px;
}

h3.md {
	font-size: 16px;
	line-height: 20px;
}

h3.sm {
	font-size: 16px;
	line-height: 18px;
}

h4.lg {
	font-size: 14px;
	line-height: 20px;
}

h4.sm {
	font-size: 14px;
	line-height: 16px;
}

p.md {
	font-size: 14px;
	line-height: 20px;
}

p.sm {
	font-size: 14px;
	line-height: 18px;
}

p.xs {
	font-size: 12px;
	line-height: 18px;
}

p.xxs {
	font-size: 10px;
	line-height: 18px;
}

label.md {
	font-size: 13px;
	line-height: 14px;
}

label.sm {
	font-size: 10px;
	line-height: 12px;
}

text.md {
	font-size: 14px;
	line-height: 16px;
}