/** @format */

@import '../../components/Commons/Sass/components/colors';

.ph-payment-global-container {
	max-width: 1185px;
	padding: 10px;
	margin: auto;
}

.ph-payment-main-container {
	padding: 10px 25px;
	margin-bottom: 40px;
}

.ph-payment-titlebox-countdown-container {
	position: relative;
}

.ph-payment-gateways-container {
	box-shadow: $primaryBoxShadow;
	background-color: $primaryBackgroundColor;
	border-radius: 4px;
	margin: 4px 4px 8px 4px;
	padding: 10px;
}

.ph-payment-card-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.ph-payment-method-title-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 5px;
	padding: 0 10px;
}

.ph-payment-method-icon {
	font-weight: 900;
	margin-right: 4px;
	color: $colorGrayText3;
}

.ph-payment-method-title {
	color: $colorGrayText3;
	margin-bottom: 0;
	font-weight: 900;
}

.ph-payment-card-main-container {
	position: relative;
}

.ph-payment-card-icon-active {
	display: unset;
}

.ph-payment-card-icon-disabled {
	display: none;
}

.ph-payment-card-icon {
	color: $successColor;
	background-color: $primaryBackgroundColor;
	position: absolute;
	right: -5px;
	bottom: -5px;
	font-size: 20px;
	border-radius: 100%;
}

.ph-payment-box-main-container {
	box-shadow: $primaryBoxShadow;
	background-color: $primaryBackgroundColor;
	border-radius: 4px;
	margin: 4px;
	padding: 20px 25px;
}

.ph-payment-box-discount-main-container {
	box-shadow: $primaryBoxShadow;
	border-radius: 4px;
	margin: 4px;
	margin-bottom: 8px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 7px;
}

.ph-payment-box-discount-image-container {
	padding: 5px;
}

.ph-payment-box-discount-image {
	width: 100%;
	max-width: 50px;
}

.ph-payment-box-discount-title {
	font-size: 12px;
	font-weight: 600;
	color: $primaryColor;
	margin-bottom: 2px;
}

.ph-payment-box-discount-description {
	margin-bottom: 0;
	font-size: 11px;
	color: $colorBackText;
	line-height: 13px;
}

.ph-payment-box-discount-subtitle {
	text-transform: capitalize;
	font-weight: 600;
}

.ph-cart-payment-excedent-modal-title-pending,
.ph-cart-payment-excedent-modal-title-success {
	margin-bottom: 15px;
	color: $colorGrayText2;
	font-style: italic;
	font-weight: 500;
}

.ph-cart-payment-excedent-modal-subtitle-pending {
	margin-bottom: 20px;
	color: $secondColor;
	font-style: italic;
	font-weight: 600;
}

.ph-cart-payment-excedent-modal-cancellation-title {
	color: $errorColor;
	font-size: 13px;
	font-style: italic;
	margin-bottom: 0;
	line-height: 17px;
}

.ph-cart-payment-excedent-modal-cancellation-button,
.ph-cart-payment-excedent-modal-cancellation-button:hover,
.ph-cart-payment-excedent-modal-cancellation-button:active,
.ph-cart-payment-excedent-modal-cancellation-button:focus {
	background-color: $primaryColor;
	border: none;
	color: $colorWhiteText;
	margin: 5px 0 8px;
	font-size: 13px;
	border-radius: 4px;
	font-weight: 300;
	text-transform: uppercase;
	width: 100%;
	height: 30px;
	max-width: 120px;
	box-shadow: none;
	padding: 4px 15px;
	border: 1px solid transparent;
	line-height: 1.5715;
	position: relative;
	display: inline-block;
	white-space: nowrap;
	text-align: center;
	background-image: none;
}

.ph-cart-payment-excedent-modal-title-success-image-container {
	padding: 2px;
	margin-bottom: 15px;
	display: block;
}

.ph-cart-payment-excedent-modal-title-success-image {
	width: 100%;
	max-width: 50px;
	margin: auto;
}

.ph-checkout-payment-button-container {
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: $mainHeaderboxShadow;
	border-radius: 4px;
	width: 100%;
	max-width: 190px;
	margin: 10px auto 0;
}

.ph-checkout-payment-button-1,
.ph-checkout-payment-button-1:hover,
.ph-checkout-payment-button-1:focus,
.ph-checkout-payment-button-1:active {
	background-color: $secondColor;
	color: $colorWhiteText;
	margin: 0 auto;
	margin-top: 0;
	font-weight: 900;
	height: 36px;
	width: 100%;
	box-shadow: none !important;
	border: none;
	border-radius: 4px 0 0 4px;
	margin-right: 1px;
	font-size: 20px;
	padding: 0 10px;
}

.ph-checkout-payment-button-2,
.ph-checkout-payment-button-2:hover,
.ph-checkout-payment-button-2:focus,
.ph-checkout-payment-button-2:active {
	background-color: $secondColor;
	color: $colorWhiteText;
	margin: 0 auto;
	margin-top: 0;
	font-weight: 900;
	height: 36px;
	width: 100%;
	box-shadow: none !important;
	border: none;
	border-radius: 0 4px 4px 0;
	font-size: 15px;
	padding: 0 35px;
}

.ph-checkout-pay-payment-modal .ant-modal-header {
	display: none;
}

.ph-checkout-pay-payment-modal .ant-modal-close {
	display: none;
}

.ph-checkout-pay-payment-modal .ant-modal-body {
	text-align: center;
}

.ph-checkout-pay-payment-modal .ant-modal-content {
	border-radius: 8px;
}

.ph-checkout-pay-payment-modal-title {
	margin-bottom: 20px;
	color: $primaryColor;
	font-weight: 600;
}

.ph-checkout-pay-payment-modal-image-container {
	padding: 0;
	margin: 0;
}

.ph-checkout-pay-payment-modal-image {
	position: absolute;
	width: 100%;
	max-width: 40%;
	top: 0;
	left: 0;
	right: 0;
	margin: auto;
	bottom: 0;
	border-radius: 100px;
	opacity: 0.1;
}

@media (max-width: 1024px) {
	.ph-payment-main-container {
		padding: 0;
		margin-bottom: 55px;
	}
}

@media (max-width: 992px) {
	.ph-payment-global-container {
		padding: 5px;
	}
	.ph-payment-box-global-container {
		padding-bottom: 10px;
	}

	.ph-payment-box-main-container {
		margin: -4px 4px 4px 4px;
	}

	.ph-payment-box-discount-main-container {
		margin-top: 0;
		margin-bottom: 12px;
	}
}

@media (max-width: 768px) {
	.ph-checkout-payment-button-main-container {
		position: fixed;
		z-index: 2;
		bottom: 0;
		background-color: $primaryBackgroundColor;
		width: 100%;
		margin-bottom: 0;
		border-top: 1px solid $thirdBackgroundColor;
		padding: 2px 15px 12px 15px;
		left: 0;
		right: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

@media (max-width: 414px) {
	.ph-payment-card-container {
		width: 120px;
		height: 120px;
	}
}

@media (max-width: 375px) {
	.ph-payment-card-main-container {
		margin-left: 10px;
	}
}
