/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-checkout-on-demand-card-container {
	box-shadow: $primaryBoxShadow;
	border-radius: 4px;
	margin: 4px;
	margin-bottom: 8px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 7px;
}

.ph-checkout-on-demand-card-image-container {
	padding: 5px;
}

.ph-checkout-on-demand-card-image {
	width: 100%;
	max-width: 30px;
}

.ph-checkout-on-demand-title {
	font-size: 12px;
	font-weight: 600;
	color: $primaryColor;
	margin-bottom: 2px;
}

.ph-checkout-on-demand-subtitle {
	margin-bottom: 0;
	font-size: 11px;
	color: $colorBackText;
	line-height: 13px;
}

.ph-checkout-on-demand-subtitle-bold {
	font-weight: 600;
	color: $primaryColor;
}
