/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-order-detail-products-payment-info-main-container {
	border-top: 1px solid $primaryColorBorder;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-order-detail-products-payment-info-inner-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

@media (max-width: 768px) {
	.ph-order-detail-products-payment-info-main-container {
		display: block;
	}

	.ph-order-detail-products-payment-info-inner-container {
		display: block;
		padding: 5px 10px;
	}
}
