/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-order-detail-products-pharmacy-container {
	position: absolute;
	top: 0;
	right: 0;
	display: block;
	text-align: center;
}

.ph-order-detail-products-pharmacy-image-container {
	margin-right: 3px;
}

.ph-order-detail-products-pharmacy-image {
	width: 100%;
	max-width: 30px;
}

.ph-order-detail-products-pharmacy-title {
	color: $colorGrayText4;
	font-weight: 900;
	font-size: 13px;
	margin-bottom: 0;
}

@media (max-width: 768px) {
	.ph-order-detail-products-pharmacy-container {
		position: relative;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.ph-order-detail-products-pharmacy-image {
		width: 100%;
		max-width: 18px;
	}
}
