/** @format */

@import "../../../../components/Commons/Sass/components/colors";

.ph-profile-recipes-global-container {
  max-width: 1185px;
  padding: 10px;
  margin: auto;
}

.ph-profile-recipes-main-container {
  padding: 0 15px;
}

.ph-profile-recipes-inner-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.ph-profile-recipes-resend-email-button,
.ph-profile-recipes-resend-email-button:active,
.ph-profile-recipes-resend-email-button:focus,
.ph-profile-recipes-resend-email-button:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primaryColor !important;
  color: $colorWhiteText;
  padding: 10px 20px;
  font-size: 15px;
  border-radius: 4px;
  font-weight: 500;
  box-shadow: $primaryBoxShadow;
  border: 2px solid $primaryColor;
  text-transform: capitalize;
  height: 36px;
}

.ph-profile-recipes-resend-email-button .anticon {
  line-height: 0 !important;
  margin-right: 4px;
}

.ph-profile-recipes-resend-email-button-title {
  margin-left: 4px;
  margin-bottom: 0;
  color: $colorWhiteText;
  font-size: 16px;
}

.ph-profile-recipes-list-main-container {
  box-shadow: $primaryBoxShadow;
  border-radius: 4px;
  padding: 20px;
  margin-top: -15px;
}

.ph-profile-recipes-list-empty-container {
  text-align: center;
}

.ph-profile-recipes-list-empty-image {
  width: 100%;
  max-width: 80px;
  margin-bottom: 20px;
}

.ph-profile-recipes-list-empty-title {
  color: $colorGrayText4;
  font-size: 16px;
  margin-bottom: 0;
}

.ph-profile-recipes-list-empty-subtitle {
  color: $colorGrayText3;
  font-size: 14px;
  margin-bottom: 0;
  font-style: italic;
}

.ph-profile-recipes-list-container {
  box-shadow: $primaryBoxShadow;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 10px;
}

.ph-profile-recipes-list-container:last-child {
  margin-bottom: 0;
}

.ph-profile-recipes-list-title {
  color: $colorGrayText3;
  margin-bottom: 0;
  margin-left: 10px;
  font-size: 13px;
}

.ph-profile-recipes-list-subtitle {
  color: $primaryColor;
  margin-bottom: 0;
  margin-left: 10px;
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  max-width: 90%;
}

.ph-profile-recipes-list-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ph-profile-recipes-list-button,
.ph-profile-recipes-list-button:active,
.ph-profile-recipes-list-button:focus,
.ph-profile-recipes-list-button:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primaryColor !important;
  color: $colorWhiteText;
  padding: 10px 20px;
  font-size: 15px;
  border-radius: 4px;
  font-weight: 500;
  box-shadow: $primaryBoxShadow;
  border: 2px solid $primaryColor;
  text-transform: capitalize;
  height: 36px;
}

.ph-profile-recipes-list-button .anticon {
  line-height: 0 !important;
  margin-right: 4px;
}

.ph-profile-recipes-list-button-title {
  margin-bottom: 0;
  color: $colorWhiteText;
  font-size: 16px;
}

.ph-profile-recipes-list-actions-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ph-profile-recipes-list-actions-icon {
  color: $primaryColor;
  margin-right: 20px;
  font-size: 20px;
  cursor: pointer;
}

.ph-profile-recipes-list-actions-icon-2 {
  color: $primaryColor;
  margin-right: 20px;
  font-size: 20px;
  cursor: pointer;
}

.ph-profile-recipes-list-loading-container {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ph-profile-recipes-list-loading-image {
  font-size: 30px;
  color: $primaryColor;
}

.ph-phofile-recipes-pagination-container {
  position: relative;
}

.ph-checkout-receipt-modal-pdf-container {
  text-align: center;
}

.ph-checkout-receipt-modal-pdf-icon {
  color: red;
  font-size: 100px;
  margin: 30px 0;
}

@media (max-width: 600px) {
  .ph-profile-recipes-global-container {
    min-height: 85vh;
  }

  .ph-profile-recipes-main-container {
    padding: 0;
  }

  .ph-profile-recipes-inner-container {
    margin-top: 15px;
    margin-bottom: 30px;
  }

  .ph-profile-recipe-main-box-title-detail-container {
    margin-top: 0;
  }

  .ph-profile-recipe-main-box-title-detail-title {
    font-size: 18px !important;
    margin-top: -5px;
  }

  .ph-profile-recipe-main-box-title-detail-description {
    margin-bottom: 0;
    margin-left: 30px;
    margin-top: -5px;
    font-size: 15px !important;
  }

  .ph-profile-recipes-resend-email-button,
  .ph-profile-recipes-resend-email-button:active,
  .ph-profile-recipes-resend-email-button:focus,
  .ph-profile-recipes-resend-email-button:hover {
    padding: 10px;
    font-size: 13px;
  }

  .ph-profile-recipes-list-main-container {
    padding: 10px;
  }

  .ph-profile-recipes-list-container {
    margin-bottom: 10px;
  }

  .ph-profile-recipes-list-title {
    margin-left: 0;
    font-size: 12px;
  }

  .ph-profile-recipes-list-subtitle {
    margin-left: 0;
    font-size: 12px;
  }

  .ph-profile-recipes-list-button,
  .ph-profile-recipes-list-button:active,
  .ph-profile-recipes-list-button:focus,
  .ph-profile-recipes-list-button:hover {
    padding: 7px;
    height: 32px;
  }

  .ph-profile-recipes-list-button .anticon {
    line-height: 0 !important;
    margin-right: 4px;
  }

  .ph-profile-recipes-list-button-title {
    margin-bottom: 0;
    color: $colorWhiteText;
    font-size: 13px;
  }

  .ph-profile-recipes-list-actions-icon {
    margin-right: 15px;
    font-size: 17px;
  }

  .ph-profile-recipes-list-actions-icon-2 {
    margin-right: 0;
    font-size: 17px;
  }
}
