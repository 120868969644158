@import '../Commons/Sass/components/colors';

.ph-stepper-global-container {
    margin-bottom: 16px !important;

    .ant-steps-item-title {
        line-height: 20px;
    }

    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
        background-color: $secondColor;
        border-color: $secondColor;
    }

    .ant-steps-item-finish .ant-steps-item-icon {
        border-color: $secondColor;
    }

    .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
        color: $secondColor;
    }

    .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
        background-color: $secondColor;
    }

    .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
        background-color: $secondColor;
    }

    .ant-steps-small .ant-steps-item-icon {
        line-height: 20px;
    }

    .cursor-pointer{
        cursor: pointer;
    }

}


@media (max-width: 575px) {
    .ph-stepper-global-container {
        margin: 8px 8px 0 8px;

        .ant-steps-item-title {
            display: none !important;
        }

        .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
            display: block;
            margin: 0 24px !important;
        }


        .ant-steps-small .ant-steps-item-tail {
            top: 10px;
        }
    }

    .step-two {
        .ant-steps-item-tail {
            left: auto;
        }
    }

    .step-three {
        .ant-steps-item-tail {
            display: none !important;
        }
    }

    .ant-steps-small .ant-steps-item-icon {
        margin: 0;
    }
}