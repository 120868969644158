/** @format */

@import '../Sass/components/colors';

.ph-footermain-main-form-label {
	color: $colorGrayText4;
	font-weight: 500;
	height: 20px;
	font-size: 14px;
}

.ph-footermain-main-form-label::before {
	display: inline-block;
	margin-right: 4px;
	color: $secondColor;
	font-size: 14px;
	font-family: SimSun, sans-serif;
	line-height: 1;
	content: '*';
}

.ph-footermain-main-form-field {
	border: 1px solid $primaryColorBorder;
	border-radius: 4px;
}
