@import "../../Sass/components/colors";

.ph-modal-global-suggest-address-container {
  text-align: left;
}

.ph-modal-global-suggest-address-container .ant-modal {
  vertical-align: top;
  text-align: left;
  top: 108px;
  left: 17%;
}

.ph-modal-global-suggest-address-container .ant-modal-content {
  border-radius: 5px;
}

.ph-modal-global-suggest-address-container .ant-modal-content .ant-modal-close {
  display: none;
}

.ph-modal-global-suggest-address-container
  .ant-modal-content
  .ant-modal-close-x {
  border-radius: 100px;
  color: $colorGrayText2;
  font-weight: bold;
  width: 20px;
  height: 20px;
  font-size: 16px;
  line-height: 0;
  padding: 2px;
}

.ph-modal-global-suggest-address-container .ant-modal-header {
  display: none;
}

.ph-modal-global-suggest-address-container .ant-modal-content .ant-modal-body {
  padding: 0 10px 5px 10px;
}

.ph-modal-global-suggest-address-container .ant-modal-footer {
  display: none;
}

.ph-modal-global-suggest-artifact:before {
  content: "";
  width: 0;
  height: 0;
  border: 0 solid transparent;
  border-right-width: 8px;
  border-left-width: 8px;
  border-bottom: 11px solid #ffffff;
  top: -11px;
  left: 18px;
  position: absolute;
  opacity: 1;
  z-index: 0;
}

.ph-modal-global-suggest-address-description {
  padding-top: 15px !important;
  margin-bottom: 0;
  font-size: 14px;
  padding: 10px;
  color: $colorGrayText4;
  letter-spacing: -0.49px;
  line-height: 18px;
}

.ph-modal-global-suggest-address-button {
  background-color: $secondColor;
  border-color: $secondColor;
  color: $colorWhiteText;
  margin: 5px 0 8px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
  text-transform: uppercase;
  width: 100%;
  height: 35px;
  max-width: 115px;
  box-shadow: $secondaryBoxShadow;
  margin-left: 5px;
  margin-right: 5px;
}

.ph-modal-global-suggest-address-button:hover,
.ph-modal-global-suggest-address-button:focus,
.ph-modal-global-suggest-address-button:active {
  background-color: $secondColor;
  border-color: $secondColor;
  color: $colorWhiteText;
}

.ph-modal-global-suggest-address-button-alternative,
.ph-modal-global-suggest-address-button-alternative:hover,
.ph-modal-global-suggest-address-button-alternative:focus,
.ph-modal-global-suggest-address-button-alternative:active {
  background-color: $primaryBackgroundColor;
  border: none;
  color: $primaryColor;
  margin: 5px 0 8px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
  letter-spacing: -0.49px;
  text-transform: uppercase;
  width: 100%;
  height: 35px;
  max-width: 115px;
  box-shadow: none;
  margin-left: 10px;
  margin-right: 25px;
}

.ph-modal-global-suggest-address-button-main-container {
  text-align: right;
  margin-top: 10px;
}

@media (max-width: 1500px) {
  .ph-modal-global-suggest-address-container .ant-modal {
    left: 10%;
  }
}

@media (max-width: 1300px) {
  .ph-modal-global-suggest-address-container .ant-modal {
    left: 5%;
  }
}

@media (max-width: 1190px) {
  .ph-modal-global-suggest-address-container .ant-modal {
    left: 2%;
  }
}

@media (max-width: 1080px) {
  .ph-modal-global-suggest-address-container .ant-modal {
    left: 0.5%;
  }
}

@media (max-width: 968px) {
  .ph-modal-global-suggest-address-container .ant-modal {
    vertical-align: top;
    text-align: left;
    top: 50px;
    left: 30%;
  }

  .ph-modal-global-suggest-artifact:before {
    left: 220px;
  }
}

@media (max-width: 600px) {
  .ph-modal-global-suggest-address-container .ant-modal {
    vertical-align: top;
    text-align: left;
    top: 40px;
    left: 2%;
  }

  .ph-modal-global-suggest-artifact:before {
    left: 200px;
  }
}
