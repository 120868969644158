/** @format */

@import '../../../Commons/Sass/components/colors';

.ph-elastic-result-not-found-main-container {
	background-color: $primaryBackgroundColor;
	border-radius: 4px;
	box-shadow: $primaryBoxShadow;
	margin: 4px;
	padding: 20px;
	text-align: center;
	width: 100%;
}

.ph-elastic-result-not-found-image-container {
	padding: 10px;
	margin-bottom: 10px;
}

.ph-elastic-result-not-found-image {
	width: 250px;
	//max-width: 250px;
}

.ph-elastic-result-not-found-title {
	color: $colorGrayText4;
}

.ph-elastic-result-not-found-subtitle {
	color: $colorGrayText2;
	margin-bottom: 20px;
}

.ph-elastic-result-not-found-subtitle-bold {
	font-weight: 600;
	color: $primaryColor;
}

.ph-elastic-result-not-found-subtitle-bold:hover,
.ph-elastic-result-not-found-subtitle-bold:active,
.ph-elastic-result-not-found-subtitle-bold:focus {
	font-weight: 600;
	color: $primaryColor;
	text-decoration: underline;
}

.ph-elastic-result-not-found-button {
	box-shadow: $mainHeaderboxShadow;
	color: $colorWhiteText !important;
	font-size: 14px;
	padding: 7px 15px;
	border-radius: 4px;
	font-weight: 300;
	background-color: $secondColor !important;
	border-color: $secondColor !important;
	display: block;
	max-width: 130px;
	margin: 0 auto 10px;
}

@media (max-width: 992px){
	.ph-elastic-result-not-found-image {
		width: 130px;
	}
}