/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-electronic-recipe-product-main-container {
	display: inline-block;
	width: 100%;
	border: 2px solid rgba(45, 114, 183, 0.4);
	border-radius: 4px;
	padding: 15px;
	transition: 0.3s ease;
}

.ph-electronic-recipe-product-main-container:hover {
	background-color: rgba(45, 114, 183, 0.1);
	transition: 0.3s ease;
}

.ph-electronic-recipe-product-title-main-container {
	cursor: pointer;
}

.ph-electronic-recipe-product-title-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-electronic-recipe-product-image-container {
	margin-right: 4px;
}

.ph-electronic-recipe-product-image {
	width: 100%;
	max-width: 20px;
	margin-top: -2px;
}

.ph-electronic-recipe-product-title {
	color: $primaryColor;
	margin-bottom: 0;
}

.ph-electronic-recipe-product-description {
	color: $primaryColor;
	text-align: center;
	margin-top: 15px;
	margin-bottom: 0;
}

.ph-electronic-recipe-product-preview-title-main-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-electronic-recipe-product-preview-image-main-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-electronic-recipe-product-preview-image-container {
	padding: 10px;
}

.ph-electronic-recipe-product-preview-image {
	width: 100%;
	max-width: 80px;
}

.ph-electronic-recipe-product-modal-container {
	margin-top: 30px;
}

.ph-electronic-recipe-product-modal-container .ant-modal-header {
	border-bottom: 1px solid $primaryColorBorder !important;
	padding: 10px 20px;
	border-radius: 8px 8px 0 0;
}

.ph-electronic-recipe-product-modal-container .ant-modal-content {
	border-radius: 8px;
}

.ph-electronic-recipe-product-modal-container .ant-modal-content .ant-modal-close {
	top: 11px;
	right: 21px;
}

.ph-electronic-recipe-product-modal-container .ant-modal-content .ant-modal-close-x {
	border-radius: 8px;
	border: 2px solid $fourthColor;
	border-radius: 100px;
	color: $fourthColor;
	font-weight: bold;
	width: 20px;
	height: 20px;
	font-size: 12px;
	line-height: 0;
	padding: 2px;
}

.ph-electronic-recipe-product-modal-container .ant-modal-body {
	padding: 10px;
}

.ph-electronic-recipe-product-modal-container .ant-modal-footer {
	padding: 0;
	border-top: none;
}

.ph-electronic-recipe-product-modal-image {
	width: 100%;
}
