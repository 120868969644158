/** @format */

@import "../../../../../../components/Commons/Sass/components/colors";

.ph-bag-main-info-cupon-input-container {
  margin: 8px 0 30px 48px;
  display: flex;
  justify-content: left;
  align-items: center;
  border: 1px solid $primaryColorBorder;
  border-radius: 4px;
  position: relative;
  max-width: 300px;
}

.ph-bag-main-info-cupon-input-container .ant-form-item {
  margin-bottom: 0 !important;
}

.ph-bag-main-info-image-container {
  padding: 2px 2px 5px 2px;
  margin-left: 10px;
}

.ph-bag-main-info-image {
  width: 100%;
  max-width: 25px;
}

.ph-address-cart-form-cupon-field {
  border: none !important;
  font-size: 13px;
  padding: 7px !important;
  color: $primaryColor;
  font-weight: 500;
}

.ph-address-cart-form-cupon-field:focus {
  border-color: transparent !important;
  border-right-width: 0px !important;
  box-shadow: none !important;
}

.ph-bag-main-info-option-title-container {
  position: absolute;
  bottom: -24px;
  right: 0;
}

.ph-bag-main-info-main-asterisk {
  color: $errorColor;
}

.ph-bag-main-info-main-subtitle {
  color: $colorGrayText3;
  font-style: italic;
  font-size: 11px;
}

.ph-bag-main-info-voucher-button-action {
  background-color: $primaryColor;
  color: $colorWhiteText;
  border: none;
  height: 34px;
  font-weight: 500;
  padding: 4px 8px;
  opacity: 1;
  transition: 0.2s ease;
  text-transform: uppercase;
  font-size: 12px;
}

.ph-bag-main-info-voucher-button-action:hover,
.ph-bag-main-info-voucher-button-action:focus,
.ph-bag-main-info-voucher-button-action:active {
  background-color: $primaryColor;
  color: $colorWhiteText;
  border: none;
  height: 34px;
  font-weight: 500;
  padding: 4px 8px;
  opacity: 1;
  transition: 0.2s ease;
}

.ph-bag-main-info-diclaimers-container {
  margin-bottom: 20px;
}

.ph-bag-main-info-disclaimer {
  text-align: left;
  letter-spacing: -0.42px;
  color: #7B7B7B;
  opacity: 1;
  font-size: 13px;
  margin: 0;
}

@media (max-width: 600px) {
  .ph-bag-main-info-cupon-input-container {
    margin: 10px auto !important;
  }

  .ph-bag-voucher-main-title-container {
    padding-left: 10px;
  }
}