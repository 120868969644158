/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-withdrawal-pharmacy-list-main-container {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	margin-bottom: 5px;
}

.ph-withdrawal-pharmacy-list-title {
	color: $colorGrayText3;
	margin-bottom: 0;
	font-weight: 600;
}

.ph-withdrawal-pharmacy-list-description {
	color: $primaryColor;
	margin-bottom: 0;
	font-size: 13px;
	font-weight: 600;
	opacity: 0.8;
	font-style: italic;
}

.ph-withdrawal-pharmacy-list-button-title-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 5px;
}

.ph-withdrawal-pharmacy-list-button-title-image {
	width: 100%;
	max-width: 17px;
}

.ph-withdrawal-pharmacy-list-button-title {
	color: $colorGrayText4;
	font-weight: 500;
	font-size: 15px;
	margin-left: 5px;
}

.ph-withdrawal-pharmacy-list-hours-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-local-select-modal-container .ant-modal-content {
	border-radius: 8px;
}

.ph-local-select-modal-container .ant-modal-content .ant-modal-close {
	top: 11px;
	right: 21px;
}

.ph-local-select-modal-container .ant-modal-content .ant-modal-close-x {
	border: 2px solid $secondColor;
	border-radius: 100px;
	color: $secondColor;
	font-weight: bold;
	width: 20px;
	height: 20px;
	font-size: 12px;
	line-height: 0;
	padding: 2px;
}

.ph-local-select-modal-container .ant-modal-header {
	border-bottom: 1px solid $primaryColorBorder !important;
	padding: 10px 20px;
	border-radius: 8px 8px 0 0;
}

.ph-local-select-modal-container .ant-modal-header .ant-modal-title {
	font-size: 16px;
	font-weight: 500;
}

.ph-local-select-modal-container .ant-modal-content .ant-modal-body {
	padding: 0 10px 10px 10px;
}

.ph-local-select-modal-container .ant-modal-footer {
	display: none;
}

.ph-withdrawal-list-pharmacy-button,
.ph-withdrawal-list-pharmacy-button:hover,
.ph-withdrawal-list-pharmacy-button:active,
.ph-withdrawal-list-pharmacy-button:focus {
	background-color: $primaryBackgroundColor !important;
	margin: 0 auto;
	margin-top: 0;
	height: 36px;
	width: 100%;
	max-width: 100px;
	max-width: 160px;
	box-shadow: $mainHeaderboxShadow !important;
	border: 2px solid $primaryColor !important;
	border-radius: 4px;
	padding: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-withdrawal-list-pharmacy-button-title {
	font-size: 13px;
	font-weight: 900;
	color: $primaryColor;
	margin-bottom: 0;
	margin-right: 5px;
}

.ph-withdrawal-list-pharmacy-button-icon {
	font-weight: 900;
	font-size: 20px;
	font-weight: 900;
	color: $primaryColor;
}

.ph-withdrawal-pharmacy-list-button-container {
	box-shadow: $primaryBoxShadow;
	border-radius: 4px;
	margin-bottom: 10px;
	padding: 10px;
	overflow: auto;
}

.ph-withdrawal-pharmacy-list-button-container:last-child {
	margin-bottom: 0;
}

.ph-withdrawal-pharmacy-list-button-info-container {
	margin-bottom: 0;
}

.ph-withdrawal-pharmacy-list-button-info-destiny-location-icon-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-withdrawal-pharmacy-list-button-info-destiny-location-icon-inner-container {
	text-align: left;
}

.ph-withdrawal-pharmacy-list-button-info-destiny-location-icon-inner-title {
	color: $colorGrayText1;
	font-size: 13px;
	text-align: left;
	margin-bottom: 0;
	font-weight: 500;
	width: 100%;
}

.ph-withdrawal-pharmacy-list-button-info-destiny-location-icon-inner-subtitle {
	color: $colorGrayText2;
	font-size: 12px;
	text-align: left;
	margin-bottom: 0;
	font-weight: 500;
	font-style: italic;
	width: 100%;
}

.ph-withdrawal-pharmacy-list-button-info-destiny-location-icon-inner-subtitle-mobile {
	color: $colorGrayText2;
	font-size: 12px;
	text-align: left;
	margin-bottom: 0;
	font-weight: 500;
	font-style: italic;
	width: 100%;
}

.ph-withdrawal-pharmacy-list-button-info-destiny-location-icon-icon {
	font-size: 25px;
}

.ph-withdrawal-pharmacy-list-button-title {
	font-size: 14px;
	color: $colorGrayText4;
	font-weight: 600;
	margin-bottom: 0;
}

.ph-withdrawal-pharmacy-list-button-subtitle-2 {
	font-size: 12px;
	color: $colorGrayText2;
	font-weight: 100;
	margin-bottom: 0;
}

.ph-withdrawal-pharmacy-list-button-subtitle-info-2 {
	font-weight: 900;
}

.ph-withdrawal-pharmacy-list-button-subtitle {
	font-size: 12px;
	color: $colorGrayText2;
	font-weight: 100;
	margin-bottom: 0;
	line-height: 12px;
	font-style: italic;
}

.ph-withdrawal-pharmacy-list-button-info-destiny-location-separator {
	border-left: 1px solid $primaryColorBorder;
	height: 70%;
	margin-top: 7px;
}

.ph-withdrawal-list-local-button-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	cursor: pointer;
}

.ph-withdrawal-list-local-button-title {
	color: $primaryColor;
	margin-bottom: 0;
	font-weight: 600;
	font-size: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-withdrawal-list-local-button-icon {
	border: 1px solid $colorGrayText2;
	width: 17px;
	min-width: 17px;
	min-height: 17px;
	height: 17px;
	border-radius: 100%;
	margin: 0 10px;
}

.ph-withdrawal-list-local-button,
.ph-withdrawal-list-local-button:hover,
.ph-withdrawal-list-local-button:active,
.ph-withdrawal-list-local-button:focus {
	background-color: $primaryColor !important;
	color: $colorWhiteText;
	margin: 0 0 0 auto;
	margin-top: 0;
	height: 36px;
	width: 100%;
	max-width: 140px;
	box-shadow: $mainHeaderboxShadow !important;
	border: 2px solid $primaryColor !important;
	border-radius: 4px;
	padding: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-withdrawal-pharmacy-image-container {
	padding: 3px;
	margin-right: 5px;
}

.ph-withdrawal-pharmacy-image {
	width: 100%;
	max-width: 22px;
}

.ph-withdrawal-pharmacy-list-component-button-info-destiny-container {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.ph-withdrawal-pharmacy-list-component-button-info-destiny-image-container {
	margin: 2px;
}

.ph-withdrawal-pharmacy-list-component-button-info-destiny-image {
	width: 100%;
	max-width: 17px;
	min-width: 17px;
}

.ph-withdrawal-pharmacy-list-component-button-info-destiny-location-icon-title {
	color: #7b7b7b;
	font-size: 13px;
	text-align: left;
	margin-bottom: 0;
	font-weight: 600;
	width: 100%;
}

.ph-withdrawal-pharmacy-list-component-button-subtitle-2 {
	font-size: 13px;
	color: #888888;
	font-weight: 100;
	margin-bottom: 0;
}

.ph-withdrawal-pharmacy-list-component-button-subtitle-info-2 {
	font-weight: 900;
	padding-left: 4px;
}

.ph-local-select-modal-content-container {
	padding: 25px;
}

.ph-local-select-modal-container .ant-modal-title {
	color: $colorGrayText4;
	font-size: 14px !important;
	font-weight: 600 !important;
}

.ph-local-select-modal-main-button-container {
	text-align: right;
	margin-top: 20px;
}

.ph-local-select-modal-main-button,
.ph-local-select-modal-main-button:hover,
.ph-local-select-modal-main-button :focus,
.ph-local-select-modal-main-button:active {
	background-color: $primaryColor;
	color: $colorWhiteText;
	margin: 0 auto;
	margin-top: 0;
	font-weight: 500;
	height: 36px;
	box-shadow: none !important;
	border: none;
	border-radius: 4px;
	font-size: 15px;
	padding: 0 35px;
}

@media (min-width: 601px) {
	.ph-withdrawal-pharmacy-list-button-info-destiny-location-icon-inner-subtitle-mobile {
		display: none;
	}
}

@media (max-width: 600px) {
	.ph-withdrawal-pharmacy-list-button-info-destiny-location-icon-container {
		display: none;
	}

	.ph-withdrawal-pharmacy-list-button-container {
		padding: 4px;
	}

	.ph-withdrawal-list-local-button-icon {
		margin: 0 5px;
	}

	.ph-withdrawal-list-local-button-container {
		display: block;
	}

	.ph-withdrawal-pharmacy-list-button-info-destiny-location-icon-inner-subtitle {
		font-size: 10px;
	}

	.ph-withdrawal-pharmacy-list-button-info-destiny-location-icon-inner-subtitle-mobile {
		font-size: 10px;
	}

	.ph-local-select-modal-content-container {
		padding: 5px;
	}
}

@media (max-width: 325px) {
	.ph-withdrawal-pharmacy-list-component-button-info-destiny-location-icon-title {
		font-size: 11px;
	}

	.ph-withdrawal-pharmacy-list-component-button-subtitle-2 {
		font-size: 12px;
	}

	.ph-withdrawal-pharmacy-list-button-info-destiny-location-icon-inner-subtitle-mobile {
		font-size: 9px;
	}
}
