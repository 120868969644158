/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-complaint-book-container {
	max-width: 900px;
	padding: 10px;
	margin: auto;
}

.ph-complaint-book-inner-container {
	height: auto;
	min-height: calc(100vh - 158px);
}

.ph-complaint-separator {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid $primaryColorBorder;
	margin: 15px auto;
	padding-bottom: 10px;
	width: 97%;
}
