/** @format */

@import './../../components/Commons/Sass/components/colors.scss';

.ph-popup-v2-card-container {
	border-radius: 10px;
	background-color: white;
	box-shadow: $secondaryBoxShadow;
	width: 100%;
	max-width: 400px;
	bottom: 25px;
	left: -560px;
	height: 200px;
	position: fixed;
	z-index: 2;
	transition: all 0.3s;
}

.popup-slider-internal {
	padding: 0px;
	img {
		max-width: 1165px;
		max-height: 276px;
		border-radius: 10px !important;
		width: 100%;
		margin: 0px auto;
	}
}

.ph-pop-v2-card-container-visible {
	left: 20px;
}

.ph-popup-v2-card-column {
	width: 100%;
}

.ph-popup-v2-card-close {
	z-index: 1;
	cursor: pointer;
	position: absolute;
	top: -20px;
	right: -20px;
}

.ph-popup-v2-card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px 0 0 8px;
}

.ph-popup-v2-card-close-icon {
	font-size: 16px;
	/* color: rgba(180, 180, 180, 1); */
}

@media (max-width: 600px) {
	.ph-pop-v2-card-container-visible {
		left: 5px;
		bottom: 60px;
	}

	.ph-popup-v2-card-container {
		max-width: 320px;
		height: 160px;
	}
  .ph-popup-v2-card-image {
    height: 160px;
  }
}

@media (max-width: 330px) {
	.ph-pop-v2-card-container-visible {
		left: 0;
	}
}
