/** @format */

@import '../../../../Sass//components/colors';

.ph-header-mobile-global-spacer {
	height: 93px;
}

.ph-header-mobile-global-container {
	padding: 5px;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 3;
	background-color: $primaryBackgroundColor;
}

.ph-header-mobile-arg {
	background-color: $fourthBackgroundColor;
}

.ph-header-mobile-global-container-shadow {
	box-shadow: $primaryBoxShadow;
}

.ph-header-mobile-normal-container {
	transition: 0.3s ease;
}

.ph-header-mobile-sticky-container {
	display: none;
	transition: 0.3s ease;
}

.ph-header-mobile-second-row-container {
	margin: 7px 0 5px 0;
}

.ph-header-mobile-second-row-sticky-container {
	margin: 3px 0;
}

.ph-header-mobile-sidebar-main-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	max-height: 39px;
}

.ph-header-mobile-cart-main-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	max-height: 39px;
}

.ph-header-mobile-footer-global-container {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 2;
	padding: 4px;
	background-color: $primaryBackgroundColor;
	border-top: 1px solid $primaryColorBorder;
}

@media (max-width: 385px) {
	.ph-header-mobile-logo-container {
		flex: 0 0 40%;
		max-width: 40%;
	}

	.ph-header-mobile-address-container {
		flex: 0 0 60%;
		max-width: 60%;
	}
}