/** @format */

.ph-checkout-countdown-container {
	position: absolute;
	top: 3px;
	right: 10px;
}

.ph-checkout-countdown-icon {
	color: #2d72b7;
	font-size: 17px;
	margin-right: 5px;
}

.ph-checkout-countdown-description {
	color: #777;
	font-size: 20px;
}

.ph-checkout-countdown-spin-icon {
	color: #2d72b7;
	font-size: 14px;
	width: 100%;
	max-width: 100px;
	padding: 0 12px 0 13px;
}

@media (max-width: 600px) {
	.ph-checkout-countdown-container {
		top: 7px;
	}
}
