/** @format */

@import '../../../../../../../../../../components/Commons/Sass/components/colors';

.ph-order-detail-products-retyrement-info-main-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-order-detail-products-retyrement-info-image-container {
	margin-right: 5px;
	margin-left: 5px;
}

.ph-order-detail-products-retyrement-info-image {
	width: 100%;
	max-width: 30px;
}

.ph-order-detail-products-retyrement-info-title {
	color: $primaryColor;
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 0;
}

@media (max-width: 768px) {
	.ph-order-detail-products-retyrement-info-main-container {
		border-bottom: 1px solid $primaryColorBorder;
	}
}
