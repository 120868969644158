@import '../../v2-config/colors.scss';

.footer-content {
	background-color: $primary-default;
	color: $generic-white;
	padding-top: 35px;
	position: inherit;
	bottom: -22px;

	.container {
		max-width: 1185px;
		margin: 0px auto;

		h3 {
			color: $generic-white;
		}

	}

	.footer-module {
		margin-bottom: 24px;
	}

	.logo-link {
		display: flex;
		margin-bottom: 24px;
	}

	.text-link {
		color: $generic-white;
		line-height: 30px;


		&:hover {
			color: $generic-grey2;
		}
	}

	.copyright {
		margin-top: 35px;
	}

	.no-hover {
		color: $generic-white;
		line-height: 30px;
		cursor: none;

		&:hover {
			color: $generic-white;
		}
	}
}

@media (max-width: 968px) {
	.footer-content {
		padding-bottom: 35px;
	}
}