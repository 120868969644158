/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-withdrawal-global-container {
	max-width: 1185px;
	padding: 10px;
	margin: auto;
}

.ph-withdrawal-main-container {
	padding: 10px 25px;
	margin-bottom: 40px;
}

.ph-withdrawal-titlebox-countdown-container {
	position: relative;
}

.ph-withdrawal-checkout-box-main-container {
	box-shadow: $primaryBoxShadow;
	background-color: $primaryBackgroundColor;
	border-radius: 4px;
	margin: 4px;
	padding: 20px 25px;
}

.ph-withdrawal-personal-data-agree-main-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 3px;
	padding-top: 10px;
}

.ph-withdrawal-personal-data-agree-main-container .ant-checkbox-checked .ant-checkbox-inner {
	background-color: $primaryColor;
	border-color: $primaryColor;
}

.ph-withdrawal-personal-data-agree-main-container .ant-checkbox-input:focus+.ant-checkbox-inner {
	border-color: $primaryColor;
}

.ph-withdrawal-personal-data-agree-main-container .ant-checkbox-checked:after {
	border: none !important;
}

.ph-withdrawal-personal-data-agree-main-container .ant-checkbox-inner {
	top: -5px !important;
}

.ph-withdrawal-personal-data-agree-link {
	color: $primaryColor;
	font-weight: 600;
	font-size: 13px;
}

.ph-withdrawal-personal-data-agree-main-container .ant-form-item-explain {
	position: absolute;
	width: 385px;
	bottom: -25px;
	margin-left: 25px;
}

.ph-withdrawal-personal-data-agree-container {
	margin-bottom: 0;
}

.ph-withdrawal-personal-data-agree-container .ant-checkbox {
	margin-top: -3px;
}

.ph-withdrawal-personal-data-agree-title {
	margin-left: 7px;
	margin-bottom: 8px;
	color: $colorGrayText3;
}

@media (max-width: 1070px) {
	.ph-withdrawal-personal-data-agree-main-container .ant-form-item-explain {
		width: 195px;
		bottom: -55px;
	}
	.ph-withdrawal-checkout-box-main-container {
		padding-bottom: 40px;
	}
}

@media (max-width: 1024px) {
	.ph-withdrawal-main-container {
		padding: 0;
	}
}

@media (max-width: 992px) {
	.ph-withdrawal-checkout-box-global-container {
		padding-bottom: 10px;
	}

	.ph-withdrawal-checkout-box-main-container {
		margin: -4px 4px 4px 4px;
	}
}

@media (max-width: 768px) {
	.ph-withdrawal-checkout-box-global-container {
		padding-bottom: 20px;
	}

	.ph-withdrawal-global-container {
		margin-top: 5px;
		margin-bottom: 20px;
		padding: 5px;
	}
}

@media (max-width: 768px) {
	.ph-withdrawal-checkout-box-main-container {
		margin: 0 4px 4px 4px;
		padding: 10px;
	}
}

@media (max-width: 600px) {
	.ph-withdrawal-personal-data-agree-main-container {
		padding: 5px;
	}

	.ph-withdrawal-personal-data-agree-title {
		font-size: 11px;
	}
	.ph-withdrawal-personal-data-agree-link {
		font-size: 11px;
	}

	.ph-withdrawal-personal-data-agree-main-container .ant-form-item-explain {
		width: 350px;
		bottom: -15px;
	}

}