/** @format */

@import '../Sass/components/colors';

.ph-loading-container {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-loading-icon {
	color: $primaryColor;
	margin-top: -120px;
}

.ph-loading-image {
	font-size: 30px;
}
