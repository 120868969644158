@import '../../../../../../Sass/components/colors';
@import '../../../../../../../../v2-config/colors.scss';
.icons-theme {
	color: $generic-dark-grey1;
	height: 30px;
	width: 30px;
	margin: 9px 3px;
	cursor: pointer;
	&:hover {
		color: $primary-hover;
	}
	&:active,
	&.active {
		color: $primary-default;
	}
}

.ph-header-mobile-cart-link-container {
	display: inline-block;
	position: relative;
	cursor: pointer;
}

.ph-header-mobile-cart-icon-container {
	display: inline-block;
	border: 1px solid $colorWhiteText;
	transition: 0.3s ease;
	border-radius: 100px;
}

.ph-header-mobile-cart-icon-container-arg {
	@extend .ph-header-mobile-cart-icon-container;
	border: 1px solid $fourthBackgroundColor;
}

.ph-header-mobile-cart-icon-main-title {
	color: $colorGrayText1;
	font-size: 8px;
	font-weight: 500;
	text-transform: uppercase;
	text-align: center;
	line-height: 7px;
	margin-top: 1px;
	margin-bottom: 0;
}

.ph-header-mobile-cart-icon-main-title-arg {
	@extend .ph-header-mobile-cart-icon-main-title;
	color: $colorWhiteText;
}

.ph-header-mobile-cart-icon {
	width: 30px;
	z-index: 1;
	border-radius: 0;
}

.ph-header-mobile-cart-baloon-icon {
	background: $secondColor;
	padding: 0 2px;
	border-radius: 50%;
	position: absolute;
	margin-top: 3px;
	z-index: 2;
	height: 16px;
	top: 6px;
	right: -2px;
	line-height: 14.4px;
	width: 16px;
	text-align: center;
}

.ph-header-mobile-cart-baloon-title {
	color: $colorWhiteText;
	font-weight: 500;
	font-size: 10px;
	position: absolute;
	top: 1px;
	left: 1px;
	right: 0;
	bottom: 0;
}

.ph-header-mobile-cart-icon-main-title-enabled {
	color: $primaryColor;
	font-size: 8px;
	font-weight: 500;
	text-transform: uppercase;
	text-align: center;
	line-height: 7px;
	margin-top: 1px;
	margin-bottom: 0;
}

.ph-header-mobile-cart-icon-main-title-enabled-arg {
	@extend .ph-header-mobile-cart-icon-main-title-enabled;
	color: $colorSecondaryTex;
}
