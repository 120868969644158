/** @format */

@import '../../../Commons/Sass/components/colors';

.ph-elastic-order-view-main-container {
	text-align: center;
	display: flex;
	align-items: center;
}

.ph-elastic-filter-sidebar-button,
.ph-elastic-filter-sidebar-button:hover,
.ph-elastic-filter-sidebar-button:active,
.ph-elastic-filter-sidebar-button:focus {
	background-color: $primaryColor;
	border-color: $primaryColor;
	box-shadow: $mainHeaderboxShadow;
	border-radius: 4px;
	color: $colorWhiteText;
	font-size: 15px;
	line-height: 19px;
	padding: 2px 5px;
	height: auto;
}

.ph-elastic-order-view-title-responsive {
	font-size: 12px;
	color: $colorGrayText2;
	margin: 0 3px;
	margin-left: 8px;
}

.ph-elastic-column-view-main-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-elastic-column-view-title {
	font-size: 12px;
	color: $colorGrayText2;
	margin: 0 7px;
}

.ph-elastic-filter-columns-button,
.ph-elastic-filter-columns-button:hover,
.ph-elastic-filter-columns-button:active,
.ph-elastic-filter-columns-button:focus {
	padding: 0 !important;
	height: auto !important;
	margin: 0 !important;
	color: rgb(149, 149, 149) !important;
	border: 1px solid rgb(230, 230, 230) !important;
	padding: 0 8px !important;
	border-radius: 5px;
}
