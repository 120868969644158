/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-order-history-purchase-global-container {
	max-width: 1185px;
	padding: 10px;
	margin: auto;
}

.ph-order-history-purchase-main-container {
	padding: 14px;
}

.ph-order-history-purchase-container {
	padding: 20px;
	border-radius: 4px;
	box-shadow: $primaryBoxShadow;
}

.ph-order-history-purchase-list-container {
	background-color: $colorWhiteText;
	border-radius: 4px;
	box-shadow: $primaryBoxShadow;
	padding: 10px 20px;
	margin-bottom: 15px;
	transition: 0.3s ease;
	cursor: pointer;
	position: relative;
}

.ph-order-history-purchase-list-container:hover {
	background-color: $thirdBackgroundColor;
	transition: 0.3s ease;
}

.ph-order-history-purchase-list-container:last-child {
	margin-bottom: 0;
}

.ph-order-history-purchase-button-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 5px;
}

.ph-order-history-purchase-button,
.ph-order-history-purchase-button:hover,
.ph-order-history-purchase-button:active,
.ph-order-history-purchase-button:focus {
	background-color: $primaryColor !important;
	color: $colorWhiteText;
	padding: 5px 20px;
	font-size: 15px;
	border-radius: 4px;
	font-weight: 900;
	box-shadow: $primaryBoxShadow;
	border: 2px solid $primaryColor;
	text-transform: capitalize;
	height: 38px;
}

@media (max-width: 768px) {
	.ph-order-history-purchase-main-container {
		padding: 0;
	}

	.ph-order-history-purchase-title-container {
		margin-top: 10px;
	}

	.ph-order-history-purchase-main-title {
		font-size: 22px;
	}

	.ph-order-history-purchase-main-subtitle {
		font-size: 17px;
		margin-bottom: 10px;
	}

	.ph-order-history-purchase-list-container {
		padding: 10px;
	}

	.ph-order-history-purchase-button-container {
		padding: 0;
	}
}

@media (max-width: 575px) {
	.ph-order-history-purchase-container {
		padding: 10px;
	}

	.ph-order-history-purchase-list-container {
		padding: 6px 10px;
		margin-bottom: 9px;
	}
}
