/** @format */

@import "../../components/Commons/Sass/components/colors";

.ph-profile-global-container {
  width: 100%;
  background-color: $thirdBackgroundColor;
}

.ph-profile-inner-container {
  max-width: 1185px;
  padding: 10px;
  margin: auto;
}

.ph-profile-main-container {
  width: 100%;
  background-color: #ffffff;
  padding: 10px;
}

.ph-profile-title-container {
  margin-top: 20px;
  margin-bottom: 5px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.ph-profile-main-title {
  font-weight: 500;
  font-size: 20px;
  color: $colorGrayText4;
  margin-bottom: 0;
  padding: 0 10px;
}

.ph-profile-main-title-span {
  color: $primaryColor;
}

.ph-profile-main-subtitle {
  font-weight: 500;
  font-size: 16px;
  color: $colorGrayText1;
  margin-bottom: 0;
  padding: 0 10px;
}

.ph-profile-main-link {
  font-size: 14px;
  color: $primaryColor;
  padding: 0 10px;
  margin-bottom: 0;
  cursor: pointer;
  font-weight: 600;
}

.ph-profile-main-link:hover {
  text-decoration: none;
}

.ph-profile-main-link-responsive {
  font-size: 14px;
  color: $primaryColor;
  padding: 10px 0;
  margin-bottom: 0;
  cursor: pointer;
  font-weight: 600;
}

.ph-profile-main-link-responsive:hover {
  text-decoration: none;
}

.ph-profile-separator {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $primaryColorBorder;
  margin: 15px auto;
  padding-bottom: 10px;
  width: 97%;
}

@media (max-width: 1024px) {
  .ph-profile-main-title {
    margin-top: 0px;
  }
}

@media (max-width: 768px) {
  .ph-profile-main-container {
    padding: 5px;
  }

  .ph-profile-separator {
    padding-bottom: 0;
  }

  .ph-profile-main-link {
    display: none;
  }
}

@media (min-width: 787px) {
  .ph-profile-main-link-responsive {
    display: none;
  }
}

@media (max-width: 575px) {
  .ph-profile-inner-container {
    padding: 10px 16px;
  }

  .ph-profile-main-container {
    padding: 0;
  }

  .ph-profile-title-container {
    margin-top: 5px;
    padding: 0px;
  }

  .ph-profile-main-title {
    font-size: 18px;
    padding: 0px;
  }

  .ph-profile-main-subtitle {
    padding: 0;
    font-size: 14px;
  }

  .ph-profile-separator {
    margin: 15px auto 10px;
  }

  .ph-profile-separator-footer-spacer {
    height: 20px;
  }
}
