/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-product-detail-social-netwok-container {
	margin-top: 15px;
	padding: 10px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-detail-simple-network-main-container {
	margin-top: 0;
	width: 100%;
}

.ph-product-detail-social-button,
.ph-product-detail-social-button:hover,
.ph-product-detail-social-button:active,
.ph-product-detail-social-button:focus {
	height: auto;
	margin: 0%;
	padding: 0;
	border: none;
	border-color: transparent !important;
	padding: 0 10px;
	box-shadow: none;
}

.ph-product-detail-social-image {
	width: 100%;
	max-width: 30px;
	opacity: 0.4;
	transition: 0.3s ease;
}

.ph-product-detail-social-image:hover {
	opacity: 0.8;
	transition: 0.3s ease;
}

@media (max-width: 1024px) {
	.ph-product-detail-social-netwok-container {
		margin-bottom: 15px;
	}
}

@media (max-width: 992px) {
	.ph-detail-simple-network-main-container {
		margin-bottom: 7px;
	}
}
