/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-modal-checkout-recipes-modal-button-container {
	position: absolute;
	right: 5px;
	bottom: 33px;
	background-color: #ffffff;
	cursor: pointer;
	z-index: 2;
	text-align: center;
	box-shadow: $primaryBoxShadow;
	border-radius: 4px;
	padding: 0 3px;
	border: 1px solid $primaryColor;
}

.ph-modal-checkout-recipes-modal-button-image-container {
	padding: 2px;
}

.ph-modal-checkout-recipes-modal-button-image {
	width: 100%;
	max-width: 40px;
}

.ph-modal-checkout-recipes-modal-button,
.ph-modal-checkout-recipes-modal-button:hover,
.ph-modal-checkout-recipes-modal-button:active,
.ph-modal-checkout-recipes-modal-button:focus {
	line-height: 13px;
	font-size: 11px;
	padding: 0;
	border: none;
	color: $primaryColor;
	border-top: 1px solid $primaryColor;
	border-radius: 0;
}

.ph-modal-checkout-recipes-modal-container .ant-modal-content {
	border-radius: 8px;
}

.ph-modal-checkout-recipes-modal-container .ant-modal-content .ant-modal-close {
	top: 14px;
	right: 21px;
}

.ph-modal-checkout-recipes-modal-container .ant-modal-content .ant-modal-close-x {
	border-radius: 8px;
	border: 2px solid $fourthColor;
	border-radius: 100px;
	color: $fourthColor;
	font-weight: bold;
	width: 20px;
	height: 20px;
	font-size: 12px;
	line-height: 0;
	padding: 2px;
}

.ph-modal-checkout-recipes-modal-container .ant-modal-header {
	border-bottom: 1px solid $primaryColorBorder !important;
	padding: 12px 20px;
	border-radius: 8px 8px 0 0;
}

.ph-modal-checkout-recipes-modal-container .ant-modal-header .ant-modal-title {
	font-size: 16px;
	font-weight: 900;
}

.ph-modal-checkout-recipes-modal-title {
	font-style: italic;
	font-weight: 500;
	color: $primaryColor;
	opacity: 0.9;
	max-width: 550px;
	margin: auto;
	margin-bottom: 10px;
}

.ph-modal-checkout-recipes-modal-container .ant-modal-content .ant-modal-body {
	padding: 10px;
	text-align: center;
}

.ph-modal-checkout-recipes-modal-container .ant-modal-footer {
	border-top: none;
	padding: 0;
}

.ph-modal-checkout-recipes-list-main-container {
	max-height: 250px;
	overflow-y: auto;
	padding: 2px;
}

.ph-modal-checkout-recipes-list-container {
	box-shadow: $primaryBoxShadow;
	border-radius: 4px;
	margin-bottom: 10px;
	padding: 7px;
}

.ph-modal-checkout-recipes-list-container:last-child {
	margin-bottom: 0;
}

.ph-modal-checkout-recipes-list-title-container {
	text-align: left;
}

.ph-modal-checkout-recipes-list-title {
	color: $colorGrayText3;
	margin-bottom: 0;
	margin-left: 10px;
	font-size: 12px;
}

.ph-modal-checkout-recipes-list-subtitle {
	color: $primaryColor;
	margin-bottom: 0;
	margin-left: 10px;
	font-size: 13px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
	max-width: 90%;
}

.ph-modal-checkout-recipes-list-button-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.ph-modal-checkout-recipes-list-button,
.ph-modal-checkout-recipes-list-button:active,
.ph-modal-checkout-recipes-list-button:focus,
.ph-modal-checkout-recipes-list-button:hover {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $primaryColor !important;
	color: $colorWhiteText;
	padding: 7px 5px;
	border-radius: 4px;
	font-weight: 500;
	box-shadow: $primaryBoxShadow;
	border: 2px solid $primaryColor;
	text-transform: capitalize;
	height: 28px;
}

.ph-modal-checkout-recipes-list-button-second-title {
	margin-bottom: 0;
	color: $primaryColor;
	font-size: 12px;
}

.ph-modal-checkout-recipes-list-second-button,
.ph-modal-checkout-recipes-list-second-button:active,
.ph-modal-checkout-recipes-list-second-button:focus,
.ph-modal-checkout-recipes-list-second-button:hover {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fff !important;
	color: $primaryColor;
	padding: 7px 5px;
	border-radius: 4px;
	font-weight: 500;
	box-shadow: $primaryBoxShadow;
	border: 1px solid $primaryColor;
	text-transform: capitalize;
	height: 28px;
}

.ph-modal-checkout-recipes-list-button-title {
	margin-bottom: 0;
	color: $colorWhiteText;
	font-size: 12px;
}

.ph-modal-checkout-recipes-list-no-data-container {
	border: 1px solid $primaryColor;
	border-radius: 4px;
	opacity: 0.8;
	padding: 10px;
	min-height: 60px;
}

.ph-modal-checkout-recipes-list-no-data-title {
	color: $colorGrayText3;
	font-weight: 600;
	font-style: italic;
	margin-top: 7px;
	margin-bottom: 0;
}

.ph-checkout-prescription-modal-pdf-container {
	text-align: center;
}

.ph-checkout-prescription-modal-pdf-icon {
	color: red;
	font-size: 100px;
	margin: 30px 0;
}

@media (max-width: 600px) {
	.ph-modal-checkout-recipes-modal-button-container {
		border: 2px solid $primaryColor;
		right: 3px;
		bottom: 31px;
	}

	.ph-modal-checkout-recipes-modal-button-image-container {
		display: none;
	}

	.ph-modal-checkout-recipes-modal-button,
	.ph-modal-checkout-recipes-modal-button:hover,
	.ph-modal-checkout-recipes-modal-button:active,
	.ph-modal-checkout-recipes-modal-button:focus {
		border-top: none;
		padding: 0;
		height: auto;
		font-weight: 600;
		top: -3px;
	}
}
