/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-withdrawal-pharmacy-routes-list-hours-selected-main-container {
	border: 1px solid $primaryColorBorder;
	border-radius: 4px;
	margin-top: 7px;
	margin-bottom: 7px;
	padding: 6px 0;
}

.ph-withdrawal-pharmacy-routes-list-hours-selected-main-title-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-withdrawal-pharmacy-routes-list-hours-selected-main-title {
	color: $primaryColor;
	font-weight: 600;
	margin-bottom: 0;
	opacity: 0.8;
	font-size: 13px;
	margin-left: 10px;
}

.ph-withdrawal-pharmacy-routes-start-destiny-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-withdrawal-pharmacy-routes-start-location-icon {
	border: 2px solid $colorGrayText1;
	border-radius: 100%;
	width: 8px;
	display: block;
	height: 8px;
	margin-right: 4px;
}

.ph-withdrawal-pharmacy-routes-start-location-icon-title {
	color: $colorGrayText3;
	font-size: 11px;
	text-align: left;
	margin-bottom: 0;
	font-weight: 500;
	text-decoration: underline;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
	max-width: 300px;
}

.ph-withdrawal-pharmacy-routes-road-location-icon {
	border: 2px solid $colorGrayText1;
	border-radius: 100%;
	width: 4px;
	display: block;
	height: 4px;
	margin-left: 2px;
	margin-top: 2px;
	margin-bottom: 2px;
	background-color: $colorGrayText1;
	opacity: 0.6;
}

.ph-withdrawal-pharmacy-routes-destiny-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-withdrawal-pharmacy-routes-destiny-image-container {
	margin-right: 1px;
	margin-left: -3px;
	margin-top: -3px;
}

.ph-withdrawal-pharmacy-routes-destiny-image {
	width: 100%;
	max-width: 12px;
}

.ph-withdrawal-pharmacy-routes-destiny-location-icon-title {
	color: $colorGrayText3;
	font-size: 11px;
	text-align: left;
	margin-bottom: 0;
	font-weight: 500;
	text-decoration: underline;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
	max-width: 300px;
}

.ph-withdrawal-pharmacy-routes-list-hours-selected-main-title-2-container {
	height: 100%;
}

.ph-withdrawal-pharmacy-routes-list-hours-selected-main-title-2 {
	color: #2d72b7;
	font-weight: 600;
	margin-bottom: 0;
	opacity: 0.8;
	font-size: 13px;
}

.ph-withdrawal-pharmacy-routes-list-hours-selected-main-subtitle-2 {
	font-weight: 600;
	margin-bottom: 0;
	font-size: 13px;
}

@media (max-width: 600px) {
	.ph-withdrawal-pharmacy-routes-list-hours-selected-main-title-2 {
		margin-left: 10px;
	}

	.ph-withdrawal-pharmacy-routes-list-hours-selected-main-title {
		margin-top: 10px;
	}

	.ph-withdrawal-pharmacy-routes-list-hours-selected-main-title-2-container {
		margin-top: 7px;
	}
	.ph-withdrawal-pharmacy-routes-address-main-container {
		padding-left: 15px;
	}
}

@media (max-width: 320px) {
	.ph-withdrawal-pharmacy-routes-list-hours-selected-main-title {
		margin-left: 5px;
		font-size: 11px;
	}

	.ph-withdrawal-pharmacy-routes-list-hours-selected-main-title-2 {
		font-size: 11px;
		margin-left: 5px;
	}
}
