/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-card-detail-profile-info-container {
	padding-right: 20px;
}

.ph-card-detail-profile-info-inner-container {
	text-align: center;
	padding-top: 10px;
}

.ph-card-detail-profile-info-image-container {
	border: 1px solid $primaryColorBorder;
	border-radius: 100px;
	padding: 10px;
	display: block;
	width: 100%;
	max-width: 150px;
	margin: auto;
}

.ph-card-detail-profile-info-image {
	width: 100%;
	max-width: 150px;
	border-radius: 100px;
}

.ph-card-detail-profile-info-title-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 0px;
}

.ph-card-detail-profile-info-subtitle {
	font-size: 16px;
	text-transform: capitalize;
	font-weight: 700;
	margin-top: 10px;
	margin-bottom: 7px;
	color: $colorGrayText4 !important;
	text-overflow: ellipsis;
	text-decoration: none;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
}

.ph-card-detail-profile-info-description {
	font-size: 15px;
	color: $primaryColor;
	font-weight: 600;
	margin-bottom: 0;
}

.ph-card-detail-profile-info-main-container {
	position: relative;
	border: 1px solid $primaryColorBorder;
	border-radius: 4px;
}

@media (max-width: 992px) {
	.ph-card-detail-profile-info-main-container {
		border-radius: 4px 4px 0 0;
	}

	.ph-card-detail-profile-info-inner-container {
		padding-top: 0;
	}

	.ph-card-detail-profile-info-image-container {
		max-width: 90px;
	}

	.ph-card-detail-profile-info-image {
		max-width: 90px;
	}

	.ph-card-detail-profile-info-subtitle {
		margin-top: 5px;
		margin-bottom: 0;
	}

	.ph-card-detail-profile-info-description {
		margin-bottom: 15px;
	}
}
