/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';
@import '../../../../../../v2-config/colors.scss';

.ph-withdrawal-pharmacy-list-global-container {
	padding: 10px;
	border-radius: 4px;
	box-shadow: $primaryBoxShadow;
	margin-top: 8px;
	margin-left: 4px;
	margin-right: 4px;
	margin-bottom: 10px;
	background-color: $generic-white;
}

.ph-withdrawal-pharmacy-local-list-main-title {
	font-size: 13px;
	font-weight: 100;
	color: $colorGrayText3;
	margin-top: 5px;
	margin-bottom: 0px;
	padding: 0 15px;
}

.ph-withdrawal-pharmacy-local-list-inner-container {
	padding: 12px;
}

.ph-withdrawal-pharmacy-local-list-main-on-demand-title {
	font-size: 13px;
	font-weight: 100;
	color: $primaryColor;
	margin-top: 5px;
	margin-bottom: 0px;
	padding: 0 15px;
}

.ph-withdrawal-pharmacy-list-main-container:last-child {
	margin-bottom: 0;
}

.ph-withdrawal-pharmacy-local-list-main-container {
	border: 1px solid $primaryColorBorder;
	border-radius: 4px;
}

.ph-withdrawal-pharmacy-local-selected-main-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 15px;
}

.ph-withdrawal-pharmacy-local-selected-image-container {
	padding: 3px;
	margin-right: 5px;
}

.ph-withdrawal-pharmacy-local-selected-image {
	width: 100%;
	max-width: 22px;
}

.ph-withdrawal-pharmacy-local-selected-main-title {
	color: $colorGrayText3;
	margin-bottom: 0;
	font-weight: 600;
}

.ph-withdrawal-pharmacy-local-list-item-container {
	padding: 10px;
}

.ph-withdrawal-pharmacy-local-list-item-main-title {
	color: $primaryColor;
	font-size: 12px;
	font-weight: 600;
	padding: 0 10px;
}

.ph-withdrawal-pharmacy-local-selector {
	max-width: 100%;
	height: 45px;
	border-radius: 6px;
	font-size: 13px;
}

.ph-withdrawal-pharmacy-local-selector .ant-select-selector {
	max-width: 100%;
	height: 45px !important;
	border-radius: 6px !important;
	font-size: 13px !important;
}

.ph-withdrawal-pharmacy-local-selector.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
	line-height: 45px;
}

.ph-withdrawal-pharmacy-local-selector.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
	line-height: 45px;
}

.ph-withdrawal-pharmacy-local-selector.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
	height: 44px !important;
}

.ph-withdrawal-pharmacy-local-selector.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
	height: 44px !important;
}

.ph-withdrawal-pharmacy-local-list-main-container .ant-input:focus,
.ph-withdrawal-pharmacy-local-list-main-container .ant-input:hover,
.ph-withdrawal-pharmacy-local-list-main-container .ant-input-focused,
.ph-withdrawal-pharmacy-local-list-main-container .ant-input:active {
	border-right-width: 0 !important;
	box-shadow: none !important;
	border: 1px solid rgba(255, 87, 34, 0.47843) !important;
	background-color: $primaryBackgroundColor !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s !important;
	background: $primaryBackgroundColor !important;
	background-color: $primaryBackgroundColor !important;
	-webkit-text-fill-color: $colorGrayText3 !important;
}

input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0px 1000px $primaryBackgroundColor inset;
	-moz-box-shadow: 0 0 0 100px $primaryBackgroundColor inset;
	box-shadow: 0 0 0 100px $primaryBackgroundColor inset;
}

.ant-select-selection-search:focus {
	outline: none !important;
}

.ph-withdrawal-pharmacy-local-list-address-image-container {
	margin-top: -5px;
	margin-left: 10px;
	margin-right: 3px;
	float: left;
}

.ph-withdrawal-pharmacy-local-list-address-image {
	width: 100%;
	max-width: 15px;
}

.ph-withdrawal-pharmacy-local-list-address-title {
	font-size: 12px;
	font-weight: 100;
	color: $colorGrayText1;
	margin-top: 5px;
	margin-bottom: 5px;
	padding: 0 15px;
}

.ph-withdrawal-pharmacy-list-button-info-start-destiny-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-withdrawal-pharmacy-list-button-info-start-location-icon {
	border: 2px solid $colorGrayText1;
	border-radius: 100%;
	width: 8px;
	min-width: 8px;
	display: block;
	height: 8px;
	min-height: 8px;
	margin-right: 4px;
}

.ph-withdrawal-pharmacy-list-button-info-start-location-icon-title {
	color: $colorGrayText3;
	font-size: 11px;
	text-align: left;
	margin-bottom: 0;
	font-weight: 600;
}

.ph-withdrawal-pharmacy-list-button-info-road-location-icon {
	border: 2px solid $colorGrayText2;
	border-radius: 100%;
	width: 4px;
	display: block;
	height: 4px;
	margin-left: 2px;
	margin-top: 2px;
	margin-bottom: 2px;
	background-color: $colorGrayText2;
	opacity: 0.6;
}

.ph-withdrawal-pharmacy-list-button-info-destiny-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-withdrawal-pharmacy-list-button-info-destiny-image-container {
	border: 1px solid $thirdColor;
	border-radius: 100%;
	padding: 0 2px;
	margin-top: 5px;
	margin-right: 10px;
}

.ph-withdrawal-pharmacy-list-button-info-destiny-image {
	width: 100%;
	max-width: 17px;
	min-width: 17px;
}

.ph-withdrawal-pharmacy-list-button-info-destiny-location-icon-title {
	color: $colorGrayText3;
	font-size: 11px;
	text-align: left;
	margin-bottom: 0;
	font-weight: 500;
	text-decoration: underline;
}

.ph-withdrawal-pharmacy-local-selected-address-main-container {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.ph-withdrawal-pharmacy-local-selected-destiny-location-local-container {
	margin-top: 5px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.ph-withdrawal-pharmacy-local-selected-destiny-location-local-title {
	color: $colorGrayText2;
	font-size: 13px;
	text-align: left;
	margin-bottom: 0;
	font-weight: 500;
	width: 100%;
}

.ph-withdrawal-pharmacy-local-selected-destiny-location-local-subtitle {
	color: $colorGrayText3;
	font-size: 12px;
	text-align: left;
	margin-bottom: 0;
	font-weight: 500;
	width: 100%;
}

.ph-withdrawal-pharmacy-local-selected-location-separator {
	border-left: 1px solid $primaryColorBorder;
	height: 60%;
	margin-top: 2px;
}

.ph-withdrawal-pharmacy-list-button-info-destiny-location-distance-title {
	font-size: 12px;
	color: $colorGrayText4;
}

.ph-withdrawal-pharmacy-list-button-info-destiny-location-distance-subtitle {
	font-weight: 600;
}

.ph-withdrawal-pharmacy-local-selected-deselect-button-container {
	margin-top: 5px;
}

.ph-withdrawal-pharmacy-local-selected-deselect-button-title {
	color: $colorGrayText1;
	margin-bottom: 0;
	font-weight: 500;
	font-size: 12px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	cursor: pointer;
}

.ph-withdrawal-pharmacy-local-selected-deselect-button-icon {
	border: 1px solid $successColor;
	background-color: $successColor;
	width: 17px;
	min-width: 17px;
	height: 17px;
	min-height: 17px;
	border-radius: 100%;
	margin: 0 10px;
}

.ph-withdrawal-pharmacy-local-selected-preview-button {
	margin-top: 10px;
	margin-bottom: 0;
	font-weight: 500;
	font-size: 12px;
	cursor: pointer;
	color: $primaryColor;
	text-decoration: underline;
}

@media (max-width: 600px) {
	.ph-withdrawal-pharmacy-list-description {
		font-size: 10px;
	}

	.ph-withdrawal-pharmacy-list-global-container {
		margin: 4px;
	}

	.ph-withdrawal-pharmacy-list-button-info-destiny-image-container {
		padding: 0 2px;
	}

	.ph-withdrawal-pharmacy-local-list-inner-container {
		padding: 7px;
	}

	.ph-withdrawal-pharmacy-local-list-item-container {
		padding: 7px;
	}

	.ph-withdrawal-pharmacy-local-selected-deselect-button-icon {
		margin: 0 0 0 6px;
	}
}

@media (max-width: 320px) {
	.ph-withdrawal-pharmacy-local-selected-deselect-button-title {
		font-size: 11px;
	}
}