.loading-page {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 250px;
	margin: 35px auto;
	height: 75vh;
	img {
		width: 100%;
	}
}
