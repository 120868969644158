/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-product-detail-simple-responsive-action-button,
.ph-product-detail-simple-responsive-action-button:hover,
.ph-product-detail-simple-responsive-action-button:active,
.ph-product-detail-simple-responsive-action-button:focus {
	animation: shadow-pulse 2s infinite;
	height: auto;
	line-height: 15px;
	background-color: $primaryColor;
	border-radius: 4px;
	color: $colorWhiteText;
	padding: 4px 15px 2px 15px;
	font-size: 15px;
	position: absolute;
	top: 12px;
	right: 15px;
	z-index: 2;
}

@keyframes shadow-pulse {
	0% {
		box-shadow: 0 0 0 0px rgba(46, 114, 182, 0.5);
	}
	100% {
		box-shadow: 0 0 0 10px rgba(46, 114, 182, 0);
	}
}

.ph-product-detail-simple-collapse {
	transform: translate(0, 0);
	transition: transform 0.6s ease;
}

.ph-product-detail-simple-collapsed {
	transform: translate(0, 345px);
	transition: transform 0.6s ease;
}

.ph-product-detail-quote-pharmacy-main-container {
	width: 100%;
	box-shadow: $primaryBoxShadow;
	padding: 10px 10px;
	border-radius: 4px;
}

.ph-product-detail-quote-tabs-main-container {
	width: 100%;
	box-shadow: $primaryBoxShadow;
	border-radius: 5px;
}

.ph-product-detail-quote-tabs-show-more {
	width: inherit;
	position: relative;
	display: flex;
	align-items: center;
	border-radius: 5px;
	box-shadow: 0px -6px 10px rgba(255, 255, 255, 1);
	padding: 5px 14px;
	font-size: 15px;
	color: $primaryColor;
	background-color: $primaryBackgroundColor;
	cursor: pointer;
	transition: transform 5s ease;

	&-transform {
		transform: rotate(90deg);
	}
}

.ph-product-detail-quote-tabs-show-more-icon {
	margin-left: 0.2rem;
}

.ph-product-detail-quote-carousel-pharmacy-title {
	color: $primaryColor;
	font-weight: 600;
	font-size: 20px;
	text-align: left;
	padding: 5px;
	margin-top: 20px;
	margin-bottom: 7px;
}

.ph-product-detail-simple-image-container {
	width: 100%;
	box-shadow: $primaryBoxShadow;
	border-radius: 4px;
	margin-bottom: 8px;
}

@media (min-width: 991px) {
	.ph-product-detail-simple-title-container {
		&:nth-last-of-type(2) {
			display: none;
		}
		&:nth-last-of-type(3) {
			display: block;
		}
	}

	.ph-product-detail-quote-tabs-main-container {
		&:nth-of-type(2) {
			display: block;
		}
		&:nth-of-type(4) {
			display: none;
		}
	}

	.ph-product-detail-quote-tabs-mobile-title {
		display: none;
	}
}

@media (max-width: 991px) {
	.ph-product-detail-simple-image-container {
		max-width: 100%;
		margin: auto;
	}

	.ph-product-detail-simple-title-container {
		&:nth-last-of-type(2) {
			display: block;
			margin-bottom: 15px;
		}
		&:nth-last-of-type(3) {
			display: none;
		}
	}

	.ph-product-detail-quote-tabs-main-container {
		&:nth-of-type(2) {
			display: none;
		}
		&:nth-of-type(4) {
			display: block;
		}
	}

	.ph-product-detail-quote-tabs-mobile-title {
		display: block;
		color: $primaryColor;
		font-size: 16px;
		margin-left: 10px;
	}

	.ph-product-detail-quote-tabs-show-more {
		display: none;
	}
}

@media (max-width: 1024px) {
	.ph-product-detail-quote-pharmacy-global-container {
		padding-right: 8px;
	}
}

@media (max-width: 992px) {
	.ph-product-detail-quote-pharmacy-global-container {
		padding-right: 0;
	}
	.ph-product-detail-simple-global-container {
		padding: 0;
	}
}

@media (min-width: 601px) {
	.ph-product-detail-simple-responsive-action-button {
		display: none;
	}
}

@media (max-width: 600px) {
	.ph-product-detail-quote-pharmacy-main-container {
		position: fixed;
		bottom: 0;
		background-color: #fff;
		z-index: 2;
		left: 0;
		right: 0;
		padding: 5px;
		box-shadow: $mainHeaderboxShadow;
	}

	.ph-product-detail-simple-collapse {
		border-radius: 0;
	}

	.ph-product-detail-simple-collapsed {
		border-radius: 0;
	}
}

@media (max-width: 380px) {
	.ph-product-detail-simple-collapsed {
		transform: translate(0, 386px);
		transition: transform 0.6s ease;
	}
}

@media (max-width: 350px) {
	.ph-product-detail-quote-pharmacy-main-container {
		padding: 5px;
		border-radius: 0;
	}
}
