/** @format */

@import '../../../Commons/Sass/components/colors';

.ph-elactic-sidebar-drawer-main-container .ant-drawer-header {
	padding: 7px;
	margin-left: 5px;
}

.ph-elactic-sidebar-drawer-main-container .ant-drawer-title {
	color: $primaryColor;
	font-size: 14px;
	font-weight: 600;
}

.ph-elactic-sidebar-drawer-main-container .ant-drawer-close {
	padding: 3px;
	color: $errorColor;
	font-size: 12px;
	border: 1px solid $errorColor;
	border-radius: 100%;
	top: 7px;
	right: 8px;
}

.ph-elactic-sidebar-drawer-main-container .ant-drawer-body {
	padding: 0;
}
