/** @format */

.ph-electronic-quote-pharmacy-price-promotion-container {
	width: 100%;
	max-width: 330px;
}

@media (max-width: 575px) {
	.ph-electronic-quote-pharmacy-price-promotion-container {
		max-width: 100px;
		margin: 0 0 0 auto;
		line-height: 15px;
	}
}
