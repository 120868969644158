/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-retirement-service-global-container {
	max-width: 1185px;
	padding: 10px;
	margin: auto;
	min-height: calc(100vh - 113px);
}

.ph-retirement-service-main-container {
	padding: 10px 25px;
	margin-bottom: 40px;
}

.ph-retirement-service-titlebox-countdown-container {
	position: relative;
}

.ph-retirement-service-box-main-container {
	box-shadow: $primaryBoxShadow;
	background-color: $primaryBackgroundColor;
	border-radius: 4px;
	margin: 4px;
	padding: 20px 25px;
}

@media (max-width: 992px) {
	.ph-retirement-service-box-global-container {
		padding-bottom: 10px;
	}

	.ph-retirement-service-box-main-container {
		margin: -4px 4px 4px 4px;
	}
}

@media (max-width: 768px) {
	.ph-retirement-service-box-global-container {
		padding-bottom: 20px;
	}

	.ph-retirement-service-box-main-container {
		margin: 0 4px 4px 4px;
		padding: 10px;
	}
}
