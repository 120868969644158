/** @format */

@import '../../components/Commons/Sass/components/colors';
@import '../../v2-config/colors.scss';

.ph-objected-recipe-global-container {
	max-width: 900px;
	padding: 10px;
	margin: auto;
	margin-bottom: 40px;
}

.ph-objected-recipe-global-inner-container {
	height: auto;
	min-height: calc(100vh - 158px);
}

.ph-objected-recipe-main-container {
	padding: 20px;
	border-radius: 4px;
	box-shadow: $mainHeaderboxShadow;
	background-color: $generic-white;
}

.ph-objected-recipe-main-title {
	font-size: 25px;
	font-weight: 900;
	color: $primaryColor;
	line-height: normal;
	margin-bottom: 5px;
}

.ph-objected-recipe-main-subtitle {
	font-size: 15px;
	color: $colorGrayText2;
	font-weight: 400;
}

.ph-objected-recipe-inner-container {
	border: 1px solid $primaryColorBorder;
	border-radius: 3px;
	transition: 0.2s ease;
	position: relative;
	margin: 0;
	padding: 15px;
}

.ph-objected-recipe-button-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 10px;
}

.ph-objected-recipe-button,
.ph-objected-recipe-button:hover,
.ph-objected-recipe-button:active,
.ph-objected-recipe-button:focus {
	background-color: $secondColor !important;
	color: $colorWhiteText;
	padding: 6px 20px;
	font-size: 15px;
	border-radius: 4px;
	font-weight: 900;
	box-shadow: $secondaryBoxShadow;
	border: 2px solid $secondColor;
	text-transform: capitalize;
	height: 38px;
}

.ph-objected-recipe-container {
	text-align: center;
}

.ph-upload-recipe-container {
	width: 100%;
	margin: 0;
}

@media (max-width: 768px) {

	.ph-recipe-objeted-pharmacy-btn,
	.ph-recipe-objeted-pharmacy-btn:hover,
	.ph-recipe-objeted-pharmacy-btn:active,
	.ph-recipe-objeted-pharmacy-btn:focus {
		max-width: 120px;
		font-size: 12px;
	}

	.ph-recipe-upload-component-title-container {
		justify-content: flex-start;
		margin-left: 10px;
	}
}

@media (max-width: 576px) {
	.ph-objected-recipe-main-subtitle {
		font-size: 12px;
	}

	.ph-recipe-objeted-pharmacy-btn,
	.ph-recipe-objeted-pharmacy-btn:hover,
	.ph-recipe-objeted-pharmacy-btn:active,
	.ph-recipe-objeted-pharmacy-btn:focus {
		max-width: 110px;
	}
}

@media (max-width: 480px) {
	.ph-objected-recipe-main-title {
		font-size: 20px;
	}
}

@media (max-width: 414px) {
	.ph-objected-recipe-global-container .ph-recipe-objeted-pharmacy-list-description {
		font-size: 11px;
	}
}

@media (max-width: 375px) {
	.ph-recipe-objeted-pharmacy-btn {
		font-size: 10px;
	}

	.ph-objected-recipe-image {
		max-width: 40px;
	}

	.ph-objected-recipe-upload-component-list li {
		font-size: 9px;
	}
}