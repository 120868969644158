/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-complaint-modal-container .ant-modal-content {
	border-radius: 8px;
}

.ph-complaint-modal-container .ant-modal-content .ant-modal-close {
	top: 11px;
	right: 21px;
}

.ph-complaint-modal-container .ant-modal-content .ant-modal-close-x {
	border-radius: 8px;
	border: 2px solid $fourthColor;
	border-radius: 100px;
	color: $fourthColor;
	font-weight: bold;
	width: 18px;
	height: 18px;
	font-size: 10px;
	line-height: 0;
	padding: 2px;
}

.ph-complaint-modal-container .ant-modal-header {
	border-bottom: 1px solid $primaryColorBorder !important;
	padding: 7px 20px;
	border-radius: 8px 8px 0 0;
}

.ph-complaint-modal-container .ant-modal-header .ant-modal-title {
	font-size: 14px;
	font-weight: 900;
}

.ph-complaint-modal-container .ant-modal-content .ant-modal-body {
	padding: 16px;
	text-align: center;
}

.ph-complaint-modal-title {
	font-size: 14px;
	margin-bottom: 0;
	font-weight: 900;
	color: $primaryColor;
}

.ph-complaint-modal-description {
	font-weight: 400;
	font-style: italic;
	font-size: 13px;
	margin-bottom: 15px;
	color: $colorGrayText3;
}

.ph-complaint-modal-info-main-container {
	padding: 20px 10px;
	border-radius: 4px;
	box-shadow: $mainHeaderboxShadow;
	height: auto;
	margin-bottom: 40px;
}

.ph-complaint-modal-info-form-container {
	padding: 0 10px;
}

.ph-complaint-modal-info-field-input {
	max-width: 100%;
	height: 45px;
	border-radius: 6px;
	font-size: 13px;
	color: $colorGrayText3;
}

.ph-complaint-modal-info-button-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 10px;
	margin-bottom: 0;
	margin-right: 11px;
}

.ph-complaint-modal-info-button,
.ph-complaint-modal-info-button:active,
.ph-complaint-modal-info-button:focus,
.ph-complaint-modal-info-button:hover {
	background-color: $secondColor !important;
	color: $colorWhiteText;
	padding: 5px 10px;
	font-size: 15px;
	border-radius: 4px;
	font-weight: 500;
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
		0 1px 5px 0 rgba(0, 0, 0, 0.12);
	border: 2px solid $secondColor !important;
	text-transform: capitalize !important;
	height: 34px;
	width: 120px;
	line-height: 10px;
}

.ph-complaint-modal-info-button[disabled] {
	background-color: $thirdBackgroundColor !important;
	border: 2px solid $primaryColorBorder !important;
}

.ph-complaint-modal-info-hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid $primaryColorBorder;
	margin: 15px auto;
	width: 97%;
	margin-top: 0;
	margin-bottom: 22px;
}

.ph-complaint-modal-info-field-select .ant-select-selector {
	max-width: 100%;
	height: 45px !important;
	border-radius: 6px !important;
	font-size: 13px !important;
}

.ph-complaint-modal-info-field-select .ant-select-selector .ant-select-selection-placeholder {
	margin-top: 6px;
}

.ph-complaint-modal-info-field-select.ant-select-single
	.ant-select-selector
	.ant-select-selection-item {
	margin-top: 6px;
}

.ph-complaint-modal-info-field-select .ant-select-selector {
	text-align: left;
}

.ph-complaint-modal-info-button {
	margin-bottom: -20px;
}
