/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-product-detail-quote-rectangle-info-main-container {
	background-color: $primaryBackgroundColor;
	box-shadow: $primaryBoxShadow;
	padding: 8px 10px;
	border-radius: 4px;
	margin-top: 7px;
	margin-bottom: 14px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
	width: 100%;
}

.ph-product-detail-quote-rectangle-main-image-container {
	margin-left: 5px;
	margin-right: 15px;
}

.ph-product-detail-quote-rectangle-main-image {
	width: 100%;
	max-width: 50px;
}

.ph-product-detail-quote-rectangle-info-title {
	color: $primaryColor;
	font-weight: 900;
	text-transform: uppercase;
	font-size: 14px;
	margin-bottom: 0;
	opacity: 0.8;
}

.ph-product-detail-quote-rectangle-info-subtitle {
	font-weight: 300;
	color: $colorGrayText3;
	font-size: 12px;
	margin-bottom: 0px;
}

.ph-product-detail-quote-rectangle-info-description-link {
	font-size: 13px;
	font-weight: 600;
	font-style: italic;
	text-decoration: underline;
	color: $primaryColor;
	margin-bottom: 0;
}

.ph-product-detail-quote-rectangle-info-description {
	font-weight: 300;
	color: $colorGrayText3;
	font-size: 13px;
	margin-bottom: 0px;
}
.ph-product-detail-quote-mobile-spacer {
	height: 8px;
}

.ph-product-detail-quote-contact-mediclic-description {
	box-shadow: $primaryBoxShadow;
	background-color: $primaryBackgroundColor;
	border-radius: 4px;
	margin-top: 0;
	margin-bottom: 8px;
	width: 100%;
	max-width: 700px;
	margin-left: auto;
}

@media (max-width: 575px) {
	.ph-product-detail-quote-rectangle-info-main-container {
		padding: 8px 10px;
		margin: 10px 0 0 0;
	}

	.ph-product-detail-quote-rectangle-main-image-container {
		margin-left: 0;
		margin-right: 10px;
	}

	.ph-product-detail-quote-rectangle-main-image {
		width: 100%;
		max-width: 40px;
	}

	.ph-product-detail-quote-rectangle-info-title {
		font-size: 12px;
	}

	.ph-product-detail-quote-rectangle-info-subtitle {
		font-size: 11px;
	}

	.ph-product-detail-quote-rectangle-info-description-link {
		font-size: 11px;
	}

	.ph-product-detail-quote-rectangle-info-description {
		font-size: 11px;
	}

	.ph-product-detail-quote-contact-mediclic-description {
		margin: 10px 0;
	}
}
