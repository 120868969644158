/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-order-detail-products-main-container {
	padding: 20px;
	border-radius: 4px;
	box-shadow: $primaryBoxShadow;
	margin-bottom: 30px;
}

.ph-order-detail-products-inner-container {
	position: relative;
	border: 1px solid $primaryColorBorder;
	border-radius: 4px;
}

.ph-order-detail-products-inner-container .ant-collapse-header {
	position: absolute !important;
	top: 0;
	left: 0;
	width: 100%;
	height: 46px;
	border-radius: 0 !important;
}

.ph-order-detail-products-inner-container .ant-collapse-content-box {
	padding: 0;
}

.ph-order-detail-products-inner-container .anticon {
	opacity: 0.6 !important;
	top: 18px !important;
}

.ph-order-detail-products-inner-container .ant-collapse-item {
	border-bottom: none;
}

.ph-order-detail-products-inner-container .ant-collapse-content {
	border-top: 1px solid $primaryColorBorder;
}

.ph-order-detail-products-title-container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px 40px 12px 15px;
}

.ph-order-detail-products-list-main-container {
	position: relative;
	padding: 20px;
}

.ph-order-detail-products-image-main-container {
	position: relative;
}

.ph-order-detail-products-image-container {
	display: block;
	text-align: center;
	padding-right: 20px;
}

.ph-order-detail-products-image {
	width: 100%;
	max-width: 110px;
}

.ph-order-detail-products-quantity {
	position: absolute;
	top: 90px;
	right: 60px;
	background-color: $primaryBackgroundColor;
	border: 1px solid $primaryColorBorder;
	padding: 2px 6px;
	line-height: 15px;
	border-radius: 50px;
	font-size: 11px;
	font-weight: 600;
}

.ph-order-detail-products-title {
	color: $primaryColor;
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 5px;
}

@media (max-width: 768px) {
	.ph-order-detail-products-inner-container .ant-collapse-header {
		height: 64px;
	}

	.ph-order-detail-products-inner-container .anticon {
		top: 26px !important;
	}

	.ph-order-detail-products-title-container {
		display: block;
		padding: 5px 40px 5px 15px;
	}

	.ph-order-detail-products-image-container {
		padding-right: 0;
		max-width: 130px;
		margin: auto;
	}

	.ph-order-detail-products-quantity {
		top: unset;
		bottom: 0;
		right: 0;
	}
}

@media (max-width: 575px) {
	.ph-order-detail-products-main-container {
		padding: 10px;
	}

	.ph-order-detail-products-list-main-container {
		padding: 10px;
	}
}
