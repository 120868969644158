/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-bag-type-receipt-main-container {
	z-index: 2;
	margin-right: 2px;
}

.ph-bag-type-receipt-image-container {
	margin-left: 2px;
}

.ph-bag-type-receipt-image {
	//width: 100%;
	//max-width: 15px;
	width: 24px;
}
