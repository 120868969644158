/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-order-detail-pharmacies-list-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 3px;
}

.ph-order-detail-pharmacies-list-title {
	color: $colorGrayText4;
	font-size: 13px;
	width: 100%;
	max-width: 160px;
	font-weight: 900;
	margin-bottom: 0;
}

.ph-order-detail-pharmacies-list-description {
	color: $colorGrayText4;
	text-transform: capitalize;
	font-size: 13px;
	width: 100%;
	font-weight: 500;
	margin-bottom: 0;
	opacity: 0.8;
}

.ph-order-detail-pharmacies-list-font-weight {
	font-weight: 900;
}

@media (max-width: 892px) {
	.ph-order-detail-pharmacies-list-title {
		font-size: 12px;
		max-width: 130px;
	}

	.ph-order-detail-pharmacies-list-description {
		font-size: 12px;
	}
}
