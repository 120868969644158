/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-bag-recipe-list-main-container {
	border-radius: 4px;
	padding: 15px;
	margin-bottom: 10px;
	box-shadow: $primaryBoxShadow;
}

.ph-bag-recipe-list-inner-container {
	padding-right: 10px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.ph-bag-recipe-list-main-container:last-child {
	margin-bottom: 0;
}

.ph-bag-recipe-list-main-container .ant-upload.ant-upload-drag {
	height: auto;
}

.ph-bag-recipe-list-laboraroty-title {
	font-size: 13px;
	font-weight: 100;
	color: $colorGrayText4;
	font-style: italic;
	margin-bottom: 5px;
}

.ph-bag-recipe-list-laboraroty-subtitle {
	color: $primaryColor;
}

.ph-bag-recipe-list-upload-image-main-container {
	position: relative;
}

.ph-bag-recipe-list-upload-bypass-recipe-image-container-enabled {
	display: unset !important;
}

.ph-bag-recipe-list-upload-bypass-recipe-image-container {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	background-color: $primaryBackgroundColor;
	opacity: 0.95;
	z-index: 2;
	display: none;
	border: 2px solid $primaryColorBorder;
	border-radius: 5px;
	padding: 15px;
	text-align: center;
}

.ph-bag-recipe-list-upload-bypass-recipe-inner-image-container {
	padding: 5px;
	display: block;
	margin-top: 10px;
}

.ph-bag-recipe-list-upload-bypass-recipe-inner-image {
	width: 100%;
	max-width: 50px;
}

.ph-bag-recipe-list-upload-bypass-recipe-subtitle {
	color: $primaryColor;
	font-size: 13px;
}

.ph-bag-recipe-list-upload-bypass-recipe-description {
	color: $colorGrayText3;
	font-size: 12px;
	font-style: italic;
	margin-bottom: 0;
}

.ph-bag-recipe-agree-container .ant-form-item {
	margin-bottom: 0;
}

.ph-bag-recipe-agree-main-container {
	box-shadow: $primaryBoxShadow;
	background-color: $primaryBackgroundColor;
	border-radius: 4px;
	padding: 5px 10px;
}

.ph-bag-recipe-agree-container .ant-checkbox {
	margin-top: -6px;
}

.ph-bag-recipe-agree-container .ant-checkbox-checked .ant-checkbox-inner {
	background-color: $primaryColor;
	border-color: $primaryColor;
}

.ph-bag-recipe-agree-container .ant-checkbox-input:focus + .ant-checkbox-inner {
	border-color: $primaryColor;
}

.ph-bag-recipe-agree-container .ant-checkbox-wrapper {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-bag-recipe-agree-container .ant-form-item-explain-error,
.ph-bag-recipe-agree-container .ant-form-item-explain-success {
	padding-left: 30px;
}

.ph-bag-recipe-agree-title {
	color: $colorGrayText2;
	font-size: 14px;
	margin-bottom: 0;
	line-height: 17px;
	font-style: italic;
	padding-left: 5px;
}

.ph-bag-recipe-list-hidden-main-container {
	margin-top: 4px !important;
}

.ph-prescription-checkout-box-global-container {
	padding-bottom: 45px;
}

.ph-bag-recipe-will-save-title {
	color: $colorGrayText3;
	font-size: 13px;
	margin-top: 3px;
	margin-left: 7px;
	margin-bottom: 0;
}

.ph-bag-recipe-will-save-subtitle {
	color: $primaryColor;
	font-weight: 600;
}

.ph-bag-recipe-will-save-title-container {
	border: 1px solid $primaryColor;
	border-radius: 4px;
	opacity: 0.8;
	padding: 15px 5px;
}

.ph-bag-recipe-will-save-title-inner-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-bag-recipe-will-save-title-image-container {
	padding: 3px;
}

.ph-bag-recipe-will-save-title-image {
	width: 100%;
	max-width: 150px;
}

.ph-bag-recipe-will-save-title-button,
.ph-bag-recipe-will-save-title-button:hover,
.ph-bag-recipe-will-save-title-button:active,
.ph-bag-recipe-will-save-title-button:focus {
	border: 1px solid $primaryColor;
	border-radius: 4px;
	box-shadow: $primaryColor;
	color: $primaryColor;
}

.ph-checkout-receipt-modal-added-pdf-main-container {
	border: 1px solid $primaryColorBorder;
	border-radius: 4px;
	padding: 5px 10px;
	text-align: center;
}

.ph-checkout-receipt-modal-added-pdf-container {
	text-align: center;
}

.ph-checkout-receipt-modal-added-pdf-icon {
	color: red;
	font-size: 100px;
	margin: 20px 0;
}

.ph-checkout-receipt-modal-added-pdf-link,
.ph-checkout-receipt-modal-added-pdf-link:hover,
.ph-checkout-receipt-modal-added-pdf-link:active,
.ph-checkout-receipt-modal-added-pdf-link:focus {
	font-size: 14px;
	color: $primaryColor;
	text-decoration: underline;
}
