@import '../../../../../../Sass/components/colors';
@import '../../../../../../../../v2-config/colors.scss';

.icons-theme-mobile-actions {
	color: $generic-dark-grey1;
	height: 30px;
	width: 30px;
	cursor: pointer;
	&:hover {
		color: $primary-hover;
	}
	&:active,
	&.active {
		color: $primary-default;
	}
}
