/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-retirement-personal-data-global-container {
	box-shadow: $primaryBoxShadow;
	padding: 10px;
	margin: 4px;
	border-radius: 4px;
}

.ph-retirement-personal-data-title {
	color: $primaryColor;
	margin-bottom: 10px;
	font-size: 13px;
	font-weight: 600;
	padding: 0 15px;
}

.ph-retirement-personal-data-form-container {
	padding: 0 5px;
}

.ph-retirement-personal-data-field-input {
	max-width: 100%;
	height: 45px;
	border-radius: 6px;
	font-size: 13px;
	color: $colorGrayText3;
}

.ph-retirement-personal-data-field-selector .ant-select-selector {
	max-width: 100%;
	height: 45px !important;
	border-radius: 6px !important;
	font-size: 13px !important;
}

.ph-retirement-personal-data-field-selector.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input)
	.ant-select-selection-search-input {
	height: 44px !important;
	color: $colorGrayText2;
}

.ph-retirement-personal-data-field-selector.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
	.ant-select-selector
	.ant-select-selection-item {
	line-height: 45px;
	color: $colorGrayText2;
}

.ph-retirement-personal-data-field-selector.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
	.ant-select-selector
	.ant-select-selection-placeholder {
	height: 44px !important;
	color: $colorGrayText2;
	line-height: 45px;
}

.ph-retirement-personal-data-field-selector.ant-select:not(.ant-select-disabled):hover
	.ant-select-selector {
	border: 1px solid rgba(255, 87, 34, 0.47843) !important;
}

.ph-retirement-personal-data-form-container .ant-input:focus,
.ph-retirement-personal-data-form-container .ant-input:hover,
.ph-retirement-personal-data-form-container .ant-input-focused,
.ph-retirement-personal-data-form-container .ant-input:active {
	border-right-width: 0 !important;
	box-shadow: none !important;
	border: 1px solid rgba(255, 87, 34, 0.47843) !important;
	background-color: #fff !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s !important;
	background: #fff !important;
	background-color: #fff !important;
	-webkit-text-fill-color: #777 !important;
}

input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0px 1000px #fff inset;
	-moz-box-shadow: 0 0 0 100px #fff inset;
	box-shadow: 0 0 0 100px #fff inset;
}

.ph-retirement-personal-data-agree-main-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0 12px;
	margin-bottom: 3px;
}

.ph-retirement-personal-data-facturation-modal-container .ant-modal-content {
	border-radius: 8px;
}

.ph-retirement-personal-data-facturation-modal-container .ant-modal-content .ant-modal-close {
	top: 11px;
	right: 21px;
}

.ph-retirement-personal-data-facturation-modal-container .ant-modal-content .ant-modal-close-x {
	border: 2px solid #ff4081;
	border-radius: 100px;
	color: #ff4081;
	font-weight: bold;
	width: 20px;
	height: 20px;
	font-size: 12px;
	line-height: 0;
	padding: 2px;
}

.ph-retirement-personal-data-facturation-modal-container .ant-modal-content .ant-modal-footer {
	text-align: center;
	padding: 0px 16px 16px;
	border-top: none;
}

.ph-retirement-personal-data-facturation-modal-container .ant-modal-header {
	border-bottom: 1px solid #e9e9e9 !important;
	padding: 10px 20px;
	border-radius: 8px 8px 0 0;
}

.ph-retirement-personal-data-facturation-modal-container .ant-modal-content .ant-modal-body {
	padding: 16px;
}

.ph-retirement-personal-data-facturation-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 12px;
}

.ph-retirement-personal-data-facturation-btn,
.ph-retirement-personal-data-facturation-btn:hover,
.ph-retirement-personal-data-facturation-btn:active,
.ph-retirement-personal-data-facturation-btn:focus {
	background: #2d72b7 !important;
	font-size: 20px;
	text-transform: capitalize;
	border-radius: 4px;
	min-width: auto;
	width: 160px;
	border-color: transparent;
	height: 36px;
	margin: 0 0 5px;
	line-height: 10px;
	color: #fff;
}

.ph-retirement-personal-data-facturation .ant-checkbox + span {
	padding: 0 !important;
}

.ph-retirement-personal-data-facturation {
	color: #7b7b7b;
}

.ph-retirement-personal-data-facturation-title {
	font-size: 17px;
	font-weight: 900;
}

.ph-retirement-personal-data-facturation-subtitle-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}

.ph-retirement-personal-data-facturation-subtitle {
	color: #aaa;
	font-weight: 500;
	font-size: 15.5px;
}

.ph-retirement-personal-data-agree-container {
	margin-bottom: 0;
}

.ph-retirement-personal-data-agree-title {
	margin-left: 7px;
	margin-bottom: 8px;
	color: $colorGrayText3;
}

.ph-retirement-personal-data-agree-container .ant-checkbox {
	margin-top: -3px;
}

.ph-retirement-personal-data-agree-main-container .ant-checkbox-checked .ant-checkbox-inner {
	background-color: $primaryColor;
	border-color: $primaryColor;
}

.ph-retirement-personal-data-agree-main-container .ant-checkbox-input:focus + .ant-checkbox-inner {
	border-color: $primaryColor;
}

.ph-retirement-personal-data-agree-main-container .ant-checkbox-checked:after {
	border: none !important;
}

.ph-retirement-personal-data-agree-main-container .ant-checkbox-inner {
	top: -5px !important;
}

.ph-retirement-personal-data-agree-link {
	color: $primaryColor;
	font-weight: 600;
	font-size: 13px;
}

.ph-retirement-personal-data-agree-main-container .ant-form-item-explain {
	position: absolute;
	width: 385px;
	bottom: -20px;
	margin-left: 25px;
}

@media (max-width: 600px) {
	.ph-retirement-personal-data-global-container {
		padding: 5px;
	}

	.ph-retirement-personal-data-title {
		padding: 0 5px;
	}

	.ph-retirement-personal-data-form-container {
		padding: 0;
	}

	.ph-retirement-personal-data-agree-main-container {
		padding: 5px;
	}

	.ph-retirement-personal-data-agree-title {
		font-size: 11px;
	}

	.ph-retirement-personal-data-agree-link {
		font-size: 11px;
	}
}
