/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-order-detail-products-item-service-main-container {
	max-width: 500px;
	margin-top: 6px;
}

.ph-order-detail-products-item-service-separator {
	border: 0 none transparent;
	border-top: 1px solid $primaryColorBorder;
	height: 1px;
	margin: 10px 0;
	display: block;
	clear: both;
}

.ph-order-detail-products-item-service-main-title {
	color: $colorGrayText4;
	font-weight: 900;
	font-size: 13px;
	margin-bottom: 10px;
}

.ph-order-detail-products-item-service-container {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $thirdBackgroundColor;
	border: 1px solid $primaryColorBorder;
	border-radius: 4px;
	padding: 5px 10px;
	margin-bottom: 5px;
}

.ph-order-detail-products-item-service-inner-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-order-detail-products-item-service-image-container {
	margin-right: 10px;
}

.ph-order-detail-products-item-service-image {
	width: 100%;
	max-width: 25px;
	border-radius: 100px;
}

.ph-order-detail-products-item-service-title {
	color: $primaryColor;
	font-weight: 500;
	font-size: 13px;
	margin-bottom: 0;
}

.ph-order-detail-products-item-service-description {
	font-weight: 100;
	font-size: 13px;
	color: $colorGrayText4;
	margin-bottom: 0;
}

.ph-order-detail-products-item-service-price-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.ph-order-detail-products-item-service-price-title {
	font-weight: 100;
	font-size: 13px;
	color: $colorGrayText4;
	margin-right: 5px;
	margin-bottom: 0;
}

.ph-order-detail-products-item-service-price-description {
	font-weight: 600;
	font-size: 13px;
	color: $colorGrayText4;
	margin-bottom: 0;
}

@media (max-width: 575px) {
	.ph-order-detail-products-item-service-container {
		padding: 5px;
		display: block;
	}

	.ph-order-detail-products-item-service-inner-container {
		justify-content: flex-start;
	}

	.ph-order-detail-products-item-service-image-container {
		margin-right: 5px;
	}

	.ph-order-detail-products-item-service-title {
		font-size: 11px;
	}

	.ph-order-detail-products-item-service-description {
		font-size: 11px;
	}

	.ph-order-detail-products-item-service-price-container {
		justify-content: flex-start;
		margin-left: 30px;
	}

	.ph-order-detail-products-item-service-price-title {
		font-size: 11px;
	}

	.ph-order-detail-products-item-service-price-description {
		font-size: 11px;
	}
}
