/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-order-history-purchase-list-title-container {
	padding: 5px;
}

.ph-order-history-purchase-list-title {
	color: $colorGrayText4;
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	margin-bottom: 3px;
}

.ph-order-history-purchase-list-description {
	color: $colorGrayText4;
	font-size: 14px;
	font-weight: 500;
	text-transform: capitalize;
	margin-bottom: 0;
}

.ph-order-history-purchase-list-title-responsive {
	color: $colorGrayText4;
	font-size: 13px;
	font-weight: 900;
	text-transform: capitalize;
	margin-bottom: 0;
	margin-right: 4px;
}

.ph-order-history-purchase-list-responsive-order-container {
	text-align: right;
}

.ph-order-history-purchase-list-responsive-order-description {
	color: $primaryColor;
	font-weight: 900;
}

.ph-order-history-purchase-list-responsive-name-description {
	color: $primaryColor;
}

@media (min-width: 769px) {
	.ph-order-history-purchase-list-title-responsive {
		display: none;
	}
}

@media (max-width: 768px) {
	.ph-order-history-purchase-list-title-container {
		padding: 0;
	}

	.ph-order-history-purchase-list-title {
		margin-bottom: 0;
		font-size: 11px;
	}

	.ph-order-history-purchase-list-description {
		font-size: 13px;
	}

	.ph-order-history-purchase-list-responsive-date-title {
		display: none;
	}

	.ph-order-history-purchase-list-responsive-date-description {
		margin-bottom: 4px;
	}

	.ph-order-history-purchase-list-responsive-order-title {
		display: none;
	}

	.ph-order-history-purchase-list-responsive-order-container {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-bottom: 3px;
	}
}

@media (max-width: 768px) {
	.ph-order-history-purchase-list-title {
		font-size: 10px;
	}
}
