/** @format */

@import "../../../../components/Commons/Sass/components/colors";

.ph-profile-info-card {
  background-color: $primaryBackgroundColor;
  border-radius: 5px;
  box-shadow: $primaryBoxShadow;
  margin: 10px;
  padding: 10px;
  transition: 0.4s ease;
  cursor: pointer;
}

.ph-profile-info-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
}

.ph-profile-info-image-container {
  padding: 5px;
}

.ph-profile-info-image {
  width: 100%;
  max-width: 25px;
  margin: auto;
}

.ph-profile-info-card-title {
  font-weight: 500;
  font-size: 18px;
  color: $colorGrayText3;
  margin-bottom: 0;
  opacity: 0.9;
}

.ph-profile-info-description-container {
  border: 1px solid $thirdBackgroundColor;
  padding: 5px 10px;
  height: 70px;
}

.ph-profile-info-card-description {
  font-size: 13px;
  color: $colorGrayText4;
  margin-top: 5px;
  margin-bottom: 5px;
  opacity: 0.9;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  line-height: 16px;
}

.ph-profile-info-card-link {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  cursor: pointer;
  color: $primaryColor;
  text-align: right;
  padding: 15px 0 0;
}

.ph-profile-info-card-description-loading {
  margin-top: 15px;
  margin-left: 20px;
}

.ph-profile-info-card-description-loading-icon {
  color: $primaryColor;
}

@media (max-width: 1070px) {
  .ph-profile-info-card {
    padding: 15px 5px;
  }

  .ph-profile-info-description-container {
    padding: 0 5px;
  }
}

.ph-profile-info-card-description-address {
  font-size: 13px;
  color: $colorGrayText4;
  margin-top: 5px;
  margin-bottom: 5px;
  opacity: 0.9;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  line-height: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  max-width: 500px;
}

@media (max-width: 575px) {
  .ph-profile-info-card {
    padding: 10px 10px 15px;
    margin: 10px 0;
  }

  .ph-profile-info-title-container {
    padding-bottom: 10px;
  }

  .ph-profile-info-card-description {
    height: auto;
    margin-bottom: 5px;
  }

  .ph-profile-info-card-description-address {
    height: auto;
    margin-bottom: 5px;
  }
}
