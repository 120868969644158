/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-bag-modal-calculate-modal .ant-modal-header {
	display: none;
}

.ph-bag-modal-calculate-modal .ant-modal-close {
	display: none;
}

.ph-bag-modal-calculate-modal .ant-modal-body {
	text-align: center;
}

.ph-bag-modal-calculate-modal .ant-modal-content {
	border-radius: 8px;
}

.ph-bag-modal-calculate-image-container {
	padding: 2px;
	margin-bottom: 5px;
	display: block;
}

.ph-bag-modal-calculate-image {
	width: 100%;
	max-width: 80px;
}

.ph-bag-modal-calculate-title {
	margin-bottom: 0px;
	color: $colorGrayText3;
	font-weight: 500;
}

.ph-bag-modal-calculate-subtitle {
	margin-bottom: 20px;
	color: $secondColor;
	font-weight: 600;
}

.ph-bag-modal-calculate-spin {
	color: $primaryColor;
	margin-bottom: 10px;
}

.ph-bag-modal-calculate-spin .anticon-spin {
	font-size: 35px;
}
