/** @format */

@import '../../../../../../../components/Commons/Sass/components/colors';

.ph-withdrawal-type-house-select-form-container {
	padding: 0 10px;
}

.ph-withdrawal-type-house-select-form-container .ant-input:focus,
.ph-withdrawal-type-house-select-form-container .ant-input:hover,
.ph-withdrawal-type-house-select-form-container .ant-input-focused,
.ph-withdrawal-type-house-select-form-container .ant-input:active {
	border-right-width: 0 !important;
	box-shadow: none !important;
	border: 1px solid rgba(255, 87, 34, 0.47843) !important;
	background-color: $primaryBackgroundColor !important;
}

.ph-withdrawal-type-house-select-form-container .ant-select-selector:focus {
	outline: none !important;
}

.ph-withdrawal-type-house-select-form-container
	.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
	.ant-select-selector
	.ant-select-selection-item {
	line-height: 44px;
	color: $colorGrayText2;
}

.ph-withdrawal-type-house-select-selector .ant-select-selector {
	max-width: 100%;
	height: 45px !important;
	border-radius: 6px !important;
	font-size: 13px !important;
}

.ph-withdrawal-type-house-select-selector.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input)
	.ant-select-selection-search-input {
	height: 44px !important;
}

.ph-withdrawal-type-house-select-selector.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
	.ant-select-selector
	.ant-select-selection-placeholder {
	height: 44px !important;
	line-height: 44px !important;
}

.ph-withdrawal-type-house-select-selector.ant-select:not(.ant-select-disabled):hover
	.ant-select-selector {
	border: 1px solid rgba(255, 87, 34, 0.47843) !important;
}

.ph-withdrawal-type-house-select-selector.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
	.ant-select-selector
	.ant-select-selection-placeholder {
	height: 44px !important;
}

.ph-withdrawal-type-house-select-field-input {
	max-width: 100%;
	height: 45px;
	border-radius: 6px;
	font-size: 13px;
	color: $colorGrayText2;
}

@media (max-width: 600px) {
	.ph-withdrawal-type-house-select-form-container {
		padding: 0;
	}
}
