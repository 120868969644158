/** @format */

@import "../../../../../../components/Commons/Sass/components/colors";
@import '../../../../../../v2-config/colors.scss';

.ph-bag-address-main-container {
  //background-color: $primaryBackgroundColor;
  transition: 0.3s ease;
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: column !important;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 3px;
}

.ph-bag-address-description-container {
  margin-top: 7px;
  margin-bottom: 30px;
  border: 1px solid $primaryColorBorder;
  border-radius: 4px;
  background-color: $generic-white;
}

.ph-bag-address-inner-container {
  position: relative;
  display: flex;
  width: 100%;
  padding: 8px 5px;
}

.ph-bag-address-image-logo-container {
  margin-right: 0;
  margin-top: -2px;
}

.ph-bag-address-image-logo {
  width: 100%;
  max-width: 18px;
}

.ph-bag-address-step-title {
  color: $colorGrayText3;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 208px;
  width: 100%;
}

.ph-bag-address-step-button,
.ph-bag-address-step-button:hover,
.ph-bag-address-step-button:focus,
.ph-bag-address-step-button:active {
  position: absolute;
  right: 3px;
  top: 3px;
  padding: 0;
  height: auto;
  line-height: 15px;
  background-color: $primaryColor;
  border-radius: 4px;
  color: $colorWhiteText;
  padding: 4px 4px 2px 4px;
  font-size: 19px;
}

.ph-bag-address-title {
  color: $colorGrayText3;
  font-size: 10px;
  margin-bottom: 0;
  line-height: 10px;
  text-overflow: ellipsis;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  width: 100%;
  max-width: 195px;
  transition: 0.3s ease;
}

.ph-bag-address-subtitle {
  font-size: 13px;
  text-overflow: ellipsis;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  color: $colorGrayText3;
  margin-bottom: 0;
  width: 100%;
  max-width: 195px;
  line-height: 18px;
  transition: 0.3s ease;
}

.ph-bag-address-title:hover {
  color: $thirdColor;
  transition: 0.3s ease;
}

.ph-bag-address-subtitle:hover {
  color: $thirdColor;
  transition: 0.3s ease;
}

.ph-bag-address-form-main-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-width: 100%;
  box-shadow: $primaryBoxShadow;
  border-radius: 4px;
}

.ph-bag-address-form-container {
  position: relative;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 0;
  width: 100%;
}

.ph-bag-address-form-container .ant-form-item {
  width: 100%;
  height: 36px;
  margin-bottom: 0;
}

.ph-bag-address-form-container .ant-form-item-has-error .ant-input:focus,
.ph-bag-address-form-container .ant-form-item-has-error .ant-input {
  border-color: $primaryColorBorder !important;
  box-shadow: none !important;
  border-right-width: 0 !important;
  font-size: 12px !important;
}

.ph-bag-address-form-search-field {
  height: 36px;
  width: 100%;
  border: 1px solid $primaryColorBorder;
  border-right-width: 0 !important;
  border-radius: 4px 0 0 4px;
  font-size: 12px;
}

.ph-bag-address-form-search-field:hover,
.ph-bag-address-form-search-field:focus,
.ant-input-focused {
  border: 1px solid $primaryColorBorder !important;
  border-color: $primaryColorBorder !important;
  border-right-width: 0 !important;
  box-shadow: none !important;
}

.ph-bag-address-form-search-field::selection {
  background: transparent !important;
}

.ph-bag-address-form-search-field-radius {
  border-radius: 4px 0 0 4px;
}

.ph-bag-address-form-search-field-no-radius {
  border-radius: 0;
}

.ph-bag-address-form-search-button {
  margin: 0;
  width: auto;
  min-width: 62px;
  height: 36px;
  border: 1px solid $primaryColor !important;
  border-radius: 0 4px 4px 0;
  background-color: $primaryColor !important;
  padding: 0 5px !important;
  color: $colorWhiteText;
}

.ph-bag-address-form-search-button .anticon {
  font-size: 20px;
  font-weight: 900;
  line-height: 40px;
}

.ph-bag-address-title-form-search-button {
  font-size: 12px;
  font-weight: 500;
  color: $colorWhiteText;
  margin-bottom: 0px;
}

.ph-bag-address-form-search-list {
  position: absolute;
  top: 105px;
  left: 44px;
  z-index: 2;
  width: 100%;
  max-width: 232px;
  box-shadow: $primaryBoxShadow;
  background-color: #fff;
}

.ph-bag-address-form-search-list-description {
  background-color: $primaryBackgroundColor !important;
  cursor: pointer;
  text-align: left;
  padding: 6px !important;
  border-bottom: 1px solid #ddd;
  font-size: 12px;
  text-overflow: ellipsis !important;
  text-decoration: none;
  overflow: hidden !important;
  white-space: nowrap;
  max-width: 100%;
  width: 100%;
  margin: 0 !important;
  height: auto !important;
  line-height: 1.5 !important;
  color: $colorGrayText4 !important;
}

.ph-bag-address-form-search-list-description:hover {
  background-color: $secondBackgroundColor !important;
}

.ph-bag-address-form-search-list-image-container {
  margin-right: 2px;
}

.ph-bag-address-on-demand-description {
  color: $errorColor;
  font-style: italic;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0;
  width: 100%;
  margin-left: 5px;
}

.ph-bag-address-on-demand-asterisk {
  color: $errorColor;
}

.ph-bag-address-form-search-list-image {
  width: 100%;
  max-width: 17px;
}

.ph-bag-address-form-container .ant-form-item-explain {
  font-size: 11px !important;
  margin-left: 14px;
}

.ph-bag-address-loading-container {
  width: 100%;
  text-align: center;
  margin-left: -45px;
  margin-bottom: 2px;
  margin-top: 10px;
}

.ph-bag-address-icon {
  color: $primaryColor;
}

.ph-bag-address-image {
  font-size: 20px;
}

.ph-bag-address-form-inner-container {
  width: 100%;
}