/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-order-detail-products-modal-receipt-link,
.ph-order-detail-products-modal-receipt-link:hover,
.ph-order-detail-products-modal-receipt-link:focus,
.ph-order-detail-products-modal-receipt-link:active {
	padding: 0;
	margin: 0;
	border: none;
	height: auto;
	color: $primaryColor;
	font-size: 13px;
	box-shadow: none;
	font-style: italic;
	font-weight: 700;
}

.ph-order-detail-products-modal-container .ant-modal-content {
	border-radius: 8px;
}

.ph-order-detail-products-modal-container .ant-modal-content .ant-modal-close {
	top: 11px;
	right: 21px;
}

.ph-order-detail-products-modal-container .ant-modal-content .ant-modal-close-x {
	border-radius: 8px;
	border: 2px solid $fourthColor;
	border-radius: 100px;
	color: $fourthColor;
	font-weight: bold;
	width: 20px;
	height: 20px;
	font-size: 12px;
	line-height: 0;
	padding: 2px;
}

.ph-order-detail-products-modal-container .ant-modal-header {
	border-bottom: 1px solid $primaryColorBorder !important;
	padding: 10px 20px;
	border-radius: 8px 8px 0 0;
}

.ph-order-detail-products-modal-container .ant-modal-header .ant-modal-title {
	font-size: 17px;
	font-weight: 900;
}

.ph-order-detail-products-modal-container .ant-modal-content .ant-modal-body {
	padding: 16px;
	text-align: center;
}

.ph-order-detail-products-modal-receipt-image {
	width: 100%;
}

.ph-order-detail-products-modal-receipt-button {
	background: $primaryColor !important;
	color: $colorWhiteText !important;
	font-size: 16px;
	font-weight: 500;
	text-transform: capitalize;
	border-radius: 4px;
	min-width: auto;
	width: 100%;
	max-width: 125px;
	border-color: transparent;
	height: 38px;
	line-height: 10px;
	box-shadow: $primaryBoxShadow;
	margin-top: 10px;
	margin-bottom: 0;
}

.ph-tracking-detail-receipt-modal-pdf-container {
	text-align: center;
}

.ph-tracking-detail-receipt-modal-pdf-icon {
	color: red;
	font-size: 100px;
	margin: 10px 0;
}

.ph-tracking-detail-receipt-modal-link,
.ph-tracking-detail-receipt-modal-link:hover,
.ph-tracking-detail-receipt-modal-link:active,
.ph-tracking-detail-receipt-modal-link:focus {
	text-decoration: underline;
	color: $primaryColor;
	margin-bottom: 20px;
	display: block;
}
