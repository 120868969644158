/** @format */
@import '../../../../../components/Commons/Sass/components/colors';

.ph-product-price-main-container {
	text-align: center;
	text-align: -webkit-center;
}

.ph-cartproducts-price-main-container {
	display: flex;
	gap: 10px;
	align-items: center;
	text-align: left !important;
	width: 100%;
	max-width: 400px;
	padding-top: 5px;
}

.ph-cartproducts-promotion-note{
	font-size: 13px;
	color: $colorGrayText4;


}

.ph-cartproducts-price-main-container-alternatives {
	position: absolute;
	left: -68px;
	width: 85px;
}

.ph-electronic-cartproducts-price-container {
	justify-content: flex-end !important;
}

.ph-product-price-container {
	display: flex;
	justify-content: center;
	align-items: baseline;
	max-width: 140px;
	//.ph-navbar-tools-container-spacer{
	//	max-width: 5px;
	//}
}

.ph-cartproducts-price-container {
	justify-content: flex-start !important;
}

@media (max-width: 600px){
	.ph-cartproducts-price-main-container {
		flex-direction: column;
		align-items: flex-start;
		max-width: none;
		gap: 0;
		padding-bottom: 9px;
	}
}

@media (max-width: 1024px) {
	.ph-cartproducts-price-main-container-alternatives {
		left: -90px;
	}
}
