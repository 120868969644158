/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-product-detail-type-recepit-container {
	display: flex;
	align-items: center;

	&:nth-child(1) {
		flex: 0.5 0.5 auto;
	}
}

.ph-product-detail-type-recepit-image-container {
	margin-right: 15px;
}

.ph-product-detail-type-recepit-image {
	width: 100%;
	max-width: 30px;
}

.ph-product-detail-type-recepit-title {
	color: $colorGrayText4;
	font-weight: 500;
	opacity: 0.8;
}

@media (max-width: 991px) {
	.ph-product-detail-type-recepit-container {
		display: flex;
		align-items: center;

		&:nth-child(1) {
			flex: 0.5 0.5 auto;
		}
	}
}
