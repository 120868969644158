/** @format */

@import '../../../../../Sass/components/colors';
@import '../../../../../../../v2-config/colors.scss';

.ph-product-price-before-symbol {
	color: $colorGrayText1;
	font-weight: 500;
	font-size: 14px;
	text-decoration: line-through;
	margin-bottom: 0;
}

.ph-product-price-before {
	color: $colorGrayText1;
	font-weight: 500;
	font-size: 14px;
	text-decoration: line-through;
	margin-bottom: 0;
	margin-right: 8px;
}

.ph-product-price-normal-symbol {
	font-size: 15px;
	color: $generic-dark-grey1;
	font-weight: 600;
	margin-bottom: 0;
}

.ph-product-price-normal {
	font-size: 20px;
	color: $generic-dark-grey1;
	font-weight: 600;
	margin: 0 5px;
}

.ph-product-price-normal-from {
	color: $colorGrayText2;
	font-size: 13px;
}

.ph-product-price-normal-home {
	font-size: 18px;
	font-weight: 700;
}
