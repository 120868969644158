/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-checkout-recipe-receipt-main-container {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	border-radius: 4px;
	padding: 2px 4px;
	float: left;
	min-width: 230px;
	border: 1px solid $primaryColorBorder;
	margin-right: 5px;
	margin-bottom: 5px;
}

.ph-checkout-recipe-receipt-image-container {
	margin-top: 3px;
	margin-right: 5px;
}

.ph-checkout-recipe-receipt-image {
	width: 100%;
	max-width: 22px;
}

.ph-checkout-recipe-receipt-title {
	font-size: 13px;
	color: $colorGrayText4;
	font-weight: 500;
	margin-bottom: 0;
	opacity: 0.8;
}

.ph-checkout-recipe-receipt-description-desktop {
	font-size: 9px;
	font-weight: 100;
	color: $colorGrayText1;
	margin-bottom: 0;
	opacity: 0.9;
}

@media (min-width: 769px) {
	.ph-checkout-recipe-receipt-description-mobile {
		display: none;
	}
}

@media (max-width: 768px) {
	.ph-checkout-recipe-receipt-description-desktop {
		display: none;
	}
	.ph-checkout-recipe-receipt-image-container {
		margin-top: -5px;
	}

	.ph-checkout-recipe-receipt-image {
		max-width: 14px;
	}

	.ph-checkout-recipe-receipt-main-container {
		border: none;
		margin-right: 0;
		margin-left: 0;
		min-width: auto;
		width: 100%;
		align-items: center;
		border-radius: unset;
		padding: 0;
	}

	.ph-checkout-recipe-receipt-inner-container {
		display: flex;
		align-items: center;
	}

	.ph-checkout-recipe-receipt-title {
		line-height: 12px;
		font-size: 11px;
	}

	.ph-checkout-recipe-receipt-description-mobile {
		color: $primaryColor;
		font-size: 10px;
		margin-bottom: 0;
		margin-left: 2px;
		margin-right: 2px;
	}
}
