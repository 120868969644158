/** @format */

@import '../../../../../../../../../../components/Commons/Sass/components/colors';

.ph-bag-pbm-carousel-slider-main-container {
	position: relative;
}

.ph-bag-pbm-carousel-slider-aproved-container {
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: rgba(255, 255, 255, 0.3);
	cursor: pointer;
}

.ph-bag-pbm-carousel-slider-container {
	box-shadow: $primaryBoxShadow;
	border: 1px solid transparent;
	margin: 5px;
	border-radius: 4px;
	padding: 10px;
	cursor: pointer;
	transition: 0.3s ease;
}

.ph-bag-pbm-carousel-slider-container:hover {
	box-shadow: $secondaryBoxShadow;
	border: 1px solid $primaryColor;
}

.ph-bag-pbm-carousel-slider-inner-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 2px 0;
}

.ph-bag-pbm-carousel-slider-image-container {
	width: 27%;
	padding: 5px;
}

.ph-bag-pbm-carousel-slider-image {
	width: 100%;
}

.ph-bag-pbm-carousel-slider-description-container {
	width: 75%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.ph-bag-pbm-carousel-slider-description-title {
	color: $colorGrayText4;
	font-size: 13px;
	font-weight: 600;
	margin-bottom: 2px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
}

.ph-bag-pbm-carousel-slider-description-subtitle {
	color: $colorGrayText2;
	font-size: 12px;
	font-weight: 500;
	margin-bottom: 0;
	height: 33px;
	line-height: 15px;
	text-transform: none;
	overflow: hidden;
}

.ph-bag-pbm-carousel-slider-description-discount {
	font-weight: 600;
	margin-bottom: 0;
	font-size: 12px;
}

.ph-bag-pbm-carousel-slider-description-inner-container {
	width: 75%;
	padding-left: 10px;
}

.ph-bag-pbm-carousel-slider-description-check-container {
	width: 25%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-bag-pbm-carousel-slider-description-check {
	border: 1px solid $primaryColorBorder;
	height: 20px;
	width: 20px;
	border-radius: 100%;
	transition: 0.3s ease;
}

.ph-bag-pbm-carousel-slider-description-check:hover {
	border: 1px solid $primaryColor;
	transition: 0.3s ease;
}

.ph-bag-pbm-carousel-arrow-prev {
	top: 55%;
	width: 23px !important;
	height: 23px !important;
	background-size: 15px 20px !important;
	left: -5px !important;
}

.ph-bag-pbm-carousel-arrow-next {
	top: 55%;
	width: 23px !important;
	height: 23px !important;
	background-size: 15px 20px !important;
	right: -5px !important;
}

.ph-bag-pbm-carousel-slider-agrement-active-container {
	border: 1px solid $successColor;
}

.ph-bag-pbm-carousel-slider-image-check {
	color: $successColor;
	font-size: 20px;
}

.ph-bag-pbm-carousel-slider-description-modal-container {
	text-align: center;
}

.ph-bag-pbm-carousel-slider-description-modal-main-title {
	color: $colorGrayText4;
	font-size: 14px;
	font-weight: 600;
}

.ph-bag-pbm-carousel-slider-description-modal-inner-container {
	border: 1px solid $primaryColorBorder;
	width: 100%;
	max-width: 300px;
	margin: auto;
	border-radius: 4px;
}

.ph-bag-pbm-carousel-slider-description-modal-inner-title {
	color: $colorGrayText3;
	margin-bottom: 0;
}

.ph-bag-pbm-carousel-slider-description-modal-inner-subtitle {
	color: $primaryColor;
	margin-bottom: 0;
}

.ph-bag-pbm-carousel-slider-description-modal-inner-discount {
	color: $secondColor;
}

.ph-bag-pbm-carousel-slider-description-modal-inner-actions {
	font-style: italic;
	font-size: 13px;
	width: 100%;
	max-width: 300px;
	margin: auto;
	margin-bottom: 10px;
}

.ph-bag-pbm-carousel-slider-description-modal-cancel-button,
.ph-bag-pbm-carousel-slider-description-modal-cancel-button:active,
.ph-bag-pbm-carousel-slider-description-modal-cancel-button:focus,
.ph-bag-pbm-carousel-slider-description-modal-cancel-button:hover {
	padding: 0;
	height: auto;
	line-height: 15px;
	font-weight: 300;
	background-color: transparent;
	border-radius: 4px;
	color: $primaryColor;
	padding: 0 30px;
	font-size: 15px;
	height: 35px;
	margin-right: 10px;
}

.ph-bag-pbm-carousel-slider-description-modal-aceptar-button,
.ph-bag-pbm-carousel-slider-description-modal-aceptar-button:active,
.ph-bag-pbm-carousel-slider-description-modal-aceptar-button:focus,
.ph-bag-pbm-carousel-slider-description-modal-aceptar-button:hover {
	margin-left: 10px;
	padding: 0;
	height: auto;
	line-height: 15px;
	font-weight: 300;
	background-color: $primaryColor;
	border-radius: 4px;
	color: $colorWhiteText;
	padding: 0 30px;
	font-size: 15px;
	height: 35px;
}
