/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-order-detail-products-item-price-total-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-order-detail-products-item-price-total-title {
	font-size: 12px;
	color: $colorGrayText4;
	font-weight: 500;
	margin-bottom: 0;
}

.ph-order-detail-products-item-price-total-subtitle {
	font-size: 12px;
	color: $primaryColor;
	font-weight: 700;
	margin-left: 3px;
	margin-right: 3px;
	margin-bottom: 0;
}

.ph-order-detail-products-item-price-total-description {
	font-size: 12px;
	color: $colorGrayText4;
	font-weight: 500;
	margin-bottom: 0;
}

.ph-order-detail-products-item-price-total-symbol {
	font-size: 12px;
	color: $colorGrayText4;
	font-weight: 700;
	margin-top: 2px;
	margin-left: 5px;
	margin-bottom: 0;
}

.ph-order-detail-products-item-price-total {
	font-size: 14px;
	color: $colorGrayText4;
	font-weight: 700;
	margin-bottom: 0;
}
