/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-objected-recipe-input-global-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: 10px;
	width: 100%;
}

.ph-objected-recipe-delivery-selector .ant-select-selector {
	width: 400px !important;
	height: 45px !important;
	border-radius: 6px !important;
	font-size: 13px !important;
}

.ph-objected-recipe-delivery-hour-selector .ant-select-selector {
	width: 400px !important;
	height: 45px !important;
	border-radius: 6px !important;
	font-size: 13px !important;
	margin-left: 12px;
}

.ph-objected-recipe-delivery-selector
	.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input)
	.ant-select-selection-search-input {
	height: 44px !important;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
	line-height: 45px;
}

.ph-objected-recipe-loading-container {
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-objected-recipe-loading-icon {
	color: $primaryColor;
	margin-top: 0;
}

.ph-objected-recipe-loading-image {
	font-size: 30px;
}

@media (max-width: 768px) {
	.ph-objected-recipe-delivery-selector .ant-select-selector,
	.ph-objected-recipe-delivery-hour-selector .ant-select-selector {
		width: 350px !important;
	}
}

@media (max-width: 576px) {
	.ph-objected-recipe-delivery-selector .ant-select-selector,
	.ph-objected-recipe-delivery-hour-selector .ant-select-selector {
		width: 250px !important;
	}
}

@media (max-width: 480px) {
	.ph-objected-recipe-input-global-container {
		display: block;
		margin-top: 20px;
	}
	.ph-objected-recipe-delivery-selector .ant-select-selector,
	.ph-objected-recipe-delivery-hour-selector .ant-select-selector {
		width: 100% !important;
		margin: 0px;
	}
}
