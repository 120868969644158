@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,500');
@import '../../../_config/colors/global.scss';

.own-card-product-desktop {
	cursor: pointer;
	max-width: 224px;
	margin: 5px;
	min-height: 362px;
	min-width: 150px;
	align-items: flex-start;
	flex-wrap: wrap;
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.05) 0 0 0 1px;

	.ant-card-body {
		padding: 10px !important;
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.own-image-icons-container {
		margin-bottom: 20px;

		.own-card-icons-container {
			.ant-image {
				z-index: 5;

				.ant-image-img {
					width: 26px;
					height: 26px;
				}
			}

			.own-card-icons-sub-container {
				position: absolute;
				bottom: 0;

				.ant-image {
					margin-top: 5px;

					.ant-image-img {
						width: 24px;
						height: 24px;
					}
				}
			}
		}

		.own-favorite-container {
			.ant-btn-icon-only {
				width: 24px;
				height: 24px;
			}
			.ph-product-favorite-actions-image-container {
				padding: 0;
			}

			.own-favorite {
				border: none;
				box-shadow: none;
				background-color: transparent;
				display: grid;

				svg {
					width: 24px;
					height: 24px;
					justify-self: flex-end;
				}
			}

			.own-favorite:hover {
				color: $secondColor;
			}

		}
	}

	.own-laboratory-label {
		color: $colorGrayText1;
		font-family: Ubuntu;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		min-height: 20px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.own-product-title {
		width: 100%;
		height: 35px;
		color: $colorGrayText4;
		font-family: Ubuntu;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		margin-bottom: 8px;
		text-align: left;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	.own-stock {
		color: $colorGrayText4;
		font-family: Ubuntu;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;

		span {
			color: $successColor;
		}
	}

	.own-promotion-prices-container {
		display: flex;
		flex-direction: column;
		margin-top: 17px;


		.own-since {
			@extend .own-stock;
			min-height: 16.2px;
		}

		.own-mecanic {
			//background-color: $mecanicColor;
			//color: $color3;
			background-color: $color3;
			color: $mecanicColor;
			display: flex;
			width: fit-content;
			min-width: 100px;
			padding-left: 10px;
			padding-right: 10px;
			height: 22px;
			justify-content: center;
			align-items: center;
			text-align: center;
			font-family: Ubuntu;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			border-radius: 2px;
			margin-bottom: 0;

		}

		.mecanic-none{
			min-height: 16.2px;
		}



		.own-mecanic-none {
			@extend .own-mecanic;
			background-color: $color3 !important;
			min-height: 23.2px;

		}

		.own-price-container {
			display: flex;
			height: 52px;
			justify-content: center;
			align-items: center;
			gap: 5px;

			.own-promotion-price {
				margin-bottom: 0;
				width: 100%;
				text-align: center;
				color: $colorGrayText1;
				font-family: Ubuntu;
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px;
				height: 24px;
				text-decoration-line: line-through;

			}


			.own-promotion-price-none {
				@extend .own-promotion-price;
			}

			.promotion-none{
				min-height: 15px;
			}

			.own-final-price {
				display: flex;
				justify-content: center;
				align-items: flex-end;
				width: 100%;
				text-align: center;
				color: $colorGrayText4;
				font-family: Ubuntu;
				font-size: 18px;
				font-style: normal;
				font-weight: 700;
				line-height: 26px;
				//position: absolute
				position: relative;
				bottom: 0;

				span {
					font-size: 13px;
					font-weight: 400;
					line-height: 20px;
					padding-bottom: 2px;
				}
			}
		}
	}

}