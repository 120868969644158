/** @format */

@import "../../../../../Sass/components/colors";

.ph-cart-inner-notification-main-container {
  width: 400px;
  padding: 10px;
  border-radius: 4px;
  box-shadow: $secondaryBoxShadow;
}

.ph-cart-inner-notification-main-container .ant-notification-notice-message {
  margin: 0;
}

.ph-cart-inner-notification-main-container .ant-notification-notice-close {
  top: 11px;
  right: 18px;
}

.ph-cart-inner-notification-main-container .ant-notification-close-icon,
.ph-cart-inner-notification-main-container .ant-notification-notice-close-icon {
  color: $colorGrayText1;
  font-weight: bold;
  width: 20px;
  height: 20px;
  font-size: 16px;
  line-height: 0;
  padding: 2px;
}

.ph-cart-inner-notification-main-title-container {
  border-bottom: 1px solid $primaryColorBorder;
  width: 95%;
  margin: auto;
  padding-bottom: 7px;
}

.ph-cart-inner-notification-main-title {
  color: $primaryColor;
  font-weight: 500;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
}

.ph-cart-inner-notification-description-main-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.ph-cart-inner-notification-image-main-container {
  text-align: center;
}

.ph-cart-inner-notification-image-container {
  margin-top: 10px;
  display: block;
  padding: 5px;
}

.ph-cart-inner-notification-image {
  width: 100%;
  max-width: 50px;
}

.ph-cart-inner-notification-title {
  font-size: 15px;
  padding-right: 0;
  font-weight: 900;
  color: $colorGrayText4;
  line-height: normal;
  margin-top: 0px;
  margin-left: 5px;
  margin-bottom: 5px;
  text-transform: capitalize;
  text-overflow: ellipsis;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  max-width: 95%;
  width: 100%;
}

.ph-cart-inner-notification-pharmacy {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ph-cart-inner-notification-pharmacy-image-container {
  margin: 0 5px;
}

.ph-cart-inner-notification-pharmacy-image {
  width: 100%;
  max-width: 15px;
}

.ph-cart-inner-notification-pharmacy-title {
  margin-top: 3px;
  margin-bottom: 0;
  font-size: 12px;
  color: $colorGrayText4;
  font-weight: 500;
  font-style: italic;
}

.ph-cart-inner-notification-quantity-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 5px;
}

.ph-cart-inner-notification-quantity-title {
  color: $primaryColor;
  font-size: 14px;
  font-weight: 300;
  opacity: 0.7;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 20px;
}

.ph-cart-inner-notification-quantity-description {
  font-weight: 900 !important;
  color: $primaryColor;
  font-size: 14px;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 3px;
  line-height: 20px;
}

.ph-cart-inner-notification-price-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 5px;
}

.ph-cart-inner-notification-price-title {
  font-size: 13px;
  line-height: 12px;
  text-align: left;
  opacity: 0.7;
  color: $colorGrayText4;
  font-weight: 500;
  margin-bottom: 3px;
}

.ph-cart-inner-notification-price-description {
  font-size: 13px;
  line-height: 12px;
  text-align: left;
  opacity: 0.7;
  color: $colorGrayText4;
  font-weight: 900;
  margin-bottom: 3px;
  margin-left: 5px;
}

.ph-cart-inner-notification-separator {
  box-shadow: none;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 1px;
  background-color: $primaryColorBorder;
  border: none;
  width: 94%;
}

.ph-cart-inner-notification-response-withdrawal-image-container {
  margin-left: 7px;
}

.ph-cart-inner-notification-response-withdrawal-image {
  width: 100%;
  max-width: 30px;
}

.ph-cart-inner-notification-loading-container .ant-spin-spinning .anticon-spin {
  font-size: 13px !important;
  color: $primaryColor;
}

.ph-cart-inner-notification-total-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
  padding: 0 5px;
}

.ph-cart-inner-notification-total-image-container {
  margin-right: 1px;
}

.ph-cart-inner-notification-total-image {
  width: 100%;
  max-width: 19px;
}

.ph-cart-inner-notification-total-title {
  font-size: 12px;
  color: $colorGrayText4;
  opacity: 1;
  font-weight: 500;
  margin-top: 4px;
  margin-bottom: 0;
}

.ph-cart-inner-notification-total-subtitle {
  color: $primaryColor;
  font-size: 12px;
  margin-top: 4px;
  margin-left: 2px;
  margin-bottom: 0;
  font-weight: 900;
}

.ph-cart-inner-notification-total-description {
  font-weight: 900;
  font-size: 12px;
  color: $colorGrayText4;
  margin-top: 4px;
  margin-left: 4px;
  margin-bottom: 0;
}
