.reserve-history-content {
	width: 100%;
	max-width: 1185px;
	margin: 35px auto;

	.pointer {
		cursor: pointer;
	}
}

.spin-container {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
}
