/** @format */

@import '../../../Commons/Sass/components/colors';
@import '../../../../v2-config/colors.scss';

.ph-elastic-filter-facet-panel-container {
	position: relative;
	width: 97%;
	margin: 4px;
	padding: 10px;
	min-height: 120px;
	box-shadow: $primaryBoxShadow;
	border-radius: 4px;
	background-color: $generic-white;
}

.ph-elastic-filter-facet-panel-inner-container {
	border: 1px solid $primaryColorBorder;
	border-radius: 4px;
	padding-top: 8px;
	padding-bottom: 8px;
}

.ph-elastic-filter-facet-panel-inner-container .ant-collapse {
	background-color: #ffffff;
	border: none;
	border-radius: 4px;
	box-shadow: none;
}

.ph-elastic-filter-facet-panel-inner-container .ant-collapse>.ant-collapse-item {
	border: none;
	background-color: #fff;
	position: relative;
}

.ph-elastic-filter-facet-panel-inner-container .ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header {
	padding: 8px 40px 8px 20px;
	font-size: 13px;
	color: $colorGrayText2;
}

.ph-elastic-filter-facet-panel-inner-container .ant-collapse-content {
	border: none;
}

.ph-elastic-filter-facet-panel-inner-container .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
	font-size: 9px;
}

.ph-elastic-filter-facet-panel-inner-container .sui-facet__title {
	display: none;
}

.ph-elastic-filter-facet-panel-inner-container .ant-collapse-content>.ant-collapse-content-box {
	padding: 0 17px 0 17px;
}

.ph-elastic-filter-facet-panel-inner-container .sui-facet-search {
	margin-top: 0;
}

.ph-elastic-filter-facet-element {
	position: relative;
	border-radius: 4px;
}

.ph-elastic-filter-facet-element .sui-facet-search__text-input {
	font-size: 12px;
	height: 26px;
	border: 1px solid $primaryColor;
}

.ph-elastic-filter-facet-element .sui-facet-search__text-input::placeholder {
	font-size: 12px;
	color: #fff;
}

.ph-elastic-filter-facet-element-icon {
	position: absolute;
	right: 0;
	background-color: $primaryColor;
	color: $colorWhiteText;
	border-radius: 0 4px 4px 0;
	padding: 6px;
	cursor: pointer;
}

.ph-elastic-filter-facet-element input[type='checkbox'] {
	accent-color: $primaryColor;
	border-radius: 10px;
	width: 11px;
	height: 11px;
	outline: none;
}

.ph-elastic-filter-facet-element .sui-multi-checkbox-facet__input-text {
	font-size: 12px;
	color: $colorGrayText1;
	text-transform: capitalize;
	position: relative;
	top: -1px;
}

.ph-elastic-filter-facet-element .sui-multi-checkbox-facet__option-label {
	margin-bottom: 10px;
}

.ph-elastic-filter-facet-element .sui-multi-checkbox-facet {
	margin-top: 10px;
	padding-top: 5px;
	padding-left: 3px;
	padding-right: 3px;
	padding-bottom: 5px;
	max-height: 215px;
	overflow-y: scroll;
}

.ph-elastic-filter-facet-element .sui-multi-checkbox-facet__option-count {
	background-color: #f4f4f4;
	color: #888;
	font-size: 10px;
	padding: 1px 7px;
	border-radius: 4px;
	min-width: 30px;
	text-align: center;
}

.ph-elastic-filter-facet-panel-inner-container .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header {
	padding: 8px 40px 8px 20px;
	font-size: 13px;
	color: #888888;
}

@media (max-width: 992px) {
	.ph-elastic-filter-facet-panel-container {
		margin: 0;
		width: 100%;
		box-shadow: none;
	}
}