/** @format */

@import '../../../../../../Sass/components/colors';
@import '../../../../../../../../v2-config/generic-colors';

.ph-sidebar-button {
	margin: 0;
	text-align: center;
	cursor: pointer;
}

.ph-sidebar-actions-icon-container {
	display: block;
}

.ph-sidebar-actions-icon {
	width: 100%;
	max-width: 33px;
	fill: #fff
}

.ph-header-mobile-sidebar-icon-main-title {
	color: $colorGrayText1;
	font-size: 8px;
	font-weight: 500;
	text-transform: uppercase;
	text-align: center;
	line-height: 7px;
	margin-top: 3px;
	margin-bottom: 0;
}

.ph-header-mobile-sidebar-icon-main-title-arg {
	@extend .ph-header-mobile-sidebar-icon-main-title;
	color: $colorWhiteText;
}

.ph-sidebar-button-description {
	margin-bottom: 0;
	color: $colorGrayText2;
	font-size: 8px;
	font-weight: 900;
	text-transform: uppercase;
	text-align: center;
	line-height: 7px;
	margin-top: 3px;
}

.ph-sidebar-collapse-global-container {
	background-color: $primaryBackgroundColor !important;
}

.ph-sidebar-collapse-global-container.ant-collapse {
	border: none !important;
}

.ph-sidebar-main-container .ant-drawer-body {
	padding: 0;
	display: flex !important;
	justify-content: space-between !important;
	flex-direction: column !important;
}

.ph-sidebar-collapse-main-container .ant-collapse-header {
	padding: 15px 35px 7px 15px !important;
	background-color: $primaryColor !important;
}

.ph-sidebar-collapse-main-container .ant-collapse-content {
	border-top: none !important;
}

.ph-sidebar-collapse-main-container .ant-collapse-content-box {
	padding: 0;
}

.ph-sidebar-collapse-main-container .anticon {
	top: 25px !important;
}

.ph-sidebar-collapse-main-container .anticon svg {
	font-size: 9px;
	color: $colorGrayText4;
}

.ph-sidebar-collapse-container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.ph-sidebar-collapse-close-icon{
    position: absolute;
	right: 10px;
    top: 10px;
	svg{
		width: 20px;
		height: 20px;
		color: #fff!important;
	}
}
.ph-sidebar-collapse-image {
	width: 100%;
	max-width: 50px;
	background-color: $primaryBackgroundColor;
	border-radius: 100%;
	box-shadow: $primaryBoxShadow;
}

.ph-sidebar-collapse-title {
	margin-top: 0;
	margin-bottom: 0;
	margin-left: 10px;
	font-size: 13px;
	font-weight: 400;
	color: $colorWhiteText;
}

.ph-sidebar-collapse-item-title-separator {
	margin-top: 0;
	margin-bottom: 0;
	margin-left: 10px;
	font-size: 13px;
	font-weight: 400;
	color: $colorWhiteText;
	text-transform: capitalize;
}

.ph-sidebar-collapse-subtitle {
	margin-top: 0;
	margin-bottom: 0;
	margin-left: 10px;
	font-size: 15px;
	font-weight: 500;
	color: $colorWhiteText;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
	max-width: 200px;
}

.ph-sidebar-collapse-item-container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 10px;
	color: $colorGrayText3;
	font-size: 14px;
	font-weight: 100;
	line-height: 25px;
}

.ph-sidebar-collapse-item-icon {
	color: $colorWhiteText;
	margin: 0 12px;
	background-color: $colorGrayText4;
	border-radius: 100%;
	padding: 3px;
}

.ph-sidebar-collapse-item-title {
	color: $colorWhiteText;
	margin-left: 10px;
	font-weight: 500;
	// text-decoration: underline;
	// font-style: italic;
}

.ph-sidebar-collapse-item-icon svg {
	color: $colorWhiteText !important;
	font-size: 10px;
}

.ph-sidebar-collapse-item-container-2 {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 10px;
	color: $colorGrayText4;
	font-size: 14px;
	font-weight: 500;
	line-height: 25px;
	background-color: #fff;
}

.ph-sidebar-collapse-item-icon-2 {
	color: $colorGrayText4;
	margin-top: -3px;
	margin-left: 12px;
	margin-right: 5px;
	background-color: $primaryBackgroundColor;
	padding: 3px;
	font-size: 16px !important;
	color: $primaryColor !important;

	path {
		stroke: $primaryColor !important;
	}

}

.ph-sidebar-collapse-main-container-2 {
	border-bottom: none !important;
}

.ph-sidebar-collapse-main-container-2 .ant-collapse-header {
	padding: 8px 35px 8px 10px !important;
	background-color: $primaryBackgroundColor !important;
}

.ph-sidebar-collapse-main-container-2 .ant-collapse-content {
	border-top: none !important;
}

.ph-sidebar-collapse-main-container-2 .ant-collapse-content .ant-collapse-content-box {
	padding: 3px 25px;
}

.ph-sidebar-collapse-item-container-3 {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	color: $colorGrayText4;
	font-size: 14px;
	font-weight: 500;
	line-height: 25px;
	background-color: $primaryBackgroundColor;
}

.ph-sidebar-categories-container {
	background-color: $primaryBackgroundColor;
	border-right: none;
}

.ph-sidebar-categories-description-container {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	padding: 7px 5px !important;
	height: auto !important;
	line-height: 28px !important;
	background-color: $primaryBackgroundColor !important;
}

.ph-sidebar-categories-title {
	color: $colorGrayText3 !important;
	font-size: 14px;
	font-weight: 100;
}

.ph-sidebar-categories-title:hover {
	color: $thirdColor !important;
}

.ph-sidebar-categories-image-container {
	margin-right: 5px;
}

.ph-sidebar-categories-image {
	width: 100%;
	max-width: 30px;
}

.ph-sidebar-pharmacies-image-container {
	margin-left: 0;
	margin-right: 12px;
}

.ph-sidebar-pharmacies-image {
	width: 100%;
	max-width: 15px;
}

.ph-sidebar-collapse-item-container-4 {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 10px;
	color: $colorGrayText4;
	font-size: 14px;
	font-weight: 500;
	line-height: 25px;
	background-color: $primaryBackgroundColor;
}

.ph-sidebar-collapse-menu-image-container {
	margin-top: -3px;
	margin-left: 13px;
	margin-right: 10px;
}

.ph-sidebar-collapse-menu-image {
	width: 15px;
}

.ph-sidebar-collapse-item-container-5 {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 10px;
	color: $colorGrayText4;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 600;
	line-height: 25px;
	background-color: $primaryBackgroundColor;
}

.ph-sidebar-address-container {
	margin-left: 20px;
	color: $colorGrayText3;
	font-size: 10px;
	overflow: visible;
	text-overflow: ellipsis;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	transition: 0.2s ease;
	margin-top: 20px;
	cursor: pointer;
}

.ph-sidebar-address-image-logo-container {
	margin-right: 2px;
}

.ph-sidebar-address-image-logo {
	width: 25px;
	margin-top: 3px;
}

.ph-sidebar-address-description-1 {
	font-size: 13px;
	font-weight: 100;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	margin-bottom: 0;
	width: 100%;
	max-width: 100%;
}

.ph-sidebar-address-description-2 {
	font-size: 14px;
	font-weight: 400;
	margin-top: -3px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
	max-width: 100%;
}

.ph-sidebar-collapse-image-logo-main-container {
	position: absolute;
	bottom: 10px;
	right: 10px;
}

.ph-sidebar-collapse-image-logo-container {
	padding: 4px;
	position: relative;
	right: 0;
}

.ph-sidebar-collapse-image-logo {
	width: 100%;
	max-width: 80px;
}

.ph-sidebar-header-main-container {
	background-color: $primaryColor !important;
	padding: 12px 15px;
	position: relative;
	padding-bottom: 45px;
}

.ph-sidebar-mobile-title-drawer-container{
	display: flex;
	svg{
		width: 20px;
		display: flex;
		height: 21.5px;
		justify-content: center;
		align-items: center;
		gap: 8px;
		flex: 1 0 0;
	}
	.ph-sidebar-drawer-title{
		padding: 0 8px 0 16px;
		color: $generic-dark-grey1;
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; 
		margin: 0;
	}

}

.ph-mobile-drawer-container-items{
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 6px 8px;
	.ph-mobile-drawer-text-items{
		color:   $generic-dark-grey2;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
	}

	svg{
		height: 18px;
		margin-right: 8px;
	}
	&:active{
		color: #2E72B7;
		background-color: #E6F9FA;
		border-left: solid 2px #2E72B7;
	}
}


