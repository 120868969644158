/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-retirement-services-loading-modal .ant-modal-header {
	display: none;
}

.ph-retirement-services-loading-modal .ant-modal-close {
	display: none;
}

.ph-retirement-services-loading-modal .ant-modal-body {
	text-align: center;
}

.ph-retirement-services-loading-modal .ant-modal-content {
	border-radius: 8px;
}

.ph-retirement-services-loading-modal-title {
	margin-bottom: 5px;
	color: $colorGrayText3;
	font-weight: 500;
}

.ph-retirement-services-loading-modal-subtitle {
	margin-bottom: 20px;
	color: $primaryColor;
	font-weight: 600;
}

.ph-retirement-services-loading-modal-image-container {
	padding: 0;
	margin: 0;
}

.ph-retirement-services-loading-modal-image {
	position: absolute;
	width: 100%;
	max-width: 40%;
	top: 0;
	left: 0;
	right: 0;
	margin: auto;
	bottom: 0;
	border-radius: 100px;
	opacity: 0.1;
}
