/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-service-detail-global-container {
	max-width: 1200px;
	padding: 10px;
	margin: auto;
}

.ph-service-detail-main-container {
	padding: 14px;
}

.ph-service-main-image-global-container {
	box-shadow: $primaryBoxShadow;
	padding: 10px;
	border-radius: 4px;
	position: relative;
	margin: 7px;
}

.ph-service-detail-tabs-main-container {
	box-shadow: $primaryBoxShadow;
	border-radius: 4px;
	margin: 8px;
	width: 98%;
}

@media (max-width: 992px) {
	.ph-service-detail-main-container {
		padding: 0;
	}

	.ph-service-main-image-global-container {
		margin: 7px 0;
		box-shadow: none;
	}

	.ph-service-detail-tabs-main-container {
		margin: 8px 0;
		width: 100%;
	}
}

@media (max-width: 600px) {
	.ph-service-main-image-global-container {
		padding: 0;
	}
}
