/** @format */

@import "../../../../components/Commons/Sass/components/colors";

.ph-update-password-global-container {
  max-width: 1185px;
  padding: 10px;
  margin: auto;
}

.ph-update-password-main-container {
  padding: 14px;
}

.ph-update-password-container {
  padding: 20px;
  border-radius: 4px;
  box-shadow: $primaryBoxShadow;
}

.ph-update-password-inner-container {
  max-width: 400px;
  margin: 20px auto 20px;
  padding: 30px;
  width: 100%;
  border: 1px solid $primaryColorBorder;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 260px;
}

.ph-update-password-form-container {
  width: 100%;
  text-align: center;
}

.ph-update-password-field-input {
  max-width: 375px;
  height: 45px;
  border-radius: 6px;
  font-size: 13px;
}

.ph-update-password-button-container {
  margin-bottom: 0;
}

.ph-update-password-button {
  background: $primaryColor !important;
  color: $colorWhiteText !important;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  border-radius: 4px;
  min-width: auto;
  width: 100%;
  max-width: 125px;
  border-color: transparent;
  height: 38px;
  line-height: 10px;
  box-shadow: $primaryBoxShadow;
}

.ph-update-password-description-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ph-update-password-description {
  color: $colorGrayText4;
  font-weight: 500;
  font-size: 16px;
  font-style: italic;
  max-width: 400px;
}

@media (max-width: 892px) {
  .ph-update-password-description {
    padding: 20px;
    max-width: 100%;
  }
}

@media (max-width: 575px) {
  .ph-update-password-global-container {
    min-height: calc(100vh - 198px);
  }
  .ph-update-password-main-container {
    padding: 0;
  }

  .ph-update-password-title-container {
    margin-top: 0;
  }

  .ph-update-password-main-title {
    font-size: 22px;
  }

  .ph-update-password-main-subtitle {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .ph-update-password-inner-container {
    min-height: auto;
    padding: 20px;
  }

  .ph-update-password-description {
    padding: 10px;
    font-size: 12px;
  }
}
