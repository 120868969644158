/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-electronic-quote-pharmacy-main-title-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;
}

.ph-electronic-quote-pharmacy-main-title-inner-container {
	padding: 0 5px;
}

.ph-electronic-quote-pharmacy-main-title {
	color: $primaryColor;
	font-weight: 500;
	opacity: 0.9;
	font-size: 18px;
	padding: 0;
	margin: 0;
}

.ph-electronic-quote-pharmacy-main-subtitle {
	color: $colorGrayText3;
	font-weight: 400;
	font-style: italic;
	opacity: 0.9;
	font-size: 12px;
	padding: 0;
	margin: 0;
}

.ph-electronic-quote-pharmacy-input-container .ant-input-number-input {
	height: 22px;
	font-size: 12px;
	padding: 0 11px 0 7px;
	color: $colorGrayText3;
}

.ph-electronic-quote-pharmacy-input-container.ant-input-number {
	border-radius: 4px;
	margin-top: 3px !important;
	width: 55px;
}

.ph-electronic-quote-pharmacy-slider-container.ant-slider {
	margin: 4px;
}

.ph-electronic-quote-pharmacy-slider-container .ant-slider-track {
	background-color: $primaryColor;
}

.ph-electronic-quote-pharmacy-slider-container .ant-slider-handle {
	border: solid 2px $primaryColor;
	width: 12px;
	height: 12px;
	background-color: $primaryColor;
}

.ph-electronic-quote-pharmacy-slider-container.ant-slider:hover .ant-slider-track {
	background-color: $primaryColor;
}

.ph-electronic-quote-pharmacy-slider-container.ant-slider:hover
	.ant-slider-handle:not(.ant-tooltip-open) {
	border-color: $primaryColor;
}
.ph-electronic-quote-dropdown-container {
	width: 100%;
	max-width: 155px;
}

.ph-electronic-quote-pharmacy-title {
	color: $colorGrayText2;
	margin-bottom: 0;
	font-size: 12px;
}

.ph-electronic-quote-pharmacy-main-title-container .ant-dropdown-trigger > .anticon.anticon-down {
	font-size: 7px !important;
}

.ph-electronic-quote-dropdown-inner-container .ant-dropdown-menu-item {
	font-size: 13px !important;
	color: $colorGrayText4;
}

.ph-electronic-quote-pharmacy-description {
	color: $colorGrayText3;
	font-size: 13px;
	padding-right: 5px;
	padding-bottom: 5px;
	border-bottom: 1px solid $colorGrayText2;
}

.ph-electronic-quote-pharmacy-list-global-container {
	max-height: 406px;
	overflow-y: auto;
}

.ph-electronic-quote-pharmacy-no-list {
	padding: 2px;
}

.ph-electronic-quote-pharmacy-is-list {
	padding: 8px;
	border: 1px solid $primaryColorBorder;
	border-radius: 4px;
}

.ph-electronic-quote-pharmacy-list-main-container {
	border-radius: 4px;
	box-shadow: $primaryBoxShadow;
	padding: 7px 10px;
	margin: 10px 0px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-electronic-quote-pharmacy-list-main-container:first-child {
	margin-top: 0;
}

.ph-electronic-quote-pharmacy-list-main-container:last-child {
	margin-bottom: 0;
}

.ph-electronic-quote-pharmacy-list-image-cenabast {
	width: 100%;
	max-width: 20px;
}

.ph-electronic-quote-pharmacy-list-image-ondemand-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-electronic-quote-pharmacy-list-image-ondemand-title {
	font-weight: 600;
	color: $colorGrayText3;
	margin-bottom: 0;
	font-size: 13px;
	line-height: 10px;
}

.ph-electronic-quote-pharmacy-list-image-ondemand-inner-container {
	margin-left: 5px;
	margin-top: -4px;
}

.ph-electronic-quote-pharmacy-list-image-ondemand {
	width: 100%;
	max-width: 16px;
}

.ph-electronic-quote-pharmacy-list-pharmacy-main-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-electronic-quote-pharmacy-list-pharmacy-inner-container {
	width: 100%;
	max-width: 210px;
}

.ph-electronic-quote-pharmacy-list-pharmacy-link-detail {
	width: 100%;
	max-width: 230px;
}

.ph-electronic-quote-pharmacy-list-pharmacy-stock-dispatch-inner-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-electronic-quote-pharmacy-list-pharmacy-geo-inner-container {
	min-width: 60px;
	text-align: center;
}

.ph-electronic-quote-pharmacy-list-pharmacy-container {
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}

.ph-electronic-quote-pharmacy-list-pharmacy-icons-container {
	max-width: 20px;
	position: absolute;
	top: 10px;
	left: 10px;
	display: flex;
}

.ph-electronic-quote-pharmacy-list-image-container {
	margin-right: 5px;
	border-radius: 50px;
	width: 100%;
	max-width: 25px;
	min-width: 25px;
}

.ph-electronic-quote-pharmacy-list-image {
	width: 100%;
	max-width: 20px;
}

.ph-electronic-quote-pharmacy-list-pharmacy-title {
	font-size: 12px;
	color: $colorGrayText3;
	font-weight: 500;
	margin-bottom: 0;
}

.ph-electronic-quote-pharmacy-list-pharmacy-title-stock-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.ph-electronic-quote-pharmacy-on-demand-button,
.ph-electronic-quote-pharmacy-on-demand-button:hover,
.ph-electronic-quote-pharmacy-on-demand-button:active,
.ph-electronic-quote-pharmacy-on-demand-button:focus {
	background-color: $primaryColor;
	border-color: $primaryColor;
	line-height: 33px;
	color: $colorWhiteText;
	margin: 0;
	font-size: 15px;
	border-radius: 4px;
	font-weight: 600;
	text-transform: uppercase;
	height: 35px;
	padding: 0 18px;
	box-shadow: $mainHeaderboxShadow;
}

.ph-electronic-quote-pharmacy-on-demand-button[disabled] {
	background-color: $thirdBackgroundColor !important;
}

.ph-electronic-quote-pharmacy-no-results-container {
	border: 1px solid $primaryColorBorder;
	border-radius: 4px;
	width: 100%;
	text-align: center;
	padding: 10px;
}

.ph-electronic-quote-pharmacy-no-results-image-container {
	width: 100%;
	max-width: 60px;
	opacity: 0.5;
}

.ph-electronic-quote-pharmacy-no-results-title {
	color: $colorGrayText3;
	margin-bottom: 0;
}

.ph-electronic-quote-pharmacy-no-results-subtitle {
	margin-bottom: 5px;
	color: $colorGrayText2;
	font-style: italic;
	font-size: 12px;
}

@media (max-width: 1060px) {
	.ph-electronic-quote-pharmacy-list-pharmacy-title {
		font-size: 13px;
	}
	.ph-electronic-quote-pharmacy-list-image-container {
		margin-right: 0;
	}
	.ph-electronic-quote-pharmacy-list-main-container {
		padding: 7px;
	}
}

@media (max-width: 992px) {
	.ph-electronic-quote-pharmacy-list-main-container {
		padding: 5px 10px;
	}
	.ph-electronic-quote-pharmacy-main-title-inner-container {
		text-align: center;
		margin-bottom: 10px;
	}

	.ph-electronic-quote-pharmacy-input-slider-container {
		padding: 0 10px;
	}
}

@media (max-width: 670px) {
	.ph-electronic-quote-pharmacy-list-pharmacy-main-container {
		display: block;
	}
}

@media (max-width: 600px) {
	.ph-electronic-quote-pharmacy-list-global-container {
		max-height: 265px;
		min-height: 265px;
	}

	.ph-electronic-quote-pharmacy-list-image {
		max-width: 13px;
	}

	.ph-electronic-quote-pharmacy-list-image-container {
		text-align: center;
	}

	.ph-electronic-quote-pharmacy-main-title {
		font-size: 15px;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.ph-electronic-quote-pharmacy-main-title-inner-container {
		text-align: center;
		margin-bottom: 0;
	}
}

@media (max-width: 575px) {
	.ph-electronic-quote-pharmacy-list-main-container {
		padding: 5px;
	}

	.ph-electronic-quote-pharmacy-list-image-cenabast {
		max-width: 16px;
	}

	.ph-electronic-quote-pharmacy-list-image-ondemand {
		max-width: 16px;
	}

	.ph-electronic-quote-pharmacy-list-pharmacy-title-stock-container {
		display: block;
		text-align: right;
	}

	.ph-electronic-quote-pharmacy-on-demand-button,
	.ph-electronic-quote-pharmacy-on-demand-button:hover,
	.ph-electronic-quote-pharmacy-on-demand-button:active,
	.ph-electronic-quote-pharmacy-on-demand-button:focus {
		padding: 8px 15px;
	}
}

@media (max-width: 500px) {
	.ph-electronic-quote-pharmacy-list-pharmacy-title {
		font-size: 11px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
}

@media (max-width: 380px) {
	.ph-electronic-quote-pharmacy-description {
		font-size: 11px;
	}

	.ph-electronic-quote-pharmacy-main-title-container {
		display: block;
		text-align: center;
	}

	.ph-electronic-quote-pharmacy-dropdown-inner-container {
		width: 100%;
		max-width: 120px;
		margin: auto;
	}

	.ph-electronic-quote-pharmacy-main-title {
		text-align: left;
	}

	.ph-electronic-quote-dropdown-container {
		margin: auto;
	}
}
