/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-order-detail-products-observation-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-order-detail-products-observation-title-aproved {
	font-size: 13px;
	color: $successColor;
	font-weight: 500;
	margin-bottom: 0;
}

.ph-order-detail-products-observation-icon-aproved {
	margin-left: 5px;
	font-size: 13px;
	color: $successColor;
}

.ph-order-detail-products-observation-title-refused {
	font-size: 13px;
	color: $errorColor;
	font-weight: 500;
	margin-bottom: 0;
}

.ph-order-detail-products-observation-icon-refused {
	margin-left: 3px;
	font-size: 13px;
	color: $errorColor;
}
