/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-bag-pbm-main-container {
	border-top: 1px solid $primaryColorBorder;
	margin-top: 10px;
	padding-top: 10px;
	padding-right: 25px;
}

.ph-bag-pbm-main-title {
	color: $primaryColor;
	opacity: 0.8;
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 5px;
}

.ph-bag-pbm-image-container {
	padding: 5px;
	max-width: 35px;
}

.ph-bag-pbm-image {
	width: 100%;
}

.ph-bag-pbm-main-description {
	color: $colorGrayText4;
	opacity: 0.8;
	font-weight: 500;
	font-size: 13px;
	font-weight: 100;
	line-height: 17px;
	margin-bottom: 0;
	padding: 0 5px;
}

.ph-bag-pbm-image-check {
	color: $successColor;
	margin-left: 10px;
	font-size: 15px;
}

.ph-bag-pbm-button-add-container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-bag-pbm-button-add,
.ph-bag-pbm-button-add:hover,
.ph-bag-pbm-button-add:focus,
.ph-bag-pbm-button-add:active {
	border: none !important;
	box-shadow: none !important;
	padding: 0 !important;
	margin: 0 !important;
	color: $primaryColor !important;
	font-size: 13px;
}

.ph-bag-pbm-button-add span {
	text-decoration: underline;
}

.ph-bag-pbm-list-modal-container .ant-modal-header {
	border-radius: 8px 8px 0 0;
	padding: 10px 15px;
}

.ph-bag-pbm-list-modal-container .ant-modal-content {
	border-radius: 8px;
}

.ph-bag-pbm-list-modal-container .ant-modal-content .ant-modal-close {
	top: 11px;
	right: 21px;
}

.ph-bag-pbm-list-modal-container .ant-modal-content .ant-modal-close-x {
	border-radius: 8px;
	border: 2px solid $fourthColor;
	border-radius: 100px;
	color: $fourthColor;
	font-weight: bold;
	width: 20px;
	height: 20px;
	font-size: 12px;
	line-height: 0;
	padding: 2px;
}

.ph-bag-pbm-list-modal-container .ant-modal-body {
	padding: 10px;
}

.ph-bag-pbm-list-modal-container .ant-modal-footer {
	padding: 0;
	border-top: none;
}

.ph-bag-pbm-covenant-list-container {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $primaryBackgroundColor;
	border: 1px solid $colorWhiteBorder;
	border-radius: 4px;
	margin-bottom: 10px;
	padding: 10px;
	transition: 0.3s ease;
}

.ph-bag-pbm-covenant-list-container:hover {
	border: 1px solid rgba(45, 114, 183, 0.4);
	background-color: $secondBackgroundColor;
}

.ph-bag-pbm-covenant-list-container:last-child {
	margin-bottom: 0;
}

.ph-bag-pbm-covenant-list-title {
	color: $primaryColor;
	margin-bottom: 0;
	font-weight: 100;
	font-size: 15px;
}

.ph-bag-pbm-covenant-list-subtitle {
	font-weight: 500;
}

.ph-bag-pbm-covenant-list-id {
	margin-bottom: 0;
	color: $colorGrayText3;
	font-size: 14px;
	font-weight: 100;
	line-height: 18px;
}

.ph-bag-pbm-covenant-list-subid {
	font-weight: 500;
}

.ph-bag-pbm-covenant-list-lab {
	margin-bottom: 0;
	color: $colorGrayText3;
	font-size: 14px;
	font-weight: 100;
	line-height: 18px;
}

.ph-bag-pbm-covenant-list-sublab {
	font-weight: 500;
}

.ph-bag-pbm-covenant-list-discount {
	margin-bottom: 0;
	color: $colorGrayText3;
	font-size: 14px;
	font-weight: 100;
	line-height: 18px;
}

.ph-bag-pbm-covenant-list-subdiscount {
	font-weight: 500;
}

.ph-bag-pbm-covenant-list-button,
.ph-bag-pbm-covenant-list-button:hover,
.ph-bag-pbm-covenant-list-button:active,
.ph-bag-pbm-covenant-list-button:focus {
	height: auto;
	line-height: 15px;
	font-weight: 300;
	background-color: $primaryColor;
	border-radius: 4px;
	color: $colorWhiteText;
	padding: 0 10px;
	font-size: 15px;
	height: 35px;
}

.ph-bag-pbm-form-modal-container .ant-modal-header {
	border-radius: 8px 8px 0 0;
	padding: 10px 15px;
}

.ph-bag-pbm-form-modal-container .ant-modal-header {
	border-radius: 8px 8px 0 0;
	padding: 10px 15px 10px 45px;
}

.ph-bag-pbm-form-modal-container-main-image-container {
	position: absolute;
	top: 8px;
	left: 8px;
}

.ph-bag-pbm-form-modal-container-main-image {
	width: 100%;
	max-width: 25px;
}

.ph-bag-pbm-form-modal-container .ant-modal-content {
	border-radius: 8px;
	position: relative;
}

.ph-bag-pbm-form-modal-container .ant-modal-content .ant-modal-close {
	top: 11px;
	right: 21px;
}

.ph-bag-pbm-form-modal-container .ant-modal-content .ant-modal-close-x {
	border-radius: 8px;
	border: 2px solid $fourthColor;
	border-radius: 100px;
	color: $fourthColor;
	font-weight: bold;
	width: 20px;
	height: 20px;
	font-size: 12px;
	line-height: 0;
	padding: 2px;
}

.ph-bag-pbm-form-modal-container .ant-modal-body {
	padding: 10px 20px;
}

.ph-bag-pbm-form-modal-container .ant-modal-footer {
	padding: 0;
	border-top: none;
}

.ph-bag-pbm-form-modal-main-title {
	margin-bottom: 2px;
	color: $colorGrayText3;
	padding: 0 5px;
	font-size: 13px;
}

.ph-bag-pbm-covenant-form-button-container {
	width: 100%;
	text-align: center;
	margin-bottom: 10px;
}

.ph-bag-pbm-form-modal-container .ant-form-item {
	margin-bottom: 18px;
}

.ph-bag-pbm-form-modal-container .ant-form-item-explain-error,
.ph-bag-pbm-form-modal-container .ant-form-item-explain-success {
	font-size: 12px;
	padding: 0 5px;
	min-height: 10px;
	position: absolute;
	bottom: -18px;
	left: 5px;
}

.ph-bag-pbm-covenant-form-button,
.ph-bag-pbm-covenant-form-button:hover,
.ph-bag-pbm-covenant-form-button:active,
.ph-bag-pbm-covenant-form-button:focus {
	padding: 0;
	height: auto;
	line-height: 15px;
	font-weight: 300;
	background-color: $primaryColor;
	border-radius: 4px;
	color: $colorGrayText0;
	padding: 0 30px;
	font-size: 15px;
	height: 35px;
}

.ph-bag-pbm-agree-modal-container .ant-modal-header {
	border-radius: 8px 8px 0 0;
	padding: 10px 15px;
	text-align: center;
}

.ph-bag-pbm-agree-modal-container .ant-modal-content {
	border-radius: 8px;
}

.ph-bag-pbm-agree-modal-container .ant-modal-content .ant-modal-close {
	top: 11px;
	right: 21px;
	display: none !important;
}

.ph-bag-pbm-agree-modal-container .ant-modal-content .ant-modal-close-x {
	border-radius: 8px;
	border: 2px solid $fourthColor;
	border-radius: 100px;
	color: $fourthColor;
	font-weight: bold;
	width: 20px;
	height: 20px;
	font-size: 12px;
	line-height: 0;
	padding: 2px;
}

.ph-bag-pbm-agree-modal-container .ant-modal-body {
	padding: 10px 20px;
}

.ph-bag-pbm-agree-modal-container .ant-modal-footer {
	padding: 0;
	border-top: none;
}

.ph-bag-pbm-agree-modal-description-container {
	padding: 10px;
	color: $colorGrayText3;
}

.ph-bag-pbm-agree-modal-checkbox {
	padding: 0 11px;
	margin-bottom: 16px;
}

.ph-bag-pbm-agree-modal-checkbox-description {
	color: $colorGrayText4;
}

.ph-bag-pbm-agree-modal-container .ant-form-item-explain.ant-form-item-explain-error,
.ph-bag-pbm-agree-modal-container .ant-form-item-explain.ant-form-item-explain-success {
	font-size: 13px;
	min-height: 20px;
	position: absolute;
	bottom: -15px;
	left: 25px;
}

.ph-bag-pbm-agree-modal-container .ant-form-item {
	margin-bottom: 0;
}

.ph-bag-pbm-agree-modal-button-container {
	width: 100%;
	text-align: center;
	margin-bottom: 10px;
}

.ph-bag-pbm-form-modal-loading-container {
	min-height: 150px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-bag-pbm-form-modal-loading-inner-container {
	text-align: center;
}

.ph-bag-pbm-form-modal-loading-image-container {
	padding: 5px;
	margin-bottom: 15px;
}

.ph-bag-pbm-form-modal-loading-image {
	color: $primaryColor;
}

.ph-bag-pbm-form-modal-loading-wait-title {
	color: $colorGrayText4;
	font-style: italic;
}

.ph-bag-pbm-covenant-form-benefict-link-title {
	color: $colorGrayText3;
	margin-bottom: 15px;
	padding: 0 10px;
}

.ph-bag-pbm-covenant-form-benefict-link,
.ph-bag-pbm-covenant-form-benefict-link:hover {
	color: $primaryColor;
	text-decoration: underline;
}

@media (max-width: 400px) {
	.ph-bag-pbm-covenant-list-container {
		display: block;
		text-align: center;
	}

	.ph-bag-pbm-covenant-list-inner-container {
		margin-bottom: 15px;
	}
}
