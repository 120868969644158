/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-order-detail-products-item-promotion-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-order-detail-products-item-promotion-image-container {
	line-height: 1px;
}

.ph-order-detail-products-item-promotion-image {
	width: 100%;
	max-width: 12px;
}

.ph-order-detail-products-item-promotion-title {
	color: $primaryColor;
	font-weight: 500;
	font-size: 13px;
	margin-left: 4px;
	margin-bottom: 0;
	text-overflow: ellipsis;
	text-decoration: none;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
	max-width: 600px;
	margin-bottom: 0;
}
