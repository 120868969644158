/** @format */

@import '../Sass/components/colors';

.ph-carouselbanner {
	position: absolute;
	top: 50%;
	margin-top: -10px;
	cursor: pointer;
	background: $thirdBackgroundColor;
	color: $colorWhiteText;
	border-radius: 100%;
	font-size: 15px;
	padding: 0px 4px;
	z-index: 1;
}

.ph-arrow-right {
	right: 10px;
}

.ph-arrow-left {
	left: 10px;
}

.ph-carouselbanner-container .slick-slide a {
	box-shadow: $secondaryBoxShadow;
}

.ph-carouselbanner-cart .slick-list {
	border-radius: 4px;
}

.ph-carouselbanner-image {
	width: 100%;
}

.ph-carouselbanner-mobile-container {
	margin: 10px 10px 0 10px;
	border-radius: 8px;
	overflow: hidden;
}
