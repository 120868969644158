/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-order-detail-global-container {
	max-width: 1185px;
	padding: 10px;
	margin: auto;
}

.ph-order-detail-main-container {
	padding: 14px;
}

.ph-order-detail-inner-container {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
}

.ph-order-detail-title-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: 15px;
}

.ph-order-detail-title-image-container {
	margin-right: 7px;
}

.ph-order-detail-title-image {
	width: 100%;
	max-width: 25px;
}

.ph-order-detail-main-title {
	color: $colorGrayText4;
	font-weight: 600;
	font-size: 23px;
	margin-bottom: 0;
}

.ph-order-detail-main-subtitle {
	font-weight: 500;
	font-size: 17px;
	margin-bottom: 20px;
	color: $primaryColor;
	margin-left: 35px;
}

.ph-order-detail-resend-email-button,
.ph-order-detail-resend-email-button:active,
.ph-order-detail-resend-email-button:focus,
.ph-order-detail-resend-email-button:hover {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $primaryColor !important;
	color: $colorWhiteText;
	padding: 5px 10px;
	font-size: 15px;
	border-radius: 4px;
	font-weight: 500;
	box-shadow: $primaryBoxShadow;
	border: 2px solid $primaryColor;
	text-transform: capitalize;
	height: 36px;
}

.ph-order-detail-resend-email-button .anticon {
	line-height: 0 !important;
}

.ph-order-detail-resend-email-button-title {
	margin-left: 4px;
	margin-bottom: 0;
	color: $colorWhiteText;
	font-size: 15px;
}

.ph-order-detail-purchase-title-container {
	position: relative;
	width: 100%;
	margin-bottom: 10px;
}

.ph-order-detail-repurchase-button {
	position: absolute;
	top: 0;
	right: 0;
}

.ph-order-detail-repurchase-button .anticon {
	line-height: 0 !important;
}

.ph-order-detail-repurchase-button-title {
	margin-left: 4px;
	margin-bottom: 0;
	color: $colorWhiteText;
	font-size: 15px;
}

@media (max-width: 992px) {
	.ph-order-detail-title-info-delivery {
		display: none;
	}
}

@media (max-width: 575px) {
	.ph-order-detail-main-container {
		padding: 0;
	}

	.ph-order-detail-title-container {
		margin-top: 50px;
	}

	.ph-order-detail-main-title {
		font-size: 18px;
	}

	.ph-order-detail-main-subtitle {
		font-size: 15px;
		margin-bottom: 10px;
	}

	.ph-order-detail-inner-container {
		margin-bottom: 10px;
	}

	.ph-order-detail-resend-email-button,
	.ph-order-detail-resend-email-button:active,
	.ph-order-detail-resend-email-button:focus,
	.ph-order-detail-resend-email-button:hover {
		position: absolute;
		top: 5px;
		right: 5px;
	}

	.ph-order-detail-resend-email-button-title {
		font-size: 14px;
	}
}

@media (max-width: 400px) {
	.ph-order-detail-repurchase-button,
	.ph-order-detail-repurchase-button:active,
	.ph-order-detail-repurchase-button:focus,
	.ph-order-detail-repurchase-button:hover {
		padding: 5px 7px;
		font-size: 13px;
	}

	.ph-order-detail-repurchase-button-title {
		font-size: 13px;
	}
}
