/** @format */

@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,500');
@import '../../../v2-config/colors.scss';
@import '../../../_config//colors//global';

a,
body,
h1,
h2,
h3,
h4,
h5,
h6,
html,
p,
span {
	font-family: Ubuntu, sans-serif !important;
	-webkit-font-smoothing: antialiased;
}

#root {
	position: relative;
	min-height: calc(100vh - 22px);
	background-color: $generic-background-grey;
}

::-webkit-scrollbar {
	width: 12px;
}

::-webkit-scrollbar-thumb {
	background: $greyAntd;
	border-radius: 8px;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgba(228, 228, 228, 1);
	border-radius: 8px;
	opacity: 0.1;
}

::-webkit-scrollbar-button:single-button {
	background-color: rgba(45, 114, 183, 0.7);
	display: block;
	border-radius: 8px;
	border-style: solid;
	height: 13px;
	width: 12px;
}

::-webkit-scrollbar-button:single-button:vertical:decrement {
	border-width: 4px 2px 4px 2px;
	border-color: transparent;
	background-image: url('https://upload.wikimedia.org/wikipedia/commons/7/7e/Chevron-up.svg');
	background-repeat: no-repeat;
	background-size: 8px;
}

::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
	opacity: 0.2 !important;
}

::-webkit-scrollbar-button:single-button:vertical:increment {
	border-width: 4px 2px 4px 2px;
	border-color: transparent;
	background-image: url('https://upload.wikimedia.org/wikipedia/commons/e/ee/Chevron-down.svg');
	background-repeat: no-repeat;
	background-size: 8px;
}

::-webkit-scrollbar-button:vertical:single-button:increment:hover {
	opacity: 0.2 !important;
}