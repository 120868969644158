/** @format */

@import '../../../../../../../../../../../../components/Commons/Sass/components/colors';

.ph-product-detail-quote-promotion-title {
	font-weight: 900;
	font-size: 12px;
	color: $primaryColor;
	margin-bottom: 0;
}

.ph-product-detail-quote-promotion-container {
	padding: 0 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 9px;
}

.ph-product-detail-quote-promotion-icon-container {
	margin-right: 5px;
}

.ph-product-detail-quote-promotion-icon {
	width: 10px;
}

@media (max-width: 575px) {
	.ph-product-detail-quote-promotion-container {
		display: none;
	}
}
