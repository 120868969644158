/** @format */

@import "../../Sass/components/colors";

.ph-notification-favorites-access-global-container {
  padding: 10px 15px;
  border-radius: 4px;
}

.ph-notification-favorites-access-global-container
  .ant-notification-notice-message {
  margin-bottom: 0 !important;
}

.ph-notification-favorites-access-title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid $primaryColorBorder;
  padding-bottom: 6px;
}

.ph-favorites-notify-image-container {
  padding: 3px;
}

.ph-favorites-notify-image {
  width: 100%;
  max-width: 17px;
}

.ph-notification-favorites-access-title {
  margin-bottom: 0;
  color: $primaryColor;
  font-size: 15px;
}

.ph-notification-favorites-access-description-container {
  border-bottom: 1px solid $primaryColorBorder;
}

.ph-notification-favorites-access-description {
  margin-bottom: 0;
  padding: 10px;
  color: $colorGrayText4;
  font-size: 14px;
}

.ph-notification-favorites-access-global-container
  .ant-notification-notice-close {
  top: 15px;
  right: 15px;
}

.ph-notification-favorites-access-actions-container {
  margin-top: 15px;
  margin-bottom: 5px;
}

.ph-notification-favorites-access-button-login,
.ph-notification-favorites-access-button-login:hover,
.ph-notification-favorites-access-button-login:active,
.ph-notification-favorites-access-button-login:focus {
  background-color: $primaryColor;
  color: $colorWhiteText;
  border: none !important;
  border-radius: 4px !important;
}

.ph-notification-favorites-access-button-register,
.ph-notification-favorites-access-button-register:hover,
.ph-notification-favorites-access-button-register:active,
.ph-notification-favorites-access-button-register:focus {
  background-color: $primaryBackgroundColor;
  color: $primaryColor;
  border: 1px solid $primaryColor;
  border-radius: 4px !important;
}

.ph-notification-favorites-access-actions-col {
  text-align: center;
}

.ph-notification-favorites-access-global-container
  .ant-notification-close-icon {
  color: $colorGrayText1;
  font-weight: bold;
  width: 20px;
  height: 20px;
  font-size: 16px;
  line-height: 0;
  padding: 2px;
}
