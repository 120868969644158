/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-complaint-info-card {
	background-color: $primaryBackgroundColor;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	border-radius: 5px;
	box-shadow: $primaryBoxShadow;
	margin: 10px;
	padding: 15px;
	transition: 0.4s ease;
	cursor: pointer;
}

.ph-complaint-info-card:hover {
	background-color: $thirdBackgroundColor;
	box-shadow: $primaryBoxShadow;
	transition: 0.3s ease;
}

.ph-complaint-info-image-container {
	padding: 5px;
}

.ph-complaint-info-image {
	width: 100%;
	max-width: 100px;
	margin: auto;
}

.ph-complaint-info-description-container {
	padding: 0 10px;
}

.ph-complaint-info-card-title {
	font-weight: 500;
	font-size: 16px;
	color: $colorGrayText4;
	margin-bottom: 0;
	opacity: 0.9;
}

.ph-complaint-info-card-description {
	font-size: 12px;
	color: $colorGrayText4;
	margin-top: 5px;
	margin-bottom: 10px;
	opacity: 0.9;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 1;
	display: -webkit-box;
	height: 55px;
}

@media (max-width: 1070px) {
	.ph-complaint-info-card {
		padding: 15px 5px;
	}

	.ph-complaint-info-description-container {
		padding: 0 5px;
	}
}

@media (max-width: 575px) {
	.ph-complaint-info-card {
		padding: 10px 5px;
		margin: 5px 0;
	}

	.ph-complaint-info-card-description {
		height: auto;
		margin-bottom: 5px;
	}
}
