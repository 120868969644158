/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-bag-allies-info-container {
	text-align: center;
	margin-top: 30px;
}

.ph-bag-allies-info-title {
	color: $colorGrayText4;
	font-size: 15px;
	font-weight: 600;
	margin-bottom: 0;
	line-height: 22px;
}

.ph-bag-allies-info-description {
	color: $colorGrayText3;
	font-size: 13px;
	font-weight: 500;
	margin-bottom: 0;
}

.ph-bag-allies-info-link,
.ph-bag-allies-info-link:hover,
.ph-bag-allies-info-link:active,
.ph-bag-allies-info-link:focus {
	color: $primaryColor;
	font-size: 13px;
	font-weight: 600;
	margin-bottom: 0;
	text-decoration: underline;
}

@media (max-width: 992px) {
	.ph-bag-allies-info-container {
		margin-top: 10px;
	}
}
