/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-bag-price-info-container {
	max-width: 240px;
	margin: auto;
}

.ph-bag-price-title-primary {
	color: #000;
	font-size: 14px;
	font-weight: 900;
}

.ph-bag-price-title-second {
	color: $colorGrayText4;
	font-size: 14px;
	font-weight: 900;
}

.ph-bag-price-title-third {
	color: $colorGrayText4;
	font-size: 13px;
	font-weight: 500;
}

.ph-bag-price-primary {
	color: $primaryColor;
	font-size: 17px;
}

.ph-bag-price-title-color {
	color: $colorGrayText2;
	font-size: 15px;
}

.ph-bag-price-info-total-price {
	padding: 0;
	line-height: 20px;
	margin-bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-bag-price-info-total-price-info-symbol {
	font-size: 14px;
	margin-right: 3px;
}

@media (max-width: 992px) {
	.ph-bag-price-info-total-price {
		display: block;
	}

	.ph-bag-price-info-total-price {
		font-size: 13px;
	}

	.ph-bag-price-primary {
		font-size: 15px;
	}
}
