/** @format */

@import '../../../../../../../components/Commons/Sass/components/colors';

.ph-product-detail-quote-description-title-container {
	border-bottom: 1px solid $primaryColorBorder;
}

.ph-product-detail-quote-description-title {
	border-right: 1px solid $primaryColorBorder;
	font-size: 13px;
	font-weight: 500;
	color: $colorGrayText4;
	padding: 11px 12px;
	margin-bottom: 0;
	height: 100%;
	display: flex;
	justify-content: flex-start;
}

.ph-product-detail-quote-description-subtitle {
	font-size: 13px;
	font-weight: 500;
	color: $colorGrayText4;
	opacity: 0.7;
	padding: 11px 10px;
	margin-bottom: 0;
	height: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-product-detail-quote-empty-field {
	color: $primaryColor;
	opacity: 0.6;
}

@media (max-width: 1024px) {
	.ph-product-detail-quote-description-title {
		font-size: 11px;
	}

	.ph-product-detail-quote-description-subtitle {
		font-size: 11px;
	}
}
