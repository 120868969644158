/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-product-detail-contact-container {
	margin-top: 0px;
	margin-bottom: 0;
	padding-bottom: 10px;
	padding: 10px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	border-top: 1px solid $secondBackgroundColor;
}

.ph-product-detail-contact-image-container {
	padding: 0px 5px;
	display: block;
}

.ph-product-detail-contact-image {
	width: 100%;
	max-width: 20px;
}

.ph-product-detail-contact-icon {
	color: #a1a1a1;
	margin-right: 4px;
}

.ph-product-detail-contact-title {
	font-size: 13px;
	color: $colorGrayText4;
	font-weight: 100;
	margin-bottom: 0;
}

.ph-product-detail-contact-link {
	color: $primaryColor;
	font-weight: 600;
}

.ph-product-detail-contact-link:hover,
.ph-product-detail-contact-link:active,
.ph-product-detail-contact-link:focus {
	color: $primaryColor;
	font-weight: 600;
	text-decoration: underline;
}

@media (max-width: 575px) {
	.ph-product-detail-contact-title {
		font-size: 12px;
	}
}
