/** @format */

@import '../../../../../../../../../../components/Commons/Sass/components/colors';

.ph-product-detail-quote-mediclic-container {
	margin-bottom: 0;
	padding-bottom: 10px;
	padding: 5px 10px 5px 10px;
}

.ph-product-detail-quote-mediclic-inner-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-product-detail-quote-mediclic-image-container {
	margin: 0 15px 15px;
}

.ph-product-detail-quote-mediclic-image {
	width: 100px;
	max-width: 100px;
}

.ph-product-detail-quote-mediclic-title {
	font-size: 13px;
	color: $colorGrayText2;
	font-weight: 500;
	margin-top: 1px;
	margin-right: 25px;
}

.ph-product-detail-quote-mediclic-subtitle {
	font-weight: 100;
	color: $colorGrayText1;
	font-size: 12px;
}

@media (max-width: 575px) {
	.ph-product-detail-quote-mediclic-image-container {
		margin: 0 5px;
	}

	.ph-product-detail-quote-mediclic-image {
		max-width: 75px;
	}

	.ph-product-detail-quote-mediclic-title {
		font-size: 12px;
	}

	.ph-product-detail-quote-mediclic-subtitle {
		font-size: 11px;
	}
}
