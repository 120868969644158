/** @format */

@import '../../../../../../components/Commons/Sass/components/colors.scss';

.ph-bag-electronic-cart-no-producs-main-container {
	box-shadow: $primaryBoxShadow;
	min-height: 200px;
	padding: 20px 30px;
	border-radius: 4px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 5px;
}

.ph-bag-electronic-cart-no-producs-container {
	text-align: center;
}

.ph-bag-electronic-cart-no-products-image {
	width: 100%;
	max-width: 80px;
	opacity: 0.4;
}

.ph-bag-electronic-cart-no-products-title {
	color: $secondColor;
	font-weight: 500;
	font-size: 15px;
	opacity: 0.5;
	margin-bottom: 0;
}

.ph-bag-electronic-cart-no-products-subtitle {
	color: $colorGrayText4;
	font-weight: 500;
	font-size: 13px;
	opacity: 0.5;
	font-style: italic;
}