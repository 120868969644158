/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-objected-recipe-upload-image-container .ant-upload.ant-upload-select-picture-card {
	width: 100%;
	margin-top: 0;
	border: 1px dashed $primaryColorBorder;
	height: 250px;
	background-color: $secondBackgroundColor;
	line-height: 1.5715;
	vertical-align: top;
}

.ph-objected-recipe-upload-image-container .ant-upload.ant-upload-select-picture-card:hover {
	border: 1px dashed $secondColor;
}

.ph-objected-recipe-upload-component-main-container {
	width: 100%;
	margin-top: 0;
}

.ph-objected-recipe-upload-component-title-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-objected-recipe-upload-component-title-icon {
	margin-right: 5px;
	color: $colorGrayText2;
	font-size: 20px;
}

.ph-objected-recipe-upload-component-title {
	color: $colorGrayText2;
	margin-bottom: 0;
	font-size: 15px;
}

.ph-objected-recipe-upload-component-subtitle {
	font-weight: 900;
}

.ph-objected-recipe-upload-component-main-container {
	margin-top: 10px;
}

.ph-objected-recipe-upload-component-list-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.ph-objected-recipe-upload-component-img-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-left: 20px;
}

.ph-objected-recipe-upload-component-list {
	margin-bottom: 0;
}

.ph-objected-recipe-upload-component-list li {
	text-align: left;
	color: $colorGrayText2;
	font-size: 12px;
	margin-bottom: 2px;
}

.ph-objected-recipe-upload-component-image {
	width: 100%;
	max-width: 100px;
	opacity: 0.6;
}

.ph-objected-recipe-upload-image-container .ant-upload-list-picture-card-container {
	width: 100%;
	height: 250px;
}

.ph-objected-recipe-upload-image-container .ant-upload-list-picture-card .ant-upload-list-item {
	border: 1px solid $primaryColorBorder;
	height: 250px;
}

.ph-checkout-receipt-modal-container .ant-modal-content {
	border-radius: 8px;
}

.ph-checkout-receipt-modal-container .ant-modal-content .ant-modal-close {
	top: 11px;
	right: 21px;
}

.ph-checkout-receipt-modal-container .ant-modal-content .ant-modal-close-x {
	border: 2px solid $secondColor;
	border-radius: 100px;
	color: $secondColor;
	font-weight: bold;
	width: 20px;
	height: 20px;
	font-size: 12px;
	line-height: 0;
	padding: 2px;
}

.ph-checkout-receipt-modal-container .ant-modal-header {
	border-bottom: 1px solid $primaryColorBorder !important;
	padding: 10px 20px;
	border-radius: 8px 8px 0 0;
}

.ph-checkout-receipt-modal-container .ant-modal-header .ant-modal-title {
	font-size: 17px;
	font-weight: 900;
}

.ph-checkout-receipt-modal-container .ant-modal-content .ant-modal-body {
	padding: 16px;
}

@media (max-width: 768px) {
	.ph-objected-recipe-upload-component-list-container {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.ph-objected-recipe-upload-component-img-container {
		padding-left: 0px;
	}
}

@media (max-width: 576px) {
	.ph-objected-recipe-upload-component-list {
		padding: 0px;
	}
	.ph-objected-recipe-upload-component-list li {
		font-size: 10px;
	}
}
