/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-order-detail-profile-title-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 5px;
}

.ph-order-detail-profile-title-image-container {
	padding: 3px;
	margin-right: 3px;
}

.ph-order-detail-profile-title-image {
	width: 100%;
	max-width: 25px;
}

.ph-order-detail-profile-title {
	color: $colorGrayText3;
	font-weight: 600;
	font-size: 20px;
	margin-top: 3px;
	margin-bottom: 0;
}

@media (max-width: 575px) {
	.ph-order-detail-profile-title {
		font-size: 15px;
	}
}
