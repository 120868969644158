/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-userdata-info-container {
	padding: 10px 20px;
	border-bottom: 1px solid $primaryColorBorder;
}

.ph-userdata-info-container:last-child {
	border-bottom: none;
}

.ph-userdata-info-title {
	color: $colorGrayText3;
	opacity: 0.7;
	font-size: 13px;
	margin-bottom: 0;
	font-weight: 600;
}

.ph-userdata-info-description {
	color: $colorGrayText4;
	font-size: 13px;
	margin-bottom: 0;
	opacity: 0.9;
}

.ph-userdata-info-no-description {
	color: $colorGrayText4;
	font-size: 13px;
	margin-bottom: 0;
	opacity: 0.6;
}

@media (max-width: 575px) {
	.ph-userdata-info-container {
		padding: 5px 10px;
	}
}