/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-bag-electronic-loading-container {
	height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: $primaryBoxShadow;
	border-radius: 4px;
	margin: 4px;
}

.ph-bag-electronic-loading-icon {
	color: $primaryColor;
}

.ph-bag-electronic-loading-image {
	font-size: 30px;
}
