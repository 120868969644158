/** @format */

@import '../../../../../../../../../../components/Commons/Sass/components/colors';

.ph-product-detail-quote-dispatch-info-main-container:last-child {
	margin-right: 0;
}

.ph-product-detail-quote-dispatch-info-image-container {
	margin-right: 5px;
}

.ph-product-detail-quote-dispatch-info-image {
	width: 100%;
	max-width: 25px;
	min-width: 25px;
	opacity: 0.9;
}

.ph-product-detail-quote-dispatch-info-title {
	font-size: 12px;
	text-align: left;
	color: $colorGrayText3;
	font-weight: 900;
	margin-bottom: 0;
	line-height: 16px;
}

.ph-product-detail-quote-dispatch-info-link {
	color: $colorGrayText4;
	opacity: 1;
	font-weight: 500;
}

.ph-product-detail-quote-dispatch-info-subtitle-dispatch {
	font-size: 14px;
	text-align: center;
	color: $primaryColor;
	font-weight: 600;
	opacity: 1;
	cursor: pointer;
	justify-content: center;
}

.ph-product-detail-quote-dispatch-info-subtitle-dispatch-disabled {
	font-size: 14px;
	text-align: left;
	color: $colorGrayText4;
	font-weight: 600;
	margin-bottom: 0px;
	opacity: 0.3;
}

.ph-product-detail-quote-dispatch-info-subtitle-pharmacy {
	font-size: 14px;
	text-align: left;
	color: $colorGrayText4;
	font-weight: 500;
	margin-bottom: 0px;
	//line-height: 10px;
	opacity: 1;
}

.ph-product-detail-quote-dispatch-info-unavailable {
	color: $colorGrayText1;
	font-size: 14px;
	font-weight: 400;
	display: flex;
	width: 100%;
}

.ph-calculate-delivery-modal-container .ant-modal-content {
	border-radius: 8px;
}

.ph-calculate-delivery-modal-container .ant-modal-content .ant-modal-close {
	top: 9px;
	right: 12px;
}

.ph-calculate-delivery-modal-container .ant-modal-content .ant-modal-close-x {
	border: 2px solid $fourthColor;
	border-radius: 100px;
	color: $fourthColor;
	font-weight: bold;
	width: 20px;
	height: 20px;
	font-size: 12px;
	line-height: 0;
	padding: 2px;
}

.ph-calculate-delivery-modal-container .ant-modal-header {
	border-bottom: 1px solid $primaryColorBorder !important;
	padding: 7px 10px;
	border-radius: 8px 8px 0 0;
}

.ph-calculate-delivery-modal-container .ant-modal-header .ant-modal-title {
	font-size: 14px;
	font-weight: 900;
}

.ph-calculate-delivery-modal-container .ant-modal-content .ant-modal-body {
	padding: 10px;
}

.ph-calculate-delivery-modal-container .ant-modal-content .ant-modal-footer {
	display: none;
}

.ph-calculate-delivery-modal-title-container {
	background-color: $primaryBackgroundColor;
}

.ph-calculate-delivery-modal-title {
	color: $colorGrayText4;
	font-weight: 600;
	font-size: 13px;
	margin-bottom: 0;
}

.ph-calculate-delivery-modal-description {
	color: $primaryColor;
	font-weight: 500;
	font-size: 12px;
	margin-bottom: 0;
	font-style: italic;
}

.ph-repurchase-form-address-link-profile-address-container {
	width: 100%;
	text-align: center;
}

.ph-repurchase-form-address-link-profile-address {
	color: $primaryColor !important;
	text-decoration: underline !important;
	font-size: 12px;
}

.ph-repurchase-form-address-component-container {
	width: 100%;
	max-width: 320px;
	margin: auto;
	position: relative;
}

.ph-repurchase-form-address-result-delivery-container {
	border: 1px solid $primaryColorBorder;
	border-radius: 4px;
	margin: 10px auto;
	width: 100%;
	max-width: 380px;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: flex-end;
}

.ph-repurchase-form-address-result-delivery-info-container {
	display: flex;
	justify-content: left;
	align-items: flex-start;
	width: 75%;
}

.ph-repurchase-form-address-result-delivery-info-title {
	margin-bottom: 0;
	color: $colorGrayText4;
	font-size: 14px;
}

.ph-repurchase-form-address-result-delivery-info-message {
	margin-bottom: 0;
	color: $colorGrayText4;
	font-size: 12px;
}

.ph-repurchase-form-address-result-delivery-info-day {
	text-transform: capitalize;
	color: $primaryColor;
	font-size: 13px;
	margin-bottom: 0;
}

.ph-repurchase-form-address-result-delivery-info-date {
	color: $colorGrayText3;
	font-style: italic;
	font-size: 11px;
	margin-bottom: 0;
}

.ph-repurchase-form-address-result-delivery-info-date-span {
	color: $errorColor;
	margin-right: 3px;
}

.ph-repurchase-form-address-result-delivery-price-title {
	font-size: 13px;
	color: $colorGrayText3;
	margin-bottom: 0;
	text-align: right;
}

.ph-repurchase-form-address-result-delivery-price-total {
	margin-bottom: 0;
	color: $colorGrayText4;
	font-size: 15px;
	font-weight: 600;
	text-align: right;
}

.ph-repurchase-form-address-result-delivery-price-container {
	width: 25%;
}

.ph-repurchase-form-address-info-preloading-container {
	position: relative;
	border: 1px solid $primaryColorBorder;
	border-radius: 4px;
	padding: 10px;
	width: 100%;
	max-width: 380px;
	margin: 10px auto;
}

.ph-repurchase-form-address-info-preloading-title {
	text-align: center;
	font-style: italic;
	color: $colorGrayText3;
	margin-bottom: 0;
	font-size: 13px;
}

.ph-repurchase-form-address-info-preloading-subtitle {
	color: $primaryColor;
}

.ph-repurchase-form-address-info-loading-container {
	opacity: 0.8;
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;
	text-align: center;
	background-color: $primaryBackgroundColor;
	border-radius: 4px;
}

.ph-repurchase-form-address-info-loading-icon {
	margin-top: 7px;
}

.ph-repurchase-form-calculate-button-container {
	width: 100%;
	border-top: 1px solid $primaryColorBorder;
	padding-top: 10px;
	text-align: right;
}

.ph-repurchase-form-calculate-button,
.ph-repurchase-form-calculate-button:hover,
.ph-repurchase-form-calculate-button:focus,
.ph-repurchase-form-calculate-button:active {
	background-color: $primaryColor;
	border-color: $primaryColor;
	color: $colorWhiteText;

	font-size: 14px;
	border-radius: 4px;
	font-weight: 500;
	text-transform: uppercase;
	width: 100%;
	height: 35px;
	max-width: 125px;
	box-shadow: $secondaryBoxShadow;
	padding: 4px 15px;
	border: 1px solid transparent;
	line-height: 1.5715;
	position: relative;
	display: inline-block;
	white-space: nowrap;
	text-align: center;
	background-image: none;
}

.ph-product-detail-quote-dispatch-info-date {
	font-weight: 500;
	font-size: 11px;
}

.ph-product-detail-quote-dispatch-info-title-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.ph-product-detail-quote-dispatch-info-subtitle-pharmacy-since {
	font-size: 13px;
	font-weight: 400;
}

.ph-product-detail-quote-dispatch-info-container {
	display: flex;
	align-items: flex-end;
}

.fade-in {
	animation: fadeIn 0.5s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@media (max-width: 1080px) {
	.ph-product-detail-quote-dispatch-info-main-container {
		padding: 0;
		max-width: 90px;
		min-width: 90px;
	}
}

@media (max-width: 992px) {
	.ph-product-detail-quote-dispatch-info-main-container {
		margin-right: 10px;
		max-width: 82px;
		min-width: 82px;
	}

	.ph-product-detail-quote-dispatch-info-image {
		max-width: 25px;
	}

	.ph-product-detail-quote-dispatch-info-title {
		font-size: 10px;
	}

	.ph-product-detail-quote-dispatch-info-subtitle {
		font-size: 9px;
	}
}

@media (max-width: 768px) {
	.ph-product-detail-quote-dispatch-info-main-container {
		margin-right: 0;
		max-width: 40px;
		min-width: 40px;
	}

	.ph-product-detail-quote-dispatch-info-container {
		align-items: center;
	}
}

@media (max-width: 670px) {
	.ph-product-detail-quote-dispatch-info-main-container {
		height: auto;
		min-width: auto;
	}

	.ph-product-detail-quote-dispatch-info-title-container {
		align-items: flex-end;
	}
}

@media (max-width: 500px) {
	.ph-product-detail-quote-dispatch-info-main-container {
		max-width: unset;
	}
}
