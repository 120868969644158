/** @format */

@import "../../../../components/Commons/Sass/components/colors";

.ph-profile-modal-container .ant-modal-content {
  border-radius: 8px;
}

.ph-profile-modal-container .ant-modal-content .ant-modal-close {
  top: 11px;
  right: 21px;
}

.ph-profile-modal-container .ant-modal-content .ant-modal-close-x {
  color: $colorGrayText1;
  font-weight: bold;
  width: 20px;
  height: 20px;
  font-size: 16px;
  line-height: 0;
  padding: 2px;
}

.ph-profile-modal-container .ant-modal-header {
  border-bottom: 1px solid $primaryColorBorder !important;
  padding: 10px 20px;
  border-radius: 8px 8px 0 0;
}

.ph-profile-modal-container .ant-modal-header .ant-modal-title {
  font-size: 17px;
  font-weight: 900;
}

.ph-profile-modal-container .ant-modal-content .ant-modal-body {
  padding: 16px;
  text-align: center;
}

.ph-profile-modal-title {
  font-size: 16px;
  margin-bottom: 7px;
  font-weight: 900;
  color: $primaryColor;
}

.ph-profile-modal-description {
  font-weight: 400;
  font-style: italic;
  font-size: 15px;
  margin-bottom: 30px;
}

.ph-profile-modal-button {
  background: $primaryColor !important;
  color: $colorWhiteText !important;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  border-radius: 4px;
  min-width: auto;
  width: 100%;
  max-width: 125px;
  border-color: transparent;
  height: 38px;
  line-height: 10px;
  box-shadow: $primaryBoxShadow;
  margin-bottom: 10px;
}
