/** @format */

.ph-checkout-old-title-main-container {
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	margin-bottom: 10px;
}

.ph-checkout-old-title-image-container {
	padding: 5px 2px;
	margin-left: 3px;
}

.ph-checkout-old-title-image {
	width: 100%;
	max-width: 28px;
}

.ph-checkout-old-title {
	color: #4d4d4d;
	font-weight: 600;
	font-size: 22px;
	margin-bottom: 0;
}

.ph-checkout-old-subtitle {
	color: #4d4d4d;
	font-weight: 500;
	font-size: 14px;
	margin-left: 6px;
	margin-bottom: 4px;
	opacity: 0.8;
}

@media (max-width: 1024px) {
	.ph-checkout-old-title-image-container {
		padding: 3px;
		margin-left: 0;
	}
}

@media (max-width: 600px) {
	.ph-checkout-old-title-main-container {
		padding-top: 5px;
	}
}

@media (max-width: 500px) {
	.ph-checkout-old-title-image {
		max-width: 25px;
	}

	.ph-checkout-old-title {
		font-size: 16px;
	}

	.ph-checkout-old-subtitle {
		margin-bottom: 2px;
		font-size: 12px;
	}
}
