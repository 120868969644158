.change-password-content {
	width: 100%;
	max-width: 1185px;
	margin: 10px auto;
	padding: 10px 0px;

	.pointer {
		cursor: pointer;
	}
}
.spin-container {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
}
