/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-bag-electronic-cart-panel-title-main-container {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.ph-bag-electronic-cart-panel-title-image-container {
	margin-right: 5px;
}

.ph-bag-electronic-cart-panel-title-image {
	width: 100%;
	max-width: 25px;
}

.ph-bag-electronic-cart-panel-main-title-main-container {
	position: relative;
	width: 100%;
}

.ph-bag-electronic-cart-panel-main-title {
	text-overflow: ellipsis;
	text-decoration: none;
	overflow: hidden;
	white-space: nowrap;
	max-width: 90%;
	width: 100%;
	font-size: 12px;
	color: $primaryColor;
	font-weight: 900;
	margin-bottom: 3px;
	line-height: 12px;
}

.ph-bag-electronic-cart-panel-main-description {
	color: $colorGrayText2;
	font-size: 12px;
	font-weight: 500;
	font-style: italic;
	text-transform: capitalize;
	text-align: left;
	margin-bottom: 0;
	line-height: 12px;
}
