/** @format */

@import '../../../../../../../components/Commons/Sass/components/colors';

.ph-withdrawal-aditional-info-form-container {
	padding: 0 5px;
}

.ph-withdrawal-aditional-info-form-container .ant-input:focus,
.ph-withdrawal-aditional-info-form-container .ant-input:hover,
.ph-withdrawal-aditional-info-form-container .ant-input-focused,
.ph-withdrawal-aditional-info-form-container .ant-input:active {
	border-right-width: 0 !important;
	box-shadow: none !important;
	border: 1px solid rgba(255, 87, 34, 0.47843) !important;
	background-color: #fff !important;
}

.ph-withdrawal-aditional-info-field-input {
	max-width: 100%;
	height: 45px;
	border-radius: 6px;
	font-size: 13px;
	color: $colorGrayText3;
}

@media (max-width: 600px) {
	.ph-withdrawal-aditional-info-form-container {
		padding: 0;
	}
}
