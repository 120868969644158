/** @format */

@import "../../../../components/Commons/Sass/components/colors";

.ph-bag-loading-global-container {
  min-height: calc(100vh - 189px);
  margin-bottom: 30px;
  margin-top: 10px;
}

.ph-bag-global-container {
  max-width: 1185px;
  padding: 10px;
  margin: auto;
}

.ph-bag-main-container {
  padding: 10px;
  margin-bottom: 40px;
  min-height: calc(100vh - 176px);
}

.ph-bag-main-title-container {
  position: relative;
}

.ph-bag-countdown-static-container {
  position: absolute;
  bottom: 0;
  right: 10px;
  line-height: 15px;
}

.ph-bag-countdown-static-icon {
  color: $primaryColor;
  font-size: 15px;
  margin-right: 5px;
  top: -1px;
  position: relative;
}

.ph-bag-countdown-static-description {
  color: $colorGrayText4;
  font-size: 17px;
}

.ph-bag-right-side-container {
  box-shadow: $primaryBoxShadow;
  border-radius: 4px;
  padding: 10px;
  margin: 5px;
}

@media (max-width: 992px) {
  .ph-bag-global-container {
    padding: 7px;
  }

  .ph-bag-main-container {
    padding: 0;
    margin-bottom: 100px;
  }

  /* 	.ph-bag-right-side-container {
		position: fixed;
		bottom: 0;
		background-color: #fff;
		z-index: 3;
		margin: 0;
		left: 0;
		width: 100%;
		box-shadow: $secondaryBoxShadow;
		padding: 4px 10px;
	} */
}

@media (max-width: 600px) {
  .ph-bag-global-container {
    padding: 5px;
  }

  .ph-bag-right-side-container {
    padding: 4px 10px;
  }
}
