/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-product-detail-dispatch-main-container {
	border-radius: 4px;
	box-shadow: $primaryBoxShadow;
	margin: 14px 0 7px;
	position: relative;
	background-color: $primaryBackgroundColor;
}

@media (max-width: 1024px) {
	.ph-product-detail-dispatch-main-container {
		margin: 10px 0 10px 0;
		padding: 8px 5px;
	}
}
