/** @format */

@import '../../Sass/components/colors';

.ph-header-secondary-space-vertical-container {
	height: 60px;
}

.ph-header-secondary-global-container {
	background-color: $primaryBackgroundColor;
	position: fixed;
	top: 0;
	z-index: 99;
	width: 100%;
	height: 100%;
	min-height: 60px;
	max-height: 60px;
	box-shadow: $secondaryBoxShadow;
}

.ph-header-secondary-global-container-arg {
	@extend .ph-header-secondary-global-container;
	background-color: $primaryColor;
}

.ph-header-secondary-main-container {
	display: flex;
	align-items: center;
	width: 100%;
	max-width: 1185px;
	padding: 8px 30px;
	margin: auto;
}

.ph-header-secondary-button {
	color: $primaryColor;
	font-size: 11px;
	text-transform: uppercase;
	font-weight: 900;
	border: 1.5px solid $primaryColorBorder;
	padding: 8px 20px;
	height: 36px;
	border-radius: 4px;
	background: $primaryBackgroundColor;
}

.ph-header-secondary-button:hover {
	color: $colorWhiteText;
	background: $primaryColor;
	border: 1px solid $primaryColor;
}

.ph-header-secondary-button-arg {
	@extend .ph-header-secondary-button;

	&:hover {
		color: $colorWhiteText;
		background: rgba(255, 255, 255, 0.1);
		border: 1px solid $colorWhiteText;
	}
}

.ph-header-secondary-logo-image{
	height: 39px;
}

@media (max-width: 1200px) {
	.ph-header-secondary-main-container {
		padding: 8px 15px;
	}
}

@media (max-width: 1024px) {
	.ph-header-secondary-global-container {
		min-height: 55px;
		max-height: 55px;
	}

	.ph-header-secondary-main-container {
		padding: 8px;
	}
}