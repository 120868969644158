/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-recipe-global-container {
	max-width: 1185px;
	padding: 10px;
	margin: auto;
}

.ph-recipe-main-container {
	padding: 10px 25px;
	margin-bottom: 40px;
}

.ph-prescription-titlebox-countdown-container {
	position: relative;
}

.ph-recipe-inner-container {
	margin-top: 25px;
}

.ph-recipe-list-main-container {
	border: 1px solid $primaryColorBorder;
	border-radius: 4px;
	padding: 15px;
	margin-bottom: 10px;
}

.ph-recipe-list-main-container:last-child {
	margin-bottom: 0;
}

.ph-recipe-product-image-container {
	padding: 5px;
	display: block;
	margin-right: 20px;
	text-align: center;
}

.ph-recipe-product-image {
	width: 100%;
	max-width: 90px;
}

.ph-checkout-recipe-list-title {
	font-size: 16px;
	color: $primaryColor;
	font-weight: 500;
	text-transform: none;
	text-overflow: ellipsis;
	text-decoration: none;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
	max-width: 470px;
	margin-bottom: 0;
}

.ph-checkout-recipe-list-laboraroty-title {
	font-size: 13px;
	font-weight: 100;
	color: $colorGrayText4;
	font-style: italic;
	margin-bottom: 5px;
}

.ph-checkout-recipe-list-laboraroty-subtitle {
	color: $primaryColor;
}

.ph-checkout-recipe-list-upload-image-main-container {
	position: relative;
}

.ph-checkout-recipe-list-upload-bypass-recipe-image-container-enabled {
	display: unset !important;
}

.ph-checkout-recipe-list-upload-bypass-recipe-image-container {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	background-color: $primaryBackgroundColor;
	opacity: 0.95;
	z-index: 2;
	display: none;
	border: 2px solid $primaryColorBorder;
	border-radius: 5px;
	padding: 15px;
	text-align: center;
}

.ph-checkout-recipe-list-upload-bypass-recipe-inner-image-container {
	padding: 5px;
	display: block;
	margin-top: 10px;
}

.ph-checkout-recipe-list-upload-bypass-recipe-inner-image {
	width: 100%;
	max-width: 50px;
}

.ph-checkout-recipe-list-upload-bypass-recipe-subtitle {
	color: $primaryColor;
	font-size: 13px;
}

.ph-checkout-recipe-list-upload-bypass-recipe-description {
	color: $colorGrayText3;
	font-size: 12px;
	font-style: italic;
	margin-bottom: 0;
}

.ph-checkout-recipe-agree-title-wp-container {
	margin-top: 0;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.ph-checkout-recipe-agree-title-wp-container .ant-checkbox {
	margin-top: 3px;
}

.ph-checkout-recipe-agree-title-wp {
	color: $colorGrayText3;
	font-size: 14px;
	margin-top: 3px;
	margin-left: 7px;
	margin-bottom: 0;
}

.ph-checkout-recipe-agree-title-wp-main-container .ant-checkbox-checked .ant-checkbox-inner {
	background-color: $primaryColor;
	border-color: $primaryColor;
}

.ph-checkout-recipe-agree-title-wp-main-container .ant-checkbox:hover .ant-checkbox-inner {
	border-color: $primaryColor;
}

.ph-recipe-list-main-container .ant-upload.ant-upload-drag {
	height: auto;
}

.ph-checkout-recipe-agree-container .ant-form-item {
	margin-bottom: 0;
}

.ph-checkout-recipe-agree-hidden-container {
	display: none;
}

.ph-checkout-recipe-agree-main-container {
	box-shadow: $primaryBoxShadow;
	background-color: $primaryBackgroundColor;
	border-radius: 4px;
	margin: 4px;
	padding: 5px 10px 0 10px;
}

.ph-checkout-recipe-agree-container .ant-checkbox {
	margin-top: -3px;
}

.ph-checkout-recipe-agree-container .ant-checkbox-checked .ant-checkbox-inner {
	background-color: $primaryColor;
	border-color: $primaryColor;
}

.ph-checkout-recipe-agree-container .ant-checkbox-input:focus + .ant-checkbox-inner {
	border-color: $primaryColor;
}

.ph-checkout-recipe-agree-container .ant-checkbox-wrapper {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-checkout-recipe-agree-title {
	color: $colorGrayText3;
	font-size: 12px;
}

.ph-checkout-recipe-list-hidden-main-container {
	margin-top: 4px !important;
}

.ph-checkout-recipe-list-main-container {
	box-shadow: $primaryBoxShadow;
	background-color: $primaryBackgroundColor;
	border-radius: 4px;
	margin: 8px 4px 8px 4px;
	padding: 10px;
}

.ph-prescription-checkout-box-global-container {
	padding-bottom: 45px;
}

.ph-checkout-recipe-agree-title-wp-show-more {
	color: $primaryColor;
	text-decoration: underline;
	cursor: pointer;
}

.ph-checkout-recipe-agree-title-wp-asterick {
	color: $errorColor;
	margin-right: 4px;
}

.ph-checkout-recipe-agree-title-wp-description {
	font-size: 12px;
	margin-top: 5px;
	color: $colorGrayText3;
	font-style: italic;
}

.ph-checkout-recipe-agree-see-more {
	display: none;
}

.ph-checkout-recipe-agree-title-wp-main-container {
	margin-top: 5px;
	margin-left: 5px;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.ph-checkout-recipe-will-save-title {
	color: $colorGrayText3;
	font-size: 13px;
	margin-top: 3px;
	margin-left: 7px;
	margin-bottom: 0;
}

.ph-checkout-recipe-will-save-subtitle {
	color: $primaryColor;
	font-weight: 600;
}

.ph-checkout-recipe-will-save-title-container {
	border: 1px solid $primaryColor;
	border-radius: 4px;
	opacity: 0.8;
	padding: 15px 5px;
}

.ph-checkout-recipe-will-save-title-inner-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-checkout-recipe-will-save-title-image-container {
	padding: 3px;
}

.ph-checkout-recipe-will-save-title-image {
	width: 100%;
	max-width: 250px;
}

.ph-checkout-recipe-will-save-title-button,
.ph-checkout-recipe-will-save-title-button:hover,
.ph-checkout-recipe-will-save-title-button:active,
.ph-checkout-recipe-will-save-title-button:focus {
	border: 1px solid $primaryColor;
	border-radius: 4px;
	box-shadow: $primaryColor;
	color: $primaryColor;
}

.ph-checkout-receipt-modal-added-pdf-main-container {
	border: 1px solid $primaryColorBorder;
	border-radius: 4px;
	padding: 5px 10px;
	text-align: center;
}

.ph-checkout-receipt-modal-added-pdf-container {
	text-align: center;
}

.ph-checkout-receipt-modal-added-pdf-icon {
	color: red;
	font-size: 100px;
	margin: 20px 0;
}

.ph-checkout-receipt-modal-added-pdf-link,
.ph-checkout-receipt-modal-added-pdf-link:hover,
.ph-checkout-receipt-modal-added-pdf-link:active,
.ph-checkout-receipt-modal-added-pdf-link:focus {
	font-size: 14px;
	color: $primaryColor;
	text-decoration: underline;
}

@media (max-width: 1024px) {
	.ph-recipe-main-container {
		padding: 0;
		margin-bottom: 55px;
	}
}

@media (max-width: 768px) {
	.ph-recipe-inner-container {
		margin-top: 0;
	}

	.ph-recipe-inner-container .ant-form-item-control-input-content {
		display: flex;
		padding: 0 15px;
	}
	.ph-recipe-product-image-container {
		text-align: center;
		margin-right: 0;
	}

	.ph-recipe-product-image {
		max-width: 90px;
	}

	.ph-checkout-recipe-list-title {
		margin-top: 5px;
		max-width: 100%;
		overflow: auto;
		text-overflow: unset;
		white-space: normal;
	}

	.ph-checkout-recipe-list-laboraroty-title {
		margin-bottom: 3px;
	}

	.ph-prescription-checkout-box-global-container {
		padding-bottom: 85px;
	}
}

@media (max-width: 600px) {
	.ph-checkout-recipe-agree-title-wp-container {
		padding: 0 5px;
	}

	.ph-recipe-global-container {
		padding: 5px;
	}

	.ph-checkout-recipe-list-main-container {
		padding: 2px;
	}

	.ph-checkout-recipe-agree-title,
	.ph-checkout-recipe-agree-title-wp {
		font-size: 11px;
		margin-top: 5px;
		margin-left: 0;
	}

	.ph-recipe-list-main-container {
		padding: 5px;
	}

	.ph-checkout-recipe-list-upload-bypass-recipe-inner-image {
		max-width: 35px;
	}

	.ph-checkout-recipe-will-save-title-container {
		padding: 10px;
	}

	.ph-checkout-recipe-will-save-title-image-container {
		margin-bottom: 10px;
	}

	.ph-checkout-recipe-will-save-title-image {
		max-width: 150px;
	}
}
