/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-complaint-book-search-button-container,
.ph-complaint-book-search-button-container:hover,
.ph-complaint-book-search-button-container:focus,
.ph-complaint-book-search-button-container:active {
	background-color: $primaryColor;
	border-radius: 4px;
	box-shadow: $primaryBoxShadow;
	color: $colorWhiteText;
	height: 35px;
	padding-left: 30px;
	padding-right: 30px;
	font-weight: 500;
	border: none !important;
}

.ph-complaint-book-picker-field {
	border-radius: 4px;
	height: 35px;
}

.ph-complaint-book-no-results-container {
	box-shadow: $primaryBoxShadow;
	padding: 15px;
	border-radius: 4px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-complaint-book-no-results-image-container {
	padding: 10px;
}

.ph-complaint-book-no-results-image {
	width: 100%;
	max-width: 300px;
}

.ph-complaint-book-no-results-title {
	color: $secondColor;
	margin-bottom: 0;
	font-weight: 600;
	text-align: center;
}

.ph-complaint-book-no-results-subtitle {
	font-style: italic;
	text-align: center;
	color: $colorGrayText3;
}
