/** @format */

@import "../Sass/components/colors";

.ph-notification-global-container {
  width: 100%;
  max-width: 330px;
  padding: 10px;
  border-radius: 4px;
  box-shadow: $thirdBoxShadow;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .ph-notification-global-container .ant-notification-close-x {
	border: 1px solid $fourthColor !important;
	border-radius: 100px !important;
	color: $fourthColor !important;
	font-weight: bold !important;
	width: 20px !important;
	height: 20px !important;
	font-size: 10px !important;
	line-height: 0 !important;
	padding: 2px 3px !important;
} */

.ph-notification-global-container .ant-notification-notice-close {
  color: $colorGrayText1;
  font-weight: 600 !important;
  width: 20px !important;
  height: 20px !important;
  font-size: 16px !important;
  line-height: 0 !important;
  padding: 3px 3px !important;
}

.ph-notification-global-container
  .ant-notification-close-x
  .ant-notification-close-icon {
  font-size: 9px;
}

.ph-notification-main-container {
  padding: 0;
}

.ph-notification-global-container .ant-notification-notice-close {
  top: 13px;
  right: 10px;
}

.ph-notification-img-global-container {
  width: 20%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ph-notification-image-container {
  display: block;
}

.ph-notification-image {
  width: 100%;
  max-width: 50px;
  border-radius: 25%;
}

.ph-notification-text-global-container {
  width: 80%;
  padding: 5px 5px 5px 9px;
}

.ant-notification {
  font-size: 5px;
}

.ph-notification-title {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  line-height: normal;
  margin-bottom: 8px;
  text-transform: capitalize;
}

.ph-notification-description {
  font-size: 13px;
  font-weight: 100;
  color: $colorGrayText4;
  line-height: normal;
  margin-bottom: 0;
  text-align: left;
  margin-bottom: 6px;
}

.ph-notification-button {
  margin: 0;
  width: auto;
  min-width: 100px;
  height: 36px;
  border: 1px solid $secondColor !important;
  border-radius: 4px;
  background-color: $secondColor;
  padding: 0 5px !important;
  color: #fff;
  float: right;
  right: 10px;
  margin-top: 10px;
  box-shadow: $primaryBoxShadow;
  &:hover {
    background-color: $secondColor;
    color: #fff;
  }
}

.ph-main-notification-warning-title {
  color: $secondColor;
}

.ph-main-notification-success-title {
  color: $primaryColor;
}

.ph-main-notification-error-title {
  color: $errorColor;
}
