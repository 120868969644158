/** @format */

@import '../../Sass/components/colors';

.ph-rational-modal-container {
	margin-top: 30px;
}

.ph-rational-modal-container .ant-modal-content {
	border-radius: 8px;
}

.ph-rational-modal-container .ant-modal-content .ant-modal-close {
	top: 11px;
	right: 21px;
}

.ph-rational-modal-container .ant-modal-content .ant-modal-close-x {
	border-radius: 8px;
	border: 2px solid $fourthColor;
	border-radius: 100px;
	color: $fourthColor;
	font-weight: bold;
	width: 20px;
	height: 20px;
	font-size: 12px;
	line-height: 0;
	padding: 2px;
}

.ph-rational-modal-container .ant-modal-body {
	padding: 10px;
}

.ph-rational-modal-container .ant-modal-footer {
	padding: 0;
	border-top: none;
}

.ph-rational-image {
	width: 100%;
}
