/** @format */

@import '../../../../../../../../../../../../components/Commons/Sass/components/colors';

.ph-product-detail-quote-promotion-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-product-detail-quote-promotion-title {
	font-weight: 500 !important;
	font-size: 14px !important;
	color: #7DA9FA !important;
	margin-bottom: 0;
	line-height: normal;
}

@media (max-width: 575px) {
	.ph-product-detail-quote-promotion-container {
		display: flex;
	}

	.ph-product-detail-quote-promotion-title {
		font-size: 13px;
	}
}