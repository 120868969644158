/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-frequent-questions-global-container {
	max-width: 1185px;
	padding: 10px;
	margin: auto;
}

.ph-frequent-questions-title-page-container {
	padding-top: 14px !important;
}

.ph-frequent-questions-main-container {
	margin: auto;
	margin-top: 30px;
}

.ph-frequent-questions-menu-container {
	padding: 20px;
	border-radius: 4px;
	box-shadow: $primaryBoxShadow;
	width: 100%;
	max-height: 550px;
}

.ph-frequent-questions-text-container {
	padding: 20px;
	border-radius: 4px;
	box-shadow: $primaryBoxShadow;
	margin-left: 10px;
	min-height: 550px;
}

.ph-frequent-questions-text {
	font-size: 16px;
	margin-bottom: 5px;
	color: $colorGrayText3;
}

.ph-frequent-questions-img {
	width: 100%;
}

.ph-frecuent-question-fpay,
.ph-frecuent-question-webpay,
.ph-frecuent-question-swetpay {
	width: 100%;
	max-width: 220px;
}

.ph-frequent-questions-strong {
	color: $primaryColor;
	font-size: 18px;
	font-weight: 400;
}

.ph-frequent-questions-inner-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 400px;
}

.ph-frequent-questions-about-img {
	width: 100%;
	max-width: 300px;
	margin-left: 200px;
}

.ph-frequent-questions-about-valors-img-title-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 10px;
}

.ph-frequent-questions-about-valors-titles {
	font-size: 17px;
	font-weight: 600;
	opacity: 0.5;
	margin-bottom: 0;
}

.ph-frequent-questions-about-img-valors {
	width: 100%;
	max-width: 30px;
	margin-right: 10px;
}

.ph-frequent-questions-inner-img {
	width: 100%;
	max-width: 200px;
}

span.ph-frequent-questions-subcategory-a {
	display: block;
	color: $colorGrayText4;
	opacity: 0.7;
	font-size: 16px;
	line-height: 30px;
	padding: 10px 0;
	border-bottom: 1px solid $primaryColorBorder;
}

span.ph-frequent-questions-subcategory-a-active {
	color: $primaryColor;
}

.ph-frequent-questions-img-answer {
	width: 100%;
	max-width: 70px;
}

.ph-frecuent-question-img-pharmacy {
	width: 100%;
	max-width: 25px;
}

span.ph-frequent-questions-subcategory-a:hover {
	color: $primaryColor !important;
}

.ph-frequent-questions-menu-category {
	font-size: 18px;
	font-weight: 700;
	opacity: 0.7;
	margin-bottom: 5px;
}

.ph-frequent-questions-menu-category-title {
	font-size: 20px;
	font-weight: 900;
	color: $primaryColor;
	margin-bottom: 5px;
}

.ph-frequent-questions-menu-category-subtitle {
	font-size: 16px;
	font-weight: 700;
	margin-bottom: 5px;
}

.ph-frequent-questions-video {
	width: 100%;
	max-width: 730px;
	height: 315px;
}

.ant-collapse-borderless {
	background-color: white;

	.ant-collapse-header-text {
		font-size: 16px;
		color: $colorGrayText3;

	}
}

@media (max-width: 992px) {
	span.ph-frequent-questions-subcategory-a {
		font-size: 15px;
		line-height: 25px;
	}

	.ph-frequent-questions-menu-category {
		font-size: 16px;
	}

	.ph-frequent-questions-text {
		font-size: 14px;
	}

	.ph-frequent-questions-about-img {
		max-width: 200px;
		margin-left: 200px;
	}
}

@media (max-width: 768px) {
	span.ph-frequent-questions-subcategory-a {
		font-size: 13px;
		line-height: 20px;
	}

	.ph-frequent-questions-about-img {
		margin-left: 120px;
	}
}

@media (max-width: 576px) {
	.ph-frequent-questions-text-container {
		margin-left: 0;
		margin-top: 20px;
	}

	.ph-frequent-questions-about-img {
		margin-left: 150px;
	}
}

@media (max-width: 480px) {
	.ph-frequent-questions-about-img {
		margin-left: 100px;
	}
}

@media (max-width: 375px) {
	.ph-frequent-questions-about-img {
		margin-left: 50px;
	}
}