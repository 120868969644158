/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-pharol-price-container {
	background-color: $primaryBackgroundColor;
	box-shadow: $primaryBoxShadow;
	padding-bottom: 10px;
	border-radius: 5px;
}

.ph-pharol-price-title-check-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.ph-pharol-price-title-container {
	flex-grow: 1;
	text-align: center;
}

.ph-pharol-price-title {
	color: #669bfb;
	letter-spacing: -0.54px;
	font-size: 18px;
}

.ph-pharol-price-title-desc-one {
	color: #555555;
	letter-spacing: -0.48px;
	font-weight: 500;
}

.ph-pharol-price-title-desc-second {
	color: #555555;
	font-weight: bold;
	letter-spacing: -0.54px;
	font-size: 18px;
}

.ph-pharol-price-checkbox {
	margin-left: auto;
	padding: 15px;
}

.ph-pharol-price-checkbox-title {
	color: #555555;
	letter-spacing: -0.45px;
	margin-right: 16px;
	font-weight: bold;
}

.ph-pharol-price-description {
	font-size: 10px;
	color: #555555;
	letter-spacing: -0.48px;
	text-align: center;
}

@media (max-width: 670px) {
	.ph-pharol-price-title-desc-second {
		font-size: 12px;
	}
	.ph-pharol-price-checkbox {
		font-size: 10px;
	}
	.ph-pharol-price-title  {
		font-size: 14px;
	}
}
