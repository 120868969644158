/** @format */

@import '../../components/Commons/Sass/components/colors';

.ph-executives-header-global-container {
	width: 100%;
	height: 700px;
	background: linear-gradient(to bottom, #d6e5fd, #f2f7ff);
	position: relative;
	overflow: hidden;
}

.ph-executives-header-main-container {
	width: 100%;
	max-width: 1165px;
	padding: 30px;
	margin: auto;
}

.ph-executives-header-content {
	width: 100%;
	align-items: center;
}

.ph-executives-header-logo-img {
	width: 100%;
	max-width: 300px;
	padding-bottom: 10px;
}

.ph-executives-header-description {
	margin-bottom: 0;
	font-size: 15px;
	font-weight: 500;
	color: $colorGrayText3;
	padding-bottom: 10px;
}

.ph-executives-header-input-inner-container {
	position: relative;
	width: 100%;
	max-width: 450px;
}

.ph-executives-header-input {
	width: 450px;
	height: 45px;
	border-radius: 6px;
	font-size: 13px;
	color: $colorGrayText3;
	position: relative;
}

.ph-executives-header-button,
.ph-executives-header-button:hover,
.ph-executives-header-button:focus,
.ph-executives-header-button:active {
	background-color: $primaryColor !important;
	color: $colorWhiteText;
	font-size: 12px;
	border-radius: 4px;
	font-weight: 900;
	padding: 2px 27px !important;
	height: 35px;
	border: $primaryColor;
	max-width: 155px;
	position: absolute;
	right: 5px;
	top: 5px;
}

.ph-executives-header-img-route {
	width: 100%;
	max-width: 650px;
	position: absolute;
	bottom: 0;
	right: 0;
}

.ph-executives-header-img-moto {
	width: 100%;
	max-width: 450px;
	position: absolute;
	bottom: 0;
	right: 30%;
}

.ph-executives-widget-movil-container {
	display: none;
}

.ph-executives-header-img-delivery {
	width: 100%;
	position: absolute;
	right: 32%;
	top: 3%;
	max-width: 300px;
}

.ph-executives-header-man-global-container {
	width: 100%;
	max-width: 260px;
	padding: 10px;
}

.ph-executives-header-widget-global-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: 10px auto auto;
}
.ph-executives-header-img-man {
	width: 50px;
}

.ph-executives-header-widet-span {
	font-size: 12px;
	font-weight: 400;
	color: $colorGrayText4;
	font-style: italic;
}

.ph-executives-header-widet-profesion {
	font-size: 11px;
	margin-bottom: 0;
	margin-left: 10px;
}

.ph-executives-header-widet-name {
	font-size: 13px;
	font-weight: 700;
	margin-bottom: 0;
	margin-left: 10px;
}

.ph-executives-header-repet-global-container {
	width: 100%;
	max-width: 350px;
	margin-top: 10%;
}

.ph-executives-header-repet-main-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-executives-header-img-repet {
	width: 100%;
	max-width: 20px;
	padding-left: 1px;
}

.ph-executives-header-repet-subtitle {
	font-size: 14px;
	font-weight: 900;
	color: $primaryColor;
	margin-bottom: 0;
	padding-left: 5px;
	padding-top: 2px;
}

.ph-executives-header-repet-medicine-container {
	padding: 10px;
	background: $primaryBackgroundColor;
	height: 100px;
	width: 100%;
	border-radius: 10px;
	position: relative;
	margin: 10px 0;
	box-shadow: $primaryBoxShadow;
}

.ph-executives-header-repet-products-container {
	padding: 10px;
	background: $primaryBackgroundColor;
	height: 100px;
	width: 100%;
	border-radius: 10px;
	position: relative;
	box-shadow: $primaryBoxShadow;
}

.ph-executives-header-repet-span {
	font-weight: bold;
	font-size: 13px;
	font-style: italic;
}

.ph-executives-header-repet-paragrahf {
	font-size: 11px;
}

.ph-executives-header-img-repet-icon-one {
	position: absolute;
	width: 80px;
	height: 100px;
	right: 0;
	top: 0;
	border-bottom-right-radius: 5px;
	border-top-right-radius: 5px;
}

.ph-executives-withyou-global-container {
	box-shadow: $primaryBoxShadow;
	padding: 15px;
	background: $primaryBackgroundColor;
	border-radius: 10px;
	position: relative;
	margin: auto;
	max-width: 1000px;
	margin-top: 80px;
	margin-bottom: 30px;
}

.ph-executives-icon-number {
	width: 20px;
}

.ph-executives-withyou-image {
	width: 350px;
}

.ph-executives-ranking-image {
	width: 250px;
}

.ph-executives-withyou-img-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-executives-image-content {
	max-width: 500px;
	width: 100%;
}

.ph-executives-withyou-main-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 30px;
}

.ph-executives-withyou-title {
	color: $colorGrayText4;
	font-weight: 600;
	font-size: 25px;
	margin-bottom: 5px;
}

.ph-executives-withyou-subtitle {
	font-size: 16px;
	color: $colorGrayText3;
	font-weight: lighter;
	margin-bottom: 0px;
}

.ph-executives-allies-global-container {
	box-shadow: $primaryBoxShadow;
	padding: 15px;
	background: $primaryColor;
	border-radius: 10px;
	margin: auto;
	max-width: 1150px;
	margin-top: 80px;
	margin-bottom: 30px;
}

.ph-executives-allies-main-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.ph-executives-allies-title {
	color: $colorWhiteText;
	font-weight: 600;
	font-size: 25px;
	margin-bottom: 5px;
}

.ph-executives-allies-subtitle {
	font-size: 16px;
	color: $colorWhiteText;
	font-weight: lighter;
	margin-bottom: 0px;
	max-width: 350px;
	width: 100%;
}

.ph-executives-allies-img-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.ph-executives-info-global-container {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	margin: auto;
	width: 100%;
	max-width: 1100px;
	margin-top: 100px;
	margin-bottom: 80px;
}

.ph-executives-info-main-container {
	box-shadow: $primaryBoxShadow;
	background: $primaryBackgroundColor;
	border-radius: 10px;
	max-width: 420px;
	width: 100%;
	height: 350px;
	padding: 50px;
	position: relative;
}

.ph-executives-info-click-title,
.ph-executives-info-access-title {
	color: $colorGrayText4;
	font-size: 25px;
	font-weight: bold;
	margin-bottom: 20px;
	text-align: center;
}

.ph-executives-info-click-subtitle,
.ph-executives-info-access-subtitle {
	color: $colorGrayText2;
	font-size: 15px;
	font-weight: lighter;
	margin-bottom: 0px;
}

.ph-executives-info-click-img,
.ph-executives-info-access-img {
	max-width: 90px;
	width: 100%;
	z-index: 1;
	position: absolute;
	top: -50px;
	left: 160px;
}

.ph-executives-metrics-global-container {
	background-image: url('https://storage.googleapis.com/master-image-bucket/react-page-static/patient-executive/bannermedical.png');
	height: 300px;
	text-align: center;
	align-items: center;
}

.ph-executives-metrics-titles-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.ph-executives-metrics-title {
	color: $colorWhiteText;
	font-size: 20px;
	margin: 0;
	font-weight: 600;
}

.ph-executives-metrics-subtitle {
	color: $colorWhiteText;
	font-size: 13px;
	margin: 0;
	font-weight: 500;
}

.ph-executives-benefits-global-container {
	max-width: 1185px;
	width: 100%;
	padding: 50px;
	margin: auto;
}

.ph-executives-benefits-main-container {
	width: 100%;
	margin: auto;
}

.ph-executives-benefits-description-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin: auto;
	width: 100%;
}

.ph-executives-benefits-innerbox-container {
	box-shadow: $primaryBoxShadow;
	padding: 15px;
	background: $primaryBackgroundColor;
	margin-bottom: 10px;
	border-radius: 4px;
	width: 60%;
}

.ph-executives-benefits-title {
	color: $colorGrayText3;
	font-weight: 600;
	font-size: 25px;
	margin-bottom: 5px;
}

.ph-executives-benefits-subtitle {
	font-size: 14px;
	padding-bottom: 10px;
	color: $colorGrayText3;
	font-weight: 400;
}

.ph-executives-benefits-subtitle-span {
	color: $colorGrayText2;
	font-weight: 400;
	font-size: 12px;
	padding-left: 10px;
}

.ph-executives-benefits-buttons-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
}

.ph-executives-benefits-app-store-icon {
	color: $colorWhiteText;
	font-size: 16px;
}

.ph-executives-benefits-google-play-icon {
	color: $primaryColor;
	font-size: 16px;
}

.ph-executives-benefits-button-app-store,
.ph-executives-benefits-button-app-store:hover,
.ph-executives-benefits-button-app-store:focus,
.ph-executives-benefits-button-app-store:active {
	background-color: $primaryColor !important;
	color: $colorWhiteText;
	font-size: 12px;
	border-radius: 4px;
	font-weight: 900;
	padding: 8px 27px !important;
	height: 45px;
	border: $primaryColor;
	margin-right: 5px;
}

.ph-executives-benefits-button-google-play,
.ph-executives-benefits-button-google-play:hover,
.ph-executives-benefits-button-google-play:active,
.ph-executives-benefits-button-google-play:focus {
	background-color: $primaryBackgroundColor !important;
	color: $primaryColor;
	font-size: 12px;
	border-radius: 4px;
	font-weight: 900;
	padding: 8px 27px !important;
	height: 45px;
	border-color: $primaryColor;
}

.ph-executives-benefits-moviles-container {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	margin: auto;
}

.ph-executives-image-movilOne,
.ph-executives-image-movilTwo {
	max-width: 200px;
	width: 100%;
}

.ph-executives-singUp-global-container {
	background-image: url('https://storage.googleapis.com/master-image-bucket/react-page-static/patient-executive/bannermedical.png');
	height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-bottom: -50px;
}

.ph-executives-singUp-text-container {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.ph-executives-singUp-title {
	color: $colorWhiteText;
	font-size: 22px;
	font-weight: bold;
	margin-bottom: 0px;
}

.ph-executives-singUp-span {
	color: $colorWhiteText;
	font-size: 13px;
	font-style: italic;
	font-weight: 500;
	margin-bottom: 25px;
}

.ph-executives-singUp-input-global-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
}

.ph-executives-singUp-input-container {
	position: relative;
	width: 100%;
	max-width: 450px;
}

.ph-executives-singUp-input {
	width: 450px;
	height: 45px;
	border-radius: 6px;
	font-size: 13px;
	color: $colorGrayText3;
	position: relative;
}

.ph-executives-singUp-button,
.ph-executives-singUp-button:focus,
.ph-executives-singUp-button:hover,
.ph-executives-singUp-button:active {
	background-color: $primaryColor !important;
	color: $colorWhiteText;
	font-size: 12px;
	border-radius: 4px;
	font-weight: 900;
	padding: 2px 27px !important;
	height: 35px;
	border: $primaryColor;
	max-width: 155px;
	position: absolute;
	right: 5px;
	top: 5px;
}

@media (max-width: 992px) {
	.ph-executives-header-description {
		font-size: 14px;
	}
	.ph-executives-header-repet-span {
		font-size: 12px;
	}
	.ph-executives-header-input-container,
	.ph-executives-header-widet-span {
		display: none !important;
	}
	.ph-executives-header-img-moto {
		right: 22%;
		max-width: 390px;
	}
	.ph-executives-header-img-delivery {
		right: 25%;
		max-width: 220px;
	}
	.ph-executives-header-img-route {
		max-width: 560px;
	}
	.ph-executives-header-repet-global-container {
		max-width: 330px;
	}
	.ph-executives-header-img-man {
		width: 40px;
	}
	.ph-executives-header-repet-paragrahf {
		font-size: 11px;
	}
	.ph-executives-withyou-global-container {
		max-width: 800px;
	}
	.ph-executives-allies-global-container {
		max-width: 900px;
	}
	.ph-executives-benefits-subtitle {
		font-size: 13px;
	}
}

@media (max-width: 768px) {
	.ph-executives-header-img-route {
		max-width: 450px;
		width: 100%;
	}
	.ph-executives-header-img-moto {
		max-width: 340px;
		width: 100%;
		right: 13%;
	}
	.ph-executives-header-repet-global-container {
		max-width: 290px;
		width: 100%;
	}
	.ph-executives-header-repet-paragrahf {
		font-size: 10px;
	}
	.ph-executives-information-banner-inner-span {
		font-size: 18px;
	}
	.ph-executives-header-man-global-container {
		display: none;
	}
	.ph-executives-header-img-delivery {
		right: 15%;
	}
	.ph-executives-widget-movil-container {
		display: block;
		padding: 5px;
		background: $primaryBackgroundColor;
		width: 100%;
		max-width: 250px;
		border-radius: 5px;
		position: relative;
		bottom: 25px;
		margin: auto;
		box-shadow: $primaryBoxShadow;
	}
	.ph-executives-widget-movil-img-man {
		max-width: 40px;
		width: 100%;
	}
	.ph-executives-widget-movil-content {
		display: flex;
		justify-content: space-around;
		align-items: center;
		margin: auto;
	}
	.ph-executives-image-movilTwo {
		display: none;
	}
	.ph-executives-benefits-moviles-container {
		justify-content: center;
	}
	.ph-executives-widget-movil-man-profesion {
		font-size: 12px;
		margin-bottom: 0;
		font-weight: bold;
	}
	.ph-executives-widget-movil-man-name {
		font-size: 11px;
	}
	.ph-executives-withyou-global-container {
		max-width: 700px;
	}
	.ph-executives-withyou-subtitle {
		font-size: 13px;
	}
	.ph-executives-allies-global-container {
		max-width: 750px;
	}
	.ph-executives-info-main-container {
		max-width: 350px;
	}
	.ph-executives-info-click-img,
	.ph-executives-info-access-img {
		left: 130px;
	}
	.ph-executives-info-click-subtitle,
	.ph-executives-info-access-subtitle {
		text-align: center;
		font-size: 13px;
	}
	.ph-executives-benefits-title {
		font-size: 24px;
	}
	.ph-executives-benefits-innerbox-container {
		width: 95%;
	}
	.ph-executives-singUp-global-container {
		align-items: flex-end;
	}
}

@media (max-width: 576px) {
	.ph-executives-header-img-moto {
		right: 20%;
	}
	.ph-executives-header-repet-global-container,
	.ph-executives-images-movilTwo-container {
		display: none;
	}
	.ph-executives-withyou-main-container {
		align-items: center;
		padding: 10px;
	}
	.ph-executives-withyou-subtitle {
		text-align: center;
	}
	.ph-executives-info-container {
		margin-top: 0;
	}
	.ph-executives-benefits-moviles-container {
		margin: 50px auto auto;
	}
	.ph-executives-widget-movil-container {
		margin: auto;
	}
	.ph-executives-header-img-delivery {
		width: 100%;
		max-width: 200px;
		right: 5%;
	}
	.ph-executives-information-banner-inner-span {
		font-size: 16px;
	}
	.ph-executives-information-banner-container {
		margin-bottom: 20px;
	}
	.ph-executives-withyou-global-container {
		max-width: 500px;
		width: 100%;
		margin-top: 40px;
	}
	.ph-executives-allies-global-container {
		max-width: 550px;
		width: 100%;
	}
	.ph-executives-allies-img-container {
		justify-content: center;
	}
	.ph-executives-allies-main-container {
		align-items: center;
		text-align: center;
		padding: 10px;
	}
	.ph-executives-ranking-image {
		max-width: 200px;
		width: 100%;
	}
	.ph-executives-allies-subtitle {
		width: 100%;
	}
	.ph-executives-info-main-container {
		margin-bottom: 100px;
	}
	.ph-executives-info-global-container {
		margin-bottom: 0;
	}
	.ph-executives-benefits-innerbox-container {
		width: 70%;
	}
	.ph-executives-header-description {
		font-size: 13px;
	}
}

@media (max-width: 480px) {
	.ph-executives-header-logo-img {
		padding: 0;
	}
	.ph-executives-header-img-delivery {
		width: 100%;
		right: 5%;
	}
	.ph-executives-withyou-global-container {
		max-width: 400px;
		width: 100%;
		margin-top: 30px;
	}
	.ph-executives-allies-global-container {
		max-width: 450px;
		width: 100%;
	}
	.ph-executives-allies-subtitle {
		width: 100%;
		font-size: 13px;
	}
	.ph-executives-header-img-moto {
		right: 15%;
	}
}

@media (max-width: 414px) {
	.ph-executives-banner-info-main-container {
		padding: 0;
	}
	.ph-executives-header-description,
	.ph-executives-benefits-subtitle-span {
		font-size: 11px;
	}
	.ph-executives-withyou-global-container {
		max-width: 350px;
		width: 100%;
	}
	.ph-executives-allies-global-container {
		max-width: 360px;
		width: 100%;
	}
	.ph-executives-benefits-innerbox-container {
		width: 100%;
	}
	.ph-executives-header-img-delivery {
		right: 0;
		top: 10%;
	}
	.ph-executives-header-img-moto {
		right: 7%;
	}
	.ph-executives-singUp-input {
		max-width: 350px;
		width: 100%;
		margin-left: 48px;
	}
	.ph-executives-singUp-button {
		right: 20px;
	}
}

@media (max-width: 375px) {
	.ph-executives-header-img-moto {
		left: 1%;
	}
	.ph-executives-singUp-input {
		margin-left: 12px;
	}
}
