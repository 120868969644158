/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-detail-simple-info-main-container {
	padding: 20px;
	margin-top: 10px;
}

.ph-detail-simple-info-main-title {
	color: $colorGrayText4;
}

.ph-detail-tabs-add-info-title {
	color: $colorGrayText3;
	font-weight: 600;
}

.ph-detail-tabs-add-info-description {
	color: $colorGrayText2;
	font-weight: 500;
	font-size: 13px;
}
