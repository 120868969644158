/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-objected-recipe-product-global-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-objected-recipe-product-text-container {
	margin: auto;
}

.ph-objected-recipe-product-title {
	font-size: 18px;
	font-weight: 900;
	color: $primaryColor;
	line-height: normal;
	margin: 3px 0;
}

.ph-objected-recipe-product-pharmacy {
	font-weight: 100;
	font-size: 15px;
	color: $colorGrayText3;
	margin-top: 0;
	margin-bottom: 0;
}

.ph-objected-recipe-product-id {
	font-size: 14px;
	color: $colorGrayText1;
	font-style: italic;
	margin-bottom: 0;
}

.ph-objected-recipe-image-container {
	margin: auto;
	width: 100%;
}

.ph-objected-recipe-image {
	max-width: 100px;
}

@media (max-width: 992px) {
	.ph-objected-recipe-product-title {
		font-size: 15px;
	}
}

@media (max-width: 576px) {
	.ph-objected-recipe-image {
		max-width: 60px;
	}
	.ph-objected-recipe-product-id {
		font-size: 12px;
	}
}

@media (max-width: 480px) {
	.ph-objected-recipe-product-title {
		font-size: 12px;
	}
	.ph-objected-recipe-product-id {
		margin-top: 0;
		font-size: 10px;
	}
	.ph-objected-recipe-product-pharmacy {
		font-size: 11px;
	}
}

@media (max-width: 414px) {
	.ph-objected-recipe-image {
		max-width: 40px;
	}
}
