/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-withdrawal-pharmacy-list-hours-selected-main-container {
	border: 1px solid $primaryColorBorder;
	border-radius: 4px;
	margin-top: 7px;
	margin-bottom: 7px;
	padding: 6px 0;
}

.ph-withdrawal-pharmacy-list-hours-selected-main-title-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-withdrawal-pharmacy-list-hours-selected-main-title {
	color: $primaryColor;
	font-weight: 600;
	margin-bottom: 0;
	opacity: 0.8;
	font-size: 13px;
	margin-left: 10px;
}

.ph-withdrawal-pharmacy-list-hours-selected-container {
	display: flex;
	flex-flow: row wrap;
}

.ph-withdrawal-pharmacy-list-hours-selected-container:last-child {
	margin-right: 0;
}

.ph-withdrawal-pharmacy-list-hours-selected-inner-container {
	display: block;
	flex: 0 0 14.2857%;
	max-width: 14.2857%;
}

.ph-withdrawal-pharmacy-list-hour {
	color: $colorGrayText4;
	font-size: 10px;
	text-align: center;
	margin-bottom: 0;
	font-weight: 600;
}

.ph-withdrawal-pharmacy-list-hour-separator {
	color: $colorGrayText4;
	font-size: 10px;
	text-align: center;
	margin-bottom: 0;
	font-weight: 600;
	line-height: 5px;
}

.ph-withdrawal-pharmacy-list-hour-title {
	color: $colorGrayText2;
	font-size: 11px;
	font-weight: 500;
	margin-bottom: 1px;
	font-style: italic;
}

.ph-withdrawal-pharmacy-list-hour-subtitle-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-withdrawal-pharmacy-list-hour-subtitle-icon-1 {
	color: $successColor;
	font-size: 10px;
	margin-top: -2px;
}

.ph-withdrawal-pharmacy-list-hour-subtitle-1 {
	font-size: 11px;
	font-weight: 600;
	color: $successColor;
	margin-bottom: 0;
}

.ph-withdrawal-pharmacy-list-hour-subtitle-icon-2 {
	color: $errorColor;
	font-size: 10px;
	margin-top: -2px;
}

.ph-withdrawal-pharmacy-list-hour-subtitle-2 {
	font-size: 11px;
	font-weight: 600;
	color: $errorColor;
	margin-bottom: 0;
}

@media (max-width: 992px) {
	.ph-withdrawal-pharmacy-list-hours-selected-container {
		margin-right: 3px;
	}

	.ph-withdrawal-pharmacy-list-hour-title {
		text-align: center;
	}

	.ph-withdrawal-pharmacy-list-hour-subtitle-container {
		justify-content: center;
	}
}

@media (max-width: 600px) {
	.ph-withdrawal-pharmacy-list-hours-selected-container {
		margin-right: 1px;
	}

	.ph-withdrawal-pharmacy-list-hour-title {
		font-size: 11px;
	}

	.ph-withdrawal-pharmacy-list-hour {
		display: none;
	}

	.ph-withdrawal-pharmacy-list-hour-separator {
		display: none;
	}

	.ph-withdrawal-pharmacy-list-hour-subtitle-1 {
		font-size: 11px;
	}

	.ph-withdrawal-pharmacy-list-hour-subtitle-2 {
		font-size: 11px;
	}

	.ph-withdrawal-pharmacy-list-hours-selected-inner-container {
		flex: 0 0 25%;
		max-width: 25%;
	}
}

@media (max-width: 320px) {
	.ph-withdrawal-pharmacy-list-hours-selected-main-title {
		font-size: 11px;
		margin-left: 4px;
	}
}
