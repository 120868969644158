/** @format */

@import "../../../../../../../../../../components/Commons/Sass/components/colors";

@mixin button-shared-styles($bgColor, $color, $padding, $fSize, $fWeight) {
  background-color: $bgColor;
  color: $color;
  margin: 0;
  padding: $padding;
  font-size: $fSize;
  border-radius: 4px;
  text-transform: uppercase;
  height: 35px;
  box-shadow: $mainHeaderboxShadow;
  font-weight: 600;
}

.ph-product-detail-comparator-button-container {
  display: flex;
  justify-content: flex-end;
}

.ph-product-detail-quote-button {
  width: 100%;

  &,
  &:hover,
  &:active,
  &:focus {
    @include button-shared-styles($secondColor,
      $colorWhiteText,
      4px 18px,
      13px,
      600);
    border-color: $secondColor;
  }
}

.ph-product-detail-alternative-button {

  &,
  &:hover,
  &:active,
  &:focus {
    @include button-shared-styles($primaryBackgroundColor,
      $primaryColor,
      4px 7px,
      13px,
      600);
    border: 2px solid $primaryColor !important;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .12);
  }
}


@media (max-width: 575px) {

  .ph-product-detail-quote-button,
  .ph-product-detail-quote-button:hover,
  .ph-product-detail-quote-button:active,
  .ph-product-detail-quote-button:focus {
    height: 30px;
    padding: 0 10px;
    line-height: 25px;
    font-size: 14px;
  }

  .ph-product-detail-alternative-button,
  .ph-product-detail-alternative-button:hover,
  .ph-product-detail-alternative-button:active,
  .ph-product-detail-alternative-button:focus {
    width: 89.81px;
    height: 30px;
    padding: 0 10px;
    line-height: 25px;
    font-size: 11px;
  }
}