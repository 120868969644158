/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-product-timeline-main-container {
	padding: 7px 0;
	display: block;
}

.ph-product-timeline-list-container {
	display: inline;
	position: relative;
}

.ph-product-timeline-image-container {
	margin-top: -2px;
	margin-left: -2px;
}

.ph-product-timeline-image {
	width: 100%;
	max-width: 25px;
}

.ph-product-timeline-master-category-title {
	font-size: 13px;
	color: $primaryColor;
	font-weight: 500;
	margin-bottom: 0;
}

.ph-product-timeline-master-category-separator {
	font-size: 13px;
	color: $colorGrayText2;
	font-weight: 500;
	margin-left: 4px;
	margin-right: 4px;
	margin-bottom: 0;
}

.ph-product-timeline-master-category-subtitle {
	font-size: 13px;
	color: $colorGrayText2;
	font-weight: 500;
	margin-bottom: 0;
}

@media (max-width: 1024px) {
	.ph-product-timeline-main-container {
		margin: 0 0 10px 0;
	}

	.ph-product-timeline-image {
		max-width: 20px;
	}

	.ph-product-timeline-master-category-title {
		font-size: 11px;
	}

	.ph-product-timeline-master-category-separator {
		font-size: 11px;
		margin-left: 3px;
		margin-right: 3px;
	}

	.ph-product-timeline-master-category-subtitle {
		font-size: 11px;
	}
}

@media screen and (max-width: 426px) {
	.ph-product-timeline-main-container {
		display: none;
	}
}

@media (max-width: 320px) {
	.ph-product-timeline-main-container {
		margin-left: 10px;
		margin-right: 10px;
	}

	.ph-product-timeline-master-category-title {
		font-size: 10px;
	}

	.ph-product-timeline-master-category-separator {
		font-size: 10px;
		margin-left: 3px;
		margin-right: 3px;
	}

	.ph-product-timeline-master-category-subtitle {
		font-size: 10px;
	}
}
