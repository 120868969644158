/** @format */

@import '../Sass/components/colors';

/** Cart Style **/

.ph-cart-form-address-main-container {
	background-color: #fff;
	transition: 0.3s ease;
	display: flex !important;
	justify-content: space-between !important;
	flex-direction: column !important;
	margin-left: 17px;
	margin-top: 3px;
}

.ph-cart-form-address-description-container {
	margin-top: 7px;
	margin-bottom: 22px;
	border: 1px solid $primaryColorBorder;
	border-radius: 4px;
}

.ph-cart-form-address-inner-container {
	position: relative;
	display: flex;
	width: 100%;
	padding: 8px 5px;
}

.ph-cart-form-address-image-logo-container {
	margin-right: 0;
	margin-top: -2px;
}

.ph-cart-form-address-image-logo {
	width: 100%;
	max-width: 18px;
}

.ph-cart-form-address-step-title {
	color: #797979;
	font-size: 12px;
	font-weight: 500;
	margin-bottom: 0;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 235px;
	width: 100%;
}

.ph-cart-form-address-step-button,
.ph-cart-form-address-step-button:hover,
.ph-cart-form-address-step-button:focus,
.ph-cart-form-address-step-button:active {
	position: absolute;
	right: 3px;
	top: 3px;
	padding: 0;
	height: auto;
	line-height: 15px;
	background-color: #2e72b6;
	border-radius: 4px;
	color: #fff;
	padding: 4px 4px 2px 4px;
	font-size: 19px;
}

.ph-cart-form-address-title {
	color: #8e8e8e;
	font-size: 10px;
	margin-bottom: 0;
	line-height: 10px;
	text-overflow: ellipsis;
	text-decoration: none;
	overflow: hidden;
	white-space: nowrap;
	font-weight: 500;
	width: 100%;
	max-width: 195px;
	transition: 0.3s ease;
}

.ph-cart-form-address-subtitle {
	font-size: 13px;
	text-overflow: ellipsis;
	text-decoration: none;
	overflow: hidden;
	white-space: nowrap;
	font-weight: 500;
	color: #8e8e8e;
	margin-bottom: 0;
	width: 100%;
	max-width: 195px;
	line-height: 18px;
	transition: 0.3s ease;
}

.ph-cart-form-address-title:hover {
	color: #00bcd4;
	transition: 0.3s ease;
}

.ph-cart-form-address-subtitle:hover {
	color: #00bcd4;
	transition: 0.3s ease;
}

.ph-cart-form-address-form-main-container {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	max-width: 100%;
	box-shadow: 1px 1px 5px 2px rgb(221 221 221);
	border-radius: 4px;
}

.ph-cart-form-address-form-container {
	position: relative;
	max-width: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	margin-bottom: 0;
	width: 100%;
}

.ph-cart-form-address-form-container .ant-form-item {
	width: 100%;
	height: 36px;
	margin-bottom: 0;
}

.ph-cart-form-address-form-container .ant-form-item-has-error .ant-input:focus,
.ph-cart-form-address-form-container .ant-form-item-has-error .ant-input {
	border-color: #ddd !important;
	box-shadow: none !important;
	border-right-width: 0 !important;
	font-size: 12px !important;
}

.ph-cart-form-address-form-search-field {
	height: 36px;
	width: 100%;
	border: 1px solid #ddd;
	border-right-width: 0 !important;
	border-radius: 4px 0 0 4px;
	font-size: 12px;
}

.ph-cart-form-address-form-search-field:hover,
.ph-cart-form-address-form-search-field:focus,
.ant-input-focused {
	border: 1px solid #ddd !important;
	border-color: #ddd !important;
	border-right-width: 0 !important;
	box-shadow: none !important;
}

.ph-cart-form-address-form-search-field::selection {
	background: transparent !important;
}

.ph-cart-form-address-form-search-field-radius {
	border-radius: 4px 0 0 4px;
}

.ph-cart-form-address-form-search-field-no-radius {
	border-radius: 0;
}

.ph-cart-form-address-form-search-button {
	margin: 0;
	width: auto;
	min-width: 62px;
	height: 36px;
	border: 1px solid #2e72b6 !important;
	border-radius: 0 4px 4px 0;
	background-color: #2e72b6 !important;
	padding: 0 5px !important;
	color: #fff;
}

.ph-cart-form-address-form-search-button .anticon {
	font-size: 20px;
	font-weight: 900;
	line-height: 40px;
}

.ph-cart-form-address-title-form-search-button {
	font-size: 13px;
	font-weight: 900;
	color: #fff;
	margin-top: -1px;
	margin-bottom: 0px;
}

.ph-cart-form-address-form-search-list {
	position: absolute;
	top: 88px;
	left: 48px;
	z-index: 2;
	width: 100%;
	max-width: 232px;
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
		0 1px 5px 0 rgba(0, 0, 0, 0.12);
	background-color: #fff;
}

.ph-cart-form-address-form-search-list-description {
	background-color: #fff !important;
	cursor: pointer;
	text-align: left;
	padding: 6px !important;
	border-bottom: 1px solid #ddd;
	font-size: 12px;
	text-overflow: ellipsis !important;
	text-decoration: none;
	overflow: hidden !important;
	white-space: nowrap;
	max-width: 100%;
	width: 100%;
	margin: 0 !important;
	height: auto !important;
	line-height: 1.5 !important;
	color: rgba(0, 0, 0, 0.85) !important;
}

.ph-cart-form-address-form-search-list-description:hover {
	background-color: rgba(46, 114, 182, 0.1) !important;
}

.ph-cart-form-address-form-search-list-image-container {
	margin-right: 2px;
}

.ph-cart-form-address-on-demand-description {
	color: #d7141f;
	font-style: italic;
	font-size: 12px;
	font-weight: 500;
	margin-bottom: 0;
	width: 100%;
}

.ph-cart-form-address-on-demand-asterisk {
	color: #d7141f;
}

.ph-cart-form-address-form-search-list-image {
	width: 100%;
	max-width: 17px;
}

.ph-cart-form-address-form-container .ant-form-item-explain {
	font-size: 11px !important;
	margin-left: 14px;
}

.ph-cart-form-address-loading-container {
	width: 100%;
	text-align: center;
	margin-left: -45px;
	margin-bottom: 2px;
	margin-top: 10px;
}

.ph-cart-form-address-icon {
	color: #2d72b7;
}

.ph-cart-form-address-image {
	font-size: 20px;
}

@media (max-width: 992px) {
	.ph-cart-form-address-main-container {
		padding: 5px 5px 5px 15px;
		margin: 0;
		min-height: auto;
	}

	.ph-cart-form-address-main-title {
		text-align: center;
	}

	.ph-cart-form-address-form-search-list {
		top: 131px;
		left: 36px;
	}
}

/** @Preorder Style **/

.ph-request-form-address-form-main-container {
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.ph-request-form-address-inner-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	border: 1px solid #ddd;
	border-radius: 6px;
	padding: 5px;
	margin-bottom: 10px;
	width: 100%;
}

.ph-request-form-address-image-logo-container {
	margin-right: 4px;
}

.ph-request-form-address-image-logo {
	width: 100%;
	max-width: 20px;
}

.ph-request-form-address-step-title {
	margin-bottom: 0;
	color: #777;
	font-size: 14px;
	line-height: 15px;
	width: 100%;
}

.ph-request-form-address-step-button,
.ph-request-form-address-step-button:hover,
.ph-request-form-address-step-button:active,
.ph-request-form-address-step-button:focus {
	border: 1px solid #2e72b6;
	background-color: #2e72b6;
	color: #fff;
	border-radius: 6px;
	margin-left: 5px;
}

.ph-request-form-address-step-button .anticon-close-circle {
	font-size: 20px;
}

.ph-request-form-address-form-container {
	display: flex;
	justify-content: center;
	width: 100%;
	align-items: flex-start;
}

.ph-request-form-address-description-container {
	position: relative;
}

.ph-request-form-address-form-container .ant-form-item {
	width: 100%;
}

.ph-request-form-address-form-search-field {
	font-size: 14px;
	height: 45px;
	border-radius: 6px 0 0 6px;
	border: 1px solid #2e72b6;
}

.ph-request-form-address-form-search-button,
.ph-request-form-address-form-search-button:hover,
.ph-request-form-address-form-search-button:focus,
.ph-request-form-address-form-search-button:active {
	width: 80px;
	height: 45px;
	border-radius: 0 6px 6px 0;
	border: 1px solid #2e72b6;
	background-color: #2e72b6;
	color: #ffffff;
	font-size: 15px;
}

.ph-request-form-address-form-search-button .anticon-search {
	font-size: 21px;
}

.ph-request-form-address-title-form-search-button {
	font-size: 12px;
	color: #fff;
	margin-top: 6px;
	font-weight: 900;
}

.ph-request-form-address-form-search-list {
	position: absolute;
	top: 45px;
	left: 0;
	background-color: #ffffff;
	z-index: 1;
	box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
		0 1px 5px 0 rgb(0 0 0 / 12%);
	width: 100%;
	max-width: 360px;
}

.ph-request-form-address-form-search-list-image-container {
	margin-right: 4px;
}

.ph-request-form-address-form-search-list-image {
	width: 100%;
	max-width: 14px;
	margin-top: -4px;
}

.ph-request-form-address-form-search-list-description {
	height: auto !important;
	line-height: 15px !important;
	padding: 7px 5px !important;
	margin: 0 !important;
	color: #777 !important;
	font-size: 13px;
	width: 100%;
	border-bottom: 1px solid #ddd;
}

.ph-request-form-address-form-search-list-description:last-child {
	border-bottom: none;
}

/** @Caculate Dispatch Style **/

.ph-repurchase-form-address-main-container {
	background-color: #fff;
	transition: 0.3s ease;
	display: flex !important;
	justify-content: space-between !important;
	flex-direction: column !important;
	margin-top: 3px;
}

.ph-repurchase-form-address-description-container {
	margin-top: 7px;
	margin-bottom: 22px;
	border: 1px solid $primaryColorBorder;
	border-radius: 4px;
}

.ph-repurchase-form-address-inner-container {
	position: relative;
	display: flex;
	width: 100%;
	padding: 8px 5px;
}

.ph-repurchase-form-address-image-logo-container {
	margin-right: 0;
	margin-top: -2px;
}

.ph-repurchase-form-address-image-logo {
	width: 100%;
	max-width: 18px;
}

.ph-repurchase-form-address-step-title {
	color: #797979;
	font-size: 12px;
	font-weight: 500;
	margin-bottom: 0;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 235px;
	width: 100%;
}

.ph-repurchase-form-address-step-button,
.ph-repurchase-form-address-step-button:hover,
.ph-repurchase-form-address-step-button:focus,
.ph-repurchase-form-address-step-button:active {
	position: absolute;
	right: 3px;
	top: 3px;
	padding: 0;
	height: auto;
	line-height: 15px;
	background-color: #2e72b6;
	border-radius: 4px;
	color: #fff;
	padding: 4px 4px 2px 4px;
	font-size: 19px;
}

.ph-repurchase-form-address-step-button-arg,
.ph-repurchase-form-address-step-button-arg:hover,
.ph-repurchase-form-address-step-button-arg:focus,
.ph-repurchase-form-address-step-button-arg:active {
	@extend .ph-repurchase-form-address-step-button;
	background-color: $primaryColor;
}


.ph-repurchase-form-address-title {
	color: #8e8e8e;
	font-size: 10px;
	margin-bottom: 0;
	line-height: 10px;
	text-overflow: ellipsis;
	text-decoration: none;
	overflow: hidden;
	white-space: nowrap;
	font-weight: 500;
	width: 100%;
	max-width: 195px;
	transition: 0.3s ease;
}

.ph-repurchase-form-address-subtitle {
	font-size: 13px;
	text-overflow: ellipsis;
	text-decoration: none;
	overflow: hidden;
	white-space: nowrap;
	font-weight: 500;
	color: #8e8e8e;
	margin-bottom: 0;
	width: 100%;
	max-width: 195px;
	line-height: 18px;
	transition: 0.3s ease;
}

.ph-repurchase-form-address-title:hover {
	color: #00bcd4;
	transition: 0.3s ease;
}

.ph-repurchase-form-address-subtitle:hover {
	color: #00bcd4;
	transition: 0.3s ease;
}

.ph-repurchase-form-address-form-main-container {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	max-width: 100%;
	box-shadow: 1px 1px 5px 2px rgb(221 221 221);
	border-radius: 4px;
}

.ph-repurchase-form-address-form-container {
	position: relative;
	max-width: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	margin-bottom: 0;
	width: 100%;
}

.ph-repurchase-form-address-form-container .ant-form-item {
	width: 100%;
	height: 36px;
	margin-bottom: 0;
}

.ph-repurchase-form-address-form-container .ant-form-item-has-error .ant-input:focus,
.ph-repurchase-form-address-form-container .ant-form-item-has-error .ant-input {
	border-color: #ddd !important;
	box-shadow: none !important;
	border-right-width: 0 !important;
	font-size: 12px !important;
}

.ph-repurchase-form-address-form-search-field {
	height: 36px;
	width: 100%;
	border: 1px solid #ddd;
	border-right-width: 0 !important;
	border-radius: 4px 0 0 4px;
	font-size: 12px;
}

.ph-repurchase-form-address-form-search-field:hover,
.ph-repurchase-form-address-form-search-field:focus,
.ant-input-focused {
	border: 1px solid #ddd !important;
	border-color: #ddd !important;
	border-right-width: 0 !important;
	box-shadow: none !important;
}

.ph-repurchase-form-address-form-search-field::selection {
	background: transparent !important;
}

.ph-repurchase-form-address-form-search-field-radius {
	border-radius: 4px 0 0 4px;
}

.ph-repurchase-form-address-form-search-field-no-radius {
	border-radius: 0;
}

.ph-repurchase-form-address-form-search-button {
	margin: 0;
	width: auto;
	height: 36px;
	border: 1px solid $primaryColor !important;
	border-radius: 0 4px 4px 0;
	background-color: $primaryColor !important;
	padding: 0 10px !important;
	color: #fff;
}


.ph-repurchase-form-address-form-search-button .anticon {
	font-size: 20px;
	font-weight: 900;
	line-height: 40px;
}

.ph-repurchase-form-address-title-form-search-button {
	font-size: 13px;
	font-weight: 900;
	color: #fff;
	margin-top: -1px;
	margin-bottom: 0px;
}

.ph-repurchase-form-address-form-search-list {
	position: absolute;
	top: 47px;
	left: 1px;
	z-index: 2;
	width: 100%;
	max-width: 232px;
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
		0 1px 5px 0 rgba(0, 0, 0, 0.12);
	background-color: #fff;
}

.ph-repurchase-form-address-form-search-list-description {
	background-color: #fff !important;
	cursor: pointer;
	text-align: left;
	padding: 6px !important;
	border-bottom: 1px solid #ddd;
	font-size: 12px;
	text-overflow: ellipsis !important;
	text-decoration: none;
	overflow: hidden !important;
	white-space: nowrap;
	max-width: 100%;
	width: 100%;
	margin: 0 !important;
	height: auto !important;
	line-height: 1.5 !important;
	color: rgba(0, 0, 0, 0.85) !important;
}

.ph-repurchase-form-address-form-search-list-description:hover {
	background-color: rgba(46, 114, 182, 0.1) !important;
}

.ph-repurchase-form-address-form-search-list-image-container {
	margin-right: 2px;
}

.ph-repurchase-form-address-on-demand-description {
	color: #d7141f;
	font-style: italic;
	font-size: 12px;
	font-weight: 500;
	margin-bottom: 0;
	width: 100%;
}

.ph-repurchase-form-address-on-demand-asterisk {
	color: #d7141f;
}

.ph-repurchase-form-address-form-search-list-image {
	width: 100%;
	max-width: 17px;
}

.ph-repurchase-form-address-form-container .ant-form-item-explain {
	font-size: 11px !important;
	margin-left: 14px;
}

.ph-repurchase-form-address-loading-container {
	width: 100%;
	text-align: center;
	margin-left: -45px;
	margin-bottom: 2px;
	margin-top: 10px;
}

.ph-repurchase-form-address-icon {
	color: #2d72b7;
}

.ph-repurchase-form-address-image {
	font-size: 20px;
}