@import '../../../v2-config/colors.scss';

@mixin link-style {
	font-size: 14px;
	letter-spacing: 1px;
	font-weight: 600;
	cursor: pointer;
}
.link-primary {
	@include link-style;
	color: $primary-default;

	&:hover {
		color: $primary-hover;
	}

	&:active {
		color: $primary-active;
	}
}
