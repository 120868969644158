/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-product-detail-price-promotion {
	font-size: 28px;
	color: $primaryColor;
	font-weight: 600;
	margin-bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-product-detail-price-promotion-symbol {
	font-size: 17px;
	color: $primaryColor;
	font-weight: 600;
	margin-bottom: -2px;
}
