/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-card-profile-main-container {
	padding: 20px;
	border-radius: 4px;
	box-shadow: $primaryBoxShadow;
	margin-bottom: 30px;
}

@media (max-width: 575px) {
	.ph-card-profile-main-container {
		padding: 10px;
	}
}
