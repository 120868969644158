/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-complaint-book-pagination-main-container {
	padding-top: 30px;
	text-align: center;
}

.ph-complaint-book-filter-pagination-container .ant-pagination-item,
.ph-complaint-book-filter-pagination-container .ant-pagination-prev,
.ph-complaint-book-filter-pagination-container .ant-pagination-next {
	list-style: none;
	min-width: 25px !important;
	height: 25px !important;
	width: 25px !important;
	box-shadow: $mainHeaderboxShadow;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 0.15rem;
	border-radius: 4px;
	text-align: center;
	border: 4px;
}

.ph-complaint-book-filter-pagination-container .ant-pagination-item-active {
	background-color: $primaryColor;
	border: 1px solid $primaryColor;
}

.ph-complaint-book-filter-pagination-container .ant-pagination-item a {
	color: $colorGrayText2;
	display: inline-block;
	font-size: 12px;
	padding: 0 3px;
	line-height: 24px;
	height: 25px !important;
	width: 25px !important;
}

.ph-complaint-book-filter-pagination-container .ant-pagination-item-active a {
	color: $colorWhiteText !important;
}

.ph-complaint-book-filter-pagination-container .ant-pagination-item-link {
	font-size: 8px;
	border-radius: 4px;
}

.ph-complaint-book-filter-pagination-container .anticon {
	position: relative;
	top: -3px;
}
