/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-preorder-schedule-succes-global-container {
	max-width: 900px;
	padding: 10px;
	margin: auto;
}

.ph-preorder-schedule-success-main-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: auto;
	min-height: calc(100vh - 133px);
}

.ph-preorder-schedule-success-inner-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.ph-preorder-schedule-success-img-container {
	text-align: center;
	margin-bottom: 5px;
}

.ph-preorder-schedule-success-img {
	width: 250px;
	//max-width: 60px;
	margin: auto;
}

.ph-preorder-schedule-success-buttons-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 5px 0;
}

.ph-preorder-schedule-success-title {
	color: $primaryColor;
	font-size: 28px;
	font-weight: 900;
	margin-bottom: 5px;
}

.ph-preorder-schedule-success-subtitle {
	text-align: center;
	color: $colorGrayText2;
	font-size: 18px;
	font-weight: 600;
	margin-top: 0;
	margin-bottom: 0;
}

.ph-magistral-recipe-schedule-success-paraghaf {
	color: $colorGrayText3;
	font-weight: 500;
	font-style: italic;
	font-size: 15px;
	margin-top: 0px;
	margin-bottom: 0px;
}

.ph-preorder-schedule-success-description-span {
	text-align: center;
	color: $colorGrayText2;
	font-size: 15px;
	font-weight: 500;
	margin-top: 0;
	margin-bottom: 15px;
}

.ph-preorder-schedule-success-description-span-i {
	color: $errorColor;
	padding-right: 5px;
}

.ph-preorder-schedule-success-description-contact {
	color: $primaryColor;
	font-size: 15px;
	padding-left: 5px;
}

.ph-preorder-schedule-success-button-return,
.ph-preorder-schedule-success-button-return:active,
.ph-preorder-schedule-success-button-return:focus,
.ph-preorder-schedule-success-button-return:hover {
	background-color: $secondColor !important;
	color: $colorWhiteText;
	padding: 5px 10px;
	font-size: 15px;
	border-radius: 4px;
	font-weight: 900;
	border: 2px solid $secondColor !important;
	text-transform: capitalize;
	height: 38px;
	width: 170px;
	text-align: center;
}

@media screen and (max-width: 992px){
	.ph-preorder-schedule-success-img {
		width: 130px;
	}
}

@media (max-width: 576px) {
	.ph-preorder-schedule-success-title {
		text-align: center;
		font-size: 22px;
	}
	.ph-preorder-schedule-success-subtitle {
		font-size: 16px;
	}
	.ph-preorder-schedule-success-description-span,
	.ph-preorder-schedule-success-description-contact {
		font-size: 12px;
	}
}

@media (max-width: 414px) {
	.ph-preorder-schedule-success-title {
		text-align: center;
		font-size: 16px;
	}
	.ph-preorder-schedule-success-subtitle {
		font-size: 14px;
	}
	.ph-preorder-schedule-success-description-span,
	.ph-preorder-schedule-success-description-contact {
		font-size: 10px;
	}
	.ph-preorder-schedule-success-img {
		max-width: 40px;
	}
}
