/** @format */

@import "../../../components/Commons/Sass/components/colors";

.ph-token-global-container {
  max-width: 1185px;
  padding: 10px;
  margin: auto;
  min-height: calc(100vh - 113px);
}

.ph-token-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ph-token-inner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ph-token-img {
  width: 100%;
  max-width: 250px;
}

.ph-token-succes-title {
  color: $primaryColor;
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 0;
}

.ph-token-description {
  font-style: italic;
  text-align: center;
  color: $colorGrayText2;
  font-size: 15px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 15px;
  max-width: 800px;
}

.ph-token-description-span-i {
  color: $errorColor;
  padding-right: 5px;
}

.ph-token-description-contact {
  color: $primaryColor;
  font-size: 15px;
  padding-left: 5px;
}

.ph-token-payment-button-container {
  display: flex;
  justify-content: center;
  margin-top: 0px;
}

.ph-token-payment-button,
.ph-token-payment-button:focus,
.ph-token-payment-button:hover,
.ph-token-payment-button:active {
  color: $colorWhiteText;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 900;
  height: 40px;
  border: 2px solid $secondColor;
  background-color: $secondColor;
  max-width: 200px;
  padding: 0 20px;
  box-shadow: $primaryBoxShadow;
  line-height: 34px;
}

.ph-token-payment-button-span {
  padding-top: 2px;
}

@media screen and (max-width: 992px) {
  .ph-token-succes-title {
    font-size: 28px;
  }
}
@media screen and (max-width: 768px) {
  .ph-token-succes-title {
    font-size: 25px;
  }
}
@media screen and (max-width: 576px) {
  .ph-token-succes-title {
    font-size: 20px;
  }
}
