@import "../../Sass/components/colors";

.ph-modal-global-welcome-by-date-info-modal-container .ant-modal-content {
  border-radius: 5px;
}

.ph-modal-global-welcome-by-date-info-modal-container
  .ant-modal-content
  .ant-modal-close {
  top: 12px;
  right: 15px;
}

.ph-modal-global-welcome-by-date-info-modal-container
  .ant-modal-content
  .ant-modal-close-x {
  border-radius: 100px;
  color: $colorGrayText2;
  font-weight: bold;
  width: 20px;
  height: 20px;
  font-size: 16px;
  line-height: 0;
  padding: 2px;
}

.ph-modal-global-welcome-by-date-info-modal-container .ant-modal-header {
  border-bottom: 1px solid $primaryColorBorder !important;
  padding: 10px 20px;
  border-radius: 5px 5px 0 0;
}

.ph-modal-global-welcome-by-date-info-modal-container
  .ant-modal-header
  .ant-modal-title {
  font-size: 16px;
  font-weight: 500;
  color: $colorGrayText4;
}

.ph-modal-global-welcome-by-date-info-modal-container
  .ant-modal-content
  .ant-modal-body {
  padding: 0 10px 5px 10px;
}

.ph-modal-global-welcome-by-date-info-modal-container .ant-modal-footer {
  display: none;
}

.ph-modal-global-welcome-by-date-info-delivery-main-container {
  width: 100%;
  text-align: center;
  padding: 5px;
  margin-top: 10px;
}

.ph-modal-global-welcome-by-date-info-delivery-image-container {
  padding: 3px;
}

.ph-modal-global-welcome-by-date-info-delivery-image {
  width: 100%;
  max-width: 100px;
}

.ph-modal-global-welcome-by-date-info-delivery-description {
  margin-bottom: 0;
  font-size: 14px;
  padding: 10px;
  color: $colorGrayText4;
  letter-spacing: -0.49px;
  line-height: 18px;
}

.ph-modal-global-welcome-button {
  background-color: $secondColor;
  border-color: $secondColor;
  color: $colorWhiteText;
  margin: 5px 0 8px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
  text-transform: uppercase;
  width: 100%;
  height: 35px;
  max-width: 165px;
  box-shadow: $secondaryBoxShadow;
  margin-left: 10px;
  margin-right: 10px;
}

.ph-modal-global-welcome-button:hover,
.ph-modal-global-welcome-button:focus,
.ph-modal-global-welcome-button:active {
  background-color: $secondColor;
  border-color: $secondColor;
  color: $colorWhiteText;
}

.ph-modal-global-welcome-button-alternative,
.ph-modal-global-welcome-button-alternative:hover,
.ph-modal-global-welcome-button-alternative:focus,
.ph-modal-global-welcome-button-alternative:active {
  background-color: $primaryBackgroundColor;
  border: none;
  color: $primaryColor;
  margin: 5px 0 8px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
  letter-spacing: -0.49px;
  text-transform: uppercase;
  width: 100%;
  height: 35px;
  max-width: 165px;
  box-shadow: none;
  margin-left: 10px;
  margin-right: 10px;
}

.ph-modal-global-welcome-button-main-container {
  text-align: center;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .ph-modal-global-welcome-button-main-container {
    max-width: 200px;
    margin: auto;
    margin-top: 10px;
  }
}
