/** @format */

@import '../../../../../components/Commons/Sass/components/colors';

.ph-confirm-global-container {
	max-width: 1185px;
	padding: 10px;
	margin: auto;
}

.ph-confirm-main-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 60vh;
}

.ph-confirm-inner-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.ph-confirm-img {
	width: 100%;
	max-width: 250px;
}

.ph-confirm-succes-title {
	color: $primaryColor;
	font-size: 30px;
	font-weight: 800;
	margin-bottom: 0;
}

.ph-confirm-description {
	font-style: italic;
	text-align: center;
	color: $colorGrayText2;
	font-size: 15px;
	font-weight: 600;
	margin-top: 5px;
	margin-bottom: 15px;
}

.ph-confirm-description-span-i {
	color: $errorColor;
	padding-right: 5px;
}

.ph-confirm-description-contact {
	color: $primaryColor;
	font-size: 15px;
	padding-left: 5px;
}

.ph-confirm-payment-button-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-confirm-payment-button,
.ph-confirm-payment-button:focus,
.ph-confirm-payment-button:hover,
.ph-confirm-payment-button:active {
	color: $colorWhiteText;
	font-size: 14px;
	border-radius: 4px;
	font-weight: 900;
	height: 40px;
	padding: 0 20px;
	border: 2px solid $secondColor;
	background-color: $secondColor;
	max-width: 220px;
	box-shadow: $primaryBoxShadow;
	line-height: 34px;
}

.ph-confirm-payment-button-span {
	padding-top: 2px;
}

@media screen and (max-width: 992px) {
	.ph-confirm-succes-title {
		font-size: 28px;
	}
}
@media screen and (max-width: 768px) {
	.ph-confirm-succes-title {
		font-size: 25px;
	}
}
@media screen and (max-width: 576px) {
	.ph-confirm-succes-title {
		font-size: 20px;
	}
}
