@import "../../../../../../components/Commons/Sass/components/colors";

.ph-bag-recipes-empty-container {
  min-height: 189px;
  max-height: 189px;
}

.ph-bag-recipes-more-inner-container {
  overflow-y: scroll;
  height: 62px;
}

.ph-bag-recipes-main-container {
  margin-top: 10px;
  margin-bottom: 10px;
}
.ph-bag-recipes-inner-container {
  display: flex;
  align-items: center;
}

.ph-bag-recipes-title-container {
  margin-left: 4px;
}

.ph-bag-recipes-main-title {
  color: $colorGrayText4;
  font-size: 13px;
  font-weight: 900;
  margin-bottom: 0;
  margin-left: 6px;
}

.ph-bag-recipes-main-title-dot {
  width: 8px;
  height: 8px;
  border: 2px solid $secondColor;
  border-radius: 100%;
  display: inline-block;
}

.ph-bag-recipes-info-list-more-container:first-child {
  padding-top: 25px;
}

.ph-bag-recipes-row-container{
  justify-content: space-between;
} 

.ph-bag-recipes-info-list-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ph-bag-recipes-info-image-container {
  padding: 0 4px;
}

.ph-bag-recipes-info-image {
  width: 100%;
  max-width: 15px;
  position: relative;
  top: -2px;
}

.ph-bag-recipes-info-title {
  margin-bottom: 0;
  color: $colorGrayText3;
  font-size: 12px;
  font-weight: 600;
  padding-left: 3px;
}

.ph-bag-recipes-info-title-success {
  color: $successColor;
  font-style: italic;
  cursor: pointer;
}

.ph-bag-recipes-info-upload-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  cursor: pointer;
  svg{
    width: 24px;
    height: 24px;
  }
}

.ph-bag-recipes-info-upload-inner-container {
  text-align: center;
  position: relative;
  right: 25px;
}

.ph-bag-recipes-info-upload-image-container {
  padding: 4px;
  display: block;
}

.ph-bag-recipes-info-upload-image {
  width: 100%;
  max-width: 24px;
}

.ph-bag-recipes-info-upload-icon {
  color: $successColor;
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
}

.ph-bag-recipes-info-upload-title {
  color: $primaryColor;
  text-decoration: underline;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
}

.ph-bag-recipes-info-upload-help-title {
  margin: 0;
  padding: 5px 10px;
  font-size: 12px;
  font-style: italic;
  color: $colorGrayText3;
}

.ph-bag-recipes-info-upload-title-success {
  color: $successColor;
  text-decoration: underline;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
}

.ph-bag-recipes-info-upload-subtitle-success {
  letter-spacing: -0.42px;
  color: #088926;
  opacity: 1;
  font-weight: 300;
  font-size: 12px;
  text-align: center;
  padding-left: 20px;
}

.ph-bag-recipes-modal-container .ant-modal-content {
  border-radius: 8px;
}

.ph-bag-recipes-modal-container .ant-modal-content .ant-modal-close {
  top: 10px;
  right: 15px;
}

.ph-bag-recipes-modal-container .ant-modal-content .ant-modal-close-x {
  border: 2px solid $errorColor;
  border-radius: 100px;
  color: $errorColor;
  font-weight: bold;
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 0;
  padding: 2px;
}

.ph-bag-recipes-modal-container .ant-modal-header {
  border-bottom: 1px solid $primaryColorBorder !important;
  padding: 7px;
  border-radius: 8px 8px 0 0;
}

.ph-bag-recipes-modal-container .ant-modal-header .ant-modal-title {
  font-size: 16px;
  font-weight: 500;
  font-weight: 600;
  color: $colorGrayText4;
  margin-left: 5px;
}

.ph-bag-recipes-modal-container .ant-modal-content .ant-modal-body {
  padding: 15px;
}

.ph-bag-recipes-modal-container .ant-modal-footer {
  display: none;
}

.ph-bag-recipes-modal-warning-title {
  padding: 10px;
  margin-bottom: 0;
  text-align: center;
  color: $primaryColor;
  font-size: 14px;
}

.ph-bag-recipes-modal-warning-subtitle {
  padding: 10px;
  margin-bottom: 0;
  text-align: center;
  color: $secondColor;
  font-size: 15px;
  font-style: italic;
}

.ph-bag-recipes-modal-warning-button-container {
  text-align: center;
  margin: 10px 0;
}

.ph-bag-recipes-modal-warning-button-1,
.ph-bag-recipes-modal-warning-button-1:hover,
.ph-bag-recipes-modal-warning-button-1:focus,
.ph-bag-recipes-modal-warning-button-1:active {
  width: 100%;
  max-width: 120px;
  border: 2px solid $primaryColor;
  border-radius: 4px;
  box-shadow: $primaryBoxShadow;
  color: $primaryColor;
  font-weight: 500;
  margin: 0 5px;
}

.ph-bag-recipes-modal-warning-button-2,
.ph-bag-recipes-modal-warning-button-2:hover,
.ph-bag-recipes-modal-warning-button-2:focus,
.ph-bag-recipes-modal-warning-button-2:active {
  width: 100%;
  max-width: 120px;
  border: 2px solid $primaryColor;
  border-radius: 4px;
  background-color: $primaryColor;
  box-shadow: $primaryBoxShadow;
  color: $colorWhiteText;
  font-weight: 500;
  margin: 0 5px;
}

.ph-bag-recipes-modal-preview-product-title-container {
  margin-bottom: 10px;
}

.ph-bag-recipes-modal-preview-product-image-container {
  padding: 3px;
}

.ph-bag-recipes-modal-preview-product-image {
  width: 100%;
  max-width: 20px;
}

.ph-bag-recipes-modal-preview-product-title {
  font-size: 12px;
  margin-bottom: 0;
  margin-top: 3px;
}

.ph-bag-recipes-modal-preview-image-container {
  padding: 5px;
}

.ph-bag-recipes-modal-preview-image {
  width: 100%;
}

.ph-bag-address-coverage-container {
  background-color: $primaryBackgroundColor;
  transition: 0.3s ease;
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: column !important;
  margin-left: 17px;
  margin-top: 8px;
}

.ph-bag-address-coverage-container-recipes-up {
  background-color: $primaryBackgroundColor;
  transition: 0.3s ease;
  display: block !important;
  justify-content: space-between !important;
  flex-direction: column !important;
  margin-left: 12px;
  margin-top: 8px;
}
.ph-bag-recipes-coverage-inputs {
  height: 36px;
  width: 100%;
  border: 1px solid #AAAAAA;
  border-radius: 4px;
  margin-bottom: 15px;
}

.ph-bag-recipes-coverage-inputs-error {
  height: 36px;
  width: 100%;
  border: 1px solid red;
  border-radius: 4px;
  margin-bottom: 15px;
}

.ph-bag-recipes-coverage-title {
  letter-spacing: -0.42px;
  color: #7B7B7B;
}

.ph-col-align-icons {
  align-self: flex-start;
}

@media (max-width: 992px) {
  .ph-bag-recipes-info-list-container {
    margin-left: 5px;
  }

  .ph-bag-recipes-info-title {
    font-size: 11px;
  }
}

.ph-check-recipe-cart {
  text-align: left;
  letter-spacing: -0.42px;
  color: #7B7B7B;
  opacity: 1;
  font-size: 13px;

    .ant-checkbox {
      top: 1.2em;
    }
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-checked.ph-check-recipe-cart-cl {
  display: none !important;
}