/** @format */

@import "../Sass/components/colors";

.ph-profile-main-box-title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;
}

.ph-profile-main-box-title-image-container {
  margin-right: 7px;
}

.ph-profile-main-box-title-image {
  width: 100%;
  max-width: 25px;
}

.ph-profile-main-box-main-title {
  color: $colorGrayText4;
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 0;
}

.ph-profile-main-box-main-subtitle {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 20px;
  color: $primaryColor;
  margin-left: 35px;
  // opacity: 0.7;
}

.ph-profile-main-box-title-detail-container {
  margin-top: 5px !important;
}

.ph-profile-main-box-title-detail-title {
  font-size: 22px !important;
  line-height: 24px !important;
}

.ph-profile-main-box-title-detail-description {
  font-size: 17px !important;
  margin-bottom: 10px !important;
}

@media (max-width: 768px) {
  .ph-profile-main-box-title-container {
    margin-top: 10px;
  }

  .ph-profile-main-box-title-image-container {
    margin-right: 3px;
  }

  .ph-profile-main-box-main-title {
    line-height: 24px;
  }

  .ph-profile-main-box-main-subtitle {
    margin-bottom: 15px;
    margin-left: 30px;
  }
}

@media (max-width: 768px) {
  .ph-profile-main-box-title-container {
    margin-top: 5px;
  }

  .ph-profile-main-box-main-title {
    font-size: 22px;
  }

  .ph-profile-main-box-main-subtitle {
    font-size: 17px;
  }
}

@media (max-width: 575px) {
  .ph-profile-main-box-main-title {
    font-size: 20px;
  }

  .ph-profile-main-box-title-detail-container {
    margin-top: 50px !important;
  }

  .ph-profile-main-box-title-detail-title {
    font-size: 18px !important;
  }

  .ph-profile-main-box-title-detail-description {
    font-size: 15px !important;
  }
}