/** @format */

@import '../../components/Commons/Sass/components/colors';

.ph-how-to-by-container {
	overflow: hidden;
}

.ph-how-to-by-header-container {
	width: 100%;
	min-height: 60vh;
	position: relative;
	background-color: $colorWhiteText;
}

.ph-how-to-by-header-content {
	height: 60vh;
}

.ph-how-to-by-header-content:after {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.45);
	z-index: 0;
}

.ph-how-to-by-header-video-content {
	object-fit: cover;
	height: 60vh;
	z-index: 0;
}

.ph-how-to-by-header-link {
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 2;
}
.ph-how-to-by-header-link-image {
	width: 100%;
	max-width: 100px;
	margin-right: 10px;
}

.ph-how-to-by-header-text-container {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ph-how-to-by-header-text-content {
	text-align: center;
}

.ph-how-to-by-header-image {
	width: 100%;
	max-width: 200px;
	filter: drop-shadow(5px 5px 5px $colorBackText);
}

.ph-how-to-by-header-title {
	color: $colorWhiteText;
	margin-bottom: 10px;
	font-size: 50px;
	text-shadow: 4px 4px 5px $colorBackText;
	font-weight: bolder;
}

.ph-how-to-by-header-subtitle {
	color: $colorWhiteText;
	font-size: 20px;
	text-shadow: 4px 4px 5px $colorBackText;
}

.ph-how-to-by-global-container {
	max-width: 1185px;
	padding: 10px;
	margin: auto;
}

.ph-how-to-by-step-one-contentainer {
	background-color: $primaryBackgroundColor;
	z-index: 1;
	position: relative;
	padding-top: 50px;
}

.ph-how-to-by-main-content-text-step-one {
	padding: 30px;
	margin: auto;
}

.ph-how-to-by-main-step {
	color: $primaryColor;
	font-size: 20px;
	font-style: italic;
}

.ph-how-to-by-main-title {
	color: $colorGrayText4;
	line-height: 35px;
	margin-bottom: 20px;
	font-weight: bolder;
}

.ph-how-to-by-main-subtitle {
	color: $colorGrayText3;
	font-size: 15px;
}

.ph-how-to-by-main-content-img-step-one {
	position: relative;
	padding: 25px;
	min-height: 400px;
}

.ph-how-to-by-main-img-step-ones {
	width: 100%;
	box-shadow: $primaryBoxShadow;
	border-radius: 5px;
}

.ph-how-to-by-animation-desktop_recipe {
	width: 100%;
	left: 20px;
	box-shadow: $primaryBoxShadow;
	border-radius: 5px;
}

.ph-how-to-by-animation-movil_upload {
	position: absolute;
	left: 10px;
	bottom: 10px;
	max-width: 150px;
	width: 100%;
	box-shadow: $primaryBoxShadow;
	border-radius: 5px;
}

.ph-how-to-by-main-img-step-one-mobile {
	width: 100%;
	box-shadow: $primaryBoxShadow;
	border-radius: 5px;
	max-width: 150px;
}

.ph-how-to-by-animation-movilone {
	position: absolute;
	left: 0;
	top: 150px;
}

.ph-how-to-by-step-two-container {
	margin: 60px 0;
	background-color: $primaryBackgroundColor;
	z-index: 1;
	position: relative;
	padding-top: 50px;
}

.ph-how-to-by-main-content-img-step-two {
	position: relative;
	padding: 25px;
	min-height: 400px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ph-how-to-by-animation-gotas {
	position: absolute;
	left: 0;
	bottom: 0;
}

.ph-how-to-by-main-steptwo-img-one {
	width: 100%;
	max-width: 130px;
	box-shadow: $primaryBoxShadow;
	border-radius: 5px;
}

.ph-how-to-by-animation-direction {
	position: absolute;
	right: 0;
	top: 20px;
}

.ph-how-to-by-main-steptwo-img-two {
	width: 100%;
	max-width: 150px;
	box-shadow: $primaryBoxShadow;
	border-radius: 5px;
}

.ph-how-to-by-animation-entrega {
	position: absolute;
	left: 0;
	top: 20px;
}

.ph-how-to-by-main-steptwo-img-three {
	width: 100%;
	max-width: 250px;
	box-shadow: $primaryBoxShadow;
	border-radius: 5px;
}

.ph-how-to-by-animation-biooil {
	position: absolute;
	right: 0;
	bottom: 0;
}

.ph-how-to-by-main-steptwo-img-four {
	width: 100%;
	max-width: 130px;
	box-shadow: $primaryBoxShadow;
	border-radius: 5px;
}

.ph-how-to-by-main-steptwo-img-five {
	width: 100%;
	max-width: 550px;
	box-shadow: $primaryBoxShadow;
	border-radius: 5px;
}

.ph-how-to-by-main-content-text-step-two {
	padding: 30px;
	margin: auto;
}

.ph-how-to-by-main-step-two-title {
	color: $primaryColor;
	font-size: 20px;
	font-style: italic;
}

.ph-how-to-by-main-step-two-subtitle {
	color: $colorGrayText4;
	line-height: 35px;
	margin-bottom: 20px;
	font-weight: bolder;
}

.ph-how-to-by-main-step-two-paraghraf {
	color: $colorGrayText2;
	font-size: 15px;
}

.ph-how-to-by-pay-container {
	background-color: $primaryBackgroundColor;
	z-index: 1;
	position: relative;
	padding-top: 50px;
}

.ph-how-to-by-main-pay-text-content {
	max-width: 650px !important;
	text-align: center;
	padding-bottom: 25px;
	margin: auto;
}

.ph-how-to-by-main-pay-title {
	color: $primaryColor;
	font-size: 20px;
	font-style: italic;
}

.ph-how-to-by-main-pay-subtitle {
	color: $colorGrayText4;
	line-height: 35px;
	margin-bottom: 20px;
	font-weight: bolder;
}

.ph-how-to-by-main-pay-paraghraf {
	color: $colorGrayText3;
	font-size: 15px;
}

.ph-how-to-by-main-pay-img-screen {
	width: 100%;
	max-width: 800px;
	margin-left: 40px !important;
	box-shadow: $primaryBoxShadow;
	border-radius: 5px;
}

.ph-how-to-by-animation-map {
	position: absolute;
	left: 5px;
	top: 30px;
}

.ph-how-to-by-main-pay-img-map {
	width: 100%;
	box-shadow: $primaryBoxShadow;
	border-radius: 5px;

	max-width: 340px;
}

.ph-how-to-by-animation-calendar {
	position: absolute;
	top: 285px;
	left: 50px;
}

.ph-how-to-by-main-pay-img-calendar {
	width: 100%;
	box-shadow: $primaryBoxShadow;
	border-radius: 5px;
	max-width: 125px;
}

.ph-how-to-by-animation-farmacy {
	position: absolute;
	left: 0;
	top: 230px;
}

.ph-how-to-by-main-pay-img-farmacy {
	width: 100%;
	box-shadow: $primaryBoxShadow;
	border-radius: 5px;
	max-width: 330px;
}

.ph-how-to-by-animation-terms {
	position: absolute;
	left: 70px;
	top: 170px;
}

.ph-how-to-by-main-pay-img-terms {
	width: 100%;
	max-width: 285px;
	box-shadow: $primaryBoxShadow;
	border-radius: 5px;
}

.ph-how-to-by-animation-producto-movil {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}
.ph-how-to-by-animation-product-movil {
	width: 100%;
	position: absolute;
	max-width: 420px;
	box-shadow: $primaryBoxShadow;
	border-radius: 5px;
}

.ph-how-to-by-animation-webpay {
	position: absolute;
	right: 0;
	top: 200px;
}

.ph-how-to-by-main-pay-img-webpay {
	width: 100%;
	max-width: 100px;
	box-shadow: $primaryBoxShadow;
	border-radius: 5px;
}

.ph-how-to-by-animation-chile {
	position: absolute;
	top: 15px;
	right: 20px;
}

.ph-how-to-by-main-pay-img-bankchile {
	width: 100%;
	max-width: 100px;
	box-shadow: $primaryBoxShadow;
	border-radius: 5px;
}

.ph-how-to-by-animation-bci {
	position: absolute;
	right: 45px;
	bottom: 20px;
}

.ph-how-to-by-main-pay-img-bci {
	width: 100%;
	max-width: 100px;
	box-shadow: $primaryBoxShadow;
	border-radius: 5px;
}

.ph-how-to-by-animation-trans {
	position: absolute;
	bottom: 20px;
	right: 175px;
}

.ph-how-to-by-main-pay-img-trans {
	width: 100%;
	max-width: 100px;
	box-shadow: $primaryBoxShadow;
	border-radius: 5px;
}

.ph-how-to-by-animation-estado {
	position: absolute;
	top: 15px;
	right: 180px;
}

.ph-how-to-by-main-pay-img-bankestado {
	width: 100%;
	max-width: 100px;
	box-shadow: $primaryBoxShadow;
	border-radius: 5px;
}

.ph-how-to-by-animation-bbva {
	position: absolute;
	right: 130px;
	top: 200px;
}

.ph-how-to-by-main-pay-img-bbva {
	width: 100%;
	max-width: 100px;
	box-shadow: $primaryBoxShadow;
	border-radius: 5px;
}

.ph-how-to-by-animation-ripley {
	position: absolute;
	right: 30px;
	top: 110px;
}

.ph-how-to-by-main-pay-img-ripley {
	width: 100%;
	max-width: 100px;
	box-shadow: $primaryBoxShadow;
	border-radius: 5px;
}

.ph-how-to-by-animation-sweetpay {
	position: absolute;
	right: 150px;
	top: 105px;
}

.ph-how-to-by-main-pay-img-sweetpay {
	width: 100%;
	max-width: 100px;
	box-shadow: $primaryBoxShadow;
	border-radius: 5px;
}

.ph-how-to-by-important-contentainer {
	background-color: $primaryBackgroundColor;
	z-index: 1;
	position: relative;
	margin-top: 50px;
}

.ph-how-to-by-important-text-container {
	margin: auto;
}

.ph-how-to-by-important-title {
	color: $primaryColor;
	font-size: 20px;
	font-style: italic;
}

.ph-how-to-by-important-subtitle {
	color: $colorGrayText3;
	line-height: 35px;
	margin-bottom: 20px;
	font-weight: bolder;
}

.ph-how-to-by-important-paraghraf {
	color: $colorGrayText2;
	font-size: 15px;
}

.ph-how-to-by-important-enlace {
	font-size: 14px;
	color: $primaryColor;
	font-style: italic;
}

.ph-how-to-by-animation-wp {
	position: absolute;
	top: 85px;
	right: 0;
}

.ph-how-to-by-important-recipewp {
	width: 100%;
	box-shadow: $primaryBoxShadow;
	border-radius: 5px;
	max-width: 200px;
	margin-right: 10px;
}

.ph-how-to-by-animation-upload {
	position: absolute;
	right: 0;
	bottom: 70px;
}

.ph-how-to-by-important-upload {
	width: 100%;
	box-shadow: $primaryBoxShadow;
	border-radius: 5px;
	max-width: 330px;
	margin-right: 10px;
}

.ph-how-to-by-dowlond-contentainer {
	background-color: $primaryBackgroundColor;
	z-index: 1;
	position: relative;
	margin: 130px 0 50px 0;
}

.ph-how-to-by-dowlond-content {
	text-align: center;
}

.ph-how-to-by-dowlond-content-title {
	color: $primaryColor;
	font-size: 20px;
	font-style: italic;
}

.ph-how-to-by-dowlond-content-subtitle {
	color: $colorGrayText4;
	line-height: 40px;
	margin-bottom: 20px;
	font-size: 45px;
}

.ph-how-to-by-dowlond-content-paraghraf {
	color: $colorGrayText3;
	font-size: 15px;
}

.ph-how-to-by-dowlond-content-buttons {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 30px;
}

.ph-how-to-by-dowlond-content-playstore,
.ph-how-to-by-dowlond-content-appstore {
	max-width: 200px;
}

.ph-how-to-by-dowlond-playstore,
.ph-how-to-by-dowlond-appstore {
	max-width: 200px;
	width: 100%;
	margin-bottom: 40px;
	padding: 5px;
}

@media (min-width: 1200px) {
	.ph-how-to-by-animation-producto-movil {
		display: none;
	}
}

@media (max-width: 992px) {
	.ph-how-to-by-step-one-contentainer {
		margin-bottom: 20px;
	}
	.ph-how-to-by-step-two-container {
		margin: 40px 0 10px 0;
	}
	.ph-how-to-by-pay-container {
		margin-bottom: -10px;
	}
	.ph-how-to-by-important-contentainer {
		margin-bottom: -10px;
	}
	.ph-how-to-by-dowlond-contentainer {
		margin-bottom: -10px;
	}

	.ph-how-to-by-animation-upload {
		bottom: 50px;
	}
	.ph-how-to-by-animation-producto-movil {
		display: none;
	}
	.ph-how-to-by-important-upload {
		margin-right: -15px;
	}
	.ph-how-to-by-important-recipewp {
		margin-right: -5px;
	}
}

@media (max-width: 768px) {
	.ph-how-to-by-main-pay-img-screen {
		margin-left: 0px !important;
	}
	.ph-how-to-by-main-content-text-step-one,
	.ph-how-to-by-main-content-text-step-two {
		text-align: center;
		margin-bottom: 0;
	}
	.ph-how-to-by-animation-upload {
		bottom: 50px;
	}
	.ph-how-to-by-animation-producto-movil {
		display: none;
	}
	.ph-how-to-by-important-text-container {
		margin-top: 30px;
		text-align: center;
		margin-bottom: 20px;
	}
	.ph-how-to-by-important-title,
	.ph-how-to-by-main-pay-title {
		margin-top: 50px;
	}
}

@media (max-width: 576px) {
	.ph-how-to-by-main-pay-img-screen {
		margin-left: 10px !important;
		max-width: 500px;
	}
	.ph-how-to-by-animation-map,
	.ph-how-to-by-animation-calendar,
	.ph-how-to-by-animation-farmacy,
	.ph-how-to-by-animation-terms,
	.ph-how-to-by-animation-webpay,
	.ph-how-to-by-animation-bbva {
		display: none;
	}
	.ph-how-to-by-animation-bci {
		right: 53px;
		bottom: -36px;
	}
	.ph-how-to-by-animation-wp {
		top: 37px;
		right: 23px;
	}
	.ph-how-to-by-animation-upload {
		bottom: 50px;
		max-width: 200px;
	}
	.ph-how-to-by-dowlond-contentainer {
		margin: 27px 0 50px 0;
	}
	.ph-how-to-by-step-one-contentainer {
		margin-bottom: 0;
	}
	.ph-how-to-by-step-two-container {
		margin: -40px 0 20px 0;
	}

	.ph-how-to-by-animation-movilone {
		top: 50px;
	}
	.ph-how-to-by-animation-movil_upload {
		max-width: 120px;
		bottom: 0;
		left: 0;
	}
	.ph-how-to-by-animation-producto-movil {
		display: none;
	}
	.ph-how-to-by-important-upload {
		margin-right: -5px;
		margin-bottom: 20px;
	}
}

@media (max-width: 480px) {
	.ph-how-to-by-header-title {
		font-size: 40px;
	}
	.ph-how-to-by-header-subtitle {
		font-size: 16px;
	}
	.ph-how-to-by-main-img-step-ones,
	.ph-how-to-by-important-upload,
	.ph-how-to-by-important-entrega,
	.ph-how-to-by-important-direction,
	.ph-how-to-by-important-recipewp,
	.ph-how-to-by-main-pay-img-screen,
	.ph-how-to-by-animation-map,
	.ph-how-to-by-animation-calendar,
	.ph-how-to-by-animation-farmacy,
	.ph-how-to-by-animation-terms,
	.ph-how-to-by-animation-bci,
	.ph-how-to-by-animation-webpay,
	.ph-how-to-by-animation-ripley,
	.ph-how-to-by-main-pay-img-bankchile,
	.ph-how-to-by-animation-trans,
	.ph-how-to-by-animation-bbva,
	.ph-how-to-by-animation-sweetpay,
	.ph-how-to-by-animation-estado,
	.ph-how-to-by-main-steptwo-img-three,
	.ph-how-to-by-main-steptwo-img-two,
	.ph-how-to-by-main-steptwo-img-five,
	.ph-how-to-by-main-img-step-one,
	.ph-how-to-by-main-steptwo-img-four {
		display: none;
	}
	.ph-how-to-by-step-one-contentainer {
		margin-bottom: 106px;
	}
	.ph-how-to-by-step-two-container {
		margin: 0px 0px 100px 0px;
	}
	.ph-how-to-by-important-contentainer {
		margin: 70px 40px 110px 70px;
	}
	.ph-how-to-by-animation-movil_upload {
		max-width: 356px;
		top: 0;
	}
	.ph-how-to-by-animation-gotas,
	.ph-how-to-by-animation-movilone {
		max-width: 346px;
		min-height: 600px;
		left: 70px;
		top: 20px;
		bottom: 120px;
	}
	.ph-how-to-by-main-steptwo-img-one {
		max-width: 346px;
		min-height: 600px;
	}
	.ph-how-to-by-main-img-step-one-mobile {
		max-width: 346px;
		min-height: 600px;
	}
	.ph-how-to-by-animation-producto-movil {
		display: block;
		max-width: 500px;
		min-height: 600px;
	}
	.ph-how-to-by-animation-product-movil {
		max-width: 335px;
		top: -12px;
		left: 70px;
	}
	.ph-how-to-by-main-content-img-step-two,
	.ph-how-to-by-main-content-img-step-one {
		min-height: 600px;
	}
	.ph-how-to-by-important-title,
	.ph-how-to-by-main-pay-title {
		margin-top: 20px;
	}
}

@media (max-width: 375px) {
	.ph-how-to-by-animation-product-movil {
		max-width: 300px;
		min-height: 200px;
		margin-left: -39px;
	}
	.ph-how-to-by-main-img-step-one-mobile {
		max-width: 350px;
		min-height: 450px;
		margin-left: -6px;
	}
	.how-to-by-step-two-container {
		margin-bottom: 150px;
	}
	.ph-how-to-by-main-content-img-step-one {
		min-height: 426px;
	}
	.ph-how-to-by-main-content-img-step-two {
		min-height: 440px;
	}
	.ph-how-to-by-main-steptwo-img-one {
		max-width: 350px;
		min-height: 350px;
		margin-left: -6px;
	}
	.ph-how-to-by-pay-container {
		margin-bottom: 70px;
	}
	.ph-how-to-by-main-pay-text-content {
		padding: 30px;
	}
	.ph-how-to-by-animation-producto-movil {
		min-height: 550px;
	}
	.ph-how-to-by-important-subtitle,
	.ph-how-to-by-dowlond-content-subtitle {
		font-size: 22px;
	}
	.ph-how-to-by-dowlond-playstore,
	.ph-how-to-by-dowlond-appstore {
		margin-bottom: 0;
	}
	.ph-how-to-by-important-title {
		margin-top: -8px;
	}
}
