.landing-container {
	padding: 8px 30px 0;
	margin: auto;
	width: 100%;
	max-width: 1185px;
}

.landing-cards-container {
	justify-content: center;
}

.landing-title-container {
	display: flex;
	width: 1165px;
	padding: 20px 10px 10px 10px;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;

	.landing-title {
		color: #555;
		font-family: Ubuntu;
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
}

@media (max-width: 768px) {
	.landing-container {
		padding: 0 5px;
	}
}
