/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-complaint-book-success-global-container {
	max-width: 1285px;
	padding: 10px;
	margin: auto;
}

.ph-complaint-book-chat-box-container {
	border-radius: 5px 5px 0 0;
	background: rgba(244, 244, 244, 1);
	width: 100%;
	height: 300px;
	overflow-y: auto;
	padding: 10px;
}

.ph-complaint-book-chat-box-container::-webkit-scrollbar {
	width: 6px;
}

.ph-complaint-book-chat-box-container::-webkit-scrollbar-track {
	background-color: none;
}

.ph-complaint-book-chat-box-container::-webkit-scrollbar-thumb {
	background: rgba(197, 197, 197, 1);
	border-radius: 6px;
}

.ph-complaint-book-message-box {
	display: flex;
}

.ph-complaint-book-message-box-right {
	justify-content: flex-end;
}

.ph-complaint-book-message-box-message {
	border-radius: 8px;
	display: flex;
	align-items: center;
	background-color: white;
	padding: 10px 10px 0;
	margin-top: 10px;
	overflow: hidden;
	word-break: break-all;
	width: fit-content;
	max-width: 70%;
}

.ph-complaint-book-message-box-message {
	align-items: center;
}

.ph-complaint-book-chat-input {
	border-radius: 0 0 8px 8px;
}

.ph-complaint-book-chat-button {
	margin-left: 14px;
}

.ph-complaint-book-chat-button .ant-btn-background-ghost.ant-btn-primary {
	border-color: #2d72b7;
	color: #2d72b7;
}

@media only screen and (max-width: 500px) {
	.ph-complaint-book-chat-button {
		margin-left: 10px;
	}
}
