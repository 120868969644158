/** @format */

@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,500');
@import '../../../../Sass/components/colors';
@import '../../../../../../v2-config/colors.scss';

.navlink-name {
	color: $generic-dark-grey2;
}

.ph-header-space-vertical-web-container {
	height: 96px;
}

.ph-header-global-container {
	background-color: $primaryBackgroundColor;
	position: fixed;
	top: 0;
	z-index: 99;
	width: 100%;
	height: 100%;
	min-height: 96px;
	max-height: 96px;
	box-shadow: 0 2px 0px -1px rgb(0 0 0 / 20%), 0 0px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
}

.ph-header-arg {
	background-color: $fourthBackgroundColor;
}

.ph-header-main-container {
	width: 100%;
	max-width: 1185px;
	padding: 8px 30px 0;
	margin: auto;

	.ph-header-container {
		min-height: 48px;

		.ph-nav-container {
			display: flex;
			align-items: center;
		}
	}
}

.ph-nav-buttons-container {
	display: flex!important;
	align-items: center;
	gap: 10px;
	
			
	.categories-button-container{
		display: flex;
		gap: 8px;
		margin: 0;
		padding: 0;
		height: 38.8px;
	}
	.ph-sidebar-actions-icon-container {
		display: block;
		.ph-sidebar-actions-icon {
			width: 100%;
			max-width: 33px;
			fill: #fff;
			width: 24px;
			height: 24px;
		}
		
	}
}
.ph-header-back-button {
	cursor: pointer;
}

.ph-header-back-icon-container {
	margin: 0 7px;
}

.ph-header-back-icon {
	width: 25px;
}


.ph-drawer-title{
	color:  $generic-dark-grey1;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; 
}
.ph-drawer-container-items{
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.ph-drawer-text-items{
		color:   $generic-dark-grey2;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		max-width: 280px;
		margin: 7px 7px;
	}
	svg{
		height: 18px;
		margin-right: 8px;
	}

	&:hover{
		color: #2E72B7;
		background-color: #E6F9FA;
		border-left: solid 2px #2E72B7;
	}
	
}

@media (max-width: 1080px) {
	.ph-header-main-container {
		padding: 8px 10px 0;
	}
}