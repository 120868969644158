/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-product-detail-price-main-container {
	text-align: center;
	text-align: -webkit-center;
}

.ph-product-detail-price-container {
	display: flex;
	justify-content: center;
	align-items: baseline;
	max-width: 180px;
}

.ph-product-detail-price-container-main-title {
	font-size: 15px;
	color: $colorGrayText4;
	margin-right: 6px;
	font-weight: 500;
}
