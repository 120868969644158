/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-recipe-objeted-pharmacy-list-hours-selected-container {
	border: 1px solid $primaryColorBorder;
	border-radius: 4px;
	margin-bottom: 5px;
	padding: 2px;
	margin-right: 2px;
	width: 14%;
}

.ph-recipe-objeted-pharmacy-list-hours-selected-container:last-child {
	margin-right: 0;
}

.ph-recipe-objeted-pharmacy-list-hour {
	color: $colorGrayText4;
	font-size: 10px;
	text-align: center;
	margin-bottom: 0;
	font-weight: 600;
}

.ph-recipe-objeted-pharmacy-list-hour-separator {
	color: $colorGrayText4;
	font-size: 10px;
	text-align: center;
	margin-bottom: 0;
	font-weight: 600;
	line-height: 5px;
}

.ph-recipe-objeted-pharmacy-list-hour-title {
	color: $primaryColor;
	font-size: 11px;
	font-weight: 600;
	margin-bottom: 1px;
}

.ph-recipe-objeted-pharmacy-list-hour-subtitle-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-recipe-objeted-pharmacy-list-hour-subtitle-icon-1 {
	color: $successColor;
	font-size: 10px;
	margin-top: -2px;
}

.ph-recipe-objeted-pharmacy-list-hour-subtitle-1 {
	font-size: 11px;
	font-weight: 400;
	color: $colorGrayText4;
	font-style: italic;
	margin-bottom: 0;
}

.ph-recipe-objeted-pharmacy-list-hour-subtitle-icon-2 {
	color: $errorColor;
	font-size: 10px;
	margin-top: -2px;
}

.ph-recipe-objeted-pharmacy-list-hour-subtitle-2 {
	font-size: 11px;
	font-weight: 400;
	color: $errorColor;
	font-style: italic;
	margin-bottom: 0;
}

@media (max-width: 768px) {
	.ph-recipe-objeted-pharmacy-list-hour-title {
		text-align: center;
	}
	.ph-recipe-objeted-pharmacy-list-hour-subtitle-container {
		justify-content: center;
	}
}

@media (max-width: 576px) {
	.ph-recipe-objeted-pharmacy-list-hours-selected-container {
		width: 14%;
		margin-right: 1px;
	}
	.ph-recipe-objeted-pharmacy-list-hour-title {
		font-size: 8px;
	}
	.ph-recipe-objeted-pharmacy-list-hour {
		display: none;
	}
	.ph-recipe-objeted-pharmacy-list-hour-separator {
		display: none;
	}
	.ph-recipe-objeted-pharmacy-list-hour-subtitle-1 {
		font-size: 10px;
	}
	.ph-recipe-objeted-pharmacy-list-hour-subtitle-2 {
		font-size: 10px;
	}
}

@media (max-width: 480px) {
	.ph-recipe-objeted-pharmacy-list-hours-selected-container {
		width: 13.99%;
		margin-right: 1px;
	}
	.ph-recipe-objeted-pharmacy-list-hour-subtitle-icon-1,
	.ph-recipe-objeted-pharmacy-list-hour-subtitle-icon-2 {
		display: none;
	}
}
