/** @format */
@import '../../../../../../../../../../components/Commons/Sass/components/colors';

@mixin price-style($color, $weight) {
	color: $color;
	font-weight: $weight;
	font-size: 14px;
	margin-bottom: 0;
}

@mixin symbol-style($color, $weight, $size) {
	color: $colorGrayText4;
	font-weight: $weight;
	font-size: $size;
	margin-bottom: 0;
}

.ph-product-detail-quote-price-product-container {
	display: flex;
	align-items: center;
	//padding-left: 1.5rem;
}

.ph-product-detail-quote-price-normal-symbol {
	@include symbol-style($colorGrayText4, 600, 14);
}

.ph-product-detail-quote-price-before-symbol {
	@include symbol-style($colorGrayText2, 500, 15);
	text-decoration: line-through;
}

.ph-product-detail-quote-price-normal {
	@include price-style($colorGrayText4, 500);
}

.ph-product-detail-quote-price-before {
	@include price-style($colorGrayText1, 500);
	text-decoration: line-through;
}

.ph-product-detail-quote-price-home {
	font-size: 16px;
}

@media (max-width: 1140px) {
	.ph-product-detail-quote-price-normal {
		font-size: 15px;
	}
}

@media (max-width: 575px) {
	.ph-product-detail-quote-price-before-symbol {
		font-size: 11px;
	}

	.ph-product-detail-quote-price-before {
		font-size: 13px;
	}

	.ph-product-detail-quote-price-normal-symbol {
		font-size: 12px;
	}

	.ph-product-detail-quote-price-normal {
		font-size: 15px;
	}
}