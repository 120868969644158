/** @format */

@import "../../../../components/Commons/Sass/components/colors";

.ph-search-tracking-global-container {
  max-width: 1185px;
  padding: 10px;
  margin: auto;
  height: 110vh;
}

.ph-search-tracking-main-container {
  padding: 14px;
}

.ph-search-tracking-container {
  text-align: center;
  padding: 20px;
  border-radius: 4px;
  box-shadow: $primaryBoxShadow;
}

.ph-search-tracking-image-container {
  padding: 5px;
}

.ph-search-tracking-image {
  width: 100%;
  max-width: 120px;
  opacity: 0.3;
}

.ph-search-tracking-title {
  color: $colorGrayText3;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.ph-search-tracking-subtitle {
  color: $primaryColor;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 30px;
}

.ph-search-tracking-field-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 380px;
  margin: 0 auto 30px;
  border-radius: 4px;
}

.ph-search-tracking-field-container .ant-form-item {
  margin-bottom: 0 !important;
}

.ph-search-tracking-field-rut {
  position: relative;
  border-top: 1px solid $colorGrayText4;
  border-bottom: 1px solid $colorGrayText4;
  border-left: 1px solid $colorGrayText4;
  border-right: none;
  border-radius: 4px 0 0 4px;
  height: 42px;
  width: 100%;
  max-width: 100%;
  font-size: 15px;
  border-color: $primaryColor !important;
  color: $primaryColor !important;
  font-weight: 500 !important;
  box-shadow: $primaryBoxShadow;
}

.ph-search-tracking-field-separator {
  height: 42px;
  border-top: 1px solid rgba(85, 85, 85, 0.2);
  border-bottom: 1px solid rgba(85, 85, 85, 0.2);
}

.ph-search-tracking-field-separator-inner {
  background: rgba(85, 85, 85, 0.2);
  width: 0;
  height: 26px;
  margin: 7px 0;
}

.ph-search-tracking-field-order {
  position: relative;
  border-top: 1px solid $colorGrayText4;
  border-bottom: 1px solid $colorGrayText4;
  border-left: none;
  border-right: none;
  border-radius: 0;
  height: 45px;
  width: 100%;
  max-width: 200px;
  font-size: 15px;
  border-color: rgba(85, 85, 85, 0.2) !important;
  color: $primaryColor !important;
  font-weight: 500 !important;
}

.ph-search-tracking-field-container .ant-form-item .ant-form-item-explain.ant-form-item-explain-error {
  position: relative !important;
  font-size: 12px !important;
  max-width: 220px;
}

.ph-search-tracking-field-container .ant-form-item .ant-form-item-explain.ant-form-item-explain-success {
  position: relative !important;
  font-size: 12px !important;
  max-width: 220px;
}

.ph-search-tracking-field-button,
.ph-search-tracking-field-button:active,
.ph-search-tracking-field-button:focus,
.ph-search-tracking-field-button:hover {
  width: 45px;
  height: 45px;
  background-color: $primaryColor;
  border: 1px solid $primaryColor;
  border-radius: 0 4px 4px 0;
  color: $colorWhiteText;
  font-size: 22px;
  padding: 10px;
  line-height: 1px;
  box-shadow: $primaryBoxShadow;
}

.ph-search-tracking-field-button .anticon-search {
  font-size: 24px;
}

.ph-search-tracking-description {
  width: 100%;
  max-width: 480px;
  font-style: italic;
  color: $colorGrayText3;
  margin: auto;
  margin-bottom: 20px;
  font-size: 15px;
}

.ph-search-tracking-quote {
  color: $errorColor;
  opacity: 0.6;
  margin-right: 3px;
}

@media (min-width: 576px) {
  .ph-search-tracking-field-button-title {
    display: none;
  }
}

@media (max-width: 575px) {
  .ph-search-tracking-main-container {
    padding: 5px;
  }

  .ph-search-tracking-title-container {
    margin-top: 0;
  }

  .ph-search-tracking-main-title {
    font-size: 22px;
  }

  .ph-search-tracking-main-subtitle {
    margin-bottom: 10px;
  }

  .ph-search-tracking-image {
    max-width: 70px;
  }

  .ph-search-tracking-title {
    margin-bottom: 15px;
  }

  .ph-search-tracking-field-container {
    display: block;
    padding: 25px 15px;
  }

  .ph-search-tracking-field-rut {
    border: 1px solid $primaryColor;
    border-radius: 4px;
    max-width: 100%;
    margin-bottom: 23px;
  }

  .ph-search-tracking-field-separator {
    display: none;
  }

  .ph-search-tracking-field-order {
    border: 1px solid $primaryColor;
    border-radius: 4px;
    max-width: 100%;
    margin-bottom: 23px;
  }

  .ph-search-tracking-field-container .ant-form-item .ant-form-item-explain.ant-form-item-explain-error {
    bottom: -1px !important;
    font-size: 11px !important;
  }

  .ph-search-tracking-field-container .ant-form-item .ant-form-item-explain.ant-form-item-explain-success {
    bottom: -1px !important;
    font-size: 11px !important;
  }

  .ph-search-tracking-field-button,
  .ph-search-tracking-field-button:active,
  .ph-search-tracking-field-button:focus,
  .ph-search-tracking-field-button:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 130px !important;
    height: 36px !important;
    max-width: 150px;
    border-radius: 4px;
  }

  .ph-search-tracking-field-button .anticon-search {
    font-size: 18px;
    margin-right: 8px;
  }

  .ph-search-tracking-field-button-title {
    margin-bottom: 0;
    color: $colorWhiteText;
    font-size: 15px;
    font-weight: 600;
  }

  .ph-search-tracking-description {
    font-size: 13px;
  }
}