/** @format */

@import "../../../../../../Sass/components/colors";
@import "../../../../../../../../v2-config//colors.scss";

.ph-search-mobile-button-form-item {
  margin-bottom: 0;
}

.ph-search-mobile-button {
  height: 39px;
  width: 100%;
  line-height: 40px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border: 1px solid $mainSearchBorderColor;
  border-radius: 8px;
  padding: 0 10px;
  background: $primaryBackgroundColor;
  font-size: 12px;
  color: $colorGrayText4;
  box-shadow: 0 1px 2px 0 $thirdBoxShadow !important;
  position: relative;
}

.ph-search-mobile-button:focus,
.ph-search-mobile-button:hover,
.ph-search-mobile-button.ant-input-focused {
  border-color: $mainSearchBorderColorMobile !important;
}

.ph-search-mobile-button-icon-pharmacy-button-container {
  position: absolute;
  top: 6px;
  right: 0;
  border-left: 1px solid $primaryColorBorder;
  padding: 1px 13px 1px 10px;

  .mobile-icons-theme-search{
    color: $primary-default;
    width: 100%;
    max-width: 25px;
    cursor: pointer;
    &:hover {
      color: $primary-hover;
    }
    &:active,
    &.active {
      color: $primary-default;
    }
  }
}

.ph-search-mobile-button-icon-pharmacy-button {
  width: 100%;
  max-width: 25px;
}

.ph-search-mobile-button-search-drawer .ant-drawer-content-wrapper {
  width: 100% !important;
}

.ph-search-mobile-button-search-drawer .ant-drawer-body {
  padding: 0;
}

.ph-search-mobile-button-close-drawer {
  position: absolute;
  top: 6px;
  left: 4px;
  z-index: 1;
  border: none;
  box-shadow: none;
  color: $secondColor;
}
