/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-order-detail-products-item-image-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.ph-order-detail-products-item-image-title {
	color: $colorGrayText4;
	font-weight: 100;
	font-size: 12px;
	margin-bottom: 0;
}

.ph-order-detail-products-item-image-title-alt-cp {
	color: $secondColor !important;
	font-weight: 600 !important;
}

.ph-order-detail-products-item-image-subtitle {
	color: $colorBackText;
	font-weight: 600;
	font-size: 12px;
	margin-left: 2px;
	margin-bottom: 0;
}

.ph-order-detail-products-item-image-image-container {
	margin-left: 5px;
}

.ph-order-detail-products-item-image-image {
	width: 100%;
	max-width: 18px;
}
