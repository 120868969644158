$colorWhiteText: rgba(255, 255, 255, 1);
$colorBackText: rgba(0, 0, 0, 1);
$colorGrayText0: rgba(248, 248, 248, 1);
$colorGrayText1: rgba(170, 170, 170, 1);
$colorGrayText2: rgba(136, 136, 136, 1);
$colorGrayText3: rgba(123, 123, 123, 1);
$colorGrayText4: rgba(85, 85, 85, 1);
$primaryBackgroundColor: rgba(255, 255, 255, 1);
$secondBackgroundColor: rgba(251, 251, 251, 1);
$thirdBackgroundColor: rgba(244, 244, 244, 1);
$primaryColor: rgb(88, 57, 142);
$secondColor: rgb(88, 57, 142);
$thirdColor: rgb(88, 57, 142);
$fourthColor: rgba(255, 64, 129, 1);
$successColor: rgb(88, 57, 142);
$errorColor: rgba(215, 20, 31, 1);
$colorWhiteBorder: rgba(255, 255, 255, 1);
$primaryColorBorder: rgba(238, 238, 238, 1);
$mainHeaderboxShadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
$primaryBoxShadow: 0 1px 3px 0 rgb(0 0 0 / 4%), 0 1px 1px 0 rgb(0 0 0 / 4%), 0 1px 3px 0 rgb(0 0 0 / 12%);
$secondaryBoxShadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.2);
$thirdBoxShadow: rgba(0, 0, 0, 0.2);
$footerPrimaryBackground: rgb(88, 57, 142);
$footerSecondBackground: rgb(88, 57, 142);
$footerTitle: rgba(255, 255, 255, 1);
$footerSubTitle: rgba(255, 255, 255, 1);
$mainSearchBorderColor: rgba(255, 255, 255, 1);
$mainSearchBorderColorMobile: rgba(255, 255, 255, 1);
$colorSecondaryTex: rgb(96, 125, 139);
$fourthBackgroundColor: rgb(88, 57, 142);
$fifthColor: rgb(245, 137, 129);
$disabled-darker: #2e2d36;
$color3: #ffffff;
$disabled: #aaaaaa;
$color2: rgb(88, 57, 142);
$background: #f7f7f7;
$disabled-dark: #555555;
$danger-light: #ff5b64;
$mecanicColor: rgb(220, 88, 56);
$greyAntd: #ededef;
$grey2: #e6f9fa;
$grey3: #f5fcfc;
