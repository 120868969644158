/** @format */

@import "../../../../../../components/Commons/Sass/components/colors";

.ph-bag-type-delivery-radio-button-form-container {
  text-align: right;
}

.ph-bag-type-delivery-radio-button-inner-container {
  //border: 1px solid $primaryColor;
  border: 1px solid $primaryColorBorder;
  border-radius: 5px;
  box-shadow: $primaryBoxShadow;
  position: relative;
  padding: 10px;
  width: 100%;
  margin: 0;
  height: 64px;
  display: flex;
  align-items: center;
  //max-width: 145px;
  //min-width: 145px;
}

.ph-bag-type-delivery-radio-button-border-disabled {
  border: 1px solid $primaryColorBorder;
}

.ph-bag-type-delivery-radio-button-border-active {
  border: 1px solid $primaryColor;
}

.ph-bag-type-delivery-radio-button-inner-container:last-child {
  margin-right: 0 !important;
}

.ph-bag-type-delivery-radio-button-inner-container span.ant-radio + * {
  padding-left: 0;
  padding-right: 30px;
}

.ph-bag-type-delivery-radio-button-inner-container .ant-radio {
  position: absolute;
  top: 22px;
  right: 9px;
}

.ph-bag-type-delivery-radio-button-container
  .ant-radio-input:focus
  + .ant-radio-inner,
.ph-bag-type-delivery-radio-button-container
  .ant-radio-wrapper:hover
  .ant-radio,
.ph-bag-type-delivery-radio-button-container .ant-radio:hover .ant-radio-inner {
  border-color: $primaryColor !important;
}

.ph-bag-type-delivery-radio-button-container {
  margin: 0;
  display: flex;
  gap: 20px;
}

.ph-bag-type-delivery-radio-button-container .ant-radio-inner:after {
  background-color: $primaryColor !important;
}

.ph-bag-type-delivery-radio-button-container
  .ant-radio-checked
  .ant-radio-inner {
  border-color: $primaryColor !important;
}

.ph-bag-type-delivery-radio-button-select-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

//.ph-bag-type-delivery-delivery-image-1-container {
//  margin-top: -2px;
//}

.ph-bag-type-delivery-delivery-image-1 {
  width: 100%;
  //max-width: 35px;
}

.ph-bag-type-delivery-delivery-title-container {
  text-align: left;
  //padding-left: 5px;
}

.ph-bag-type-delivery-delivery-title {
  color: $colorGrayText4;
  margin-bottom: 0;
  line-height: 20px;
  font-weight: 400;
  font-size: 14px;
}

.ph-bag-type-delivery-delivery-subtitle {
  color: $primaryColor;
  margin-bottom: 0;
  line-height: 17px;
  font-weight: 500;
  font-size: 12px;
}

.ph-bag-type-delivery-radio-loading-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
}

.ph-bag-type-delivery-radio-loading-button .anticon {
  margin-top: 10px;
  color: $secondColor;
}

.ph-bag-type-delivery-radio-no-delivery-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
}

@media (max-width: 700px) {
  .ph-bag-type-delivery-radio-button-container{
    flex-direction: column;
  }
  .ph-bag-type-delivery-radio-button-form-container {
    margin-top: 10px;
    text-align: center;
  }
  .ph-bag-type-delivery-radio-button-form-container .ant-form-item {
    margin-bottom: 7px;
  }
}
