/** @format */

@import '../Commons/Sass/components/colors';

.ph-stepper-old-main-container {
	margin: 12px 0;
	padding-bottom: 15px;
	display: flex !important;
	flex-direction: row;
}

.ph-stepper-old-main-container .ant-steps-item-icon .ant-steps-icon {
	top: -4px;
	line-height: 0;
}

.ph-stepper-old-main-container.ant-steps-small .ant-steps-item-icon {
	width: 20px;
	height: 20px;
	font-weight: 500;
}

.ph-stepper-old-main-container .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
	background: $errorColor;
	border-color: $errorColor;
	width: 20px;
	height: 20px;
	margin: 0 5px 0 0;
}

.ph-stepper-old-main-container .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
	font-size: 13px;
	color: $errorColor;
	font-weight: 600;
	padding-right: 7px;
	line-height: 21px;
}

.ph-stepper-old-main-container.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
	padding-left: 7px;
}

.ph-stepper-old-main-container .ant-steps-item-wait .ant-steps-item-icon {
	background-color: $colorGrayText2;
	border-color: $colorGrayText2;
}

.ph-stepper-old-main-container .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
	color: $colorWhiteText;
}

.ph-stepper-old-main-container.ant-steps-small .ant-steps-item-title {
	font-size: 13px;
	line-height: 21px;
}

.ph-stepper-old-main-container .ant-steps-small .ant-steps-item-title::after {
	top: 11px;
}

.ph-stepper-old-main-container .ant-steps-item-finish .ant-steps-item-icon {
	background-color: $errorColor;
	border-color: $errorColor;
}

.ph-stepper-old-main-container .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
	background-color: $errorColor;
}

.ph-stepper-old-main-container .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
	color: $colorWhiteText;
}

.ph-stepper-old-main-container .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
	color: $errorColor;
}

.ph-stepper-old-main-container .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
	background-color: $colorGrayText0;
}

.ph-stepper-old-main-container .ant-steps-item-title::after {
	top: 10px;
}

.ph-stepper-old-main-container .ant-steps-item-title::after {
	position: absolute;
	top: 16px;
	left: 100%;
	display: block;
	width: 9999px;
	height: 1px;
	background: $colorGrayText0;
	content: '';
}

@media (max-width: 992px) {
	.ph-stepper-old-main-container {
		padding-top: 10px;
		padding-bottom: 15px;
	}
}

@media (max-width: 675px) {
	.ph-stepper-old-main-container {
		padding-top: 10px;
		padding-bottom: 5px;
		padding-left: 10px;
		padding-right: 10px;
	}

	.ph-stepper-old-main-container .ant-steps-item-title {
		font-size: 0 !important;
		padding: 0;
	}

	.ph-stepper-old-main-container .ant-steps-item-title::after {
		top: 10px;
	}

	.ph-stepper-old-main-container.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
		display: none;
	}

	.ph-stepper-old-main-container .ant-steps-item:last-child {
		flex: none !important;
		margin-right: none !important;
	}
	.ph-stepper-old-main-container.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
		margin-right: 0;
	}

	.ph-stepper-old-main-container.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
		position: absolute;
		top: 10px;
		z-index: -1;
	}
	.ph-stepper-old-main-container.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
		width: 100%;
		height: 1px;
	}
	.ph-stepper-old-main-container.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
		width: 100%;
		height: 1px;
	}
	.ph-stepper-old-main-container .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
		background-color: $errorColor;
	}
}
