/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-bag-products-group-total-info-container {
	border-top: 1px solid $primaryColorBorder;
	width: 100%;
	text-align: right;
}

.ph-bag-products-group-total-info-inner-container {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 10px 15px 10px 10px;
}

.ph-bag-products-group-total-info-price-title {
	margin-bottom: 0;
	color: $colorGrayText4;
	font-weight: 500;
	font-size: 12px;
	padding-left: 15px;
}

.ph-bag-products-group-total-info-price-title-2 {
	margin-bottom: 0;
	color: $colorGrayText4;
	font-weight: 500;
	font-size: 13px;
	padding-left: 15px;
}

.ph-bag-products-group-total-info-price-symbol {
	color: $primaryColor;
	font-size: 12px;
	padding-left: 2px;
	font-style: normal;
}

.ph-bag-products-group-total-info-price {
	color: $colorGrayText4;
	font-size: 15px;
	font-weight: 500;
	font-style: normal;
	margin: 0 5px;
}

.ph-bag-products-group-total-info-price-2 {
	@extend .ph-bag-products-group-total-info-price;
	color: $primaryColor;
	font-weight: 600;
}

.ph-bag-products-group-no-total-title {
	margin-bottom: 0;
	//color: $secondColor;
	//font-weight: 500;
	//font-size: 13px;
	//padding-left: 40px;
	//font-style: italic;
	//padding: 1px 5px;
	font-family: Ubuntu, sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	color: $colorGrayText4;
}

@media (max-width: 992px) {
	.ph-bag-products-group-total-info-inner-container {
		padding: 5px;
		align-items: flex-end;
		margin-right: 10px;
	}

	.ph-bag-products-group-total-info-price-title {
		font-size: 11px;
		display: flex;
		flex-direction: column;
	}

	.ph-bag-products-group-total-info-price-title-2 {
		display: flex;
		flex-direction: column;
		font-size: 12px;
		margin-right: 0;
	}

	.ph-bag-products-group-total-info-price {
		margin-right: 0;
	}

	.ph-bag-products-group-subtotal-since {
		font-size: 11px;
	}
}
