/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-bag-banner-main-container {
	box-shadow: $primaryBoxShadow;
	margin: 10px 5px;
	border-radius: 4px;
}

.ph-bag-banner-loader-main-container {
	box-shadow: $primaryBoxShadow;
	margin: 4px 4px 10px 4px;
	border-radius: 4px;
	padding: 9px;
	display: flex;
	justify-content: center;
	align-items: center;
}
