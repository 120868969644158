/** @format */

@import '../../../../components/Commons/Sass/components/colors';
@import '../../../../v2-config/colors.scss';

.ph-bag-products-group-main-container {
	box-shadow: $primaryBoxShadow;
	border-radius: 4px;
	position: relative;
	margin: 5px;
	margin-bottom: 10px;
	background-color: $generic-white;
	padding: 10px;
}

.ph-bag-products-group-inner-container {
	//padding: 15px;
	padding-bottom: 10px;
}

.ph-bag-products-group-phamracy-title-container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
}

.ph-bag-products-group-phamracy-icon{
	width: 24px;
}

.ph-bag-products-group-phamracy-title-number {
	color: $secondColor;
	font-size: 22px;
	font-weight: 600;
	margin-right: 10px;
}

.ph-bag-products-group-phamracy-image-container {
	padding: 0 3px;
	margin-right: 5px;
}

.ph-bag-products-group-phamracy-image {
	width: 100%;
	max-width: 16px;
}

.ph-bag-products-group-phamracy-title-pharmacy {
	margin-bottom: 3px;
	color: $colorGrayText3;
	font-size: 17px;
	line-height: 18px;
}

.ph-bag-products-group-phamracy-title-description {
	margin-bottom: 0;
	//color: $primaryColor;
	color: $colorGrayText3;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 14px;
}

.ph-bag-product-list-loading-container {
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(255, 255, 255, 0.9);
	width: 100%;
	height: 100%;
	z-index: 3;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
}

.ph-bag-product-list-loading-inner-container {
	text-align: center;
}

.ph-bag-product-list-loading {
	color: $secondColor;
	padding-bottom: 10px;
}

.ph-bag-product-list-loading-title {
	color: $primaryColor;
}

.ph-bag-pharmacy-coverage-view {
	cursor: pointer;
	color: #2D72B7;
	font-size: 14px;
	font-weight: 600;
}

@media (max-width: 600px) {
	.ph-bag-products-group-inner-container {
		padding: 10px;
	}
}