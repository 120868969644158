@import '../../../v2-config/colors.scss';

@mixin button-style {
	font-size: 13px;
	border-radius: 4px;
	font-weight: 500;
	text-transform: uppercase;
	height: 36px;
	min-width: 130px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 5px auto 10px;
	letter-spacing: 1px;
}
.button-primary {
	@include button-style;
	background-color: $button-primary-background-color-default;
	border-color: $button-primary-border-color-default;
	color: $button-primary-color-default;
	box-shadow: $button-primary-shadow-default;

	&:hover {
		background-color: $button-primary-background-color-hover;
		border-color: $button-primary-border-color-hover;
		color: $button-primary-color-hover;
		box-shadow: $button-primary-shadow-hover;
	}

	&:active {
		background-color: $button-primary-background-color-active;
		border-color: $button-primary-border-color-active;
		color: $button-primary-color-active;
		box-shadow: $button-primary-shadow-active;
	}
	&:disabled {
		background-color: $button-primary-background-color-disabled;
		border-color: $button-primary-border-color-disabled;
		color: $button-primary-color-disabled;
		box-shadow: $button-primary-shadow-disabled;
	}
	&:focus {
		background-color: $button-primary-background-color-default;
		border-color: $button-primary-border-color-default;
		color: $button-primary-color-default;
		box-shadow: $button-primary-shadow-default;
	}
}

.button-secondary {
	@include button-style;
	background-color: $button-secondary-background-color-default;
	border-color: $button-secondary-border-color-default;
	color: $button-secondary-color-default;
	box-shadow: $button-secondary-shadow-default;

	&:hover {
		background-color: $button-secondary-background-color-hover;
		border-color: $button-secondary-border-color-hover;
		color: $button-secondary-color-hover;
		box-shadow: $button-secondary-shadow-hover;
	}

	&:active {
		background-color: $button-secondary-background-color-active;
		border-color: $button-secondary-border-color-active;
		color: $button-secondary-color-active;
		box-shadow: $button-secondary-shadow-active;
	}

	&:disabled {
		background-color: $button-secondary-background-color-disabled;
		border-color: $button-secondary-border-color-disabled;
		color: $button-secondary-color-disabled;
		box-shadow: $button-secondary-shadow-disabled;
	}
	&:focus {
		background-color: $button-secondary-background-color-default;
		border-color: $button-secondary-border-color-default;
		color: $button-secondary-color-default;
		box-shadow: $button-secondary-shadow-default;
	}
}

.button-tertiary {
	@include button-style;
	background-color: $button-tertiary-background-color-default;
	border-color: $button-tertiary-border-color-default;
	color: $button-tertiary-color-default;
	box-shadow: $button-tertiary-shadow-default;

	&:hover {
		background-color: $button-tertiary-background-color-hover;
		border-color: $button-tertiary-border-color-hover;
		color: $button-tertiary-color-hover;
		box-shadow: $button-tertiary-shadow-hover;
	}

	&:active {
		background-color: $button-tertiary-background-color-active;
		border-color: $button-tertiary-border-color-active;
		color: $button-tertiary-color-active;
		box-shadow: $button-tertiary-shadow-active;
	}

	&:disabled {
		background-color: $button-tertiary-background-color-disabled;
		border-color: $button-tertiary-border-color-disabled;
		color: $button-tertiary-color-disabled;
		box-shadow: $button-tertiary-shadow-disabled;
	}
	&:focus {
		background-color: $button-tertiary-background-color-default;
		border-color: $button-tertiary-border-color-default;
		color: $button-tertiary-color-default;
		box-shadow: $button-tertiary-shadow-default;
	}
}
