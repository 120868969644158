/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-request-quotation-global-container {
	max-width: 900px;
	padding: 10px;
	margin: auto;
}

.ph-request-quotation-global-inner-container {
	height: auto;
	min-height: calc(100vh - 158px);
}

.ph-request-quotation-main-container {
	padding: 20px 10px;
	border-radius: 4px;
	box-shadow: $mainHeaderboxShadow;
	height: auto;
	margin-bottom: 40px;
}

.ph-request-quotation-form-main-container {
	position: relative;
}

.ph-request-quotation-link-unitpharmacy {
	display: block;
	color: $primaryColor;
	font-style: italic;
	text-decoration: underline;
	padding: 0 15px;
	margin-bottom: 10px;
	opacity: 0.9;
}

.ph-request-quotation-link-unitpharmacy:hover {
	color: $primaryColor;
	text-decoration: underline;
	opacity: 1;
}

.ph-request-quotation-form-checkbox-container {
	padding: 0 15px;
}

.ph-request-quotation-form-checkbox-container .ant-checkbox-inner {
	background-color: $primaryBackgroundColor !important;
	border: 1px solid $primaryColorBorder !important;
}

.ph-request-quotation-form-checkbox-container .ant-checkbox-checked .ant-checkbox-inner {
	background-color: $primaryColor !important;
	border-color: $primaryColor !important;
}

.ph-request-quotation-form-checkbox-description {
	color: $colorGrayText3;
}

.ph-request-quotation-recipe-container {
	width: 100%;
	max-width: 500px;
	margin: auto;
	padding: 20px 0;
}

.ph-request-quotation-form-container,
.ph-request-quotation-upload-container,
.ph-request-quotation-button-container {
	padding: 0 10px;
}

.ph-request-quotation-address-container {
	position: absolute;
	bottom: 0;
	right: 0;
}

.ph-request-quotation-upload-container {
	margin: 20px 0;
	height: 300px;
}

.ph-request-quotation-field-input {
	max-width: 100%;
	height: 45px;
	border-radius: 6px;
	font-size: 13px;
	color: $colorGrayText2;
}

.ph-request-quotation-button-container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 0;
}

.ph-request-quotation-button,
.ph-request-quotation-button:active,
.ph-request-quotation-button:focus,
.ph-request-quotation-button:hover {
	background-color: $secondColor !important;
	color: $colorWhiteText;
	padding: 5px 10px;
	font-size: 15px;
	border-radius: 4px;
	font-weight: 600;
	box-shadow: $secondaryBoxShadow;
	border: 2px solid $secondColor !important;
	text-transform: capitalize;
	height: 36px;
	width: 150px;
}

.ph-request-quotation-button[disabled] {
	background-color: $secondBackgroundColor !important;
	border: 2px solid $thirdBackgroundColor !important;
}

.ph-request-quotation-form-upload-image {
	padding: 0 10px;
}

@media (max-width: 767px) {
	.ph-request-quotation-form-upload-image {
		margin-top: 20px;
	}
}
