@import '../../../v2-config/colors.scss';

@mixin text-area-style {
	font-size: 18px;
	border-radius: 10px;
}
.text-area-primary {
	@include text-area-style;
	border-color: $primary-default;

	&:hover {
		border-color: $primary-hover;
	}

	&:active {
		border-color: $primary-active;
	}
}
