/** @format */

@import '../../../components/Commons/Sass/components/colors';
@import '../../../v2-config/colors.scss';

.ph-auth-background {
	background-color: $primary-default;
	align-content: center;
	height: 100vh;
}
.ph-auth-login-main-container {
	background-color: $primaryBackgroundColor;
	min-height: 100vh;
	margin-bottom: -88px;
}

.ph-auth-login-inner-container {
	width: 100%;
	max-width: 600px;
	margin: auto;
	position: absolute;
	top: -60px;
	bottom: 0;
	right: 37.4%;
	max-height: 544px;
	height: auto;
	z-index: 1;

	//Valida que sea el ambiente de CL
	@if $primaryColor ==rgba(45, 114, 183, 1) {
		.ant-form-item-explain-error {
			margin-left: 30px;
			padding: 0 10px;
		}
	}
}

.ph-auth-login-form-main-container {
	display: flex !important;
	justify-content: space-between !important;
	flex-direction: column !important;
	height: auto;
	background-color: $primaryBackgroundColor;
	box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
	border-radius: 4px;
}

.ph-auth-login-body-container {
	padding: 25px;
	max-width: 650px;
	margin: auto;
}

.ph-auth-login-title-container {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 15px;
}

.ph-auth-login-img-container {
	padding: 3px;
	margin-right: 5px;
}

.ph-auth-login-img {
	width: 100%;
	max-width: 29px;
}

.ph-auth-login-title {
	font-size: 22px;
	font-weight: 900;
	color: $colorGrayText3;
	text-align: left;
	margin-bottom: 0;
}

.ph-auth-login-subtitle {
	color: $primaryColor;
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 300;
	letter-spacing: -0.2px;
}

.ph-auth-login-form-inner-container {
	margin-bottom: 15px;
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-auth-login-form-container .ant-form-item-control-input {
	text-align: center;
}

.ph-auth-login-form-container .ant-form-item {
	margin-bottom: 18px;
}

.ph-auth-login-form-container .ant-form-item-explain.ant-form-item-explain-error {
	position: absolute;
	bottom: -15px;
	left: 30px;
	font-size: 12px;
	padding: 0 10px;
	line-height: 15px;
	min-height: auto;
}

.ph-auth-login-form-container .ant-form-item-explain.ant-form-item-explain-success {
	position: absolute;
	bottom: -15px;
	left: 30px;
	font-size: 12px;
	padding: 0 10px;
	line-height: 15px;
	min-height: auto;
}

.ph-auth-login-field-input {
	max-width: 470px;
	height: 45px;
	border-radius: 6px;
	font-size: 13px;
}

.ph-auth-login-recover-link-container {
	text-align: right;
	margin-right: 25px;
}

.ph-auth-login-recover-link-description {
	color: $primaryColor;
	font-size: 13px;
	font-weight: 300;
	cursor: pointer;
	opacity: 1;
	padding: 0 10px;
}

.ph-auth-login-social-google {
	background-color: $secondColor !important;
	color: $colorWhiteText !important;
	height: 40px;
	border-radius: 4px !important;
	width: 100%;
	min-width: 220px;
	max-width: 220px;
	opacity: 1 !important;
	cursor: pointer;
	font-size: 17px !important;
	margin: 0 15px;
}

.ph-auth-login-social-google span {
	width: 100%;
}

.ph-auth-login-social-google span i {
	margin-left: 0 !important;
}

.ph-auth-login-social-facebook {
	background-color: #3b5998 !important;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 6px 0px !important;
	color: $colorWhiteText !important;
	height: 40px;
	border-radius: 4px !important;
	width: 100%;
	min-width: 220px;
	max-width: 220px;
	margin: auto;
	border: none;
	opacity: 1;
	cursor: pointer;
	margin: 0 15px;
	font-size: 17px !important;
}

.ph-auth-login-register-container {
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 470px;
	margin: auto;
	padding-top: 15px;
	padding-bottom: 15px;
}

.ph-auth-login-register-description {
	font-size: 13px;
	margin-bottom: 10px;
	color: $colorGrayText4;
	margin-bottom: 0;
	font-weight: 300;
	opacity: 1;
}

.ph-auth-login-register-link-description {
	margin-left: 5px;
	font-size: 13px;
	font-weight: 600;
	margin-bottom: 0;
	color: $primaryColor;
	opacity: 1;
}

.ph-auth-login-footer-container {
	border-top: 1px solid $primaryColorBorder;
	padding: 10px 25px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-auth-login-footer-button-home-inner-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-auth-login-footer-button-home-icon {
	margin-bottom: 0;
	color: $primaryColor !important;
	opacity: 1 !important;
	margin-top: -1px;
	margin-right: 2px;
}

.ph-auth-login-footer-button-home-title {
	margin-bottom: 0;
	color: $primaryColor !important;
	font-size: 15px;
}

.ph-auth-login-main-button-container {
	text-align: center;
}

.ph-auth-login-main-button-container .ant-form-item {
	margin-bottom: 0;
}

.ph-auth-login-main-button,
.ph-auth-login-main-button:hover,
.ph-auth-login-main-button:focus,
.ph-auth-login-main-button:active {
	font-size: 18px;
	margin-top: 15px;
	background-color: $primaryColor !important;
	text-transform: none;
	color: $colorWhiteText !important;
	font-weight: 500;
	width: 100%;
	border-radius: 4px;
	margin: 0;
	min-width: 200px;
	max-width: 200px;
	height: 40px;
	margin-bottom: 0;
	box-shadow: $primaryBoxShadow;
	border: none !important;
}

.ph-auth-login-main-button span {
	position: relative;
	top: -2px;
}

.ph-auth-login-separator-main-container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 10px;
	max-width: 490px;
	margin: auto;
	margin-bottom: 25px;
}

.ph-auth-login-separator-line {
	width: 100%;
	border: 0;
	height: 1px;
	background: $thirdBackgroundColor;
	background-image: #eee;
}

.ph-auth-login-separator-title {
	padding: 0 10px;
	font-size: 18px;
	color: $colorGrayText0;
}

.ph-auth-login-logo-container {
	margin-bottom: 15px;
}

.ph-notification-global-container .ant-notification-close-x .ant-notification-close-icon {
	font-size: 16px !important;
}

@media (max-width: 1700px) {
	.ph-auth-login-inner-container {
		right: 37.1%;
	}
}

@media (max-width: 1500px) {
	.ph-auth-login-inner-container {
		right: 37%;
	}
}

@media (max-width: 1300px) {
	.ph-auth-login-inner-container {
		right: 36.9%;
	}
}

@media (max-width: 1199px) {
	.ph-auth-login-inner-container {
		right: 33.2%;
	}
}

@media (max-width: 1150px) {
	.ph-auth-login-inner-container {
		right: 33%;
	}
}

@media (max-width: 1060px) {
	.ph-auth-login-inner-container {
		right: 32.8%;
	}
}

@media (max-width: 950px) {
	.ph-auth-login-inner-container {
		right: 32.9%;
		max-width: 590px;
	}

	.ph-auth-login-form-inner-container {
		padding: 20px 0;
	}
}

@media (max-width: 900px) {
	.ph-auth-login-inner-container {
		max-width: 550px;
	}
}

@media (max-width: 840px) {
	.ph-auth-login-inner-container {
		position: relative;
		top: unset;
		bottom: unset;
		right: unset;
		background-color: $primaryBackgroundColor;
		max-width: 100%;
		padding: 20px;
		max-height: 500px;
		margin: 0;
	}

	.ph-auth-login-form-main-container {
		max-width: 550px;
		margin: auto;
	}

	.ph-auth-login-logo-container {
		max-width: 550px;
		margin: auto;
	}
}

@media (max-width: 590px) {
	.ph-auth-login-body-container {
		max-width: 100%;
		width: 100%;
	}

	.ph-auth-login-form-inner-container {
		display: block;
		text-align: center;
	}

	.ph-auth-login-social-google {
		min-width: unset;
		margin-bottom: 15px;
	}

	.ph-auth-login-social-facebook {
		min-width: unset;
	}
}

@media (max-width: 575px) {
	.ph-auth-login-logo-container {
		margin-top: -7px;
	}
}

@media (max-width: 400px) {
	.ph-auth-login-inner-container {
		padding: 15px;
	}

	.ph-auth-login-body-container {
		padding: 15px;
	}

	.ph-auth-login-title-container {
		padding: 15px 0;
	}

	.ph-auth-login-main-button {
		min-width: unset;
		padding: 0 30px;
	}
}

@media (max-width: 320px) {
	.ph-auth-login-body-container {
		padding: 10px;
	}

	.ph-auth-login-form-inner-container {
		padding: 0 0 10px 0;
	}

	.ph-auth-login-social-facebook {
		margin: 0;
	}

	.ph-auth-login-social-google {
		margin-left: 0;
		margin-right: 0;
	}

	.ph-auth-login-main-button {
		padding: 0 10px;
	}

	.ph-auth-login-register-container {
		display: block;
		text-align: center;
	}
}

@media (max-height: 585px) {
	.ph-auth-login-inner-container {
		margin: unset;
		top: 15px;
	}
}

@media (max-width: 320px) and (max-height: 585px) {
	.ph-auth-login-inner-container {
		top: 0;
	}
}
