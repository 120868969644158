/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-checkout-service-main-box-delivery-main-title {
	padding: 0 15px;
	margin-top: 10px;
	margin-bottom: 25px;
	font-size: 25px;
	text-align: center;
	font-weight: 600;
	color: $colorGrayText4;
}

.ph-checkout-service-main-box-delivery-price-container {
	padding: 15px;
	border-top: 1px solid $primaryColorBorder;
	border-bottom: 1px solid $primaryColorBorder;
	margin: 10px 0;
}
