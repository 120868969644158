/** @format */

@import '../Commons/Sass/components/colors';

.ph-product-category-timeline-main-container {
	margin-top: 15px;
	margin-bottom: 0;
	padding: 0 25px;
}

.ph-product-category-timeline-list-container {
	display: inline;
	position: relative;
}

.ph-product-category-timeline-master-category-title {
	font-size: 13px;
	color: $primaryColor;
	font-weight: 500;
	margin-bottom: 0;
	text-transform: capitalize;
}

.ph-product-category-timeline-master-category-separator {
	font-size: 13px;
	color: $colorGrayText2;
	font-weight: 500;
	margin-left: 4px;
	margin-right: 4px;
	margin-bottom: 0;
}

.ph-product-category-timeline-master-category-subtitle {
	font-size: 13px;
	color: $colorGrayText2;
	font-weight: 500;
	margin-bottom: 0;
	text-transform: capitalize;
}

@media (max-width: 1280px) {
	.ph-product-category-timeline-main-container {
		margin-top: 10px;
		margin-bottom: 0;
	}
}

@media (max-width: 1024px) {
	.ph-product-category-timeline-main-container {
		margin-top: 3px;
		margin-bottom: -3px;
	}

	.ph-product-category-timeline-master-category-title {
		font-size: 11px;
	}

	.ph-product-category-timeline-master-category-separator {
		font-size: 11px;
		margin-left: 3px;
		margin-right: 3px;
	}

	.ph-product-category-timeline-master-category-subtitle {
		font-size: 11px;
	}
}

@media (max-width: 600px) {
	.ph-product-category-timeline-main-container {
		padding: 0 10px;
	}
}

@media (max-width: 320px) {
	.ph-product-category-timeline-master-category-title {
		font-size: 10px;
	}

	.ph-product-category-timeline-master-category-separator {
		font-size: 10px;
		margin-left: 3px;
		margin-right: 3px;
	}

	.ph-product-category-timeline-master-category-subtitle {
		font-size: 10px;
	}
}
