/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-checkout-box-service-subtotal-price {
	font-weight: 900;
	padding: 0 15px;
	font-size: 12px;
	color: $colorGrayText4;
	line-height: 20px;
	margin-bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-checkout-box-service-subtotal-price-delivery {
	font-weight: 900;
	padding: 0;
	font-size: 12px;
	color: $colorGrayText4;
	line-height: 20px;
	margin-bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-checkout-box-service-subtotal-promotion {
	color: $primaryColor !important;
}

.ph-checkout-box-service-subtotal-voucher {
	color: $secondColor !important;
}

.ph-checkout-box-service-subtotal-price-info {
	font-size: 12px;
	color: $colorGrayText4;
}
