/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-product-detail-dispatch-service-info-main-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 5px;
}

.ph-product-detail-dispatch-service-info-image-container {
	margin-right: 5px;
}

.ph-product-detail-dispatch-service-info-image {
	width: 100%;
	max-width: 30px;
}

.ph-product-detail-dispatch-service-info-title {
	font-size: 14px;
	text-align: left;
	color: $primaryColor;
	font-weight: 900;
	margin-top: 3px;
	margin-bottom: 0;
}

.ph-product-detail-dispatch-service-info-link {
	color: $primaryColor;
}

.ph-product-detail-dispatch-service-info-link:hover,
.ph-product-detail-dispatch-service-info-link:active,
.ph-product-detail-dispatch-service-info-link:focus {
	color: $primaryColor;
}

.ph-product-detail-dispatch-service-info-subtitle {
	font-size: 13px;
	text-align: left;
	color: $colorGrayText4;
	font-weight: 400;
	margin-bottom: 0px;
	padding: 5px 15px;
}
