/** @format */

@import '../../../../components/Commons/Sass/components/colors';

.ph-product-detail-main-image-global-container {
	box-shadow: $primaryBoxShadow;
	padding: 10px;
	border-radius: 4px;
	position: relative;
	margin-bottom: 10px;
}

.ph-product-detail-quote-main-image-global-container {
	padding: 10px;
	position: relative;
	margin: auto;
	background-color: $primaryBackgroundColor;
	border-radius: 5px;
}

.ph-product-detail-main-image-main-container {
	cursor: zoom-in;
}

.ph-product-detail-main-image-container {
	padding: 5px;
	display: block;
}

.ph-product-detail-main-image {
	width: 100%;
}

@media (max-width: 1024px) {
	.ph-product-detail-main-image-global-container {
		box-shadow: none;
		max-width: 350px;
		margin: auto;
	}
}
