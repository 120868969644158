.search-content {
	width: 100%;
	max-width: 1185px;
	margin: 10px auto;
	padding: 10px 0px;
	.card-primary {
		margin-top: 20px;
		margin-bottom:10px;
	}
}
