/** @format */

@import '../../components/Commons/Sass/components/colors';

.ph-building-global-container {
	max-width: 1185px;
	padding: 10px;
	margin: auto;
}

.ph-building-inner-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: auto;
	min-height: calc(100vh - 133px);
}

.ph-building-main-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.ph-building-content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-building-img {
	width: 100%;
	max-width: 600px;
}

.ph-building-title {
	color: $colorGrayText4;
	font-size: 30px;
	font-weight: 500;
	margin-bottom: 0;
}

.ph-building-sub-title {
	color: $primaryColor;
	font-size: 30px;
	font-weight: 800;
	margin-bottom: 0;
	text-transform: capitalize;
}

.ph-building-name {
	color: $colorGrayText4;
	font-size: 20px;
	font-weight: 600;
	margin-top: 0;
	margin-bottom: 0;
}

.ph-building-mail {
	color: $colorGrayText4;
	font-size: 16px;
	margin-top: -5px;
	margin-bottom: 0;
	font-style: italic;
}

.ph-building-oc {
	font-size: 15px;
	font-weight: 500;
	color: $colorGrayText4;
	margin-bottom: 0px;
}

.ph-building-span-oc {
	color: $primaryColor;
	font-size: 20px;
	margin-bottom: 0;
	font-weight: 900;
	margin-left: 5px;
}

.ph-building-span {
	color: $primaryColor;
	font-size: 14px;
	padding-left: 5px;
}

.ph-building-description {
	font-size: 15px;
	color: $colorGrayText4;
	font-weight: 400;
	text-align: center;
	margin: 15px;
	max-width: 700px;
}

.ph-building-sub-description {
	font-weight: 600;
	text-transform: capitalize;
}

.ph-building-description-span-i {
	color: $errorColor;
	padding-right: 5px;
}

.ph-building-description-span {
	font-style: italic;
	text-align: center;
	color: $colorGrayText2;
	font-size: 14px;
	font-weight: 600;
	margin-top: 2px;
	margin-bottom: 15px;
}

.ph-building-payment-button-container {
	display: flex;
	justify-content: center;
	margin-top: 0px;
}

.ph-building-payment-button,
.ph-building-payment-button:focus,
.ph-building-payment-button:hover,
.ph-building-payment-button:active {
	color: $colorWhiteText;
	font-weight: bold;
	margin: auto;
	height: 36px;
	width: 100%;
	max-width: 160px;
	box-shadow: $primaryBoxShadow;
	border: 2px solid $secondColor;
	background-color: $secondColor;
	border-radius: 4px;
	padding: 5px 20px;
}

.ph-building-payment-button-span {
	padding-top: 5px;
}

.ph-building-payment-button-tracking {
	font-weight: 600;
	text-decoration: underline;
	margin-top: 10px;
	font-size: 13px;
	font-style: italic;
	color: $primaryColor;
}

@media screen and (max-width: 992px) {
	.ph-building-img {
		max-width: 150px;
	}
	.ph-building-title {
		font-size: 28px;
	}
	.ph-building-oc {
		font-size: 16px;
	}
	.ph-building-description {
		font-size: 13px;
	}
	.ph-building-name {
		font-size: 18px;
	}
}
@media screen and (max-width: 768px) {
	.ph-building-img {
		max-width: 200px;
	}
	.ph-building-title {
		font-size: 27px;
	}
	.ph-building-name {
		font-size: 20px;
	}
	.ph-building-oc {
		font-size: 15px;
		margin-bottom: 5px;
	}
	.ph-building-description {
		font-size: 14px;
		margin: auto;
		margin-bottom: 5px;
	}
	.ph-building-description-span {
		font-size: 12px;
		margin: auto;
		margin-bottom: 10px;
	}
}
@media screen and (max-width: 576px) {
	.ph-building-title {
		font-size: 20px;
		margin-bottom: 5px;
	}
	.ph-building-name {
		font-size: 15px;
		margin-bottom: 2px;
	}
	.ph-building-mail {
		font-size: 12px;
	}
	.ph-building-oc {
		font-size: 14px;
		margin-bottom: 5px;
	}
	.ph-building-description {
		font-size: 12px;
		margin: auto;
		padding-bottom: 10px;
	}
	.ph-building-description-span {
		font-size: 12px;
		margin-bottom: 5px;
	}
}
