/** @format */

@import '../../../../../../../../../../components/Commons/Sass/components/colors';

.ph-electronic-quote-button,
.ph-electronic-quote-button:hover,
.ph-electronic-quote-button:active,
.ph-electronic-quote-button:focus {
	background-color: $secondColor;
	border-color: $secondColor;
	color: $colorWhiteText;
	margin: 0;
	padding: 4px 18px;
	font-size: 15px;
	border-radius: 4px;
	font-weight: 600;
	text-transform: uppercase;
	height: 35px;
	box-shadow: $mainHeaderboxShadow;
}

.ph-electronic-alternative-button,
.ph-electronic-alternative-button:hover,
.ph-electronic-alternative-button:active,
.ph-electronic-alternative-button:focus {
	background-color: $primaryBackgroundColor;
	border: 2px solid $primaryColor !important;
	color: $primaryColor;
	margin: 0;
	font-size: 13px;
	border-radius: 4px;
	padding: 4px 7px;
	font-weight: 500;
	text-transform: uppercase;
	height: 35px;
	box-shadow: $mainHeaderboxShadow;
}

@media (max-width: 575px) {
	.ph-electronic-quote-button,
	.ph-electronic-quote-button:hover,
	.ph-electronic-quote-button:active,
	.ph-electronic-quote-button:focus {
		height: 30px;
		padding: 0 10px;
		line-height: 25px;
		font-size: 14px;
	}

	.ph-electronic-alternative-button,
	.ph-electronic-alternative-button:hover,
	.ph-electronic-alternative-button:active,
	.ph-electronic-alternative-button:focus {
		height: 30px;
		padding: 0 10px;
		line-height: 25px;
		font-size: 11px;
	}
}
