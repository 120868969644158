/** @format */

.ph-checkout-request-error-service-global-container {
	max-width: 1185px;
	padding: 10px;
	margin: auto;
}

.ph-checkout-request-error-service-main-container {
	margin-top: 50px;
	margin-bottom: 70px;
	text-align: center;
	min-height: calc(100vh - 253px);
}

.ph-checkout-request-error-service-image-container {
	padding: 10px;
}

.ph-checkout-request-error-service-image {
	opacity: 0.6;
	width: 100%;
	max-width: 250px;
}

.ph-checkout-request-error-service-title {
	color: #555;
	font-weight: 900;
	font-size: 25px;
	opacity: 0.8;
	margin-bottom: 0;
}

.ph-checkout-request-error-service-description {
	color: #555;
	font-weight: 500;
	font-size: 20px;
	opacity: 0.6;
}

@media (max-width: 768px) {
	.ph-checkout-request-error-service-image-container {
		padding: 5px;
	}

	.ph-checkout-request-error-service-image {
		max-width: 150px;
	}

	.ph-checkout-request-error-service-title {
		font-size: 15px;
	}

	.ph-checkout-request-error-service-description {
		font-size: 12px;
	}
}
