/** @format */

@import "../../../../../../../../components/Commons/Sass/components/colors";

.ph-product-detail-quote-pharmacy-main-title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 0 10px 0;
}

.ph-product-detail-quote-pharmacy-main-title-inner-container {
  padding: 0;
  margin-left: 10px;
}

.ph-product-detail-quote-pharmacy-main-title {
  color: $primaryColor;
  font-weight: 500;
  opacity: 1;
  font-size: 18px;
  padding: 0;
  margin: 0;
}

.ph-product-detail-quote-pharmacy-list-pharmacy-inner-container {
  width: 96%;
}

.ph-product-detail-quote-pharmacy-list-pharmacy-stock-mobile-open {
  display: none;
}

.ph-product-detail-quote-pharmacy-main-subtitle {
  color: $colorGrayText3;
  font-weight: 400;
  font-style: italic;
  opacity: 0.9;
  font-size: 12px;
  padding: 0;
  margin: 0;
}

.ph-product-detail-quote-pharmacy-filters-container {
  display: flex;
  column-gap: 35px;
  margin-left: auto;
}

.ph-product-detail-quote-pharmacy-filter-button-container {
  display: none;
}

.ph-product-detail-quote-pharmacy-input-slider-container {
  display: flex;
  align-items: center;

  &>h4 {
    width: 70px;
  }
}

.ph-product-detail-quote-pharmacy-title {
  color: $colorGrayText2;
  margin-bottom: 0;
  font-size: 12px;
}

.ph-product-detail-quote-pharmacy-input-container .ant-input-number-input {
  height: 22px;
  font-size: 12px;
  padding: 0 11px 0 7px;
  color: $colorGrayText3;
}

.ph-product-detail-quote-pharmacy-input-container.ant-input-number {
  border-radius: 4px;
  margin-top: 3px !important;
  width: 55px;
}

.ph-product-detail-quote-pharmacy-slider-container.ant-slider {
  margin: 4px;
}

.ph-product-detail-quote-pharmacy-slider-container .ant-slider-track {
  background-color: $primaryColor;
}

.ph-product-detail-quote-pharmacy-slider-container .ant-slider-handle {
  border: solid 2px $primaryColor;
  width: 12px;
  height: 12px;
  background-color: $primaryColor;
}

.ph-product-detail-quote-pharmacy-slider-container.ant-slider:hover .ant-slider-track {
  background-color: $primaryColor;
}

.ph-product-detail-quote-pharmacy-slider-container.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: $primaryColor;
}

.ph-product-detail-quote-select-container {
  width: 100%;
  display: flex;
  align-items: center;

  &>h4 {
    width: 90px;
  }
}

.ph-product-detail-quote-pharmacy-main-title-container .ant-dropdown-trigger>.anticon.anticon-down {
  font-size: 7px !important;
}

.ph-product-detail-quote-select {
  font-size: 12px !important;
  width: 180px;
  color: $colorGrayText4;
}

.ph-product-detail-quote-pharmacy-description {
  color: $colorGrayText3;
  font-size: 13px;
  padding-right: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid $colorGrayText2;
}

.ph-product-detail-quote-pharmacy-list-global-container {
  // max-height: 406px;
  // overflow-y: auto;
  margin-bottom: 15px;
}

.ph-product-detail-quote-pharmacy-no-list {
  padding: 2px;
}

.ph-product-detail-quote-pharmacy-is-list {
  padding: 8px;
  border: 1px solid $primaryColorBorder;
  border-radius: 4px;
}

.ph-product-detail-quote-pharmacy-list-pharma {
  padding: 6px 0px 0px 20px;
}

.ph-product-detail-quote-pharmacy-list-main-container {
  border-radius: 5px;
  box-shadow: $primaryBoxShadow;
  margin: 15px 0 0;
  background-color: $primaryBackgroundColor;
  align-items: stretch;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &>div:nth-child(2),
  &>div:nth-child(3),
  &>div:nth-child(4),
  &>div:nth-child(5),
  &>div:nth-child(6) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &>div:nth-child(2)>div:first-child,
  &>div:nth-child(3)>div:first-child,
  &>div:nth-child(4)>div:first-child,
  &>div:nth-child(5)>div:first-child,
  &>div:nth-child(6)>div:first-child {
    font-size: 14px;
    color: $colorGrayText4;
    font-weight: 500;
  }
}



.ph-product-detail-quote-pharmacy-list-info-desktop {
  padding: 10px;

  &:first-child {
    border: 1px solid #ededef;
    border-radius: 5px;
    padding-right: 0;
  }
}

.ph-product-detail-quote-pharmacy-list-image-cenabast {
  width: 100%;
  max-width: 20px;
}

.ph-product-detail-quote-pharmacy-list-image-ondemand-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ph-product-detail-quote-pharmacy-list-image-ondemand-title {
  font-weight: 600;
  color: $colorGrayText3;
  margin-bottom: 0;
  font-size: 13px;
  line-height: 10px;
}

.ph-product-detail-quote-pharmacy-list-image-ondemand-inner-container {
  margin-left: 5px;
  margin-top: -4px;
}

.ph-product-detail-quote-pharmacy-list-image-ondemand {
  width: 100%;
  max-width: 16px;
}

.ph-product-detail-quote-pharmacy-list-pharmacy-main-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ph-product-detail-quote-pharmacy-list-pharmacy-stock-dispatch-inner-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ph-product-detail-quote-pharmacy-list-pharmacy-geo-inner-container {
  min-width: 60px;
  text-align: center;
}

.ph-product-detail-quote-pharmacy-list-pharmacy-container {
  position: relative;
  justify-content: flex-start;
  align-items: center;

  .ph-product-detail-quote-pharmacy-list-pharmacy-title-container {
    display: flex;
  }

}


.ph-product-detail-quote-pharmacy-list-pharmacy-icons-container {
  max-width: 20px;
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
}

.ph-product-detail-quote-pharmacy-list-image-container {
  margin-right: 5px;
  border-radius: 50px;
  width: 100%;
  max-width: 25px;
  min-width: 25px;
}

.ph-product-detail-quote-pharmacy-list-image {
  width: 100%;
  max-width: 20px;
}

.ph-product-detail-quote-pharmacy-list-pharmacy-title {
  color: $colorGrayText4;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ph-product-detail-quote-pharmacy-list-pharmacy-subtitle {
  color: $colorGrayText4;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px
}

.ph-product-detail-quote-pharmacy-stock-distance-container {
  display: flex;
}

.ph-product-detail-quote-pharmacy-list-pharmacy-title-stock-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ph-product-detail-quote-pharmacy-on-demand-button,
.ph-product-detail-quote-pharmacy-on-demand-button:hover,
.ph-product-detail-quote-pharmacy-on-demand-button:active,
.ph-product-detail-quote-pharmacy-on-demand-button:focus {
  background-color: $primaryColor;
  border-color: $primaryColor;
  line-height: 33px;
  color: $colorWhiteText;
  margin: 0;
  font-size: 15px;
  border-radius: 4px;
  font-weight: 600;
  text-transform: uppercase;
  height: 35px;
  padding: 0 18px;
  box-shadow: $mainHeaderboxShadow;
}

.ph-product-detail-quote-pharmacy-on-demand-button[disabled] {
  background-color: $thirdBackgroundColor !important;
}

.ph-product-detail-quote-pharmacy-no-results-container {
  border: 1px solid $primaryColorBorder;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  padding: 0 10px 12px 10px;
}

.ph-product-detail-quote-pharmacy-no-results-image-container {
  width: 100%;
  max-width: 60px;
  opacity: 0.5;
}

.ph-product-detail-quote-pharmacy-no-results-title {
  color: $colorGrayText3;
  margin-bottom: 0;
}

.ph-product-detail-simple-alternative-button {
  background-color: $primaryBackgroundColor;
  color: $primaryColor;
  margin: 0;
  padding: 4px 7px;
  font-size: 13px;
  border-radius: 4px;
  font-weight: 600;
  text-transform: uppercase;
  height: 35px;
  box-shadow: $mainHeaderboxShadow;
  border: 2px solid $primaryColor !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ph-product-detail-simple-alternative-button:hover,
.ph-product-detail-simple-alternative-button:active,
.ph-product-detail-simple-alternative-button:focus {
  text-decoration: none;
  color: $primaryColor;
}

.ph-product-detail-quote-pharmacy-show-more {
  margin-bottom: 6px;

  & .ph-product-detail-simple-alternative-button {
    width: 100%;
  }
}

.ph-product-detail-quote-pharmacy-no-results-subtitle {
  margin-bottom: 5px;
  color: $colorGrayText2;
  font-style: italic;
  font-size: 12px;
}

.second-row-margin {
  //margin-top: 30px;
  position: absolute;
  bottom: 16px;

  &:nth-child(1) {
    width: 100%;
  }
}

.active-collapsed {
  &>div:first-child {
    display: flex;

    &>div:nth-last-child(1) {
      display: inline-block;
    }
  }

  &>div:last-child {
    &>div:first-child {
      &>div:last-child {
        display: none;
      }
    }

    &>div:last-child {
      display: none;
    }
  }
}

.ph-product-detail-quote-pharmacy-list-collapsed-container {
  margin: 0;
  max-height: 0;
  overflow: hidden;
  width: 100%;
  transition: max-height 0.2s ease-out;
}

.ph-product-detail-quote-pharmacy-list-collapsed-header {
  &>div:nth-child(n + 1) {
    display: none;
  }
}

.ph-product-detail-quote-pharmacy-list-collapsed-price {
  &:nth-child(n + 1) {
    &> :nth-child(n + 1) {
      display: none;
    }
  }
}

.ph-product-detail-quote-pharmacy-list-collapsed-agreements-list {
  display: block;
  margin-bottom: 12px;

  &>div:nth-child(n + 1) {
    display: block;
    box-shadow: 0 0 0 black;
    width: 100%;
  }
}

.ph-product-detail-quote-pharmacy-list-collapsed-promotion-list {
  display: block;
}

.ph-product-detail-quote-pharmacy-list-collapsed-buttons {
  & .ph-product-detail-simple-alternative-button {
    display: none;
  }

  & .ph-product-detail-quote-button {
    display: none;
  }
}

.ph-product-detail-quote-pharmacy-agreement-collapse {
  display: flex;
  align-items: center;

  &> :nth-last-child(1) {
    padding-left: 3px;
    font-size: 9px;
    transition: transform 0.2s ease-out;
  }

  &.active-collapsed {
    &> :nth-last-child(1) {
      padding-right: 2px;
      font-size: 8px;
      transform: rotate(-180deg);
    }
  }
}

.ph-product-detail-quote-pharmacy-list-pharmacy-distance-container {
  margin-left: auto;
  margin-right: 10px;
}

.ph-product-detail-quote-pharmacy-list-pharmacy-distance-text {
  font-size: 13px;
  color: #555555;
  letter-spacing: -0.49px;
  font-weight: 450;
}

.ph-product-detail-quote-agreements-title {
  font-weight: 500;
  font-size: 14px;
  color: $primaryColor;
  margin-bottom: 0;
  text-align: right;
  overflow-wrap: break-word;
  width: 40%;
}


.ph-product-detail-detailpharmacy-dispatch-unavailable {
  font-size: 14px;
  font-weight: 400;
  color: $colorGrayText1;
}

@media (max-width: 1060px) {


  .ph-product-detail-quote-pharmacy-list-image-container {
    margin-right: 0;
  }

  .ph-product-detail-quote-pharmacy-list-main-container {
    padding: 7px;
  }
}

@media (max-width: 992px) {
  .ph-product-detail-quote-pharmacy-list-pharmacy-distance-container {
    display: none;
  }

  .ph-product-detail-quote-pharmacy-list-collapsed-container {
    margin: 8px 0;
  }

  .ph-product-detail-quote-pharmacy-list-info-desktop {
    display: none !important;

    &:first-child {
      display: block !important;
      border: 0px solid #ededef;
    }
  }

  .ph-product-detail-quote-pharmacy-list-collapsed-header {
    border-style: solid none;
    border-width: 1px;
    border-color: rgb(0, 0, 0, 0.3);
    align-items: center;
    font-size: 12px;

    &>div:nth-child(2) {
      margin-left: auto;
    }

    &>div:nth-child(n + 1) {
      display: flex;
      align-items: center;
      margin: 10px 0;
    }
  }

  .ph-product-detail-detailsimple-header-first {
    font-size: 14px;
    font-weight: 500;
    color: #7B7B7B;
  }

  .ph-product-detail-detailsimple-header-second {
    font-size: 14px !important;
    margin-left: auto;
    font-weight: 600;
    font-size: 1px;
    color: #555555;
  }

  .ph-product-detail-quote-pharmacy-list-collapsed-price {
    padding: 0 0 12px 0;
    font-size: 16px;
    font-weight: 500;
    color: #555555;
    display: flex;

    &:nth-child(n + 1) {
      &> :nth-child(n + 1) {
        display: block;
      }
    }

    &>div:nth-child(2) {
      margin-left: auto;
    }
  }

  .ph-product-detail-quote-pharmacy-list-collapsed-regular-price {
    margin: 10px 0;
    font-weight: 500;
    color: #7B7B7B;
    display: flex;
    justify-content: space-between;

    &:nth-child(n + 1) {
      &> :nth-child(n + 1) {
        display: block;
      }
    }

    &>div:nth-child(2) {
      margin-left: auto;
    }
  }

  .ph-product-detail-quote-pharmacy-list-collapsed-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;

    & .ph-product-detail-simple-alternative-button {
      display: block;
      font-size: 15px;
      padding: 0 12px;
    }

    & .ph-product-detail-quote-button {
      display: block;
      padding: 0 12px;
    }
  }

  .ph-product-detail-quote-pharmacy-main-title-container {
    display: block;
    max-height: 406px;
    padding: 0 11px;
  }

  .ph-product-detail-quote-pharmacy-list-main-container {
    padding: 5px 10px;
  }

  .ph-product-detail-quote-pharmacy-main-title-inner-container {
    //text-align: center;
    margin-bottom: 10px;
    margin-left: 0;
  }

  .ph-product-detail-quote-pharmacy-filters-container {
    display: block;
  }

  .ph-product-detail-quote-pharmacy-input-slider-container {
    width: 100%;
    max-width: none;
    margin-bottom: 10px;

    &>h4 {
      width: 90px;
    }
  }

  .ph-product-detail-quote-pharmacy-filter-button-container {
    display: block;
    margin-left: auto;

    &> :first-child {
      margin-left: 5px;
    }
  }

  .ph-product-detail-quote-select-container {
    gap: 0;
  }

  .ph-product-detail-quote-select {
    width: 100%;

    &:last-child {
      width: 80%;
    }
  }

  .ant-btn.ph-product-detail-quote-filters-button {
    padding: 0 5px 2px;
    height: fit-content;
  }

  .ph-product-detail-quote-pharmacy-list-collapsed-agreements-list {
    margin-bottom: 12px;
  }

  .second-row-margin {
    position: relative;
    top: 5px;
  }

  .ph-product-detail-quote-pharmacy-list-collapsed-promotion-list {
    font-size: 18px;
    font-weight: 700;
  }

  .ph-product-detail-quote-price-main-container-no-padding {
    text-align: -webkit-center;
    display: flex !important;
    align-items: center;
  }
}

@media (max-width: 670px) {
  .ph-product-detail-quote-pharmacy-list-pharmacy-main-container {
    display: block;
  }

  .ph-product-detail-quote-pharmacy-list-margin {
    padding: 0px 0px 0px 10px;
  }
}

@media (max-width: 600px) {
  .ph-product-detail-quote-pharmacy-list-pharma {
    padding: 0px 0px 0px 0px;
  }

  .ph-product-detail-quote-pharmacy-list-image {
    max-width: 18px;
  }

  .ph-product-detail-quote-pharmacy-list-image-container {
    text-align: center;
  }

  .ph-product-detail-quote-pharmacy-main-title {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .ph-product-detail-quote-pharmacy-main-title-inner-container {
    //text-align: center;
    margin-bottom: 0;
  }
}

@media (max-width: 575px) {
  .ph-product-detail-quote-pharmacy-list-main-container {
    padding: 12px 12px 6px 12px;
  }

  .ph-product-detail-quote-pharmacy-list-image-cenabast {
    max-width: 16px;
  }

  .ph-product-detail-quote-pharmacy-list-image-ondemand {
    max-width: 16px;
  }

  .ph-product-detail-quote-pharmacy-list-pharmacy-title-stock-container {
    display: block;
    text-align: right;
  }

  .ph-product-detail-quote-pharmacy-on-demand-button,
  .ph-product-detail-quote-pharmacy-on-demand-button:hover,
  .ph-product-detail-quote-pharmacy-on-demand-button:active,
  .ph-product-detail-quote-pharmacy-on-demand-button:focus {
    padding: 8px 15px;
  }
}

@media (max-width: 500px) {
  .ph-product-detail-quote-pharmacy-list-image-container {
    margin-right: 8px;
  }

  .ph-product-detail-quote-pharmacy-list-pharmacy-mobile-header {
    display: flex;
    flex-direction: column;
  }

  .ph-product-detail-quote-pharmacy-list-pharmacy-stock-mobile-open {
    display: none;
    font-size: 14px;
    font-weight: 400;
    //  letter-spacing: -0.42px;

    &.green {
      color: $successColor;
    }

    &.orange {
      color: $secondColor;
    }

    &.gray {
      color: $colorGrayText3;
    }

    &.benvida-green {
      color: $primaryColor;
    }
  }


}

@media (max-width: 380px) {
  .ph-product-detail-quote-pharmacy-description {
    font-size: 11px;
  }

  .ph-product-detail-quote-pharmacy-dropdown-inner-container {
    width: 100%;
    max-width: 120px;
    margin: auto;
  }

  .ph-product-detail-quote-pharmacy-main-title {
    text-align: left;
  }

  .ph-product-detail-quote-dropdown-container {
    margin: auto;
  }
}