/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-map-retirement-routes-marker-pharol {
	color: $primaryColor;
	font-weight: 700;
	text-align: center;
}

.ph-map-retirement-routes-container .leaflet-container {
	width: 100%;
	height: 200px;
	z-index: 1;
	margin: auto;
}
