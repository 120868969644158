/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-order-detail-tracking-main-container {
	border-radius: 4px;
	box-shadow: $primaryBoxShadow;
	position: relative;
}

.ph-order-detail-tracking-main-container .ant-progress {
	line-height: 0;
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
	border-radius: 4px 4px 0 0;
}

.ph-order-detail-tracking-main-container .ant-progress-outer {
	line-height: 0;
	border-radius: 4px 4px 0 0;
}

.ph-order-detail-tracking-main-container .ant-progress-inner {
	line-height: 0;
	border-radius: 4px 4px 0 0;
}

.ph-order-detail-tracking-main-container .ant-progress-bg {
	line-height: 0;
	border-radius: 4px 4px 4px 0;
}

.ph-order-detail-tracking-main-container .ant-progress-bg {
	line-height: 0;
	border-radius: 4px 4px 4px 0;
	background-color: $primaryColor;
}

.ph-order-detail-tracking-container {
	padding: 50px 0 10px 0;
}

.ph-order-detail-tracking-container .ant-timeline {
	padding: 0 30px;
}

.ant-timeline-item-head-ph-order-detail-tracking-container-step-active {
	color: $primaryColor !important;
	font-size: 22px;
	box-shadow: $primaryBoxShadow;
	border-radius: 100px;
	padding: 3px;
}

.ant-timeline-item-head-ph-order-detail-tracking-container-step-disabled {
	color: $colorGrayText4 !important;
	font-size: 22px;
	opacity: 0.2;
	box-shadow: $primaryBoxShadow;
	border-radius: 100px;
	padding: 3px;
}

.ant-timeline-item-head-ph-order-detail-tracking-container-step-canceled {
	@extend .ant-timeline-item-head-ph-order-detail-tracking-container-step-active;
	color: $errorColor !important;
}

.tracking-status-active {
	opacity: 1;
}

.tracking-status-disabled {
	opacity: 0.2;
}

.ph-order-detail-tracking-container .ant-timeline-item-tail {
	top: 19px;
	height: calc(100% - 23px);
}

.ph-order-detail-tracking-date-container {
	padding: 2px;
}

.ph-order-detail-tracking-date-title {
	color: $colorGrayText4;
	font-weight: 500;
	font-style: italic;
	font-size: 13px;
	margin-top: 5px;
	margin-bottom: 0;
	padding: 0 20px;
}

.ph-order-detail-tracking-date-icon {
	color: $colorGrayText4;
	font-size: 20px;
	margin-top: 5px;
	margin-bottom: 0;
	padding: 0 20px;
}

.ph-order-detail-tracking-list-container {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.ph-order-detail-tracking-list-inner-title {
	color: $colorGrayText4;
	font-size: 14px;
	margin-bottom: 0;
	font-weight: 900;
}

.ph-order-detail-tracking-list-inner-description {
	color: $colorGrayText4;
	font-size: 12px;
	margin-bottom: 0;
	font-weight: 500;
}

.tracking-status-image-disabled {
	filter: grayscale(1);
}

.ph-order-detail-tracking-list-inner-description-image {
	width: 100%;
	max-width: 45px;
}

@media (max-width: 768px) {
	.ph-order-detail-tracking-container {
		padding: 30px 0 10px 0;
	}

	.ph-order-detail-tracking-inner-container {
		padding: 0 20px;
	}

	.ph-order-detail-tracking-date-title {
		padding: 0;
		margin-top: 0;
	}

	.ph-order-detail-tracking-date-icon {
		padding: 0;
		margin-top: 0;
	}
}

@media (max-width: 575px) {
	.ph-order-detail-tracking-container .ant-timeline {
		padding: 0 15px;
	}
}