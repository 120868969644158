/** @format */

@import "../../../../../../components/Commons/Sass/components/colors";

.ph-profile-address-button-delete,
.ph-profile-address-button-delete:active,
.ph-profile-address-button-delete:focus,
.ph-profile-address-button-delete:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $secondColor !important;
  color: $colorWhiteText;
  padding: 5px 10px;
  font-size: 15px;
  border-radius: 4px;
  font-weight: 500;
  box-shadow: $primaryBoxShadow;
  border: 2px solid $secondColor;
  text-transform: capitalize;
  height: 36px;
}

.ph-profile-address-button-delete .anticon {
  line-height: 0 !important;
}

.ph-profile-address-button-delete-title {
  margin-left: 4px;
  margin-bottom: 0;
  color: $colorWhiteText;
  font-size: 15px;
}

.ph-profile-address-none-list-content {
  padding: 50px;
  text-align: center;
}

.ph-profile-address-image-none {
  max-width: 120px;
  width: 100%;
}

.ph-profile-address-title-none {
  color: $colorGrayText2;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}

.ph-profile-address-description-none {
  width: 100%;
  max-width: 480px;
  font-style: italic;
  color: $colorGrayText3;
  margin: auto;
  margin-bottom: 20px;
  font-size: 15px;
}
