/** @format */

@import '../../../components/Commons/Sass/components/colors';

.ph-auth-register-main-container {
	background-color: $primaryBackgroundColor;
	min-height: 100vh;
}

.ph-auth-register-inner-container {
	width: 100%;
	max-width: 600px;
	margin: auto;
	position: absolute;
	top: 0px;
	bottom: 0;
	right: 37.4%;
	max-height: 667px;
	height: auto;
	z-index: 1;
}

.ph-auth-register-form-main-container {
	display: flex !important;
	justify-content: space-between !important;
	flex-direction: column !important;
	height: auto;
	background-color: $primaryBackgroundColor;
	box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
	border-radius: 4px;
}

.ph-auth-register-body-container {
	padding: 25px 25px 15px 25px;
	width: 100%;
	max-width: 475px;
	margin: auto;
}

.ph-auth-register-title-container {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 0 15px;
	margin-bottom: 15px;
}

.ph-auth-register-img-container {
	padding: 3px;
	margin-right: 5px;
}

.ph-auth-register-img {
	width: 100%;
	max-width: 29px;
}

.ph-auth-register-title {
	font-size: 22px;
	font-weight: 900;
	color: $colorGrayText4;
	text-align: left;
	margin-bottom: 0;
}

.ph-auth-register-subtitle {
	color: $primaryColor;
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 300;
	letter-spacing: -0.2px;
}

.ph-auth-register-form-container .ant-form-item-control-input {
	text-align: center;
}

.ph-auth-register-form-container .ant-form-item {
	margin-bottom: 18px;
}

.ph-auth-register-form-container .ant-form-item-explain.ant-form-item-explain-error {
	position: absolute;
	bottom: -15px;
	left: 0px;
	font-size: 12px;
	padding: 0 10px;
	line-height: 15px;
	min-height: auto;
}

.ph-auth-register-form-container .ant-form-item-explain.ant-form-item-explain-success {
	position: absolute;
	bottom: -15px;
	left: 30px;
	font-size: 12px;
	padding: 0 10px;
	line-height: 15px;
	min-height: auto;
}

.ph-auth-register-field-input {
	max-width: 470px;
	height: 43px;
	border-radius: 6px;
	font-size: 13px;
}

.ph-auth-register-recover-link-container {
	text-align: right;
	margin-right: 25px;
}

.ph-auth-register-recover-link-description {
	color: $primaryColor;
	font-size: 13px;
	font-weight: 300;
	cursor: pointer;
	opacity: 1;
	padding: 0 10px;
}

.ph-auth-register-register-container {
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 470px;
	margin: auto;
}

.ph-auth-register-register-description {
	font-size: 13px;
	margin-bottom: 10px;
	color: $colorGrayText4;
	margin-bottom: 0;
	font-weight: 300;
	opacity: 1;
}

.ph-auth-register-register-link-description {
	margin-left: 5px;
	font-size: 13px;
	font-weight: 600;
	margin-bottom: 0;
	color: $primaryColor;
	opacity: 1;
}

.ph-auth-register-footer-container {
	border-top: 1px solid $primaryColorBorder;
	padding: 10px 25px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-auth-register-footer-button-home-inner-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-auth-register-footer-button-home-icon {
	margin-bottom: 0;
	color: $primaryColor !important;
	opacity: 1 !important;
	margin-top: -1px;
	margin-right: 2px;
}

.ph-auth-register-footer-button-home-title {
	margin-bottom: 0;
	color: $primaryColor !important;
	font-size: 15px;
}

.ph-auth-register-main-button-container {
	text-align: center;
}

.ph-auth-register-main-button-container .ant-form-item {
	margin-bottom: 0;
}

.ph-auth-register-main-button,
.ph-auth-register-main-button:hover,
.ph-auth-register-main-button:focus,
.ph-auth-register-main-button:active {
	font-size: 18px;
	margin-top: 15px;
	background-color: $primaryColor !important;
	text-transform: none;
	color: $colorWhiteText !important;
	font-weight: 500;
	width: 100%;
	border-radius: 4px;
	margin: 0;
	min-width: 200px;
	max-width: 200px;
	height: 40px;
	margin-bottom: 0;
	box-shadow: $primaryBoxShadow;
	border: none !important;
}

.ph-auth-register-main-button span {
	position: relative;
	top: -2px;
}

.ph-auth-register-separator-main-container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 10px;
	max-width: 490px;
	margin: auto;
	margin-bottom: 25px;
}

.ph-auth-register-separator-line {
	width: 100%;
	border: 0;
	height: 1px;
	background: $thirdBackgroundColor;
	background-image: $thirdBackgroundColor;
}

.ph-auth-register-separator-title {
	padding: 0 10px;
	font-size: 18px;
	color: $colorGrayText0;
}

.ph-auth-register-logo-container {
	margin-bottom: 15px;
}

.ph-auth-register-checkbox-container {
	padding: 0 5px;
}

.ph-auth-register-checkbox-container .ant-checkbox-checked .ant-checkbox-inner {
	background-color: $primaryColor;
	border-color: $primaryColor;
}

.ph-auth-register-checkbox-container .ant-form-item-control-input {
	min-height: 22px;
}

.ph-auth-register-checkbox-description {
	font-size: 13px;
	color: $primaryColor;
}

.ph-auth-register-checkbox-container .ant-form-item {
	margin-bottom: 18px;
}

.ph-auth-register-checkbox-container .ant-form-item-explain.ant-form-item-explain-error {
	position: absolute;
	bottom: -15px;
	left: 16px;
	font-size: 12px;
	padding: 0 10px;
	line-height: 15px;
	min-height: auto;
}

.ph-auth-register-checkbox-container .ant-form-item-explain.ant-form-item-explain-success {
	position: absolute;
	bottom: -15px;
	left: 16px;
	font-size: 12px;
	padding: 0 10px;
	line-height: 15px;
	min-height: auto;
}

.ph-auth-register-loading-icon {
	height: 44px;
	display: flex;
	align-items: center;
}

.ph-auth-register-main-logo-image {
	color: $primaryColor;
}

@media (max-width: 1700px) {
	.ph-auth-register-inner-container {
		right: 37.1%;
	}
}

@media (max-width: 1500px) {
	.ph-auth-register-inner-container {
		right: 37%;
	}
}

@media (max-width: 1300px) {
	.ph-auth-register-inner-container {
		right: 36.9%;
	}
}

@media (max-width: 1199px) {
	.ph-auth-register-inner-container {
		right: 33.2%;
	}
}

@media (max-width: 1150px) {
	.ph-auth-register-inner-container {
		right: 33%;
	}
}

@media (max-width: 1060px) {
	.ph-auth-register-inner-container {
		right: 32.8%;
	}
}

@media (max-width: 950px) {
	.ph-auth-register-inner-container {
		right: 32.9%;
		max-width: 590px;
	}
}

@media (max-width: 900px) {
	.ph-auth-register-inner-container {
		max-width: 550px;
	}
}

@media (max-width: 840px) {
	.ph-auth-register-inner-container {
		position: relative;
		top: unset;
		bottom: unset;
		right: unset;
		background-color: $secondBackgroundColor;
		max-width: 100%;
		padding: 20px;
		max-height: 500px;
		margin: 0;
	}

	.ph-auth-register-form-main-container {
		max-width: 550px;
		margin: auto;
	}

	.ph-auth-register-logo-container {
		max-width: 550px;
		margin: auto;
	}
}

@media (max-width: 590px) {
	.ph-auth-register-body-container {
		max-width: 100%;
		width: 100%;
	}
}

@media (max-width: 575px) {
	.ph-auth-register-logo-container {
		margin-top: -7px;
	}
}

@media (max-width: 400px) {
	.ph-auth-register-inner-container {
		padding: 15px;
	}

	.ph-auth-register-body-container {
		padding: 15px;
	}

	.ph-auth-register-title-container {
		padding: 15px 0;
	}

	.ph-auth-register-main-button {
		min-width: unset;
		padding: 0 30px;
	}
}

@media (max-width: 320px) {
	.ph-auth-register-body-container {
		padding: 10px;
	}

	.ph-auth-register-main-button {
		padding: 0 10px;
	}

	.ph-auth-register-register-container {
		display: block;
		text-align: center;
	}
}

@media (max-height: 750px) {
	.ph-auth-register-inner-container {
		margin: unset;
		top: 15px;
	}
}

@media (max-height: 750px) and (max-width: 540px) {
	.ph-auth-register-inner-container {
		margin: unset;
		top: 0;
	}
}

@media (max-width: 320px) and (max-height: 750px) {
	.ph-auth-register-inner-container {
		top: 0;
	}
}
