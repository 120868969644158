/** @format */

@import '../Commons/Sass/components/colors';

.ph-checkout-title-main-container {
	display: flex;
	justify-content: flex-start;
	margin-bottom: 10px;
	align-items: center;
}

.ph-checkout-title-image-container {
	padding-right: 3px;
}

.ph-checkout-title-image {
	width: 100%;
	max-width: 33px;
}

.ph-checkout-title-image-bag {
	vertical-align: baseline;
	width: 100%;
	width: 24px;
}

.ph-checkout-title-container {
	display: flex;
	align-items: center;
}

.ph-checkout-title {
	color: $colorGrayText4;
	font-weight: 600;
	font-size: 16px;
	margin-bottom: 3px;
	line-height: 18px;
	margin-left: 5px;
}

.ph-checkout-subtitle {
	color: $colorGrayText4;
	font-weight: 500;
	font-size: 14px;
	margin-left: 0;
	margin-bottom: 0;
	opacity: 1;
	padding: 8px;
}

@media (max-width: 1024px) {
	.ph-checkout-title-image-container {
		padding: 3px;
		margin-left: 0;
	}
}

@media (max-width: 500px) {
	.ph-checkout-title-image {
		max-width: 24px;
	}

	.ph-checkout-title {
		font-size: 16px;
	}

	.ph-checkout-subtitle {
		margin-bottom: 2px;
		font-size: 14px;
	}
}