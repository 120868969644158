/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-card-homedelivery-info-main-container {
	position: relative;
	border: 1px solid $primaryColorBorder;
	border-radius: 4px;
	margin-left: 15px;
}

@media (max-width: 992px) {
	.ph-card-homedelivery-info-main-container {
		border-top: none;
		border-left: 1px solid $primaryColorBorder;
		border-right: 1px solid $primaryColorBorder;
		border-bottom: 1px solid $primaryColorBorder;
		border-radius: 0 0 4px 4px;
		margin-left: 0;
	}
}
