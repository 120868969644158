/** @format */

@import '../../../../../../../../../../components/Commons/Sass/components/colors';

.ph-electronic-quote-main-title-stock-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	max-width: 140px;
	min-width: 140px;
}

.ph-electronic-quote-main-title-stock-title {
	font-size: 12px;
	font-weight: 500;
	color: $colorGrayText2;
	margin-right: 5px;
	margin-bottom: 0;
}

.ph-electronic-quote-main-title-stock-description {
	font-size: 12px;
	font-weight: 600;
	color: $colorGrayText2;
	margin-bottom: 0;
}

.ph-electronic-quote-main-title-stock-description-green {
	color: $successColor;
	font-weight: 300;
}

.ph-electronic-quote-main-title-stock-description-orange {
	color: $secondColor;
	font-weight: 300;
}

.ph-electronic-quote-main-title-stock-description-red {
	color: $errorColor;
	font-weight: 300;
}

@media (max-width: 992px) {
	.ph-electronic-quote-main-title-stock-container {
		width: auto;
		max-width: unset;
		padding-right: 10px;
	}
}

@media (max-width: 575px) {
	.ph-electronic-quote-main-title-stock-container {
		bottom: 5px;
		right: 5px;
		min-width: 140px;
	}
}
