/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-modal-recipes-modal-container .ant-modal-content {
	border-radius: 8px;
}

.ph-modal-recipes-modal-container .ant-modal-content .ant-modal-close {
	top: 14px;
	right: 21px;
}

.ph-modal-recipes-modal-container .ant-modal-content .ant-modal-close-x {
	border-radius: 8px;
	border: 2px solid $fourthColor;
	border-radius: 100px;
	color: $fourthColor;
	font-weight: bold;
	width: 20px;
	height: 20px;
	font-size: 12px;
	line-height: 0;
	padding: 2px;
}

.ph-modal-recipes-modal-container .ant-modal-header {
	border-bottom: 1px solid $primaryColorBorder !important;
	padding: 12px 20px;
	border-radius: 8px 8px 0 0;
}

.ph-modal-recipes-modal-container .ant-modal-header .ant-modal-title {
	font-size: 16px;
	font-weight: 900;
}

.ph-modal-recipes-modal-main-title {
	font-weight: 600;
	color: $secondColor;
	max-width: 550px;
	margin: auto;
	font-size: 17px;
	margin-top: 5px;
}

.ph-modal-recipes-modal-title {
	font-style: italic;
	font-weight: 500;
	color: $primaryColor;
	opacity: 0.9;
	max-width: 550px;
	margin: auto;
	margin-bottom: 25px;
}

.ph-modal-recipes-modal-container .ant-modal-content .ant-modal-body {
	padding: 16px;
	text-align: center;
}

.ph-modal-recipes-field-input {
	max-width: 450px;
	height: 45px;
	border-radius: 6px;
	font-size: 13px;
}

.ph-modal-recipes-field-container {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	max-width: 375px;
	margin: 0 auto 30px;
	border-radius: 4px;
	box-shadow: $primaryBoxShadow;
}

.ph-modal-recipes-field-container .ant-form-item {
	margin-bottom: 0 !important;
	width: 100%;
}

.ph-modal-recipes-field-location {
	position: relative;
	border-top: 1px solid $primaryColor;
	border-bottom: 1px solid $primaryColor;
	border-left: 1px solid $primaryColor;
	border-right: none;
	border-radius: 4px 0 0 4px;
	height: 42px;
	width: 100%;
	max-width: 100%;
	font-size: 15px;
	border-color: $primaryColor !important;
	box-shadow: none !important;
	color: $primaryColor !important;
	font-weight: 400 !important;
}

.ph-modal-recipes-field-container .ant-form-item .ant-form-item-explain.ant-form-item-explain-error {
	position: absolute !important;
	bottom: -25px !important;
	left: 10px !important;
	font-size: 12px !important;
}

.ph-modal-recipes-field-container .ant-form-item .ant-form-item-explain.ant-form-item-explain-success {
	position: absolute !important;
	bottom: -25px !important;
	left: 10px !important;
	font-size: 12px !important;
}

.ph-modal-recipes-modal-container .ant-modal-footer {
	border-top: none;
	padding: 0;
}

.ph-modal-recipes-field-button,
.ph-modal-recipes-field-button:active,
.ph-modal-recipes-field-button:focus,
.ph-modal-recipes-field-button:hover {
	width: 42px;
	height: 42px;
	background-color: $primaryColor;
	border: 1px solid $primaryColor;
	border-radius: 0 4px 4px 0;
	color: $colorWhiteText;
	font-size: 22px;
	padding: 10px;
	line-height: 1px;
}

.ph-modal-recipes-field-button .anticon-search {
	font-size: 24px;
}

.ph-modal-recipes-modal {
	position: absolute;
	top: 42px;
	left: 0;
	z-index: 2;
	width: 100%;
	max-width: 333px;
	box-shadow: $primaryBoxShadow;
	background-color: $primaryBackgroundColor;
}

.ph-modal-recipes-modal-description {
	background-color: $primaryBackgroundColor !important;
	cursor: pointer;
	text-align: left;
	padding: 7px !important;
	border-bottom: 1px solid $primaryColorBorder;
	font-size: 13px;
	text-overflow: ellipsis !important;
	text-decoration: none;
	overflow: hidden !important;
	white-space: nowrap;
	max-width: 100%;
	width: 100%;
	margin: 0 !important;
	height: auto !important;
	line-height: 1.5 !important;
	color: $colorGrayText4 !important;
}

.ph-modal-recipes-modal-description:hover {
	background-color: $thirdBackgroundColor !important;
}

.ph-modal-recipes-modal-image-container {
	margin-right: 4px;
}

.ph-modal-recipes-modal-image {
	width: 100%;
	max-width: 17px;
}

.ph-modal-recipes-field-main-button,
.ph-modal-recipes-field-main-button:hover,
.ph-modal-recipes-field-main-button:active,
.ph-modal-recipes-field-main-button:focus {
	background: $primaryColor;
	color: $colorWhiteText;
	font-size: 16px;
	font-weight: 500;
	text-transform: capitalize;
	border-radius: 4px;
	min-width: auto;
	width: 100%;
	max-width: 125px;
	border-color: transparent;
	height: 38px;
	line-height: 10px;
	box-shadow: $primaryBoxShadow;
	margin-bottom: 0;
}

.ph-modal-recipes-modal-upload-image-container {
	width: 100%;
	max-width: 450px;
	margin: auto;
}

.ph-modal-recipes-modal-preview-image-main-container {
	width: 100%;
	max-width: 400px;
	margin: auto;
	margin-bottom: 15px;
}

.ph-modal-recipes-modal-preview-button-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-modal-recipes-modal-preview-button,
.ph-modal-recipes-modal-preview-button:hover,
.ph-modal-recipes-modal-preview-button:active,
.ph-modal-recipes-modal-preview-button:focus {
	background: #ffffff;
	border: 1px solid $primaryColor !important;
	color: $primaryColor;
	font-size: 13px;
	font-weight: 500;
	text-transform: capitalize;
	border-radius: 4px;
	min-width: auto;
	width: 100%;

	max-width: 150px;
	border-color: transparent;
	height: 38px;
	line-height: 10px;
	box-shadow: $primaryBoxShadow;
	margin-bottom: 0;
}

.ph-modal-recipes-modal-preview-image-container {
	padding: 5px;
	cursor: pointer;
}

.ph-modal-recipes-modal-preview-image {
	width: 100%;
	max-width: 200px;
}

.ph-checkout-receipt-modal-process-pdf-main-container {
	border: 1px solid $primaryColorBorder;
	border-radius: 4px;
	padding: 5px;
}

.ph-checkout-receipt-modal-process-pdf-container {
	text-align: center;
}

.ph-checkout-receipt-modal-process-pdf-icon {
	color: red;
	font-size: 100px;
	margin: 20px 0;
}

.ph-checkout-receipt-modal-process-pdf-link {
	font-size: 12px;
}

@media (max-width: 600px) {
	.ph-modal-recipes-field-container .ant-form-item .ant-form-item-explain.ant-form-item-explain-error {
		bottom: -1px !important;
		font-size: 11px !important;
	}

	.ph-modal-recipes-field-container .ant-form-item .ant-form-item-explain.ant-form-item-explain-success {
		bottom: -1px !important;
		font-size: 11px !important;
	}

	.ph-modal-recipes-modal-preview-button {
		margin-top: 15px;
		height: 28px;
		max-width: 130px;
		padding: 4px 5px;
	}
}
