/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-order-detail-products-title-component-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ph-order-detail-products-component-title {
	color: $colorGrayText4;
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	opacity: 0.8;
	margin-bottom: 0;
}

.ph-order-detail-products-component-description {
	margin-left: 3px;
	color: $primaryColor;
	font-size: 14px;
	font-weight: 600;
	text-transform: capitalize;
	margin-bottom: 0;
	opacity: 0.9;
}

@media (max-width: 768px) {
	.ph-order-detail-products-component-title {
		font-size: 11px;
	}

	.ph-order-detail-products-component-description {
		font-size: 12px;
	}
}
