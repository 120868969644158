/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-list-info-detail-profile-cardinfo-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	padding: 10px 15px;
	border-bottom: 1px solid $primaryColorBorder;
}

.ph-list-info-detail-profile-cardinfo-container:last-child {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	padding: 10px 15px;
	border-bottom: none;
}

.ph-list-info-detail-profile-cardinfo-title {
	color: $primaryColor;
	font-size: 13px;
	font-weight: 900;
	width: 100%;
	min-width: 145px;
	max-width: 145px;
	margin-bottom: 0;
}

.ph-list-info-detail-profile-cardinfo-description {
	color: $colorGrayText4;
	font-size: 13px;
	margin-bottom: 0;
	text-overflow: ellipsis;
	text-decoration: none;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
}

.ph-list-info-detail-profile-cardinfo-inner-description {
	opacity: 0.5;
}

@media (max-width: 575px) {
	.ph-list-info-detail-profile-cardinfo-title {
		font-size: 11px;
		min-width: 110px;
		max-width: 110px;
	}

	.ph-list-info-detail-profile-cardinfo-description {
		font-size: 12px;
	}
}
