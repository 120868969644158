/** @format */

@import '../../../../components/Commons/Sass/components/colors.scss';

.ph-modal-types-recipe-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10px;

}

.ph-modal-types-recipe-container-i {
    max-width: 35px;
    width: 100%;
    margin-bottom: 3px;

}

.ph-modal-types-recipes-hero-title {
    font-size: 18px;
    color: $colorGrayText4;
    font-weight: 800;
    margin-left: 15px;
    margin-bottom: 8px;
    text-align: center;
}

.ph-modal-types-recipes-hero-parra {
    font-size: 15px;
    font-weight: 300;
    color: $colorGrayText4;
}