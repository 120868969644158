/** @format */

.ph-carousel-prev {
	width: 23px !important;
	height: 23px !important;
	background-size: 25px 45px !important;
	left: 0 !important;
}

.ph-carousel-next {
	width: 23px !important;
	height: 23px !important;
	background-size: 25px 45px !important;
	right: 0 !important;
}
