/** @format */

@import '../../../../../../components/Commons/Sass/components/colors';

.ph-product-detail-mini-carousel-container {
	margin: 5px;
}

.ph-product-detail-mini-carousel-title {
	color: $primaryColor;
	font-weight: 600;
	font-size: 20px;
	text-align: left;
	padding-top: 20px;
	padding-left: 25px;
	padding-right: 25px;
	margin-bottom: 10px;
}

.ph-product-detail-main-image-global-container {
	background-color: $primaryBackgroundColor;
}

.ph-product-detail-rational-hover-container {
	cursor: pointer;
}

.ph-product-detail-img-bolsa-not-stock-content {
	position: relative;
	width: 100%;
	padding-left: 10px;
}

.ph-product-detail-type-recepit-main-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.ph-product-detail-img-bolsa-not-stock-content-p {
	position: absolute;
	margin-top: 10px;
	margin-left: 23px;
}

.ph-product-detail-img-bolsa-not-stock-content-p h4 {
	margin-top: -2px;
	margin-left: 10px;
	color: $colorGrayText3;
}

.ph-product-detail-img-bolsa-not-stock {
	width: 100%;
	max-width: 100%;
	filter: grayscale(1);
	transition-property: filter;
	transition-duration: 1s;
	opacity: 0.5;
}

.ph-product-detail-info-disclaimer {
	text-align: left;
	letter-spacing: -0.42px;
	color: #7b7b7b;
	opacity: 1;
	font-size: 13px;
	margin-top: 10px;
}

.ph-product-detail-simple-tabs-main-container {
	box-shadow: $primaryBoxShadow;
	border-radius: 5px;
	width: 100%;

	& > .ph-detail-simple-infotabs-container {
		max-height: fit-content;
	}
}

.ph-product-detailpharmacy-title-container {
	&:nth-child(1) > div:first-child {
		display: none;
	}

	&:nth-child(2) > div:first-child {
		display: block;
	}
}

.ph-product-detailpharmacy-subtitle-container {
	display: none;
}

.ph-product-detail-detailpharmacy-container {
	display: block;
}

@media (max-width: 1024px) {
	.ph-product-detail-simple-tabs-main-container {
		margin-left: 0px;
		width: 100%;
	}
}

@media (max-width: 991px) {
	.ph-product-detailpharmacy-title-container {
		&:nth-child(1) > div:first-child {
			display: block;
		}

		&:nth-child(2) > div:first-child {
			display: none;
		}
	}

	.ph-product-detailpharmacy-type-recepit {
		display: none;
	}

	.ph-product-detailpharmacy-contact-container {
		display: none;
	}

	.ph-product-detailpharmacy-subtitle-container {
		display: block;
		color: $primaryColor;
		margin: 10px 0;
		font-size: 16px;
		font-weight: 500;
		margin-left: 10px;
	}

	.ph-product-detail-detail-image-container {
		margin-bottom: 10px;
		box-shadow: $primaryBoxShadow;
		background-color: $primaryBackgroundColor;
	}
}
