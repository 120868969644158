@import '../../../v2-config/colors.scss';

@mixin date-picker-style {
	width: 100% !important;
	height: 40px !important;
	line-height: 37px !important;
	border-radius: 10px !important;
}
.date-picker-primary {
	@include date-picker-style;
	&:hover {
		border-color: $primary-hover;
	}

	&:active {
		border-color: $primary-active;
	}
}
