/** @format */

@import '../../../../../../../../components/Commons/Sass/components/colors';

.ph-product-detail-subscription-main-container {
	padding: 12px 0;
}

.ph-product-detail-subscription-container {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	margin-top: 10px;

	& > div {
		margin-bottom: 0 !important;
	}
}

.ph-product-detail-subscription-container .ant-form-item-explain-error,
.ph-product-detail-subscription-container .ant-form-item-explain-success {
	padding: 0 15px !important;
	font-size: 13px !important;
}

.ph-product-detail-subscription-field {
	box-shadow: $mainHeaderboxShadow !important;
	border-radius: 50px;
	height: 36px;
	padding: 6.5px 16px;
	border: none !important;
	border-color: transparent !important;
	font-size: 14px;
	width: 300px;
}

.ph-product-detail-subscription-field:focus {
	border: none !important;
	border-color: transparent !important;
	border-right-width: 0 !important;
}

.ph-product-detail-subscription-button,
.ph-product-detail-subscription-button:hover,
.ph-product-detail-subscription-button:focus,
.ph-product-detail-subscription-button:active {
	background-color: #ddd;
	border-color: $colorGrayText2;
	color: $colorGrayText2;
	margin: 0;
	font-size: 13px;
	border-radius: 100px;
	font-weight: 600;
	text-transform: uppercase;
	width: 100%;
	max-width: 130px;
	height: 36px;
	box-shadow: $mainHeaderboxShadow;
	margin: 0 15px;
}

.ph-product-detail-subscription-title {
	font-size: 13px;
	font-weight: 500;
	text-align: center;
	padding: 0 8%;
	color: $colorGrayText4;
	margin-bottom: 5px;
	margin-top: 10px;
}

@media (max-width: 1024px) {
	.ph-product-detail-subscription-main-container {
		margin: 0 0 10px 0;
	}
}

@media (max-width: 575px) {
	.ph-product-detail-subscription-container {
		display: block;
		text-align: center;
	}

	.ph-product-detail-subscription-field {
		width: 100%;
	}

	.ph-product-detail-subscription-title {
		margin-top: 10px;
	}
}
