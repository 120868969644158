@import '../../../v2-config/colors.scss';
.pagination-default {
	.ant-pagination-disabled,
	.ant-pagination-item-link,
	.ant-pagination-item,
	.ant-pagination-item-active {
		border-radius: 5px !important;
		color: $primary-default;
		border-color: $primary-default;
	}

	.ant-pagination-item-active a {
		color: $primary-default;
	}
}
