.book-content {
	width: 100%;
	max-width: 1185px;
	margin: 10px auto;
	padding: 10px 0px;
	.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
		min-width: 100px
	}
}

