/** @format */

@import '../../../../../../../../../../components/Commons/Sass/components/colors';

.ph-order-detail-products-total-price-container {
	display: flex;
	justify-content: center;
	align-items: center;
	border-left: 1px solid $primaryColorBorder;
	min-height: 45px;
	padding: 10px;
}

.ph-order-detail-products-total-price-title {
	font-weight: 500;
	color: $colorGrayText2;
	font-size: 14px;
	margin-right: 5px;
	margin-bottom: 0;
}

.ph-order-detail-products-total-price-description {
	font-weight: 600;
	color: $colorGrayText4;
	font-size: 14px;
	margin-bottom: 0;
}

.ph-order-detail-products-total-price-description-blue {
	color: $primaryColor !important;
}

.ph-order-detail-products-total-price-description-blue-weight {
	color: $primaryColor !important;
	font-weight: 900 !important;
}

.ph-order-detail-products-total-price-description-black {
	color: $colorBackText !important;
}

@media (max-width: 768px) {
	.ph-order-detail-products-total-price-container {
		border-left: none;
		padding: 0;
		min-height: auto;
	}

	.ph-order-detail-products-total-price-title {
		width: 50%;
		text-align: left;
	}

	.ph-order-detail-products-total-price-description {
		width: 50%;
		text-align: right;
	}
}
